<template>
  <div :class="viewType + '-container'">
    <!-- MODAL -->
    <ModalTips
      :modalTitle="tipsTitle"
      :showModal="showModal"
      :onClickCancel="hideModal"
    />

    <!-- AKS QUESTION FORM -->
    <div :class="viewType + '-content'">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          class="wrapper-wizard"
          v-if="!questionAsked"
        >
          <div
            key="title"
            :class="{
              'title is-3': viewType === 'page',
              'title is-3 has-text-centered no-margin-bottom': viewType === 'widget'
            }"
          >Looking for a solution? Ask the S2M network!</div>
          <div
            key="subtitle"
            class="has-margin-bottom"
            :class="{ 'has-text-centered': viewType === 'widget' }"
          >
            {{
            viewType === 'page'
            ? 'The S2M network involved a variety of experts. Ask your questions, chose tags that relate to it, and we will send it only to the people who can help you with them.'
            : `We're happy to help to match the right person to answer your question.`
            }}
          </div>
          <template v-if="viewType === 'widget'">
            <!-- WIDGET VIEW -->
            <transition
              name="slide-fade"
              mode="out-in"
              key="wizard"
            >
              <TextInput
                v-if="viewType === 'widget' && currentStep === 1"
                key="textInput"
                v-model="questionText"
                class="textarea is-borderless has-fixed-size"
                placeholder="My question is ..."
              />
              <TagInput
                v-if="viewType === 'widget' && currentStep === 2"
                key="tags"
                v-model="tagsInput"
                :text="questionText"
                :setFocusInput="true"
                whyText="To connect your question to the right people you must enter one or more keywords."
              />
            </transition>
          </template>
          <div
            v-else
            class="columns"
          >
            <div class="column">
              <!-- PAGE VIEW -->
              <div class="is-hidden-tablet has-margin-bottom">
                <a @click="() => { showModal = true; $googleAnalytics.pushEvent(`Page-${$route.name}`, 'link-AskQuestionTips', 'AskQuestion'); }">Give me tips about asking questions</a>
              </div>

              <TextInput
                v-model="questionText"
                class="textarea"
                placeholder="My question is ..."
              />
              <TagInput
                ref="tagInputComponent"
                v-model="tagsInput"
                :text="questionText"
                whyText="To connect your question to the right people you must enter <strong>one</strong> or <strong>more</strong> keywords."
              />
            </div>
          </div>

          <!-- CONTROLS -->
          <div
            v-if="!questionAsked"
            class="level"
          >
            <div class="level-left">
              <a
                v-if="viewType !== 'page'"
                @click="() => { showModal = true; $googleAnalytics.pushEvent(`Page-${$route.name}`, 'link-AskQuestionTips', 'AskQuestion'); }"
              >Give me tips about asking questions</a>
            </div>
            <div class="level-right has-text-right">
              <transition
                name="fade"
                mode="out-in"
              >
                <a
                  v-if="viewType !== 'page' && currentStep !== 1"
                  @click="updateStep(-1)"
                  class="is-link link-back"
                >
                  <span class="icon">
                    <i class="fas fa-arrow-left"></i>
                  </span>
                  <span>Back</span>
                </a>
              </transition>
              <transition
                name="fade"
                mode="out-in"
              >
                <button
                  class="button has-margin-left is-primary"
                  :disabled="disableButton"
                  @click="() => { currentStep !== 2 ? updateStep(1) : save() }"
                  :class="[!disableButton && !isSaving ? '' : 'is-disabled', {'is-loading' : isSaving}]"
                >
                  <span v-text="currentStep < 2 ? 'Next' : 'Post question'"></span>
                </button>
              </transition>
            </div>
          </div>
        </div>

        <div
          v-else
          key="confirm"
          class="has-text-centered"
        >
          <!-- CONFIRM VIEW -->
          <div class="title is-3">Thank you for posting your question!</div>
          <figure class="image is-inline-block is-centered is-128x128 has-margin-bottom">
            <img
              class="is-rounded has-background-success"
              src="@/assets/img/QuestionSend.gif"
              width="128"
              height="128"
            />
          </figure>
          <div class="has-margin-bottom has-text-weight-bold">Now explore other questions to see if you can help someone else?</div>

          <div class="has-margin-bottom">
            <router-link
              :to="{ name: 'Questions'}"
              @click.native="$googleAnalytics.pushEvent(`Page-${$route.name}`, 'link-ExploreQuestions', 'AskQuestion');"
              class="button is-primary"
            >Explore questions</router-link>
          </div>
          <a
            @click="startOver"
            class="has-text-weight-bold"
          >Ask a new question</a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import questionProvider from '@/providers/question'
import ModalTips from '@/components/question/ModalTips'
import TagInput from '@/components/question/TagInput'
import TextInput from '@/components/question/TextInput'
export default {
  props: {
    viewType: {
      type: String,
      default: 'page'
    },
    startCurrentStep: {
      type: Number,
      default: 1
    }
  },

  components: {
    ModalTips,
    TagInput,
    TextInput
  },

  data() {
    return {
      currentStep: this.startCurrentStep,
      isSaving: false,
      tipsTitle: 'Tips about asking questions',
      showModal: false,
      questionAsked: false,
      questionText: '',
      tagsInput: ''
    }
  },

  computed: {
    disableButton() {
      if (this.questionText.length <= 10) {
        return true
      } else if (this.currentStep === 2 && !this.tagsInput.length) {
        return true
      }
      return false
    }
  },

  methods: {
    /**
     * Hide tips modal
     */
    hideModal() {
      this.showModal = false
    },

    /**
     * Set view
     */
    updateStep(nextPrev) {
      this.currentStep = this.currentStep + nextPrev
    },

    /**
     * Ask a new question
     */
    startOver() {
      this.currentStep = 1
      this.tagsInput = ''
      this.questionText = ''
      this.questionAsked = false
    },

    /**
     * Save question
     */
    save() {
      let self = this

      if (!this.isSaving) {
        this.isSaving = true
        if (this.questionText !== '') {
          self.questionAsked = true
          questionProvider.methods
            .saveQuestion(
              this.$options.filters.stripHtml(this.questionText, true),
              this.tagsInput
            )
            .then(response => {
              self.$googleAnalytics.pushEvent(`Page-${self.$route.name}`, 'button-PostQuestion', 'AskQuestion')
              self.questionText = ''
              self.tagInput = ''
              self.tags = []
              self.currentStep = 3
            })
            .catch(e => { })
            .finally(() => {
              this.isSaving = false
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-container {
  .widget-content {
    > :not(.textarea),
    > :not(.button),
    a:not(.button),
    .title {
      color: white;
    }

    .is-borderless {
      border-color: transparent;
    }
  }
}

.page-container {
  .textarea {
    height: 300px;
  }
}
</style>
