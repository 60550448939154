<template>
  <div class="site-footer has-background-primary-dark  has-text-white">
    <div class="container padding_vertical is-fluid">
      <hr class="separator" />
      <div class="columns is-multiline">
        <div class="column is-two-fifths">
          <a
            href="https://www.facebook.com/Seats2meetcom"
            target="_blank"
            class="m-3"
          >
            <span class="icon is-large has-text-white">
              <font-awesome-icon
                :icon="['fab', 'facebook-f']"
                size="2x"
              />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/company/seats2meet.com"
            target="_blank"
            class="m-3"
          >
            <span class="icon is-large has-text-white">
              <font-awesome-icon
                :icon="['fab', 'linkedin-in']"
                size="2x"
              />
            </span>
          </a>
          <a
            href="https://twitter.com/s2m"
            target="_blank"
            class="m-3"
          >
            <span class="icon is-large has-text-white">
              <font-awesome-icon
                :icon="['fab', 'twitter']"
                size="2x"
              />
            </span>
          </a>
        </div>
        <div class="column is-unselectable">
          <div class="columns is-mobile is-multiline">
            <div class="column is-half-mobile is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <h6 class="title is-5 has-text-white">About us</h6>
              <ul>
                <li>
                  <a
                    class="has-text-white"
                    :href="`${s2m_base_url}/about`"
                    target="_blank"
                  >About Seats2meet</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    :href="`/Locations`"
                    target="_blank"
                  >Locations</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    href="https://magazine.seats2meet.com"
                    target="_blank"
                  >S2M Stories</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    :href="`${s2m_base_url}/${langPrefix}/contact`"
                    target="_blank"
                  >Contact S2M International</a>
                </li>
              </ul>
            </div>

            <div class="column is-half-mobile is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <h6 class="title is-5 has-text-white">What we do</h6>
              <ul>
                <li>
                  <a
                    class="has-text-white"
                    :href="now_base_url"
                    target="_blank"
                  >S2M Network</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    :href="`/events`"
                    target
                  >Event Calendar</a>
                </li>
              </ul>
            </div>

            <div class="column is-half-mobile is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <h6 class="title is-5 has-text-white">General</h6>
              <ul>
                <li>
                  <a
                    class="has-text-white"
                    :href="`${s2m_base_url}/${langPrefix}/privacy`"
                    target="_blank"
                  >Privacy Policy</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    :href="`${s2m_base_url}/${langPrefix}/terms`"
                    target="_blank"
                  >Terms</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    @click="setShowCookieSettingsModal(true)"
                    target
                  >Cookie settings</a>
                </li>
                <li>
                  <a
                    class="has-text-white"
                    href="https://gdpr.seats2meet.com"
                    target="_blank"
                  >GDPR compliant</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      now_base_url: process.env.VUE_APP_NOW_BASE_URL,
      s2m_base_url: process.env.VUE_APP_S2M_BASE_URL
    }
  },

  computed: {
    ...mapGetters({
      langPrefix: 'getCurrentLanguagePrefix'
    })
  },

  methods: {
    ...mapMutations(
      'cookieStore', ['setShowCookieSettingsModal']
    ),
  },
}
</script>
