const state = {
  chatHub: [],
  notificationHub: []
}
const getters = {
  getChatHub: state => state.chatHub,
  getNotificationHub: state => state.notificationHub
}
const mutations = {
  setChatHub: (state, newState) => {
    // state.chatHub = newState
  },
  setNotificationHub: (state, newState) => {
    state.notificationHub = newState
  }
}

const actions = {
  /**
   * Add new chat and join group
   */
  newChat: (context, chatId) => {
    // context.state.chatHub.server.joinGroup('Chat' + chatId)
  },

  /**
   * Join to notification group
   */
  joinNotificationGroup: context => {
    context.state.notificationHub.server.joinGroup(
      'Notification_' + context.getters.getProfile.Key
    )
  },

  /**
   * Join profile chat groupt
   */
  joinProfileChatGroup: (context, profileId) => {
    // context.state.chatHub.server.joinGroup('ProfileChat' + profileId)
  },

  /**
   * Send message
   */
  sendMessage: (context, data) => {
    // context.state.chatHub.server.sendMessage(
    //   data.chatId,
    //   context.getters.getProfileToken,
    //   data.message,
    //   ''
    // )
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}
