import Vue from 'vue'

/**
 * Declare Variable
 */
const _reservationPageState = {
  bottom: false,
  page: 1,
  itemsPerPage: 25,
  searchTerm: '',
  channelId: 0,
  locationId: 0,
  meetingtypeId: 0,
  showHistory: false,
  statusId: 2,
  startDate: new Date(),
  reachedEnd: false,
  reservations: [],
  endDate: new Date(new Date().getFullYear() + 5, 11, 31,0,0,0),
}

const state = {
  reservation: null,
  locationLanguages: null,
  locationOpeningHours: null,
  locationManager: null,
  reservationPageState: { ..._reservationPageState },
  busy: 0,
  reservationRestoreOptions: [],
  isOutdatedBrowser: false
}

const getters = {
  isBusy: (state) => state.busy > 0,
  isLocked: (state) => {
    if (
        state.reservation && (
        state.reservation.HasInvoice > 0 ||
        state.reservation.StatusId === 3 ||
        state.reservation.HoursUntilStart <= 24)
        || state.isOutdatedBrowser
      ) {
        return true
      }

      return false
  },
}

const mutations = {
  /**
   * Set reservation in store
   */
  setReservation: (state, newState) => {
    state.reservation = newState
  },

  setLocationOpeningHours(state, newState) {
    state.locationOpeningHours = newState
  },

  setLocationLanguages(state, newState) {
    state.locationLanguages = newState
  },

  setLocationManager(state, newState) {
    state.locationManager = newState
  },

  setReservationRestoreOptions(state, newState) {
    state.reservationRestoreOptions = newState
  },

  /**
   * Set reservation page state in store
   */
  setReservationsPageState: (state, newState) => {
    state.reservationsPageState = newState
  },

  resetReservationPageState: () => {
    state.reservationsPageState = { ..._reservationPageState }
  },

  /**
   * Keep track of the number of API interactions being processed
   */
  increaseBusyState: (state) => {
    state.busy = state.busy + 1
  },
  decreaseBusyState: (state) => {
    state.busy = Math.max(0, state.busy - 1)
  },

  updateIsOutdatedBrowserState(state, newState) {
    state.isOutdatedBrowser = newState
  }
}

const actions = {
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
