<template>
  <div class="padding has-text-centered">
    <img
      class="img-loader"
      :src="loaderUrl"
      alt="loader"
    >
  </div>
</template>

<script>
export default {
  props: {
    showSmall: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaderUrl: ''
    }
  },

  created() {
    this.loaderUrl = require(`@/assets/img/s2m-loader-${this.showSmall ? 'small' : 'big'}.gif`)
  },
}
</script>

<style lang="scss" scoped>
img {
  background: transparent;
}
</style>
