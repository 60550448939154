import Vue from 'vue'

Vue.filter('toCurrency', function(value, culture = 'nl-NL', currency = 'EUR') {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})

Vue.filter('roundToZero', (num) => {
  let self = this
  return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
})
    
Vue.filter('roundToTwo', (num) => {
  return +(Math.round(num + 'e+2') + 'e-2')
})