<template>
  <div
    ref="chatControl"
    class="chatMessages"
  >
    <div
      ref="chatMessagesWrapper"
      class="messages-wrapper"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          class="chatMessagesList has-padding"
          ref="chatMessagesList"
        >
          <ChatMessageItem
            v-for="message in Messages"
            :key="message.Id"
            :chatMessage="message"
            class="chatMessage"
          />
        </div>
      </transition>
    </div>
    <div
      ref="chatInputFooter"
      class="chat-input-footer has-padding has-background-white"
    >
      <textarea
        class="input"
        v-model="messageText"
        ref="inputMessageText"
        :maxlength="inputMessageMaxlength"
      />
      <a
        class="button-send button is-primary ml-4 is-align-items-center"
        :disabled="messageText.length === 0"
        :class="{'is-disabled': messageText.length === 0, 'is-loading': isSendingMessage}"
        @click="sendChatMessage"
      >
        <span class="icon is-align-items-center">
          <i class="font-icon s2m-icon-mail-send"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import chatProvider from '../providers/chat'
import ChatMessageItem from '@/components/partial/ChatMessageItem'

export default {
  props: {
    chat: {
      type: Object,
      default: null
    },
    selectedProfileId: {
      type: Number,
      default: 0
    },
    systemChatMessage: {
      type: String,
      default: ''
    }
  },

  components: {
    ChatMessageItem
  },

  data() {
    return {
      mChat: this.chat,
      messageText: this.systemChatMessage || '',
      hideMessages: true,
      isSendingMessage: false,
      currentChatInputFooterHeight: 0,
      chatControlHeight: 0,
      inputMessageMaxlength: 3000
    }
  },

  computed: {
    ...mapState('chatStore', ['chats']),
    ...mapGetters({
      profileToken: 'getProfileToken',
      selectedPublicProfile: 'getSelectedPublicProfile'
    }),
    Messages() {
      return this.chat ? this.chat.Messages : []
    }
  },

  watch: {
    Messages() {
      this.scrollDownToLastMessage()
    }
  },

  created() { },

  mounted() {
    this.getActiveChat()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    ...mapActions('chatStore', ['getProfileChats', 'updateExistingChat']),
    ...mapMutations('chatStore', ['setChats']),

    resizeHandler() {
      let self = this
      this.hideMessages = true
      self.chatControlHeight = self.$refs.chatControl.offsetHeight
      self.currentChatInputFooterHeight = 0
      self.calculateMessageListHeight()
    },

    /**
     * Calculate messages wrapper list height
     */
    calculateMessageListHeight() {
      let self = this

      this.currentChatInputFooterHeight = this.$refs.chatInputFooter.offsetHeight
      this.$refs.chatMessagesWrapper.style.cssText = `height: ${this.chatControlHeight - this.currentChatInputFooterHeight}px;`
      this.hideMessages = false
      let t = setTimeout(() => {
        self.scrollDownToLastMessage()
        clearTimeout(t)
      }, 200)
    },

    /**
     * Get active chat
     */
    getActiveChat() {
      if (!this.chat) {
        let activeChats = this.chats.filter(obj => {
          for (let i = 0, length = obj.Profiles.length; i < length; i++) {
            if (obj.Profiles[i].ProfileId === this.selectedPublicProfile) {
              return true
            }
          }
          return false
        })

        if (activeChats.length > 0) {
          this.chat = activeChats[0]
        }
      }

      this.scrollDownToLastMessage()
    },

    /**
     * Scroll down to last message
     */
    scrollDownToLastMessage() {
      if (typeof this.$refs.chatMessagesList === 'undefined') { return }
      let self = this
      let t = setTimeout(() => {
        self.$refs.chatMessagesList.scrollTop = 99999
        clearTimeout(t)
      }, 300)
    },

    /**
     * Send message
     */
    async sendChatMessage() {
      var self = this
      this.messageText = this.$options.filters.stripHtml(this.messageText, true)
      this.messageText = this.messageText.substring(0, this.inputMessageMaxlength)

      if (self.isSendingMessage || this.messageText === '') { return }
      self.isSendingMessage = true

      /**
       * Send new message to existing chat
       */
      if (this.chat !== null) {
        await chatProvider.methods.sendMessage(this.chat.Id, this.messageText).then(response => {
          this.updateExistingChat(response.data)
        })

        self.processResponse()
      } else {
        /**
         * Create new chat
         */
        await chatProvider.methods.createChat(this.selectedProfileId, this.messageText)
          .then(response => {
            let chats = self.chats
            let chatIndex = chats.findIndex(c => c.Id === response.data.Id)
            if (chatIndex > -1) {
              chats[chatIndex].LastActivity = new Date().getTime()
              chats[chatIndex].Messages = response.data.Messages

              chats = chats.sort(
                (a, b) =>
                  parseFloat(b.LastActivity) - parseFloat(a.LastActivity)
              )
            } else {
              chats.push(response.data)
            }
            self.setChats(chats)
          })
          .catch(e => { })
          .then(() => {
            self.processResponse()
          })
      }
    },

    processResponse() {
      this.messageText = ''
      this.isSendingMessage = false
    }
  }
}
</script>

<style lang="scss" scoped>
.chatMessages {
  $inputHeight: 130px;
  position: relative;
  padding-bottom: 130px;
  height: 100%;
  overflow: hidden;

  .messages-wrapper {
    height: 100%;

    .chatMessagesList {
      overflow: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }

  .chat-input-footer {
    height: $inputHeight;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    textarea {
      height: 100%;
    }

    .button-send {
      width: 60px;
    }
  }
}
</style>
