var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"card-location has-framework-roundness",staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.gotoLocation(_vm.locationObj.Id)}}},[_vm._m(0),_c('img',{style:({
        backgroundImage: `url(${_vm.$options.filters.getLocationImage(
          _vm.locationObj.Image,
          _vm.locationObj.Id,
          320
        )})`
      }),attrs:{"src":require("@/assets/img/spacer5_3.gif"),"alt":_vm.locationObj.Name,"width":"320","height":"240"}}),_c('div',{staticClass:"card-content padding"},[_c('p',{staticClass:"has-text-centered title is-5 has-text-blue-dark locationTitle"},[_vm._v(" "+_vm._s(_vm.locationObj.Name)+" ")]),_c('p',{staticClass:"has-text-centered"},[_vm._v(" "+_vm._s(_vm.locationObj.City)+" "),(_vm.showCheckInDistance)?[_c('br'),_c('span',{staticClass:"is-size-7"},[_vm._v("( "),_c('i',{staticClass:"fas fa-map-marker-alt"}),_vm._v(" "+_vm._s(_vm.locationObj.Distance.toFixed(1))+" km.)")])]:_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hover-overlay"},[_c('div',{staticClass:"button-bar has-text-centered"},[_c('button',{staticClass:"button is-outlined is-inverted is-yellow"},[_vm._v(" See details ")])])])
}]

export { render, staticRenderFns }