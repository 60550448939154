<template>
  <div>
    <DrawerSiteMenu
      v-if="showMenuDrawer"
      :showDrawer="showMenuDrawer"
      :closeFunc="hideSiteMenuDrawer"
    />

    <DrawerProfileNav
      v-if="showProfileDrawer"
      :showDrawer="showProfileDrawer"
      :closeFunc="hideProfileMenuDrawer"
    />

    <!-- STAGING MESSAGE BAR -->
    <article
      v-if="isStaging"
      style="background: #ff6600; color: white;"
    >
      <div class="has-padding has-text-centered">
        <span class="is-size-6-mobile is-size-2-desktop has-text-white">
          You are now viewing our test site. Please go to
          <a
            href="https://passport.seats2meet.com"
            class="has-text-white"
          >https://passport.seats2meet.com</a>
        </span>
      </div>
    </article>

    <!-- MENU -->
    <nav
      class="navbar-custom is-unselectable"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <div class="is-hidden-tablet navbar-item">
          <a
            v-if="isLoggedIn"
            role="button"
            aria-label="menu"
            aria-expanded="false"
            @click="showSiteMenuDrawer"
            class="box box-bar p-2 icon-text is-shadowless mr-4"
          >
            <span class="icon">
              <font-awesome-icon
                :icon="['fas', 'bars']"
                size="1x"
              />
            </span>
            <span class="is-size-7">Menu</span>
          </a>
        </div>
        <a
          class="navbar-link is-inline-flex is-align-items-center pl-0 is-arrowless"
          href="/"
        >
          <img
            src="@/assets/img/logo.svg"
            alt="Seats2meet.com"
            class="logo"
          />
          <span class="brandLabel is-size-6-mobile">Passport</span>
        </a>
      </div>

      <div class="navbar-custom-menu">
        <div
          v-if="!isLoggedIn"
          class="navbar-end"
        >
          <div class="navbar-item is-hidden-touch">Connect</div>
          <div class="navbar-item is-hidden-touch">Collaborate</div>
          <div class="navbar-item is-hidden-touch">Grow</div>
          <div class="navbar-item is-hidden-touch">
            <LinkItem
              :item="{ text: 'Seats2meet.com', externalUrl: s2m_base_url}"
              class="navbar-link is-arrowless"
              @click.native="() => { $googleAnalytics.pushEvent('SiteTopMenu', 'MainMenu', 'link-Seats2meet.com'); }"
            />
          </div>
        </div>
        <div
          v-if="isLoggedIn"
          class="navbar-end"
        >
          <template v-for="(menuItem, index) in siteMenu">
            <div
              v-if="!menuItem.menu.length"
              :key="'m_' + index"
              class="navbar-item is-hidden-touch"
            >
              <LinkItem
                :item="menuItem"
                class="navbar-link is-arrowless"
                @click.native="() => { $googleAnalytics.pushEvent('SiteTopMenu', menuItem.gaAction, menuItem.gaLabel); hideDrowdown(menuItem); }"
              />
            </div>
            <div
              v-else
              :key="'ms_' + index"
              class="navbar-item is-hidden-touch"
            >
              <MenuItemDropdown :menuItem="menuItem" />
            </div>
          </template>

          <div class="navbar-item">
            <ProfileMenuItem position="is-right" />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DrawerSiteMenu from './DrawerSiteMenu'
import MenuItemDropdown from './MenuItemDropdown'
import DrawerProfileNav from './DrawerProfileNav'
// import ProfileNav from './ProfileNav'
import LinkItem from '@/components/UI/LinkItem'
import ProfileMenuItem from './ProfileMenuItem'

export default {
  components: {
    DrawerSiteMenu,
    DrawerProfileNav,
    // ProfileNav,
    LinkItem,
    MenuItemDropdown,
    ProfileMenuItem
  },

  data() {
    return {
      passport_base_url: process.env.VUE_APP_BASE_URL,
      s2m_base_url: process.env.VUE_APP_S2M_BASE_URL,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      leftDrawerIsActive: false,
      isStaging: false,
      menuTimerAction: null,
      disableMenuActions: false,
      showMenuDrawer: false,
      showProfileDrawer: false,
      screenWidth: 0,
      screenListener: null,
      blockShownMenu: false,
      siteMenu: this.$store.getters['menuStore/getSiteMenu']
    }
  },

  computed: {
    ...mapGetters({
      drawerIsActive: 'getDrawerIsActiveState',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      notifications: 'getNotifications',
      profile: 'getProfile',
      passportState: 'getPassportState',
      langPrefix: 'getCurrentLanguagePrefix'
    }),

    numberOfMessages() {
      let totalNewMessages = 0
      if (this.notifications.length) {
        totalNewMessages += this.notifications.filter(n => n.IsRead === false)
          .length
      }
      return totalNewMessages
    },

    showProfileNavTouch() {
      return this.screenWidth < 1019
    }
  },

  created() {
    this.isStaging =
      this.passport_base_url.indexOf('-staging.seats2meet') > -1 ||
      document.location.href.indexOf('localhost:808') > -1
    this.setActiveMenu()

    // Monitor screen resize
    window.addEventListener('resize', this.handleScreenResize)
    this.handleScreenResize()
  },

  destroyed() {
    // Destroy listerners
    window.removeEventListener('resize', this.handleScreenResize)
  },

  watch: {
    '$route.name': function(routeName) {
      this.siteMenu.map(m => {
        m.hasSelectedMenu = false
        m.isHover = false
      })

      this.setActiveMenu()
    }
  },

  methods: {
    handleScreenResize() {
      this.screenWidth = window.innerWidth
    },

    setActiveMenu() {
      this.siteMenu.forEach((menu, index) => {
        if (menu.menu.length) {
          let submenuIndex = menu.menu.findIndex(
            sm => sm.routeName === this.$route.name
          )
          if (submenuIndex !== -1) {
            this.siteMenu[index].hasSelectedMenu = true
          }
        }
      })
    },

    /**
     * Site drawer control
     */
    showSiteMenuDrawer() {
      this.showMenuDrawer = true
    },

    hideSiteMenuDrawer() {
      this.showMenuDrawer = false
    },

    /**
     * Profile drawer control
     */
    showProfileMenuDrawer() {
      this.showProfileDrawer = true
    },

    hideProfileMenuDrawer() {
      this.showProfileDrawer = false
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-custom {
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  font-size: 16px;

  .navbar-item {
    font-weight: 400;

    .box-bar {
      border: 1px solid $grey;

      ::v-deep .dropdown-menu {
        .icon {
          background-color: $primary-dark;
          color: white;
          border-radius: 50%;
          svg {
            width: 8px;
          }
        }
      }
    }
  }

  .navbar-brand {
    a.navbar-link {
      background-color: transparent !important;
      &:hover,
      &:active {
        background-color: transparent !important;
      }
    }
    img {
      height: 7rem;
      @media screen and (max-width: 1023px) {
        height: 5rem;
      }
    }
  }

  .navbar-brand {
    .brandLabel {
      margin-left: 5px;
      color: $primary-dark;
      text-transform: uppercase;
      font-size: 22px;
      font-weight: $weight-bold;
    }
  }

  .navbar-custom-menu {
    flex-grow: 1;
    flex-shrink: 0;
    align-items: stretch;
    display: flex;

    .navbar-end {
      align-items: stretch;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      justify-content: space-between;

      .navbar-item {
        align-items: center;
        display: flex;

        .navbar-link {
          cursor: pointer;
          border-radius: 13px;
          display: inline-flex;
          justify-content: center;
          padding-bottom: 0.5em;
          padding-left: 1em;
          padding-right: 1em;
          padding-top: 0.5em;
          text-align: center;
          white-space: nowrap;
          text-decoration: none;
          color: $body-color;
          transition: 0.3s;

          &.is-active,
          &:hover {
            color: $primary;
            background-color: $primary-light;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
