<template>
  <div class="timePickerWrapper">
    <div class="field">
      <input
        class="slider is-medium is-circle is-fullwidth"
        step="15"
        v-bind:min="minTime"
        v-bind:max="maxTime"
        v-model="mEndTime"
        @input="fireUpdateEndTime"
        type="range"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    minTime: { type: Number, required: false, default: 0 },
    maxTime: { type: Number, required: false, default: 0 },
    startTime: { type: Number, required: false, default: 0 },
    endTime: { type: Number, required: false, default: 0 }
  },
  data() {
    return {
      mEndTime: this.endTime
    }
  },
  computed: {},
  methods: {
    fireUpdateEndTime(event) {
      this.$emit('updateEndTime', event.target.value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/template.scss';

$thumbBgColor: $green;

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 16px; /* Specified height */
  background: $grey-light; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 1; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 5px;

  /* Mouse-over effects */
  &:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }

  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    background-color: $green;
    border: 0px solid $grey-light;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
