<template>
  <div>
    <div
      v-if="viewType === 'compact'"
      class=""
    >
      <div class="title is-3 has-text-centered has-text-blue-dark has-text-centered">Your data, you are in control</div>
      <div class="columns is-multiline compact-profile-stats">
        <div class="column is-full-touch is-two-thirds-tablet">
          <div class="columns is-gapless is-multiline">
            <div class="column is-half stat stats-checkin">
              <div class="stat-content padding has-text-white has-background-primary has-text-centered">
                <a
                  class="is-tooltip-info tooltip"
                  data-tooltip="Here is the total of how many times you checked in."
                >
                  <i class="font-icon s2m-icon-information-small-circle-outline has-text-white"></i>
                </a>
                <span class="title is-2 has-text-white">{{ profileStats.NrOfCheckins }}</span>
                <span class="has-text-white">Check-ins</span><span class=""></span>
              </div>
            </div>
            <div class="column is-half stat stats-question">
              <div class="stat-content padding has-text-white has-background-success has-text-centered">
                <a
                  class="is-tooltip-info tooltip is-tooltip-multiline"
                  data-tooltip="These are the questions you asked in the Seats2meet ecosystem."
                >
                  <i class="font-icon s2m-icon-information-small-circle-outline has-text-white"></i>
                </a>
                <router-link
                  @click.native="$googleAnalytics.pushEvent(routeName, 'button-QuestionsAsked', 'ProfileStats')"
                  :to="{ name: 'QuestionsAsked' }"
                >
                  <span class="title is-2 has-text-white">{{ profileStats.NrOfQuestions }}</span>
                  <span class="has-text-white">Questions asked</span>
                </router-link>
              </div>
            </div>
            <div class="column is-half stat stats-answers">
              <div class="stat-content padding has-text-white has-background-primary-dark has-text-centered">
                <a
                  class="is-tooltip-info tooltip"
                  data-tooltip="These are the answers you gave."
                >
                  <i class="font-icon s2m-icon-information-small-circle-outline has-text-white"></i>
                </a>
                <router-link
                  @click.native="$googleAnalytics.pushEvent(routeName, 'button-AnswersGiven', 'ProfileStats')"
                  :to="{ name: 'QuestionsAnswered'}"
                >
                  <span class="title is-2 has-text-white">{{ profileStats.NrOfAnswers }}</span>
                  <span class="has-text-white">Answers given</span>
                </router-link>
              </div>
            </div>
            <div class="column is-half stat stats-matched">
              <div class="stat-content padding has-text-white has-background-grey has-text-centered">
                <a
                  class="is-tooltip-info tooltip"
                  data-tooltip="The number of times you appeared as a match."
                >
                  <i class="font-icon s2m-icon-information-small-circle-outline has-text-white"></i>
                </a>
                <router-link
                  @click.native="$googleAnalytics.pushEvent(routeName, 'button-MatchedWith', 'ProfileStats')"
                  to="/matchedwith"
                >
                  <span class="title is-2 has-text-white">{{ profileStats.NrOfTimesMatched }}</span>
                  <span class="has-text-white">Times matched</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-full-touch is-one-third-tablet">
          <div class="content has-padding-left">
            <p>
              This passport transforms your personal data into social capital that enables you to connect, collaborate and grow. Read all about our high standards for data protection in our GDPR section.
            </p>
            <p class="buttons is-centered">
              <a
                @click="$googleAnalytics.pushEvent(routeName, 'button-GDPR', 'ProfileStats')"
                class="button is-primary"
                href="https://gdpr.seats2meet.com"
                target="_blank"
              >Read more about GDPR</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="viewType === 'full'"
      class="profileStatsContainer"
    >
      <div class="title is-3 is-marginless">Your data, you are in control</div>
      <p class="has-margin-bottom-x2">Check <a
          href="https://gdpr.seats2meet.com"
          target="_blank"
        >here</a> how we protect your personal data</p>
      <div
        v-if="profileStats"
        id="profileStats"
        class="columns"
      >
        <div class="column is-4">
          <div class="box">
            <div class="has-margin-bottom">
              <strong class="has-text-grey">Check-ins</strong>
              <a
                class="is-tooltip-info tooltip is-tooltip-multiline"
                data-tooltip="Here is the total of how many times you checked in. We split it into locations, virtual and events."
              >
                <i class="fas fa-info-circle"></i>
              </a>
              <p class="title is-1 has-text-primary">{{ profileStats.NrOfCheckins }}</p>
            </div>

            <div class="has-margin-bottom">
              <strong class="is-5 has-text-grey">Virtual</strong>
              <a
                class="is-tooltip-info tooltip is-tooltip-multiline"
                data-tooltip="A virtual check-in means you were not physically present at a Seats2mee location yet you are linked."
              >
                <i class="fas fa-info-circle"></i>
              </a>
              <p class="title is-3 has-text-grey">{{ profileStats.NrOfCheckinsNetwork }}</p>
            </div>

            <div class="has-margin-bottom">
              <strong class="is-5 has-text-grey">Location</strong>
              <a
                class="is-tooltip-info tooltip is-tooltip-multiline"
                data-tooltip="A check-in at a location means you were physically present at a Seats2mee location."
              >
                <i class="fas fa-info-circle"></i>
              </a>
              <p class="title is-3 has-text-grey">{{ profileStats.NrOfCheckinsLocation }}</p>
            </div>

            <div>
              <strong class="is-5 has-text-grey">Event</strong>
              <a
                class="is-tooltip-info tooltip is-tooltip-multiline"
                data-tooltip="A check-in at an event means you were present at the event."
              >
                <i class="fas fa-info-circle"></i>
              </a>
              <p class="title is-3 has-text-grey">{{ profileStats.NrOfCheckinsEvent }}</p>
            </div>
          </div>
        </div>
        <div class="column is-8">
          <div class="box">
            <div class="columns">
              <router-link
                @click.native="$googleAnalytics.pushEvent(routeName, 'button-QuestionsAsked', 'ProfileStats')"
                :to="{ name: 'QuestionsAsked' }"
                class="column"
              >
                <strong class="has-text-grey">Questions</strong>
                <a
                  class="is-tooltip-info tooltip is-tooltip-multiline"
                  data-tooltip="These are the questions you asked in the Seats2meet ecosystem."
                >
                  <i class="fas fa-info-circle"></i>
                </a>
                <p class="title is-1 has-text-primary">{{ profileStats.NrOfQuestions }}</p>
              </router-link>

              <router-link
                @click.native="$googleAnalytics.pushEvent(routeName, 'button-AnswersGiven', 'ProfileStats')"
                :to="{ name: 'QuestionsAnswered' }"
                class="column"
              >
                <strong class="has-text-grey">Answers</strong>
                <a
                  class="is-tooltip-info tooltip"
                  data-tooltip="These are the answers you gave."
                >
                  <i class="fas fa-info-circle"></i>
                </a>
                <p class="title is-1 has-text-primary">{{ profileStats.NrOfAnswers }}</p>
              </router-link>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <router-link
                v-if="profileStats.NrOfTimesMatched > 0"
                @click.native="$googleAnalytics.pushEvent(routeName, 'button-MatchedWith', 'ProfileStats')"
                to="/matchedwith"
                class="column"
              >
                <strong class="has-text-grey">Shown as match</strong>
                <a
                  class="is-tooltip-info tooltip is-tooltip-multiline"
                  data-tooltip="The number of times you appeared as a match."
                >
                  <i class="fas fa-info-circle"></i>
                </a>
                <p class="title is-1 has-text-primary">{{ profileStats.NrOfTimesMatched }}</p>
              </router-link>

              <router-link
                @click.native="$googleAnalytics.pushEvent(routeName, 'button-Reviews', 'ProfileStats')"
                to="/reviews"
                class="column"
              >
                <strong class="has-text-grey">Reviews</strong>
                <a
                  class="is-tooltip-info tooltip"
                  data-tooltip="These are the reviews you gave."
                >
                  <i class="fas fa-info-circle"></i>
                </a>
                <p class="title is-1 has-text-success">{{ profileStats.NrOfReviews }}</p>
              </router-link>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div
                  @click="() => {goToReservations(2, 2); $googleAnalytics.pushEvent(routeName, 'button-WorkspaceBookings-Final', 'ProfileStats'); }"
                  class="has-margin-bottom"
                >
                  <strong class="has-text-grey">Workspace reservations</strong>
                  <a
                    class="is-tooltip-info tooltip is-tooltip-multiline"
                    data-tooltip="These are all your bookings (canceled as well as confirmed ones)."
                  >
                    <i class="fas fa-info-circle"></i>
                  </a>
                  <p class="title is-1 has-text-success">{{ profileStats.NrOfWsReservations }}</p>
                </div>

                <div @click="() => {goToReservations(2, 2); $googleAnalytics.pushEvent(routeName, 'button-WorkspaceBookings-Final', 'ProfileStats'); }">
                  <strong class="is-5 has-text-grey">Final</strong>
                  <a
                    class="is-tooltip-info tooltip"
                    data-tooltip="Bookings you made."
                  >
                    <i class="fas fa-info-circle"></i>
                  </a>
                  <p class="title is-3 has-text-grey">{{ profileStats.NrOfWsReservationsFinal }}</p>
                </div>

                <div
                  @click="() => {goToReservations(2, 3); $googleAnalytics.pushEvent(routeName, 'button-WorkspaceBookings-Cancelled', 'ProfileStats'); }"
                  class="has-margin-bottom"
                >
                  <strong class="is-5 has-text-grey">Cancelled</strong>
                  <a
                    class="is-tooltip-info tooltip"
                    data-tooltip="Bookings you canceled."
                  >
                    <i class="fas fa-info-circle"></i>
                  </a>
                  <p class="title is-3 has-text-grey">{{ profileStats.NrOfWsReservationsCancelled }}</p>
                </div>
              </div>

              <div class="column">
                <div
                  @click="() => {goToReservations(1, 2); $googleAnalytics.pushEvent(routeName, 'button-MeetingspaceBookings-Final', 'ProfileStats'); }"
                  class="has-margin-bottom"
                >
                  <strong class="has-text-grey">Meetingspace reservations</strong>
                  <p class="title is-1 has-text-link">{{ profileStats.NrOfMsReservations }}</p>
                </div>

                <div @click="() => {goToReservations(1, 2); $googleAnalytics.pushEvent(routeName, 'button-MeetingspaceBookings-Final', 'ProfileStats'); }">
                  <strong class="is-5 has-text-grey">Final</strong>
                  <p class="title is-3 has-text-grey">{{ profileStats.NrOfMsReservationsFinal }}</p>
                </div>

                <div
                  @click="() => {goToReservations(1, 3); $googleAnalytics.pushEvent(routeName, 'button-MeetingspaceBookings-Cancelled', 'ProfileStats'); }"
                  class="has-margin-bottom"
                >
                  <strong class="is-5 has-text-grey">Cancelled</strong>
                  <p class="title is-3 has-text-grey">{{ profileStats.NrOfMsReservationsCancelled }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    viewType: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      routeName: `Page-${this.$route.name}`
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profileStats: 'getProfileStats'
    })
  },

  methods: {
    goToReservations(meetingtype, status) {
      this.$store.commit('setFilterReservationsMeetingtype', meetingtype)
      this.$store.commit('setFilterReservationsStatus', status)

      this.$router.push({ path: `/reservations` })
    }
  },

  mounted() { }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.compact-profile-stats {
  padding: 13px;
  .column {
    padding: 2px;
  }
  .stat-content {
    position: relative;
    padding-top: 3 * $gap;
    padding-bottom: 3 * $gap;
    border: 2px solid white;

    @media screen and (max-width: $desktop) {
      padding-top: 4 * $gap;
      padding-bottom: 4 * $gap;
    }

    @media screen and (max-width: $tablet) {
      padding-top: 2 * $gap;
      padding-bottom: 2 * $gap;
    }

    a.is-tooltip-info {
      right: 5px;
      position: absolute;
      top: 5px;
    }

    span.title {
      display: block;
      margin-bottom: 5px;
    }
  }
}

.profileStatsContainer {
  #profileStats {
    text-transform: uppercase;
    .subtitle {
      text-transform: uppercase;
    }
  }
}
</style>
