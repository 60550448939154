<template>
  <div class="wrapper-imageCropper">
    <div class="cropper-controls is-flex-grow-1 mr-3">
      <div class="has-margin-bottom">
        <div class="file is-primary mb-5">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              accept="image/jpg, image/jpeg"
              @change="onFileChanged"
              rel="fileInput"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">Choose photo</span>
            </span>
          </label>
        </div>

        <div
          v-if="previewReady && isValidImage && !wrongFileType"
          class="icon-text is-flex-wrap-nowrap mb-5"
        >
          <span class="icon is-medium has-text-blue">
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              size="lg"
            />
          </span>
          <span v-text="infoNotification"></span>
        </div>

        <!-- Zoom control -->
        <div
          v-if="showSlider"
          class="slider-field is-flex field mb-5"
        >
          <strong class="icon is-size-5">-</strong>
          <input
            class="slider is-medium is-circle is-fullwidth"
            step="0.1"
            v-bind:min="0.1"
            v-bind:max="0.7"
            type="range"
            :value="0.1"
            @input="cropperZoomTo"
          />
          <strong class="icon is-size-5">+</strong>
        </div>
        <div
          v-if="!isValidImage || wrongFileType"
          class="icon-text is-flex-wrap-nowrap has-text-danger"
        >
          <span class="icon is-medium">
            <font-awesome-icon
              :icon="['fa', 'exclamation-triangle']"
              size="lg"
            />
          </span>
          <span v-if="!wrongFileType">Image is too small. Choose another image larger then 400px.</span>
          <span v-if="wrongFileType">Chosen image type is not allowed! Image type has to be JPG or JPEG.</span>
        </div>
      </div>
    </div>
    <div class="img-container-wrapper">
      <div class="img-container">
        <img
          id="image"
          src="/img/placeholder_no_image.png"
          alt="source"
        />
      </div>
    </div>
    <!-- <div class="wrapper-previews">
            <div class="profilePreview"></div>
            <div class="profilePreview"></div>
            <div class="profilePreview"></div>
          </div> -->
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  name: 'ProfileImageCropper',

  data() {
    return {
      imageURL: '/img/placeholder_no_image.png',
      isValidImage: true,
      imgCropper: null,
      previewReady: false,
      isMinimalSizeAtLoad: false,
      allowedFileTypes: [
        'image/png',
        'image/jpg',
        'image/jpeg',
      ],
      wrongFileType: false,
      imageCheckedTimer: null
    }
  },

  computed: {
    showSlider() {
      return this.previewReady && !this.isMinimalSizeAtLoad
    },

    saveButtonDisabled() {
      return !this.isValidImage || !this.previewReady || this.wrongFileType
    },

    infoNotification() {
      return this.isMinimalSizeAtLoad ?
        'Drag the photo for the best result.'
        :
        'Drag and zoom the photo for the best result.'
    }
  },

  watch: {
    saveButtonDisabled: {
      handler: function(val) {
        let _this = this
        this.imageCheckedTimer && clearTimeout(this.imageCheckedTimer)
        this.imageCheckedTimer = setTimeout(() => {
          this.$emit('updateSaveButtonState', val)
          clearTimeout(_this.imageCheckedTimer)
        }, 25);
      }
    },
  },

  beforeDestroy() {
    this.imageURL && URL.revokeObjectURL(this.imageURL)
  },

  methods: {
    /**
     * Render image cropper
     */
    renderCropper(imageSource) {
      let _this = this
      const previews = document.querySelectorAll('.profilePreview');
      const image = document.getElementById('image');
      this.previewReady = false;
      image.src = imageSource

      // First destroy cropperjs instance when exists before creating instance 
      this.imgCropper && this.imgCropper.destroy();

      // Delete all childs in preview elements
      _this.each(previews, function(elem) {
        while (elem.firstChild) {
          elem.removeChild(elem.firstChild);
        }
      });

      // Create cropper instance
      this.imgCropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 3,
        dragMode: 'move',
        zoomOnWheel: false,
        zoomOnTouch: false,
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        autoCropArea: 1,
        ready() {
          // Occupy preview elements
          let clone = this.cloneNode();
          clone.className = '';
          clone.style.cssText = (
            'display: block;' +
            'width: 100%;' +
            'min-width: 0;' +
            'min-height: 0;' +
            'max-width: none;' +
            'max-height: none;'
          );

          _this.each(previews, function(elem) {
            elem.appendChild(clone.cloneNode());
          });
          let t = setTimeout(() => {
            _this.imgCropper.zoomTo(0.1)
            let CROPPER_DATA = this.cropper.getImageData()
            _this.isMinimalSizeAtLoad = CROPPER_DATA.naturalWidth <= 600 || CROPPER_DATA.naturalHeight <= 600
            _this.isValidImage = !(CROPPER_DATA.naturalWidth <= 350 || CROPPER_DATA.naturalHeight <= 350)
            clearTimeout(t)
          }, 0)
          _this.previewReady = true;
        },

        zoom(event) {
        },

        crop(event) {
          // When preview is ready update preview elemens when
          // crop data is updated.
          if (!_this.previewReady) {
            return;
          }

          var data = event.detail;
          var cropper = this.cropper;
          var imageData = cropper.getImageData();
          var previewAspectRatio = data.width / data.height;

          _this.each(previews, function(elem) {
            var previewImage = elem.getElementsByTagName('img').item(0);
            var previewWidth = elem.offsetWidth;
            var previewHeight = previewWidth / previewAspectRatio;
            var imageScaledRatio = data.width / previewWidth;

            elem.style.height = previewHeight + 'px';
            previewImage.style.width = imageData.naturalWidth / imageScaledRatio + 'px';
            previewImage.style.height = imageData.naturalHeight / imageScaledRatio + 'px';
            previewImage.style.marginLeft = -data.x / imageScaledRatio + 'px';
            previewImage.style.marginTop = -data.y / imageScaledRatio + 'px';
          });
        },
      });
    },

    /**
     * Loop method with used to indentify the preview containers
     */
    each(arr, callback) {
      var length = arr.length;
      var i;

      for (i = 0; i < length; i++) {
        callback.call(arr, arr[i], i, arr);
      }

      return arr;
    },

    /**
     * Get cropped image base64 data
     */
    getCroppedCanvas() {
      return this.imgCropper.getCroppedCanvas({
        width: 1024,
        height: 1024,
        minWidth: 300,
        minHeight: 300,
        maxWidth: 1024,
        maxHeight: 1024,
        fillColor: '#fff',
        imageSmoothingEnabled: false,
        imageSmoothingQuality: 'high',
      }).toDataURL('image/jpeg')
    },

    /**
     * Rerender image cropper with new chosen image
     */
    onFileChanged(event) {
      const _this = this
      const file = event.target.files[0]

      // Check if file is a image
      this.wrongFileType = false
      if (this.allowedFileTypes.indexOf(event.target.files[0].type.toLowerCase()) === -1) {
        this.wrongFileType = true
      }

      const image = new Image()

      // Clear old blob
      this.imageURL && URL.revokeObjectURL(this.imageURL)
      this.imageURL = URL.createObjectURL(file)
      image.src = this.imageURL
      image.onload = () => {
        let resizedImage = _this.resizeImage(image)

        if (!resizedImage) {
          // No resize needed
          resizedImage = image.src
        }
        else {
          // Source image is resized
          resizedImage = resizedImage
        }

        let t = setTimeout(function() {
          _this.renderCropper(resizedImage);
          resizedImage = ''
          clearTimeout(t)
        }, 10)
      }
    },

    /**
     * Emit cropped image and close modal
     */
    getNewPhoto() {
      // Clear image in memory
      this.imageURL && URL.revokeObjectURL(this.imageURL)

      return this.getCroppedCanvas()
    },

    /**
     * Cropper controls
     */
    cropperZoomTo(evt) {
      const CONTAINER_DATA = this.imgCropper.getCropBoxData()

      if (evt.target.value > 0.6) { return }

      this.imgCropper.zoomTo(evt.target.value, {
        x: CONTAINER_DATA.width / 2,
        y: CONTAINER_DATA.height / 2,
      })
    },

    /**
     * Resize original image if needed
     */
    resizeImage(img) {
      const MAX_WITH = 1920
      const MAX_HEIGHT = 1920
      let isResized = false
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > MAX_WITH) {
          //height *= max_width / width;
          height = Math.round(height *= MAX_WITH / width);
          width = MAX_WITH;

          isResized = true
        }
      } else {
        if (height > MAX_HEIGHT) {
          //width *= max_height / height;
          width = Math.round(width *= MAX_HEIGHT / height);
          height = MAX_HEIGHT;

          isResized = true
        }
      }

      if (isResized) {
        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg", 1);
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
$cropperSize: 200px;

.wrapper-imageCropper {
  display: flex;

  @media screen and (max-width: 400px) {
    .file-icon {
      display: none;
    }
    .img-container-wrapper {
      flex-shrink: 1;
      transform: scale(0.8);
    }
  }

  .img-container {
    display: inline-flex;
    align-items: center;
    background: #ddd;
    @include rounded(50%);
    @include box-shadow();
    overflow: hidden;
    flex-shrink: 0;
    width: $cropperSize;
    height: $cropperSize;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .slider {
    width: 100%;
  }

  .wrapper-previews {
    display: flex;
    flex-grow: 1;
    .profilePreview {
      @include rounded(50%);
      @include box-shadow();
      margin-right: 15px;
      overflow: hidden;
      background-color: $grey-light;
      &:nth-child(1) {
        width: 120px;
        height: 120px;
      }
      &:nth-child(2) {
        width: 84px;
        height: 84px;
      }
      &:nth-child(3) {
        display: none;
        width: 38px;
        height: 38px;
      }
    }
  }
}
</style>