<script>
import { v4 as uuidV4 } from 'uuid'

import { required } from 'vuelidate/lib/validators'

/**
 * This Input Field component provides a solid basis for automatically validated
 * input fields through configuration via props. It leverages Vuelidate for the
 * validation process.
 *
 * This Input Field component provides a few features to automate input field
 * functionality and limit configuration requirements.
 *
 * One of the core functions is the validation mechanism, which relies on
 * Vuelidate. This mechanism is triggered automatically when the field value
 * changes, after the user has deselected the field for the first time.
 *
 * By registering the Input Field component at the Form component, it is also
 * validated when the form is being processed, or during Form Page navigation.
 *
 * Through it's connection with the Form component, this component also reacts
 * to changes in the Form state, such as when it is being processed.
 *
 * By leveraging an internal fieldValue property, while maintaining a `value`
 * prop and passing on the `input` event, setting a `v-model` on this component
 * has become optional. The value of the input is gathered by the Form
 * component in any case, and made available to the implementing component
 * via a callback during the submit process, which determines what is done with
 * the data (like sending it to an API, or passing it to the Vuex store, etc).
 *
 */
export default {
  name: 'BaseFormInputField',
  // Inherited from Tabs parent component
  inject: {
    getFormStatus: {
      type: Array,
      default: null
    },
    registerInputField: {
      type: Function,
      default: null
    },
    unregisterInputField: {
      type: Function,
      default: null
    },
  },

  props: {
    name: {
      type: String,
      default: String(new Date().getTime()),
      required: false,
      note: 'The input data name'
    },
    label: {
      type: String,
      default: '',
      note:
        'The field label and/or instruction text. Strongly recommended, but optional.'
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    placeolder: {
      type: String,
      default: '',
      note:
        'The field placeholder and/or instruction text. Strongly recommended, but optional.'
    },
    id: {
      type: String,
      default: '',
      note:
        'The id used to connect the input and label elements. Random if not supplied.'
    },
    inputSizeClass: {
      type: String,
      default: '',
      note: 'Bulma class name size. is-small, is-medium, is-large. By leaving this empty the size will be set to normal.'
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
      note:
        'Value of the input field. Usually set through `v-model`. Not required!'
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Whether the input field is disabled'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isStatic: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false,
      note: 'Whether the input field is required. Overrides validationRules.'
    },

    validateOnInput: {
      type: Boolean,
      default: false,
      note: 'Validate input on earch key press.'
    },

    showAllOpenErrors: {
      type: Boolean,
      default: false,
      note: 'When true all open errors will shown'
    },

    validationRules: {
      type: Object,
      default: () => {
        return {}
      },
      note: 'An object with Vuelidate validation rules'
    },

    errorMessages: {
      type: Object,
      default: () => {
        return {}
      },
      note:
        'An object with error messages. The keys should be the same as the Vuelidate validation rules.'
    },
    error: {
      type: [String, Array],
      default: ''
    }
  },
  data() {
    return {
      /**
       * This variable contains the visible error message
       */
      errorMessage: '',
      /**
       * Whether the input field is currently used by the visitor
       */
      focused: false,
      /**
       * A unique label/input id, in case none was supplied
       */
      uuid: null,
      /**
       * This is the internal input value. This internal value makes a v-model
       * binding to this component optional.
       */
      fieldValue: '',
      /**
       * Whether to validate on a field value change.
       * This is activated after the first `blur` event
       */
      validateOnChange: false,
      /**
       * Whether the input has been validated at least once
       */
      validated: false,
      /**
       * Default validation error messages
       */
      defaultErrorMessages: {
        required: this.$t('Form.InputErrors.Required'),
        email: this.$t('Form.InputErrors.InvalidEmail')
      }
    }
  },
  computed: {
    hasErrors() {
      return this.errorMessage.length
    },

    /**
     * Either use the supplied id, or generate a unique one.
     */
    labelId() {
      return this.id === '' ? this.uniqueId() : this.id
    },
    /**
     * It is recommended to bind the <label> element through $ref, to ensure
     * the label and input id are identical.
     */
    inputId() {
      return this.$refs.label ? this.$refs.label.for : this.labelId
    },
    /**
     * A field can be disabled manually through the property
     * and it is also automatically disabled when the form is being submitted
     *
     * @return {boolean}
     */
    disabledStatus() {
      if (!this.getFormStatus) {
        return
      }
      return this.disabled || this.getFormStatus[0] !== 'READY'
    },
    /**
     * Whether the field is a required field
     * This may be used to put a * behind the label
     */
    isRequired() {
      return !!this.required
    },
    /**
     * List of classes set depending on state
     */
    fieldClassList() {
      return {
        'Field--disabled': this.disabledStatus,
        'Field--error': this.hasPassedValidation,
        'Field--focused': this.focused,
        'Field--required': this.isRequired
      }
    },
    /**
     * Indicate whether validation has run at least once, and is currently passed
     */
    hasPassedValidation() {
      return this.validated && this.isValid()
    }
  },
  /**
   * Vuelidate validation rules. Set through the validationRules prop. Modified by required prop
   */
  validations() {
    let rules = this.validationRules || {}

    if (this.required) {
      rules.required = required
    } else if (rules.required) {
      delete rules.required
    }

    return Object.keys(rules).length === 0 ? {} : { fieldValue: rules }
  },

  watch: {
    /**
     * Observe changes to the `value` prop, in case a `v-model` was bound to
     * the Form input component. If so, we want that to be the leading value
     */
    value(newValue) {
      this.fieldValue = newValue
    },
    /**
     * Whenever the field value changes, run the validation
     * This is enabled after the first `blur` event.
     *
     * We use a validateOnChange flag instead of a flag on the validation method
     * itself, because we want to always be able to explicitly trigger validations,
     * like when navigating to a different page before inserting a required value
     */
    fieldValue() {
      if (this.validateOnChange) {
        this.runValidation()
      }
    },
    /**
     * Run validation as soon as we should start watching for changes
     */
    validateOnChange() {
      if (this.validateOnChange) {
        this.runValidation()
      }
    }
  },
  /**
   * Register the input field in the Form
   */
  created() {
    // Upon creation, check for a field value passed through the `value` prop
    this.fieldValue = this.value

    // Register the field
    // Note: in case a field was already registered by this name, this field
    // will replace the old field, while adopting the value of the old field.
    // Note: This happens with paged forms.
    if (this.registerInputField) {
      this.registerInputField({
        name: this.name,
        validate: this.runValidation,
        isValid: this.isValid,
        getValue: this.getValue,
        setValue: this.setValue
      })
    }
  },

  beforeDestroy() {
    if (this.unregisterInputField && this.name) {
      this.unregisterInputField({ name: this.name })
    }
  },

  methods: {
    /**
     * Generate a unique id used to couple the input and label tags
     *  This method is only used an id was not explicitly provided as a prop
     */
    uniqueId() {
      // CHeck whether the uuid is null, to avoid double generation of uuid,
      // causing a possible mismatch between input an label.
      return (this.uuid = this.uuid !== null ? this.uuid : uuidV4())
    },

    /**
     * A method to retrieve the field value
     */
    getValue() {
      return this.fieldValue
    },

    /**
     * A method to allow the field value to be set
     */
    setValue({ value }) {
      this.fieldValue = value
    },

    // *******************************************
    //   DOM EVENT HANDLERS
    // *******************************************

    /**
     * As you can see, there are two methods for each event.
     * This makes it easier for extending components to expand on the original
     * functionality of these events, and also means we don't have to update
     * all extending components whenever the base functionality changes.
     */

    onFocus(e) {
      this.focus(e)
    },
    onBlur(e) {
      this.blur(e)
    },
    onChange(e) {
      this.change(e)
    },
    onInput(e) {
      this.input(e)
    },

    focus(e) {
      this.focused = true
      this.$emit('focus', e)
    },
    blur(e) {
      this.focused = false
      this.validateOnChange = true
      this.$emit('blur', e)
    },
    change(e) {
      let value = e.target.matches('[type="checkbox"]')
        ? e.target.checked
        : e.target.value

      this.fieldValue = value
      this.$emit('change', value)
    },
    input(e) {
      let value = e.target.matches('[type="checkbox"]')
        ? e.target.checked
        : e.target.value

      this.fieldValue = value
      this.$emit('input', value)

      if (this.validateOnInput) {
        this.validateField()
      }
    },

    // *******************************************
    //    VALIDATION Methods
    // *******************************************

    /**
     * Run the validation procedure
     */
    runValidation({ silent } = { silent: false }) {
      this.validate({ silent })

      this.$emit('validated', {
        value: this.getValue(),
        field: this,
        valid: this.isValid()
      })

      if (silent) {
        return
      }

      if (!this.isValid()) {
        this.setErrorMessage()
      } else {
        this.clearErrorMessage()
      }

      // Always enable validation on change after validation occurred
      // A user may have skipped a required field, which we want to
      // validate on change after the submit action caused an error
      this.validateOnChange = true
    },

    /**
     * Run the Vuelidate validation
     */
    validate({ silent } = { silent: false }) {
      this.$v.$touch()

      if (!silent) {
        // Indicate that validation has run at least once
        this.validated = true
      }
    },

    validateField() {
      if (!this.isValid()) {
        this.setErrorMessage()
      } else {
        this.clearErrorMessage()
      }
    },

    /**
     * Return the latest Vuelidate validation result
     */
    isValid() {
      return !this.$v.$invalid
    },

    /**
     * Reset the Vuelidate and internal validation status
     */
    resetValidation() {
      this.clearErrorMessage()
      this.$v.$reset()
    },

    /**
     * Go through the validation rules for this input, and show the error message of the first validation rule that was not passed.
     */
    errorMessageSelector({ validator }) {
      if (this.error) {
        return this.error
      }

      let rules = Object.keys(this.$v.fieldValue.$params) || []

      let message = ''
      let messages = []
      // Go through the rules, and exit at the first invalid rule
      rules.some(rule => {
        if (!validator[rule]) {
          // Check for a custom message, fall back to the default
          if (this.errorMessages[rule]) {
            message = this.errorMessages[rule]
            messages.push(this.errorMessages[rule])
          } else if (this.defaultErrorMessages[rule]) {
            message = this.defaultErrorMessages[rule]
            messages.push(this.defaultErrorMessages[rule])
          }
          return !this.showAllOpenErrors
        }
      })
      return !this.showAllOpenErrors ? message : messages
    },

    /**
     * The visible error message is determined by the Vuelidate result, and
     * by the developer supplied message selector.
     */
    setErrorMessage() {
      this.errorMessage = this.errorMessageSelector({
        validator: this.$v.fieldValue
      })
    },

    /**
     * Clear the visible error message
     */
    clearErrorMessage() {
      this.errorMessage = ''
    }
  },

  render(h) {
    let children = []

    if (this.label !== '') {
      children.push(
        h('label', {
          class: {
            Field__label: true
          },
          ref: 'label',
          domProps: {
            for: this.labelId,
            innerText: this.label
          }
        })
      )
    }
    children.push(
      h('input', {
        class: {
          Field__input: true
        },
        on: {
          input: this.onInput,
          focus: this.onFocus,
          blur: this.onBlur,
          keyup: this.onChange
        },
        domProps: {
          id: this.inputId,
          name: this.name,
          value: this.fieldValue,
          disabled: this.disabledStatus
        }
      })
    )
    if (this.errorMessage) {
      children.push(
        h('span', {
          class: {
            Field__error: true
          },
          domProps: {
            innerText: this.errorMessage
          }
        })
      )
    }

    return h('div', {
      class: Object.assign(this.fieldClassList, {
        Field: true,
        Field__wrapper: true
      }),
      children
    })
  }
}
</script>
