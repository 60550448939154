import Vue from 'vue'
import axios from 'axios'

export default {
  methods: {
    /**
     * Get opening hours 
     * @returns {Object}
     */
    async getOpeningHours({
      date = new Date(),
      channelId = 0,
      locationId = 0,
      meetingtypeId = 0
    } = {}
    ) {
      date = Vue.options.filters.dateObjectIsoDateString(date)
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/openinghour`,
        {
          params: {
            date,
            channelId,
            locationId,
            meetingtypeId
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    }
  }
}
