<template>
  <div
    v-if="isInitiated && article"
    class="sidebarNav sidebarNav-event"
    :class="{'isOpen' : activeDrawerType === 'articleDetailDrawer'}"
  >
    <div class="padding header">
      <button
        href="javascript:"
        class="button"
        @click="closeArticleDetailDrawer"
      >
        <span class="icon"><i class="fas fa-chevron-left"></i></span>
        <span>Close</span>
      </button>
    </div>

    <div class="wrapper">
      <div class="collage">
        <figure class="image is-5by3">
          <img
            src="@/assets/img/spacer5_3.gif"
            :style="{backgroundImage: 'url(' + article.ImageUrl + ')' }"
          >
        </figure>
      </div>
      <div class="padding">
        <p class="locationTitle title is-4 has-text-blue-dark">{{article.Title}}</p>
        <p :inner-html.prop="article.Short"></p>
      </div>
    </div>

    <div class="sidebarNav-footer padding has-text-centered btn-bottom">
      <a
        :href="article.Url"
        class="button is-primary is-medium"
      >
        <span>Go to article</span>
        <span class="icon"><i class="fas fa-chevron-right"></i></span></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import article from '../providers/article'

export default {
  data() {
    return {
      article: null
    }
  },

  computed: {
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      selectedArticle: 'getSelectedArticle'
    })
  },

  mounted() {
    this.getArticleData()
  },

  methods: {
    closeArticleDetailDrawer() {
      this.selectedCheckin = null
      this.$store.commit('setSelectedArticle', 0)
      this.$store.commit('setActiveDrawerType', '')
    },

    getArticleData() {
      article.methods.getArticle(this.selectedArticle).then(response => {
        this.article = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.sidebarNav {
  $headerHeight: 70px;
  $footerHeight: 70px;
  $sidebarWidth: 420px;

  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.75s; /* 0.5 second transition effect to slide in the sidenav */
  width: 0;
  z-index: 31;
  @include box-shadow();
  border-left: 5px solid $blue-dark;

  &.isOpen {
    width: $sidebarWidth;
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }
  }
  .sidebarNav-header {
    top: 0;
    height: $headerHeight;
    left: 0;
    position: absolute;
    width: 100%;
    .close-button {
      font-weight: bold;
      color: darken(lightgray, 20%);
      &:hover {
        color: darken(lightgray, 50%);
      }
    }
  }

  .sidebarNav-wrapper {
    bottom: $footerHeight;
    overflow: hidden;
    padding: $gap;
    position: absolute;
    top: $headerHeight;
    width: $sidebarWidth;
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }

    div.content {
      margin: 0;
      height: 100%;
      overflow: auto;
      padding: 0;
      width: 100%;

      .imageHolder {
        height: 90px;
        margin-bottom: $gap * 2;
        figure.image {
          margin-top: $gap;
          display: inline-block;
        }
      }

      .textarea {
        height: 30vh;
      }
    }
  }
  .sidebarNav-footer {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;

    .button {
      width: 46%;
      margin-left: $gap;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
