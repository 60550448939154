<template>
  <div
    v-if="matchObj"
    v-masonry-tile
  >
    <!-- Checkin -->
    <div
      v-if="match.Type === 'Checkin'"
      class="match-item-checkin box p-4 is-shadowless has-background-primary"
    >
      <div class="has-text-centered">
        <AvatarImage
          class="is-84x84 m-auto"
          :imageSrc="matchObj.ProfileImage"
          :itemId="matchObj.ProfileId"
          :itemName="matchObj.ProfileName"
          :photoSize="84"
        />
        <div class="box-content box is-shadowless p-4 pt-6 has-background-white">
          <div
            class="has-text-weight-bold mb-3"
            v-text="matchObj.ProfileName"
          ></div>
          <div class="is-size-7 mb-3">
            <div
              class="icon-text is-flex-wrap-nowrap is-justify-content-center"
              v-if="showTimeMatchItem && !hasSameStartEndTime"
            >
              <span class="icon has-text-primary-dark">
                <i class="far fa-clock"></i>
              </span>
              <span>
                {{ printTime(matchObj) }}
              </span>
            </div>
            <div
              class=""
              v-if="matchObj.LocationId > 0 && showLocation"
            >
              <span class="icon has-text-primary-dark">
                <i class="fas fa-map-marker-alt"></i>
              </span>
              <span>{{ matchObj.LocationName }}</span>
              <span v-if="matchObj.Distance > -1">({{ matchObj.Distance.toFixed(0) }}km)</span>
            </div>
          </div>

          <div
            v-if="showTags && matchObj.Tags !== ''"
            class="tags is-centered mb-4"
          >
            <span
              v-for="(tag, index) in match.Tags.split(',').splice(0, 2)"
              :key="index"
              class="tag is-outlined is-white"
            >{{ tag }}</span>
            <strong
              v-if="match.Tags.split(',').splice(2).length !== 0"
              class="tag is-outlined is-white"
            >+{{ match.Tags.split(',').splice(2).length }}</strong>
          </div>
          <div class="bottom">
            <a
              @click="MatchClicked(matchObj)"
              class="custom-button is-rounded is-success is-outlined"
              title="View profile"
            >
              <span class="text">View profile</span>
              <span class="icon is-medium">
                <font-awesome-icon :icon="['far', 'ellipsis-h']" />
              </span>
            </a>
            <a
              @click="MatchClicked(matchObj)"
              class="custom-button is-rounded is-success is-outlined"
              title="Send message"
            >
              <span class="text">Send message</span>
              <span class="icon is-medium">
                <font-awesome-icon :icon="['fas', 'comment-alt-dots']" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Event -->
    <div
      class="match-item-event"
      v-else-if="match.Type === 'Event'"
    >
      <EventTile
        class="is-compact"
        :showTypeLabel="true"
        :event="matchObj"
      />
    </div>

    <!-- Question -->
    <div
      v-else-if="match.Type === 'Question'"
      @click="MatchClicked(matchObj)"
      class="match-item-question location-item box p-4 is-shadowless has-background-primary-light is-clickable"
    >
      <div class="mb-2 is-size-7 has-text-primary-dark">QUESTION</div>
      <div class="content">
        <i class="fas fa-quote-left mr-1"></i>
        <span :inner-html.prop="
              matchObj.Name | stripHtml(true) | stringShortner(40, true)
            "></span>
        <i class="fas fa-quote-right ml-1"></i>
        <hr class="separator" />
        <div class="profile is-flex is-align-items-center">
          <AvatarImage
            class="is-48x48 mb-0"
            :imageSrc="matchObj.Image"
            :itemId="matchObj.ProfileId"
            :itemName="matchObj.ProfileName"
            :photoSize="84"
          />
          <strong
            v-if="matchObj.ProfileName !== ''"
            class="has-size-8"
          >{{
            matchObj.ProfileName
          }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import matchProvider from '@/providers/match'
import profileProvider from '@/providers/profile'
import EventTile from './events/EventTile.vue'
import AvatarImage from './UI/AvatarImage.vue'

export default {
  props: {
    match: null,

    showTimeMatchItem: {
      type: Boolean,
      default: true
    },

    showLocation: {
      default: false,
      type: Boolean
    },

    showWorkingon: {
      default: false,
      type: Boolean
    },

    showTags: {
      default: false,
      type: Boolean
    },

    showTime: {
      default: true,
      type: Boolean
    },

    showDistance: {
      default: true,
      type: Boolean
    }
  },

  components: {
    EventTile,
    AvatarImage
  },

  data() {
    return {
      matchObj: this.convertMatchData(),
      messageText: '',
      showCheckin: true,
      showMessageInput: false,
      showRemove: false,
      gaCategory: `Page-${this.$route.name}`
    }
  },

  computed: {
    ...mapState('chatStore', ['chats']),
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      currentCheckin: 'getCurrentCheckin'
    }),

    hasSameStartEndTime() {
      let s = this.$options.filters.timeFromDateTimeStamp(this.matchObj.StartTime)
      let e = this.$options.filters.timeFromDateTimeStamp(this.matchObj.EndTime)
      return s === e
    }
  },

  created() {
  },

  methods: {
    convertMatchData() {
      let convertedData = null

      // if (this.type === 'Article') {
      //   _componentData = {
      //     component: () => ({ component: import('@/components/articles/ArticleItem.vue') }),
      //     props: {
      //       article: this.match ? {
      //         Id: this.match.ItemId,
      //         Title: this.match.Name,
      //         Date: this.match.Date,
      //         Image: this.match.Image,
      //         Key: this.match.Key,
      //         Location: this.match.Location,
      //         LocationId: this.match.LocationId,
      //         Author: '',
      //         Short: this.$options.filters.stringShortner(this.$options.filters.stripHtml(this.match.Description), 100),
      //         Tags: this.match.Tags,
      //         TagMatches: this.match.TagMatches,
      //         EnableComments: false,
      //       } : this.match
      //     }
      //   }
      // }

      if (this.match.Type === 'Checkin') {
        convertedData = {
          ProfileId: this.match.ItemId,
          ProfileName: this.match.Name,
          ProfileImage: this.match.Image,
          StartMinutes: this.match.StartMinutes,
          EndMinutes: this.match.EndMinutes,
          Location: this.match.Location,
          Tags: this.match.Tags || '',
          TagMatches: this.match.TagMatches,
          WorkingOn: this.match.Description
        }
      }

      if (this.match.Type === 'Event') {
        convertedData = {
          Id: this.match.ItemId,
          Name: this.match.Name,
          Image: this.match.Image,
          StartDate: this.match.Date,
          EndDate: this.match.Date,
          StartMinutes: this.match.StartMinutes,
          EndMinutes: this.match.EndMinutes,
          LocationName: this.match.Location,
          TagMatches: this.match.TagMatches,
        }
      }

      // if (this.match.type === 'Question') {
      //   convertedData = {
      //     Id: this.match.ItemId,
      //     Name: this.match.Name,
      //     Image: this.match.Image,
      //     ProfileId: this.match.ItemId,
      //     ProfileName: this.match.Name,
      //     ProfileImage: this.match.Image,
      //     StartDate: this.match.Date,
      //     EndDate: this.match.Date,
      //     StartMinutes: this.match.StartMinutes,
      //     EndMinutes: this.match.EndMinutes,
      //     TagMatches: this.match.TagMatches,
      //   }
      // }

      return convertedData
    },

    printTime(match) {
      let startTime = this.$options.filters.timeFromDateTimeStamp(match.StartTime)
      let endTime = this.$options.filters.timeFromDateTimeStamp(match.EndTime)
      let startMinutes = this.$options.filters.timeToMinutes(match.StartTime)
      let endMinutes = this.$options.filters.timeToMinutes(match.EndTime)
      let time = `${startTime} - ${endTime}`
      if (endMinutes < startMinutes) {
        time = `${endTime} - ${startTime}`
      }
      return time
    },

    getBackgroundClass(itemType) {
      let cls = ''
      switch (itemType) {
        case 'Checkin': // Check-in
          cls = 'profile-item has-background-white'
          break
        case 'Article': // Article
          cls = 'article-item has-background-white'
          break
        case 'Event': // Event
          cls = 'event-item has-background-white'
          break
        case 'Location': // Location
          cls = 'location-item has-background-white'
          break
        case 'Question': // Question
          cls = 'question-item has-background-primary-light'
          break
        default:
          cls = 'has-background-white'
          break
      }
      return cls
    },

    MatchClicked(value) {
      let gaAction = ''

      switch (this.match.Type) {
        case 'Checkin':
          // Check-in
          gaAction = 'button-CheckinDetailView'

          // Route push
          this.showProfileDetail(value.ProfileId)
          break
        case 'Article':
          // Article
          gaAction = 'button-ArticleDetailSummery'

          // Route push
          this.showArticleDetail(value.ItemId)
          break
        case 'Event':
          // Event
          gaAction = 'button-EventDetail'

          // Route push
          this.$router.push({
            name: 'EventDetail',
            params: { eventId: value.ItemId }
          })
          break
        case 'Location':
          // Location
          gaAction = 'button-LocationDetail'

          // Route push
          this.showLocationDetail(value.ItemId)
          break
        // case 4:
        //   // Question
        //   gaAction = 'button-QuestionDetail'

        //   // Route push
        //   this.$router.push({ path: `questions/${value.ItemId}` })
        //   break
        // case 5:
        //   // Jobs
        //   // TODO: Go to job
        //   break
        // case 6:
        //   // Groups
        //   // TODO: Go to group
        //   break
        // default:
        //   break
      }

      // Push GA event
      // this.$googleAnalytics.pushEvent('matched item', 'button', gaLabel)
      this.$googleAnalytics.pushEvent(this.gaCategory, gaAction, 'Matches | Tile-Match')
    },

    showArticleDetail(articleId) {
      this.$store.commit('setSelectedArticle', articleId)
    },


    showLocationDetail(locationId) {
      this.$store.commit('setSelectedLocation', locationId)
    },

    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },

    setShowMessageInput() {
      this.showMessageInput = true
    },
    cancelShowMessageInput(comment) {
      this.showMessageInput = false
      this.messageText = ''
    },

    setShowCheckin() {
      this.setVisibleItem('checkin')
    },
    setShowRemove() {
      this.setVisibleItem('remove')
    },
    setVisibleItem(item) {
      this.showCheckin = item === 'checkin'
      this.showRemove = item === 'remove'
    }
  }
}
</script>

<style lang="scss" scoped>
.match-item {
  @include box-shadow();
  @include rounded($gap);
  overflow: hidden;
  position: relative;
  border-width: 5px;
  border-style: solid;
  height: 100%;
  width: 100%;

  &:hover {
    .hover-overlay {
      background: rgba($black, 0.5);
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      .button-bar {
        display: block;
      }
    }
  }
  .hover-overlay {
    display: none;
    .button-bar {
      bottom: $gap * 2;
      display: none;
      position: absolute;
      width: 100%;
    }
  }

  &.article-item {
    border-color: $blue-dark;
  }
  &.location-item {
    border-color: $yellow;
    img {
      width: 100%;
    }
  }
  &.profile-item {
    border-color: $primary;
    .image {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.event-item {
    border-color: $red-light;

    .locationDetail {
      line-height: normal;
      margin-bottom: 5px;
      padding-left: 15px;
      position: relative;
      .fa-map-marker-alt {
        left: 0;
        position: absolute;
        top: 3px;
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.match-item-question {
  border: none;
  .profile {
    ::v-deep .image {
      float: left;
      display: inline-block;
      height: 38px;
      margin-top: unset;
      margin-left: unset;
      margin-bottom: 5px;
      margin-right: 5px;
      width: 38px;
    }
  }
}

.match-item-checkin {
  .is-84x84 {
    width: 84px;
    height: 84px;
  }
  .box-content {
    margin-top: -#{$gap * 1.5};
    .custom-button {
      display: inline-flex;
      position: relative;
      &:not(:first-child) {
        margin-left: 10px;
      }

      &.is-rounded {
        overflow: hidden;
        @include rounded(50%);
      }

      .text {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        width: 1px;
        height: 1px;
      }
      &.is-success {
        &.is-outlined {
          color: $primary-dark;
          border: $control-border-width solid $primary-dark;
          background-color: white;
          :hover {
            color: white;
            background-color: $primary-dark;
          }
        }
      }
    }
  }
}
</style>
