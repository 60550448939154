import Vue from 'vue'
import axios from 'axios'
import store from '../store/store'

let fetchProfileCancel = null
let checkAuthPinCancel = null

export default {
  methods: {
    // async checkEmail(email) {
    //   const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/check?email=${email}`, {
    //     headers: {
    //       'Content-type': 'application/json',
    //       apiToken: process.env.VUE_APP_API_KEY,
    //     }
    //   })
    //   return response
    // },


    async getProfileChats() {
      return await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/chat`, {
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    async confirmProfile(token) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/${token}/confirm`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Fetch profile verification key
     * @param {string} FirstName 
     * @param {string} LastName 
     * @param {string} Email 
     * @param {string} Language 
     * @param {Number} ChannelId 
     */
    async fetchProfile({ FirstName, LastName, Email, Password = '', Language, ChannelId, TermsId, HasAgreedToTerms } = {}) {
      fetchProfileCancel && fetchProfileCancel('Request cancelled')

      return axios.post(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/Profile/fetch`,
        {
          FirstName,
          LastName,
          Email,
          Password,
          Language,
          ChannelId,
          TermsId,
          HasAgreedToTerms
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            fetchProfileCancel = c;
          })
        })
    },

    /**
     * Check user pin authentication
     * @param {String} VerificationKey 
     * @param {String} Pin 
     */
    async checkAuthPin({ VerificationKey, Pin }) {
      checkAuthPinCancel && checkAuthPinCancel('Request cancelled')
      return axios.post(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/login/pin`, {
        VerificationKey,
        Pin
      },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            checkAuthPinCancel = c;
          })
        })
    },

    deleteProfile() {
      return axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/${store.getters.getProfile.Key}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    /**
     * Get public profile
     * @param {Number} profileId 
     * @returns 
     */
    getPublicProfile(profileId) {
      return axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/${profileId}/public`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    // getProfile: () => {
    //   return axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/profiles`, {
    //     headers: {
    //       'Content-type': 'application/json',
    //       apiToken: process.env.VUE_APP_API_KEY,
    //       token: process.env.VUE_APP_API_KEY,
    //       profileToken: store.getters.getProfileToken
    //     }
    //   })
    // },

    async getProfileStats() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/${store.getters.getProfile.Id}/stats`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveRecommendationEvaluation(profileId, type, matchId) {
      let criteria = {
        ProfileId: profileId,
        Type: type,
        MatchId: matchId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/profiles/match/evaluation`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveProfile(profile) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile`, {
        ChannelId: 1,
        Language: 'en',
        Profile: profile
      },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveProfileLastChecked() {
      let criteria = {
        action: 'update'
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/profiles/checked`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async uploadImage(base64jpeg) {
      let base64string = base64jpeg.split(',')[1]
      
      return await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/image`,
        // base64Image,
        base64string,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },
  }
}
