<template>
  <Drawer
    v-if="showDrawer"
    :showDrawer="showDrawer"
    :closeFunc="closeFunc"
    drawerCssClass="has-background-primary"
    textCssClass="has-text-white"
  >
    <template v-slot:content>
      <div class="drawer-content">
        <router-link
          :to="{ name: 'Dashboard' }"
          @click.native="closeFunc"
          class="navbar-item is-unselectable"
          :class="{ 'is-active': $route.name === 'Dashboard' }"
        >
          <span>Passport</span>
        </router-link>
        <template v-for="(menuItem, index) in siteMenu">
          <div
            :key="'m_div_' + index"
            class="is-divider is-small"
          ></div>
          <LinkItem
            v-if="!menuItem.menu.length"
            :key="'m_' + index"
            :item="menuItem"
            class="navbar-item"
            @click.native="() => { $googleAnalytics.pushEvent('SiteMobileMenu', menuItem.gaAction, menuItem.gaLabel);  closeFunc(); }"
          />
          <div
            v-else
            :key="'m' + index"
            class="category-item has-padding-left has-padding-right has-text-weight-bold is-size-6 has-text-grey"
          >
            <div>{{ menuItem.text }}</div>
          </div>
          <LinkItem
            v-for="(submenuItem, menuItemIndex) in menuItem.menu"
            :key="'m_' + index + 'msi_' + menuItemIndex"
            :item="submenuItem"
            :class="{
                      'is-active': $route.name === submenuItem.routeName
                    }"
            class="navbar-item"
            @click.native="() => { if(submenuItem.modal) { setActiveModalType(submenuItem.modal) } $googleAnalytics.pushEvent('SiteMobileMenu', menuItem.gaAction, submenuItem.gaLabel); closeFunc() }"
          />
        </template>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Drawer from '@/components/UI/Drawer'
import LinkItem from '@/components/UI/LinkItem'

export default {
  name: 'drawer-site-menu',

  props: {
    closeFunc: {
      type: Function,
      default: () => { }
    },

    showDrawer: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Drawer,
    LinkItem
  },

  computed: {
    ...mapState('menuStore', ['siteMenu'])
  },

  methods: {
    ...mapMutations(['setActiveModalType'])
  }
}
</script>

<style lang="scss" scoped>
.drawer-content {
  margin-left: -$gap;
  margin-right: -$gap;
}
.category-item {
  div {
    font-size: 16px;
    text-transform: uppercase;
  }
}

.navbar-item {
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: $gap;
  padding-right: $gap;

  &:hover,
  &.is-active {
    color: white;
    background-color: transparent;
    text-decoration: unset;
  }
}
</style>
