<template>
  <div
    @click="gotToEvent()"
    class="eventTile box p-0 is-shadowless eventTile has-background-grey-lighter is-clickable"
  >
    <div class="col-image">
      <figure class="image is-4by3 tile-image">
        <img
          class="has-background-grey-light"
          src="/img/spacer1_1.gif"
          :style="{ backgroundImage: `url('${image}')` }"
        />
      </figure>
    </div>
    <div class="col-content p-4">
      <div class="mb-3">
        <h5 class="title is-5 mb-4">
          <div
            v-if="showTypeLabel"
            class="mb-2 is-size-7 has-text-grey"
          >EVENT</div>
          <a
            @click="gotToEvent()"
            v-html="name"
            class="has-text-black"
          >
          </a>
        </h5>
        <div
          class="is-6 has-text-primary has-text-weight-bold"
          v-html="date"
        >
        </div>
      </div>
      <div class="icon-text is-flex-wrap-nowrap">
        <span class="icon has-text-primary-dark">
          <font-awesome-icon :icon="['fas', 'map-marker']" />
        </span>
        <span>
          {{locationAddress}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTile',

  props: {
    event: {
      type: Object,
      required: true,
    },
    showTypeLabel: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    }
  },

  computed: {
    name() {
      return this.event.Name;
    },

    date() {
      let strDate = this.$options.filters.dateFormat(this.event.StartDate, 'en', '', 'dd MMM')
      let endDate = this.$options.filters.dateFormat(this.event.EndDate, 'en', '', 'dd MMM')
      if (strDate !== endDate) {
        strDate += ' - ' + endDate
      }

      let strTime = ''
      if (this.event.StartMinutes === 0 && (this.event.EndMinutes === 0 || this.event.EndMinutes === 1440)) {
        return strDate
      }

      strTime = this.$options.filters.minutesToTime(this.event.StartMinutes)
      if (this.event.EndMinutes !== this.event.StartMinutes) {
        strTime += ' - ' + this.$options.filters.minutesToTime(this.event.EndMinutes)
      }
      strDate += ', ' + strTime

      return strDate
    },

    // date() {
    //   if (this.event.StartDate === this.event.EndDate) {
    //     if (this.event.StartMinutes) {
    //       //display date and time
    //       return this.$dateFns.intlFormat(
    //         this.$dateFns.parseISO(this.event.StartDate),
    //         this.dateFormat, {
    //         locale: this.$i18n.locale
    //       }
    //       ) + ", " + this.$options.filters.minutesToTime(this.event.StartMinutes)
    //     }
    //     //display only date
    //     return this.$dateFns.intlFormat(
    //       this.$dateFns.parseISO(this.event.StartDate),
    //       this.dateFormat, {
    //       locale: this.$i18n.locale
    //     }
    //     )
    //   } else {
    //     //display both start and end date that stretches over multiple days
    //     return this.$dateFns.intlFormat(
    //       this.$dateFns.parseISO(this.event.StartDate),
    //       this.dateFormat, {
    //       locale: this.$i18n.locale
    //     }
    //     ) + " - " +
    //       this.$dateFns.intlFormat(
    //         this.$dateFns.parseISO(this.event.EndDate),
    //         this.dateFormat, {
    //         locale: this.$i18n.locale
    //       }
    //       )
    //   }
    // },

    locationAddress() {
      let locationName = this.event.LocationName

      if (this.event.City) {
        var cityInSnakeCase = this.event.City.toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.substring(1))
          .join(' ');

        locationName = locationName + ", " + cityInSnakeCase
      }

      if (this.event.Country) {
        locationName = locationName + ", " + this.event.Country
      }

      return locationName
    },

    image() {
      return this.$options.filters.getEventImageSrc(
        this.event.Image,
        this.event.Id,
        640
      );
    },
  },

  methods: {
    gotToEvent() {
      this.$router.push({ name: 'EventDetail', params: { 'eventId': this.event.Id } })
    }
  },
};
</script>

<style lang="scss" scoped>
.eventTile {
  height: 100%;
  &:not(.is-compact) {
    @media screen and (min-width: $desktop) {
      display: flex;
      .col-image {
        margin-left: -1px;
        flex-shrink: 1;
        width: 45%;
        max-width: 320px;
      }
      .col-content {
        display: flex;
        flex-direction: column;
        width: 55%;

        div:first-child {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>