<template>
  <div :class="{'modal-content-tips': shownInModal}">
    <div
      v-for="(tip, index) in tips"
      :key="'tip_' + index"
      class="row-tip"
      :class="[(index + 1) !== tips.length ? 'has-margin-bottom' : 'has-margin-bottom-x2']"
    >
      <span class="wrapper-tip-number has-margin-right">
        <span class="icon is-medium has-text-blue-dark">
          <i class="fas fa-lg fa-comment-alt"></i>
        </span>
        <strong class="has-text-centered has-text-white">{{ index + 1}}</strong>
      </span>
      <span>
        <strong v-text="tip.title"></strong><br />
        <span v-html="tip.text"></span>
      </span>
    </div>
    <div
      class="title is-5"
      :class="{'is-hidden-touch': !alwaysShowIframe }"
    >Demo video: How to ask your questions</div>
    <iframe
      :class="{'is-hidden-touch': !alwaysShowIframe }"
      width="100%"
      src="https://www.youtube-nocookie.com/embed/zMAPrJ53XHE"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    shownInModal: {
      type: Boolean,
      default: false
    },
    alwaysShowIframe: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tips: [
        {
          title: 'Keep your question short.',
          text: 'The more specific your question, the more specific the answer you’ll get.'
        },
        {
          title: 'Choose your tags carefully.',
          text: 'The algorithm uses your tags to match you with people who can answer your question.'
        },
        {
          title: 'Pick a good photo.',
          text: 'Use a headshot of your friendly face. It’s the first impression!'
        },
        {
          title: 'Keep your profile up to date.',
          text: 'So people can more easily connect and collaborate with you. Update your profile on a regular basis.'
        },
        {
          title: 'Don’t confuse questions with promotion.',
          text: 'Do you want to promote your workshop or other event? Create an event in your passport instead. It will automatically appear on our event calendar.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.row-tip {
  display: flex;
  .wrapper-tip-number {
    position: relative;

    strong {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
}

iframe {
  height: 240px;
  @media screen and (max-width: 260px) {
    height: 180px;
  }
}
.modal-content-tips {
  iframe {
    height: 340px;
    @media screen and (max-width: 530px) {
      height: 260px;
    }
  }
}
</style>
