<template>
  <div>
    <h3
      v-if="!hideTitle"
      class="title is-3 has-text-blue-dark"
    >Forgotten your password?</h3>
    <div class="content">
      <p>Fill in your email address and receive a confirmation code to set a new password.</p>
    </div>
    <message
      v-if="email.length > 1 && emailInvalid"
      class="is-warning"
    >
      <div class="icon-text">
        <span class="icon notification-icon fa-lg">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span>Email address you entered is incorrect</span>
      </div>
    </message>

    <div class="field">
      <form
        onSubmit="return false;"
        autocomplete="off"
      >
        <label class="label">Email</label>
        <div class="control">
          <input
            v-if="!profileEmail"
            class="input is-medium"
            ref="email"
            autocomplete="none"
            aria-autocomplete="both"
            v-bind:class="{ 'is-success': (emailNotEmpty && !emailInvalid), 'is-danger': showEmailWarning }"
            type="email"
            placeholder="Email"
            maxlength="64"
            v-model="email"
          >
          <span
            v-if="profileEmail"
            class="input is-medium"
            v-text="profileEmail"
          ></span>
        </div>
        <p v-if="showEmailWarning">
          <span
            class="help"
            v-bind:class="{ 'is-danger': showEmailWarning }"
          >This email is invalid</span>
        </p>
      </form>
    </div>
    <nav class="level is-mobile">
      <div class="level-left">
        <div class="level-item content">
          <a
            v-if="linkActionLogin"
            @click="linkActionLogin()"
            class="icon-text"
          >
            <span class="icon">
              <i class="fas fa-arrow-circle-left"></i>
            </span>
            <span>Back to log in</span>
          </a>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <a
            @click="sendResetPasswordRequest"
            class="button is-medium is-primary"
            :disabled="emailInvalid"
            :class="{ 'is-disabled' : emailInvalid, 'is-loading': isSending }"
          >
            <span>Send</span>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import loginProvider from '../../providers/login'
import Message from '../UI/Message.vue'
export default {
  components: { Message },
  name: 'ForgotPassword',

  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },

    linkActionLogin: {
      type: [Boolean, Function],
      default: false
    },

    profileEmail: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      email: this.profileEmail,
      isSending: false
    }
  },

  computed: {
    emailEmpty() {
      return this.email === ''
    },
    emailNotEmpty() {
      return this.email !== ''
    },
    emailInvalid() {
      return !/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.email)
    },
    showEmailWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && (this.emailEmpty || this.emailInvalid)) {
        showWarning = true
      }

      return showWarning
    },
  },

  methods: {
    sendResetPasswordRequest() {
      // Strip HTML tags
      this.email = this.$options.filters.stripHtml(this.email)
      this.email = this.email.trim()

      if (!this.email || this.isSending) { return }
      this.isSending = true
      loginProvider.methods.resetPassword(this.email).then(response => {
        // this.setShowResetPasswordConfirm()

        if (response.status === 200) {
          // Emit actie
          this.$emit('sentSuccessfully')
        }
      })
        .catch(() => {
          this.isSending = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>