import axios from 'axios'

let getTagSuggestionCancellationToken = null

export default {
  methods: {
    cancelGetTagSuggestion() {
      if (getTagSuggestionCancellationToken) {
        getTagSuggestionCancellationToken.cancel()
      }
    },

    async getTagSuggestion(tag, itemsPerPage = 10) {
      getTagSuggestionCancellationToken = axios.CancelToken.source()
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/tag/suggestion`,
        {
          cancelToken: getTagSuggestionCancellationToken.token,
          params: {
            q: tag,
            page: 1,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },
  }
}
