<template>
  <div
    v-if="isInitiated"
    class="drawer right has-background-white"
    :class="{ 'is-active': activeDrawerType === 'askQuestionDrawer' }"
  >
    <div class="drawer-content">
      <div class="padding header">
        <a
          class="close-button"
          @click="hide()"
        >
          <span class="icon"><i class="fas fa-times-circle"></i></span>
          <span>Close</span>
        </a>
      </div>
      <div class="wrapper has-padding-left has-padding-right">
        <create-question
          v-if="isLoggedIn"
          :viewType="'small'"
          :setCurrentStep="1"
          class=""
        ></create-question>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn'
    })
  },

  methods: {
    hide() {
      this.$store.commit('setActiveDrawerType', '')
    }
  }
}
</script>
