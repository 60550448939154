import Vue from 'vue'

Vue.filter('dateTimeStampToDate', val => {
  // Convert dateTimestamp to date
  return !isNaN(val) ? new Date(val) : 'invalid'
})

Vue.filter(
  'dateFormat',
  (val, culture = 'en', format = 'fullDateFormat', customFormat = '') => {
    let calendarLabels = CalendarLanguageLabels[culture]
    let dateTemplate = CalendarLanguageLabels[culture][format]
    if (customFormat !== '') {
      dateTemplate = customFormat
    }
    if (Object.prototype.toString.call(val) === '[object String]') {
      val = new Date(val)
    } else if (typeof val === 'number') {
      // Convert dateTimestamp to date
      val = !isNaN(val) ? new Date(val) : 'invalid'
    }
    if (Object.prototype.toString.call(val) === '[object Date]') {
      dateTemplate = dateTemplate.replace('dd', val.getDate())
      dateTemplate = dateTemplate.replace(
        'DD',
        calendarLabels.dayNames[val.getDay()]
      )
      dateTemplate = dateTemplate.replace('mm', val.getMonth() + 1)
      dateTemplate = dateTemplate.replace(
        'MMM',
        calendarLabels.monthNamesShort[val.getMonth()]
      )
      dateTemplate = dateTemplate.replace(
        'MM',
        calendarLabels.monthNames[val.getMonth()]
      )
      dateTemplate = dateTemplate.replace('yy', val.getFullYear())
      return dateTemplate
    }
    return ''
  }
)

Vue.filter('longDateFormat', (val, culture = 'en') => {
  let calendarLabels = CalendarLanguageLabels[culture]
  let dateTemplate = CalendarLanguageLabels[culture].longDateFormat
  if (Object.prototype.toString.call(val) === '[object String]') {
    val = new Date(val)
  }
  if (Object.prototype.toString.call(val) === '[object Date]') {
    dateTemplate = dateTemplate.replace('dd', val.getDate())
    dateTemplate = dateTemplate.replace(
      'DD',
      calendarLabels.dayNames[val.getDay()]
    )
    dateTemplate = dateTemplate.replace('mm', val.getMonth() + 1)
    dateTemplate = dateTemplate.replace(
      'MMM',
      calendarLabels.monthNamesShort[val.getMonth()]
    )
    dateTemplate = dateTemplate.replace(
      'MM',
      calendarLabels.monthNames[val.getMonth()]
    )
    dateTemplate = dateTemplate.replace('yy', val.getFullYear())
    return dateTemplate
  }
  return ''
})

Vue.filter('timeFromDateTimeStamp', val => {
  if (val) {
    let d = new Date(val)
    return d.getHours() + ':' + ('0' + d.getMinutes()).substr(-2)
  }
  return 0
})

Vue.filter('getMonthName', (month, culture = 'en', fullName = false) => {
  let calendarLabels = CalendarLanguageLabels[culture]

  return calendarLabels[!fullName ? 'monthNamesShort' : 'monthNames'][month - 1]
})

Vue.filter('convertTimeStampToIsoDateTimeString', val => {
  let d = new Date(val)

  let yyyy = d.getFullYear()
  let mm = ('0' + (d.getMonth() + 1)).slice(-2)
  let dd = ('0' + d.getDate()).slice(-2)
  let hh = d.getHours()
  let min = ('0' + d.getMinutes()).slice(-2)

  return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min
})

Vue.filter('dateObjectIsoDateString', dateObj => {
  if (typeof dateObj !== 'undefined') {
    let newDateObj = new Date()
    if (typeof dateObj === 'string') {
      newDateObj = parseIsoDateStringToDate(dateObj)
    } else {
      newDateObj = dateObj
    }
    let day = newDateObj.getDate().toString()
    let month = (newDateObj.getMonth() + 1).toString()
    let year = newDateObj.getFullYear().toString()

    if (month.length === 1) {
      month = '0' + month.toString()
    }

    if (day.length === 1) {
      day = '0' + day.toString()
    }

    return year + '-' + month + '-' + day
  }
  return ''
})

Vue.filter('parseIsoDateStringToDate', val => {
  return parseIsoDateStringToDate(val)
})

Vue.filter('getDayFromISODate', val => {
  return parseIsoDateStringToDate(val).getDate()
})

Vue.filter('getMonthFromISODate', val => {
  return parseIsoDateStringToDate(val).getMonth()
})

Vue.filter('getYeahFromISODate', val => {
  return parseIsoDateStringToDate(val).getFullYear()
})

Vue.filter('daysBetweenCurrentDate', dateValue => {
  let currentDate = new Date()

  let one = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  let two = new Date(
    dateValue.getFullYear(),
    dateValue.getMonth(),
    dateValue.getDate()
  )

  // Do the math.
  let millisecondsPerDay = 1000 * 60 * 60 * 24
  let millisBetween = two.getTime() - one.getTime()
  let days = millisBetween / millisecondsPerDay

  // Round down.
  return Math.floor(days)
})

Vue.filter('timeToMinutes', val => {
  let d = new Date(val)
  let hours = d.getHours()
  let minutes = d.getMinutes()

  return Number(hours * 60 + 15 * Math.ceil(minutes / 15))
})

Vue.filter('minutesToTime', val => {
  let realmin = val % 60
  let hours = Math.floor(val / 60)
  return ('0' + hours).slice(-2) + ':' + ('0' + realmin).slice(-2)
})

function parseIsoDateStringToDate(dateString) {
  let time = ('' + dateString).replace(/-/g, '/').replace(/[TZ]/g, ' ')
  let date = new Date(time)
  return date
}

let CalendarLanguageLabels = {
  // DEUTSCH
  de: {
    closeText: 'Schliessen',
    prevText: '&#x2190',
    nextText: '&#x2192',
    currentText: 'Today',
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez'
    ],
    dayNames: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    weekHeader: 'Wk',
    dateFormat: 'dd.mm.yy',
    isoDateFormat: 'yy-mm-dd',
    shortDateFormat: 'dd MMM yy',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'DD, dd. MM yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  },
  // ENGLISH
  en: {
    closeText: 'Close',
    prevText: '&#x2190',
    nextText: '&#x2192',
    currentText: 'Today',
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ],
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'fr', 'Sa'],
    weekHeader: 'Wk',
    dateFormat: 'mm/dd/yy',
    isoDateFormat: 'yy-mm-dd',
    shortDateFormat: 'dd MMM yy',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'DD, MM dd, yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  },
  // SPANISH
  es: {
    ago: 'hace',
    second: 'segundo',
    seconds: 'segundos',
    minute: 'momento',
    minutes: 'momentos',
    hour: 'horas',
    hours: 'horas',
    monthDayFormat: 'MM dd',
    shortDateFormat: 'DD, MMM dd, yy',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'DD, MM dd, yy',
    closeText: 'Cerrar',
    prevText: '&#x3CAnt',
    nextText: 'Sig&#x3E',
    currentText: 'Hoy',
    monthNames: [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre'
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic'
    ],
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado'
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    weekHeader: 'Sm',
    dateFormat: 'dd/mm/yy',
    isoDateFormat: 'yy-mm-dd',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  },
  // NETHERLANDS
  nl: {
    closeText: 'Sluiten',
    prevText: '&#x2190',
    nextText: '&#x2192',
    currentText: 'Vandaag',
    monthNames: [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december'
    ],
    monthNamesShort: [
      'jan',
      'feb',
      'maa',
      'apr',
      'mei',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec'
    ],
    dayNames: [
      'zondag',
      'maandag',
      'dinsdag',
      'woensdag',
      'donderdag',
      'vrijdag',
      'zaterdag'
    ],
    dayNamesShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
    dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
    weekHeader: 'Wk',
    dateFormat: 'dd-mm-yy',
    isoDateFormat: 'yy-mm-dd',
    shortDateFormat: 'dd MMM yy',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'DD dd MM yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  },
  // JAPANESE
  ja: {
    closeText: '閉じる',
    prevText: '&#x2190',
    nextText: '&#x2192',
    currentText: '今日',
    monthNames: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月'
    ],
    monthNamesShort: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月'
    ],
    dayNames: [
      '日曜日',
      '月曜日',
      '火曜日',
      '水曜日',
      '木曜日',
      '金曜日',
      '土曜日'
    ],
    dayNamesShort: ['日', '月', '火', '水', '木', '金', '土', '日'],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土', '日'],
    weekHeader: 'Wk',
    dateFormat: 'yy/mm/dd',
    isoDateFormat: 'yy-mm-dd',
    shortDateFormat: 'dd MMM yy',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'yy年MMddDD',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  },
  // PORTUGUESE
  pt: {
    closeText: 'Fechar',
    prevText: '&#x2190',
    nextText: '&#x2192',
    currentText: 'Hoje',
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Mar&ccedilo',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ],
    dayNames: [
      'Domingo',
      'Segunda-feira',
      'Ter&ccedila-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'S&aacutebado'
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'S&aacuteb'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'S&aacuteb'],
    weekHeader: 'Sem',
    dateFormat: 'dd/mm/yy',
    isoDateFormat: 'yy-mm-dd',
    longDateFormat: 'dd MM yy',
    fullDateFormat: 'DD, dd MM yy',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  }
}
