<template>
  <div class="wrapper-noAuthLogin">
    <Message class="info-explanation is-info">
      {{ $t('Components.Auth.NoAuthLoginForm.Text_Explanation', localeOverride.locale) }}
    </Message>

    <Message
      v-if="logginOnly && profileNotFound"
      class="is-warning"
    >
      {{ $t('Components.Auth.NoAuthLoginForm.Message_UserNotFound', localeOverride.locale) }}
    </Message>

    <BaseForm
      ref="form"
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <EmailField
        name="email"
        type="email"
        :label="$t('Components.ForgotLoginRegister.General.Label_EmailAddress', localeOverride.locale)"
        required
        :inputSizeClass="inputSize"
        :validationRules="emailValidationRules()"
        @input="reInitializeForm"
      />
      <TransitionExpand>
        <div
          v-if="showFullForm && !logginOnly"
          class="mb-4"
        >
          <Message class="is-warning">
            {{ $t('Components.ForgotLoginRegister.Register.Message_MissingData', localeOverride.locale) }}
          </Message>

          <div class="columns is-multiline">
            <div class="column is-half">
              <RequiredField
                name="firstName"
                :label="$t('Components.ForgotLoginRegister.Register.Label_FirstName', localeOverride.locale)"
                :inputSizeClass="inputSize"
                required
              />
            </div>

            <div class="column is-half">
              <RequiredField
                name="lastName"
                :label="$t('Components.ForgotLoginRegister.Register.Label_LastName', localeOverride.locale)"
                :inputSizeClass="inputSize"
                required
              />
            </div>

            <div class="column is-half">
              <BaseBulmaField
                name="password"
                type="password"
                v-model="password"
                :validateOnInput="true"
                :showAllOpenErrors="true"
                :label="$t('Components.ForgotLoginRegister.General.Label_Password', localeOverride.locale)"
                :inputSizeClass="inputSize"
                :validationRules="passwordValidationRules"
                :errorMessages="passwordErrorMessages"
                required
              />
            </div>

            <div class="column is-full">
              <strong v-text="$t('Components.ForgotLoginRegister.Register.Text_Tags', localeOverride.locale)"></strong>
              <span class="has-text-danger">*</span>
              <TagInput
                name="tags"
                :inputSizeClass="inputSize"
                :required="false"
                :validationRules="tagsValidationRules()"
                :errorMessages="tagsErrorMessages"
              />
            </div>

            <div class="column is-full">
              <PrivacyTerms
                :showOnPage="false"
                name="privacyTerms"
                :required="true"
                @termsData="setTermsData"
              />
            </div>
          </div>
          <!-- profile terms -->
        </div>
      </TransitionExpand>

      <div :class="`level is-mobile`">
        <div :class="`level-left`"></div>
        <div :class="`level-right`">
          <div :class="`level-item`">
            <a
              :class="[{ 'is-primary' : !isProcessing }, { 'is-loading': isProcessing }]"
              class="button"
              @click="doSubmit"
              v-html="$t('Components.Auth.NoAuthLoginForm.Button_Request', localeOverride.locale)"
            >
            </a>
          </div>
        </div>
      </div>
    </BaseForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email, minLength } from 'vuelidate/lib/validators'
import BaseForm from '@/components/UI/Form/BaseForm'
import RequiredField from '@/components/UI/Form/RequiredField'
import EmailField from '@/components/UI/Form/EmailField'
import Message from '@/components/UI/Message'
import TransitionExpand from '../UI/TransitionExpand.vue'
import TagInput from '../UI/Form/TagInput.vue'
import profileProvider from '../../providers/profile'
import PrivacyTerms from '../Terms/PrivacyTerms.vue';


export default {
  name: 'NoAuthLoginForm',

  inject: {
    logginOnly: {
      type: Boolean,
      default: false
    },
    localeOverride: {
      type: Object,
      default: function() { return { locale: this.$i18n.locale } }
    },
  },

  props: {
    inputSize: {
      type: String,
      default: ''
    },
    showTermsOnPage: {
      type: Boolean,
      default: false
    }
  },

  components: {
    BaseForm,
    RequiredField,
    EmailField,
    Message,
    TransitionExpand,
    TagInput,
    PrivacyTerms
  },

  data() {
    return {
      isProcessing: false,
      emailCheckTimeout: '',
      showFullForm: false,
      profileNotFound: false,
      email: '',
      password: '',
      timer: null,
      tagsErrorMessages: {
        required: this.$t('Form.InputErrors.Required', this.localeOverride.locale),
        minArrLength: 'Add minimal 3 tags'
      },
      tags: '',
      terms: null,
      passwordErrorMessages: {
        required: this.$t('Form.InputErrors.Required'),
        minLength: this.$t('Form.InputErrors.minLength', { minLength: 8 }),
        uppercaseCharacter: this.$t('Form.InputErrors.uppercaseCharacter'),
        numberCharacter: this.$t('Form.InputErrors.numberCharacter')
      }
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn']),

    passwordValidationRules() {
      let rules = {}

      rules['required'] = required()
      if (this.password.trim() !== '') {
        rules['minLength'] = minLength(8)
        rules['uppercaseCharacter'] = (val) => { return /(?=.*[A-Z])/.test(val) }
        rules['numberCharacter'] = (val) => { return /(?=.*\d)/.test(val) }
      }

      return rules
    },
  },

  created() { },

  methods: {
    setTermsData(data) {
      this.terms = data
    },

    async doSubmit() {
      if (this.$refs.form) {
        await this.checkForm()
        this.$refs.form.submitForm()
      }
    },

    reInitializeForm() {
      if (this.showFullForm) {
        this.showFullForm = false
        this.email = ''
        this.password = ''
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          await this.checkForm()
          clearTimeout(this.timer)
        }, 50);
      }
    },

    emailValidationRules() {
      return {
        required,
        email
      }
    },

    tagsValidationRules() {
      return {
        required,
        minArrLength(value) {
          if (value === '' || value.split(',').length < 3) {
            return false
          }
          return true
        }
      }
    },

    async checkForm() {
      await this.$refs.form.runValidation({ silent: true })
    },

    // Handle form submittion
    async handleSubmit({ formData }) {
      return new Promise(async (resolve, reject) => {
        const IS_VALID = this.$refs.form.isValid()
        if (!IS_VALID) {
          return reject({ response: 'INVALID' })
        }
        this.profileNotFound = false

        // Fetch profile
        return profileProvider.methods.fetchProfile({
          FirstName: formData.firstName ?? '',
          LastName: formData.lastName ?? '',
          Email: formData.email,
          Password: formData.password ?? '',
          Language: this.$i18n.locale,
          ChannelId: 1,
          TermsId: this.terms ? this.terms.Id : 0,
          HasAgreedToTerms: formData.privacyTerms,
        })
          .then(response => {
            this.$emit('callbackAction', {
              authVerificationKey: response.data,
              authEmail: formData.email,
              agreeToTerms: formData.agreeToTerms ?? false
            })
            resolve({ response: 'COMPLETED' })
          })
          .catch(() => {
            this.showFullForm = this.logginOnly ? false : true
            this.profileNotFound = this.logginOnly ? true : false
            this.timer && clearTimeout(this.timer)
            this.timer = setTimeout(() => {
              this.$refs.form.runValidation({ silent: false })
              clearTimeout(this.timer)
            }, 50);
            reject({ response: 'INVALID' })
          })
      })
    },

    // Do action for current form status
    onStatusChange({ status, form }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'VALID':
          this.formError = !form.isValid()
          break
        case 'INVALID':
          this.formError = form.isValid()
          this.isProcessing = false
          break
        case 'COMPLETED':
          this.isProcessing = false
          break
      }
    },
  },
}
</script>
