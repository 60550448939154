<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      class="modal"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Start a virtual room to facilitate your online meetings</p>
          <button
            @click="close"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="content has-margin-bottom">
            <p>
              Seats2meet.com gives you the opportunity to start your own virtual room. At no cost.
              It's easy and based on the name of your meeting. The URL you can share is:
              https://bla.seats2meet.com/<strong>youruniquename</strong>
            </p>
          </div>

          <div class="has-margin-bottom">
            <div class="title is-3 has-text-blue-dark">Create your private room</div>
            <div class="wrapper-input-button">
              <div class="field has-addons">
                <p class="control">
                  <span class="button-label button is-static">https://bla.seats2meet.com/</span>
                </p>
                <p class="control control-input">
                  <input
                    type="text"
                    class="input"
                    v-model="roomName"
                  ></p>
              </div>
              <div class="button-control">
                <button
                  v-if="!roomName"
                  class="button is-disabled"
                  :disabled="roomName"
                >Start</button>
                <a
                  v-else
                  :href="'https://bla.seats2meet.com/' + roomName"
                  target="_blank"
                  class="button is-primary"
                  @click="()=> { $googleAnalytics.pushEvent(`Page-${$route.name}`, 'button-Start', getGaLabel()); close(); }"
                >Start</a>
              </div>
            </div>
          </div>

          <div class="title is-3 has-text-blue-dark">Tips</div>
          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">1</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              You may end up in another room with the same name. No problem, just leave the room and create one with a different name.
            </div>
          </div>

          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">2</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              Protect (as an option) your room with a password. Enter as the first attendee to set it. You will also be the moderator. The option is at the bottom under the <span class="icon is-small">
                <font-awesome-icon :icon="['fa', 'info-circle']" />
              </span> button.
            </div>
          </div>

          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">3</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              Share the url with the people you'll have the meeting with.
            </div>
          </div>

          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">4</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              The meeting room disappears when everyone leaves. Create a new one for each meeting.
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="level is-mobile">
            <div class="level-left has-padding-right">
              <div>Powered by Jitsi.<br />
                <a
                  @click="() => { $googleAnalytics.pushEvent(`Page-${$route.name}`, 'link-PoweredByJitsi', getGaLabel()); close() }"
                  href="https://magazine.seats2meet.com/jitsi-wat-is-het-en-waarom-deze-tool-gebruiken/"
                  target="_blank"
                  rel="noopener"
                >Learn more about why we use this tool!</a></div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    onClickCancel: {
      type: Function,
      default: function() { }
    },

    sectionName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      roomName: ''
    }
  },

  methods: {
    ...mapMutations(['setActiveModalType']),

    getGaLabel() {
      return (this.sectionName ? this.sectionName + ' | ' : '') + 'Popup-StartOwnVirtualRoom'
    },

    close() {
      this.setActiveModalType('')
      this.onClickCancel()
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

.wrapper-input-button {
  display: flex;

  .field {
    flex-grow: 1;
    .control-input {
      width: 100%;
    }
  }

  .button-control {
    margin-left: $gap;
  }

  @media screen and (max-width: 570px) {
    flex-direction: column;

    .button-label {
      font-size: $size-7;
      height: 32px;
    }

    .button-control {
      margin-left: 0;
      text-align: right;
    }
  }
}

.row-tip {
  display: flex;
  margin-bottom: $gap;
  align-items: center;
  .wrapper-tip-icon {
    $iconSize: 32px;
    height: $iconSize;
    width: $iconSize;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-right: $gap;
    position: relative;
    .tip-number {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $iconSize;
      width: $iconSize;
      font-size: 16px;
      color: white;
      font-weight: $weight-bold;
    }
    .font-icon {
      display: inline-block;
      font-size: $iconSize;
      height: $iconSize;
      width: $iconSize;
      line-height: 1;
      color: $blue-dark;
      &:before {
        line-height: 1;
      }
    }
  }
}

.modal-card-foot {
  .level {
    width: 100%;
    @media screen and (max-width: 419px) {
      flex-direction: column;

      .level-left {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
