<template>
  <Modal
    ref="modal"
    :modalTitle="$t('Components.CookieBar.Modal.Title_ModalSettings')"
    modalSize='extra-large'
    confirmButtonCssClass="is-primary"
    :hideActionButton="false"
    :buttonLabelConfirm="$t('Form.Control.Save')"
    :onClickSave="save"
    :hideCancelButton="true"
    :onClickCancel="closeModal"
    :hideCloseButton="true"
    :showModal="showModal"
  >
    <template v-slot:content>
      <loader v-if="isLoading" />
      <div v-else>
        <div class="box is-shadowless mb-6 p-4 has-background-primary-light">
          <p v-html="$t('Components.CookieBar.Modal.Text_Description')"></p>
        </div>
        <div
          v-for="cookieOption in cookies.CookieTypes"
          :key="'c' + cookieOption.Id"
          class="is-flex mt-5"
        >
          <label class="checkbox ml-4 mr-5 mt-1">
            <input
              type="checkbox"
              :disabled="!cookieOption.IsEditable"
              :checked="myCurrentCookiesSettings[cookieOption.Key]===1"
              @change="onChange(cookieOption, $event)"
            />
          </label>
          <div v-html="cookieOption.Short"></div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { eraseCookie, getCookie, setCookie } from '@/utils/browserStorage'
import Modal from '../UI/Components/Modal.vue'
import Loader from '../UI/Loader.vue'

export default {
  name: 'ModalCookieSettings',
  components: { Loader, Modal },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      myCurrentCookiesSettings: {},
      isLoading: true
    }
  },

  computed: {
    ...mapState(
      'cookieStore', ['cookies', 'currentCookieLanguage']
    )
  },

  async mounted() {
    if (this.currentCookieLanguage !== this.$i18n.locale) {
      //loader
      await this.fetchCookieApiData()
      //loader
      this.setCurrentCookieLanguage(this.$i18n.locale)
    }
    this.isLoading = false

    if (Object.keys(this.cookies).length !== 0) {
      const userPreferences = getCookie('s2m_cc') !== '' ? JSON.parse(getCookie('s2m_cc')) : ''
      this.myCurrentCookiesSettings.Version = this.cookies.Version
      this.myCurrentCookiesSettings.Id = this.cookies.Id

      for (let i = 0; i < this.cookies.CookieTypes.length; i++) {
        const cookieType = this.cookies.CookieTypes[i]
        const cookieValue = typeof userPreferences === 'undefined' ? -1 : userPreferences[cookieType.Key]

        this.myCurrentCookiesSettings[cookieType.Key] = !cookieType.IsEditable ? 1 : cookieValue

        if (cookieType.Key === 'ca' && typeof userPreferences === 'undefined') {
          this.myCurrentCookiesSettings[cookieType.Key] = 1
        }
      }
    }
  },

  methods: {
    ...mapMutations('cookieStore', ['setShowCookieSettingsModal', 'updateCookieIsSetState', 'setCurrentCookieLanguage']),
    ...mapActions('cookieStore', ['saveCookieSettings', 'fetchCookieApiData']),

    closeModal() {
      this.onClickCancel()
    },

    onChange(cookieOption, evt) {
      if (cookieOption.IsEditable === true) {
        this.myCurrentCookiesSettings[cookieOption.Key] = evt.target.checked ? 1 : -1
      } else {
        this.myCurrentCookiesSettings[cookieOption.Key] = 1
      }
    },

    save() {
      this.saveCookieSettings(this.myCurrentCookiesSettings)

      let t = setTimeout(() => {
        this.onClickCancel()
        clearTimeout(t)
      }, 5);
    },
  }
}
</script>