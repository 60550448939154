<template>
  <article class="media media-comments">
    <div class="media-left">
      <avatar-image
        class="is-48x48 mb-6"
        :itemId="mComment.ProfileId"
        :imageSrc="mComment.ProfileImage"
        :itemName="mComment.ProfileName"
      />
    </div>
    <div class="media-content">
      <div class="content">
        <div class="has-text-grey postDetailBar is-size-7">
          <span class="date">
            {{ mComment.CreatedOn | dateTimeStampToDate | longDateFormat }} -
            {{ mComment.CreatedOn | timeFromDateTimeStamp }}
          </span>
        </div>
        <div class="is-size-7">
          <div class="text-comment mb-4">
            <a @click.stop="showProfileDetail(mComment.ProfileId)">
              <strong>{{ mComment.ProfileName }},</strong>
            </a>
            {{ mComment.Text }}
          </div>
          <div>
            <a
              v-if="isLoggedIn && !showCommentInput"
              @click="setShowCommentInput()"
              class="button is-small is-primary"
            >
              <span class="icon">
                <i class="fas fa-reply"></i>
              </span>
              <span>Reply</span>
            </a>
            <div v-if="showCommentInput">
              <textarea
                class="textarea has-margin-bottom"
                v-model="commentText"
              />
              <a
                @click="cancelShowCommentInput"
                class="button is-ghost mr-2"
              >Cancel</a>
              <a
                @click="sendComment(mComment)"
                class="button is-primary"
              >Send</a>
            </div>
          </div>
        </div>

        <comment-item
          v-for="comment in filterComments(mComment.Id)"
          :key="comment.Id"
          :commentObj="comment"
          :comments="mComments"
        ></comment-item>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import comment from '@/providers/comment'

export default {
  props: {
    commentObj: null,
    comments: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      commentText: '',
      mComment: this.$props.commentObj,
      mComments: this.$props.comments,
      showCommentInput: false
    }
  },
  computed: mapGetters({
    isLoggedIn: 'getIsLoggedIn'
  }),
  methods: {
    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },

    filterComments(parentId) {
      return this.mComments.filter(c => c.ParentId === parentId)
    },

    setShowCommentInput(comment) {
      this.showCommentInput = true
    },

    cancelShowCommentInput(comment) {
      this.showCommentInput = false
    },

    sendComment(commentObj) {
      comment.methods
        .replyToComment(commentObj.Id, this.commentText)
        .then(response => {
          this.mComments.push(response.data)
          this.commentText = ''
          this.showCommentInput = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
.text-comment {
  word-break: break-word;
  word-wrap: break-word;
}
.media {
  margin-top: 0.5rem;
}
@media screen and (max-width: 420px) {
  .media-left {
    display: none;
  }
  .media-content {
    margin-left: $gap;
  }
}
</style>
