<template>
  <div
    :class="profileDropdownClasses"
    v-click-outside="closeDropDown"
  >
    <div class="dropdown-trigger">
      <div
        class="is-flex is-align-items-center is-clickable"
        @click="toggleDropDown()"
      >
        <div>
          <AvatarImage
            class="image-profile is-1by1"
            :key="avatarKey"
            v-if="isLoggedIn"
            :itemId="profile.Id"
            :itemName="profileFullName"
            :imageSrc="profile.Image"
          />
          <figure
            v-else
            class="image-profile image is-1by1"
          >
            <img
              src="/img/spacer1_1.gif"
              class="is-rounded has-shadow has-background-grey-light"
              :style="{ backgroundImage: `url(/img/defaultProfileImage_v2.png)` }"
              :alt="$t('general.Text_Login')"
            />
          </figure>
        </div>
        <span class="icon vertically-aligned">
          <font-awesome-icon :icon="['fas', !dropdownVisible ? 'angle-down' : 'angle-up']" />
        </span>
      </div>

      <div
        class="dropdown-menu is-unselectable"
        id="dropdown-menu"
        role="menu"
      >
        <div class="dropdown-content has-background-grey-light">
          <div v-if="!isLoggedIn">
            <div class="dropdown-item">
              <strong>{{ greeting }}</strong>,
              <br />
              login to access your personal environment.
            </div>
            <hr class="dropdown-divider has-background-grey mx-4" />
            <div class="dropdown-item">
              <router-link
                v-if="$route.name !== 'Login'"
                @click.native="() => { closeDropDown(); }"
                :to="{ name: 'Login' }"
                class="icon-text is-align-items-center"
              >
                <span class="icon is-small mr-1">
                  <font-awesome-icon :icon="['fas', 'angle-right']" />
                </span>
                <span class="link-holder">Login</span>
              </router-link>
            </div>
          </div>

          <div v-if="isLoggedIn">
            <div class="dropdown-item">
              <strong>{{ greeting }} {{ profile.FirstName }}</strong>,
            </div>

            <template v-for="(main, index) in profileMenu">
              <div
                :key="'m' + index"
                v-if="main.showOnDesktop"
              >
                <hr class="dropdown-divider has-background-grey mx-4" />
                <div class="category-item has-padding-left has-padding-right has-text-weight-bold is-size-6 has-text-primary-dark">
                  <div>{{ main.categoryName }}</div>
                </div>
                <div
                  class="dropdown-item"
                  v-for="(menuItem, menuItemIndex) in main.menu"
                  :key="index + '_mi' + menuItemIndex"
                >
                  <LinkItem
                    :item="menuItem"
                    :isDropdownItem="true"
                    @click.native="() => { $googleAnalytics.pushEvent('ProfileTopMenu', main.gaAction, menuItem.gaLabel); closeDropDown(); }"
                    class="icon-text is-align-items-start is-flex-wrap-nowrap"
                    :class="{ 'is-active': $route.name === menuItem.routeName }"
                  />
                </div>
              </div>
            </template>

            <template v-if="passportState.Locations.length">
              <hr class="dropdown-divider has-background-grey mx-4" />
              <div class="dropdown-item">
                <a
                  @click="() => { $googleAnalytics.pushEvent('ProfileTopMenu','My locations', 'link-my-locations'); closeDropDown(); }"
                  :href="manageUrl + '/account/dashboard'"
                  class="icon-text"
                >
                  <span class="icon is-small mr-1">
                    <font-awesome-icon :icon="['fas', 'angle-right']" />
                  </span>
                  <span>My locations</span>
                </a>
              </div>
            </template>

            <hr class="dropdown-divider has-background-grey mx-4" />
            <div class="dropdown-item">
              <a
                @click="() => { logout(); closeDropDown(); }"
                class="icon-text"
                :class="{ 'is-loading': IsLoggingOut }"
              >
                <span class="icon is-small mr-1">
                  <font-awesome-icon :icon="['fas', 'angle-right']" />
                </span>
                <span>Logout</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../../eventBus/event-bus'
import LinkItem from '../UI/LinkItem.vue'

export default {
  name: 'ProfileMenuItem',

  components: {
    LinkItem,
  },

  data() {
    return {
      dropdownVisible: false,
      IsLoggingOut: false,
      manageUrl: process.env.VUE_APP_CHP_URL,
      profileMenu: this.$store.getters['menuStore/getProfileMenu'],
      newImageCounter: 0
    }
  },

  computed: {
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      notifications: 'getNotifications',
      profile: 'getProfile',
      passportState: 'getPassportState'
    }),

    avatarKey() {
      return 'p_' + this.profile.Id + '_' + this.newImageCounter
    },

    numberOfMessages() {
      let totalNewMessages = 0
      if (this.notifications.length) {
        totalNewMessages += this.notifications.filter(n => n.IsRead === false)
          .length
      }
      return totalNewMessages
    },

    profileFullName() {
      if (!this.profile || this.profile.Id === 0) { return '' }
      return this.profile.FirstName + ' ' + this.profile.LastName
    },

    greeting() {
      let today = new Date()
      let currentHour = today.getHours()
      let hello = ''

      if (currentHour < 12) {
        hello = 'Good morning'
      } else if (currentHour < 18) {
        hello = 'Good afternoon'
      } else {
        hello = 'Good evening'
      }

      return hello
    },

    profileDropdownClasses() {
      if (this.dropdownVisible) {
        return 'dropdown is-active is-right'
      } else {
        return 'dropdown is-right'
      }
    },
  },

  created() {
    EventBus.$on('reloadProfileImage', this.updateNewImageCounter)
  },

  beforeDestroy() {
    EventBus.$off('reloadProfileImage', this.updateNewImageCounter)
  },

  methods: {
    updateNewImageCounter() {
      this.newImageCounter++
    },

    closeDropDown() {
      this.dropdownVisible = false
    },

    toggleDropDown() {
      this.dropdownVisible = !this.dropdownVisible
    },

    handleDropdownMenuItemClick(submenuItem) {
      if (submenuItem.modal) {
        this.setActiveModalType(submenuItem.modal)
      }
      this.closeDropDown()
    },

    logout() {
      this.IsLoggingOut = true
      this.selectedMatch = null
      this.isVisibleSendMessageInputView = false
      this.$store.dispatch('logoff')
    },
  },

  directives: {
    'click-outside': {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // check that click was outside the el and its children
          if (!(el == event.target || el.contains(event.target))) {
            //  if it was, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.image-profile {
  width: 40px;
  height: 40px;
  ::v-deep img {
    max-height: unset;
  }
}

.message-notification {
  background: $red;
  color: white;
  border-radius: 9999px;
  font-size: 10px;
  line-height: 1rem;
}
</style>