<template>
  <div class>
    <div class="row-whyTags has-margin-bottom">
      <span class="message is-warning has-margin-right has-text-weight-bold has-text-warning">Required</span>
      <span v-html="whyText"></span>
    </div>

    <transition-group
      name="fade"
      mode="out-in"
      tag="div"
      class="tagsarea textarea field is-grouped is-grouped-multiline tags"
      :class="{ 'is-borderless' : inputBorderless }"
    >
      <span
        v-for="(tag, index) in tags"
        :key="'t_'+index"
        class="tag is-outlined is-white is-medium"
      >
        <span>{{tag}}</span>
        <a
          @click="removeTag(index)"
          class="delete has-background-danger"
        ></a>
      </span>
      <span
        key="tagInput"
        class="wrapper-input"
      >
        <input
          ref="tagInput"
          type="text"
          v-model="tagInput"
          class="input input-tag"
          placeholder="Add tag"
          @keyup.enter="addTag"
        />
        <a
          @click="addTag"
          key="addTagButton"
          class="button is-small is-primary"
          :class="{'is-disabled': !tagInput }"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </a>
      </span>
    </transition-group>
  </div>
</template>

<script>
import questionProvider from '@/providers/question'
import tagProvider from '@/providers/tag'
export default {
  props: {
    inputBorderless: {
      type: Boolean,
      default: false
    },
    setFocusInput: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    whyText: {
      type: String,
      default: `To connect your question to the right people, we need to know a bit more about this question. You must enter one or more keywords that describe the question best.`
    }
  },

  data() {
    return {
      isLoadingTagsFromQuestion: false,
      tagInput: '',
      tags: [],
      tagInputPlaceHolder: 'Add tag',
      timer: null
    }
  },

  methods: {
    /**
     * Add tag to tags array
     */
    addTag() {
      if (this.tagInput) {
        let text = this.$options.filters.stripHtml(this.tagInput)
        let arr = text.split(/[.\*+-/_#]/)
        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.tags.push(arr[i].trim())
          }
        }
        arr = []
        text = ''
        this.tagInput = ''
        this.$refs.tagInput.focus()
        this.$emit(
          'input',
          this.$options.filters.stripHtml(this.tags.join(','))
        )
      }
    },

    /**
     * Remove tag from array
     */
    removeTag(index) {
      this.tags.splice(index, 1)
      this.$emit('input', this.$options.filters.stripHtml(this.tags.join(',')))
    },

    /**
     * Check if tag already exists in array
     */
    tagAlreadyAdded(tag) {
      var index = this.tags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 80px;
  height: auto;

  &:last-child {
    margin-bottom: $gap;
  }

  .tags {
    .tag {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-tag {
      min-width: 140px;
      width: 140px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
