<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      :key="'modalContent' + Number(showModal)"
      class="modal"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-background"></div>
      <div
        class="modal-card"
        :class="[
          'modal-card-' + modalSize,
          unsetFillModeCssClass,
          animateClass,
        ]"
      >
        <header class="modal-card-head">
          <p
            v-if="!hideHeader"
            class="modal-card-title"
          >
            {{ modalTitle }}
          </p>
          <button
            v-if="!hideHeader && !hideCloseButton"
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
        </header>

        <section
          ref="modalCardBody"
          class="modal-card-body"
          :class="cssClassModalBody"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <div v-if="!isSavingSuccess && !isSavingError">
              <slot name="content"></slot>
            </div>

            <section
              v-if="isSavingSuccess"
              class="content-successMessage has-text-centered"
            >
              <SuccessAnimation class="has-margin-bottom-x2" />
              <div
                v-if="savingSuccessMessage && !isSavingError"
                class="title is-4"
                v-text="savingSuccessMessage"
              ></div>
              <slot name="successMessageSlot"></slot>
            </section>
            <section
              v-if="isSavingError"
              class="content-successMessage has-text-centered"
            >
              <ErrorAnimation class="has-margin-bottom-x2" />
              <div
                v-if="savingErrorMessage && !isSavingSuccess"
                class="title is-4"
                v-text="savingErrorMessage"
              ></div>
              <slot name="errorMessageSlot"></slot>
            </section>
          </transition>
        </section>

        <transition
          name="fade"
          mode="out-in"
        >
          <footer
            v-if="!hideFooter"
            class="modal-card-foot"
          >
            <TransitionExpand>
              <ui-submit-form-controls
                v-if="!hideFooterButtons"
                :onClickSave="onClickSave"
                :onClickCancel="closeModal"
                :isSaving="isSaving"
                :disableSaveButton="disableSaveButton"
                :buttonLabelConfirm="buttonLabelConfirm"
                :buttonLabelCancel="buttonLabelCancel"
                :hideCancelButton="hideCancelButton"
                :hideSaveButton="hideSaveButton"
                :confirmButtonCssClass="confirmButtonCssClass"
              />
            </TransitionExpand>
          </footer>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import ErrorAnimation from '@/components/UI/Components/ErrorAnimation'
import SuccessAnimation from '@/components/UI/Components/SuccessAnimation'
import TransitionExpand from '../TransitionExpand.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'modal',

  components: {
    ErrorAnimation,
    SuccessAnimation,
    TransitionExpand,
  },

  inject: {
    localeOverride: {
      type: Object,
      default: function() { return { locale: this.$i18n.locale } }
    },
  },

  props: {
    buttonLabelConfirm: {
      type: String,
      default: function() {
        return this.$t('Form.Control.Save', this.localeOverride.locale)
      },
    },
    buttonLabelCancel: {
      type: String,
      default: function() {
        return this.$t('Form.Control.Cancel', this.localeOverride.locale)
      },
    },
    confirmButtonCssClass: {
      type: String,
      default: 'is-primary',
    },
    disableSaveButton: {
      type: Boolean,
      default: false,
    },

    isSaving: {
      type: Boolean,
      default: false,
    },

    isSavingSuccess: {
      type: Boolean,
      default: false,
    },
    savingSuccessMessage: {
      type: String,
      default: function() {
        return this.$t('General.SavingSuccess', this.localeOverride.locale)
      },
    },
    isSavingError: {
      type: Boolean,
      default: false,
    },
    savingErrorMessage: {
      type: String,
      default: function() {
        return this.$t('General.SavingError', this.localeOverride.locale)
      },
    },

    modalTitle: {
      type: String,
      default: '',
    },
    onClickCancel: {
      type: Function,
      default: function() { },
      required: false,
    },
    onClickSave: {
      type: Function,
      default: function() { },
      required: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    hideFooterButtons: {
      type: Boolean,
      default: false,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideSaveButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Sizes are:
     * - dynamic (size will be determent by content)
     * - small (width is set to auto)
     * - normal (is bulma default)
     * - large (1024)
     * - extra-large (1200)
     */
    modalSize: {
      type: String,
      default: 'normal',
    },
    cssClassModalBody: {
      type: String,
      default: ''
    }
  },

  watch: {
    hideFooterButtons() {
      if (this.timerCalculateModalBodySize) {
        clearTimeout(this.timerCalculateModalBodySize)
      }
      this.timerCalculateModalBodySize = setTimeout(() => {
        this.calculateModalBodySize()
        clearTimeout(this.timerCalculateModalBodySize)
      }, 500);
    }
  },

  data() {
    return {
      modalAnimation: this.showModal,
      animateTimer: null,
      unsetFillModeCssClass: 'unset-fill-mode',
      timerCalculateModalBodySize: null
    }
  },

  computed: {
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
    }),

    clearBodyOverflowHidden() {
      return this.activeDrawerType === ''
    },

    animateClass() {
      let cls = 'animated fade'
      cls = cls + (this.modalAnimation ? 'In' : 'Out')
      cls = cls + ' faster'
      return cls
    },
  },

  created() {
    // Body tag
    document.body.style.overflow = 'hidden'
    // HTML tag
    document.documentElement.style.overflow = 'hidden'
  },

  mounted() {
    this.calculateModalBodySize()
    window.addEventListener('resize', this.calculateModalBodySize)
    let t = setTimeout(() => {
      this.calculateModalBodySize()
      clearTimeout(t)
    }, 10);
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    window.removeEventListener('resize', this.calculateModalBodySize)
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }

    if (this.clearBodyOverflowHidden) {
      // Body tag
      document.body.style.overflow = ''
      // HTML tag
      document.documentElement.style.overflow = ''
    }
  },

  methods: {
    closeModal() {
      this.unsetFillModeCssClass = ''
      this.modalAnimation = false
      this.animateTimer = setTimeout(this.onClickCancel, 0)
    },

    calculateModalBodySize() {
      this.$emit('modalBodySize', this.$refs.modalCardBody.getBoundingClientRect())
    }
  },
}
</script>

<style lang="scss">
.modal {
  .modal-card-body {
    position: relative;
    .vc-popover-content-wrapper {
      position: absolute !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.modal {
  z-index: 10000000000;

  .modal-card {
    &.animated {
      animation-fill-mode: unset;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: $gap;
    padding-right: $gap;
  }
  @media screen and (min-width: 769px) {
    .modal-card {
      &-dynamic {
        width: auto !important;
      }
      &-large {
        width: 100% !important;
        max-width: 990px;
        @media screen and (max-width: 1020px) {
          padding-left: $gap;
          padding-right: $gap;
        }
      }
      &-extra-large {
        width: 100% !important;
        max-width: 1200px;
        @media screen and (max-width: 1230px) {
          padding-left: $gap;
          padding-right: $gap;
        }
      }
    }
  }
  .modal-card-body {
    .vc-popover-content-wrapper {
      position: fixed !important;
    }
  }
  .modal-card-foot {
    .buttons {
      flex: 1;
    }
  }
}
</style>
