<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="isInitiated"
      class="checkinWorkingOnWrapper has-text-centered"
    >
      <div class="field">
        <input
          type="text"
          @focus="getLatLon"
          v-on:keyup.13="saveWorkingOn"
          class="input input-workingOn is-normal"
          v-model="workingOn"
          :placeholder="placeholder"
        />
        <p v-if="showWorkingOnWarning">
          <span
            class="help"
            v-bind:class="{ 'is-danger': showWorkingOnWarning }"
          >Please add what you're working on today</span>
        </p>
        <hr class="separator no-margin-bottom" />
        <button
          class="button is-primary is-normal"
          :disabled="isSaving || isLoading"
          :class="{ 'is-loading': isSaving || isLoading }"
          @click="saveWorkingOn"
          v-text="buttonText"
        >
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import checkinProvider from '../providers/checkin'
import { EventBus } from '../eventBus/event-bus'

export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      workingOn: this.getWorkinOn()
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      currentCheckin: 'getCurrentCheckin',
      hasAgreedToTerms: 'getHasAgreedToTerms',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      latitude: 'getLatitude',
      longitude: 'getLongitude'
    }),

    buttonText() {
      if (this.currentCheckin && !this.currentCheckin.HasLeft && this.currentCheckin.EventId === 0) { return 'Update' }
      if (this.currentCheckin && !this.currentCheckin.HasLeft && this.currentCheckin.EventId !== 0) { return 'Continue' }
      return 'Save'
    },

    workingOnEmpty() {
      if (typeof this.workingOn === 'undefined' || this.workingOn === '') {
        return true
      }
      return false
    },
    workingOnNotEmpty() {
      if (typeof this.workingOn !== 'undefined' && this.workingOn !== '') {
        return true
      }
      return false
    },

    showWorkingOnWarning() {
      let showWarning = false

      if (
        this.validationCounter > 0 &&
        (typeof this.workingOn === 'undefined' ||
          this.workingOn === '' ||
          this.workingOn.length <= 2)
      ) {
        showWarning = true
      }

      return showWarning
    }
  },

  watch: {
    currentCheckin() {
      this.workingOn =
        this.currentCheckin !== null ? this.currentCheckin.WorkingOn : ''
    }
  },

  mounted() {
    this.workingOn = this.getWorkinOn()
  },

  created() {
    let self = this
    EventBus.$on('isLoadingMatchesCheck', value => {
      self.isLoading = value
    })
  },


  methods: {
    getLatLon() {
      if (
        this.currentCheckin === null ||
        (this.currentCheckin !== null &&
          this.currentCheckin.LocationId === 0 &&
          this.currentCheckin.EventId === 0)
      ) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.processPosition)
        }
      }
    },

    getWorkinOn() {
      let checkin = this.currentCheckin

      if (checkin && !checkin.HasLeft) {
        return checkin.WorkingOn
      }
      return ''
    },

    processPosition(position) {
      this.$store.commit('setLatitude', position.coords.latitude)
      this.$store.commit('setLongitude', position.coords.longitude)
    },

    saveWorkingOn() {
      if (this.isSaving) { return }

      if (this.currentCheckin) {
        // Update checkin
        this.updateCheckin()

        // Push GA event
        this.$googleAnalytics.pushEvent(
          `Page-${this.$route.name}`,
          'button-Update',
          'Focus'
        )
      } else {
        this.createNewCheckin()
      }
    },

    /**
     * Post new checkin
     */
    createNewCheckin() {
      let currentMinutes = this.$options.filters.timeToMinutes(new Date())
      let rounded = Math.ceil(currentMinutes / 15) * 15
      let startTime = rounded
      let endTime = 1439

      if (!this.isSaving) {
        this.isSaving = true

        checkinProvider.methods.postCheckin({
          StartMinutes: startTime,
          EndMinutes: endTime,
          Latitude: this.latitude,
          Longitude: this.longitude,
          Status: this.$options.filters.stripHtml(this.workingOn)
        })
          .then(response => {
            this.$emit('checkedIn', response.data)
            this.$store.commit('setCurrentCheckin', response.data)
          })
          .catch(e => {
            this.$console.info(e)
          })
          .then(() => {
            this.isSaving = false
          })
      }
    },

    /**
     * Update current checkin
     */
    updateCheckin() {
      let validationCounter = this.validationCounter
      validationCounter++
      this.validationCounter = validationCounter

      if (this.isSaving && this.showWorkingOnWarning === true) { return }

      this.isSaving = true

      let latitude = this.currentCheckin.Latitude
      let longitude = this.currentCheckin.Longitude

      if (
        this.currentCheckin.LocationId === 0 &&
        this.currentCheckin.EventId === 0 &&
        latitude === 0 &&
        longitude === 0
      ) {
        latitude = this.latitude
        longitude = this.longitude
      }

      checkinProvider.methods.updateCheckin({
        Id: this.currentCheckin.Id,
        StartDate: this.currentCheckin.StartDate,
        StartMinutes: this.currentCheckin.StartMinutes,
        EndDate: this.currentCheckin.EndDate,
        EndMinutes: this.currentCheckin.EndMinutes,
        Latitude: latitude,
        Longitude: longitude,
        Status: this.$options.filters.stripHtml(this.workingOn),
        Tags: this.currentCheckin.Tags
      })
        .then(response => {
          this.$emit('callbackCheckinUpdated', response.data)
          this.$store.commit('setCurrentCheckin', response.data)
        })
        .catch(e => { })
        .then(() => {
          this.isSaving = false
        })
    },

    getStartTimeNearestQuarter() {
      let now = new Date()
      let minutes = now.getMinutes()
      let hours = now.getHours()
      let year = now.getFullYear()
      let month = now.getMonth()
      let day = now.getDate()

      let m = (parseInt((minutes + 7.5) / 15) * 15) % 60
      let h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours

      return new Date(year, month, day, h, m)
    },

    getEndTime() {
      let now = new Date()
      let year = now.getFullYear()
      let month = now.getMonth()
      let day = now.getDate()

      return new Date(year, month, day, 23, 59)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media screen and (max-width: $desktop) {
    width: 86%;
  }
}
</style>
