import Vue from 'vue'
import { mapGetters } from 'vuex'
import Router from 'vue-router'
import store from '../store/store.js'
import log from '@/providers/log'

/**
 * Route files
 */
import aboutRoutes from '@/router/AboutRoutes'
import articleRoutes from '@/router/ArticleRoutes'
import eventRoutes from '@/router/EventRoutes'
import networkRoutes from '@/router/NetworkRoutes'
import proposalRoutes from '@/router/ProposalRoutes'
import reservationRoutes from '@/router/ReservationRoutes'

const Chats = () => import('@/views/Chats')
const ConfirmProfile = () => import('@/views/ConfirmProfile')
const Dashboard = () => import('@/views/Dashboard')
const Groups = () => import('@/views/Groups')
const Jobs = () => import('@/views/Jobs')
const Inbox = () => import('@/views/Inbox')
const Login = () => import('@/views/Login')
const MatchedWith = () => import('@/views/MatchedWith')
const Matches = () => import('@/views/Matches')
// const ProfileDetail = () => import('@/views/profile/ProfileDetail')
const Questions = () => import('@/views/Questions')
const QuestionDetail = () => import('@/views/QuestionDetail')
const Register = () => import('@/views/Register')
const ResetPassword = () => import('@/views/ResetPassword')
// const Reviews = () => import('@/views/Reviews')
const Vouchers = () => import('@/views/Vouchers')
const Locations = () => import('@/views/Locations')

Vue.use(Router)

let routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/chats',
    name: 'Chats',
    // component: Chats,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/confirm/:confirmToken',
    name: 'ConfirmProfile',
    component: ConfirmProfile
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/inbox',
    name: 'Inbox',
    component: Inbox,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/matchedwith',
    name: 'MatchedWith',
    component: MatchedWith,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/matches',
    name: 'Matches',
    component: Matches,
    meta: {
      requiresAuth: true
    }
  },

  /**
   * Question routes
   */
  {
    path: '/questions',
    name: 'Questions',
    // component: Questions,
    // props: { showQuestionsProfileCreated: true, pageView: 'all' },
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/questionsanswered',
    redirect: { name: 'QuestionsAnswered' }
  },
  {
    path: '/questions/answered',
    name: 'QuestionsAnswered',
    component: Questions,
    props: { showQuestionsProfileAnswered: true, pageView: 'answered' },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questions/asked',
    name: 'QuestionsAsked',
    component: Questions,
    props: { showQuestionsProfileAnswered: true, pageView: 'asked' },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questions/new',
    name: 'QuestionsNew',
    component: Questions,
    props: { showQuestionsProfileAnswered: true, pageView: 'new' },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questions/relevant',
    name: 'QuestionsRelevant',
    redirect: { name: 'Questions'}
    // component: Questions,
    // props: { showQuestionsProfileAnswered: true, pageView: 'recommended' },
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/questions/:questionId',
    name: 'QuestionDetail',
    component: QuestionDetail,
    meta: {
      requiresAuth: true
    }
  },
  // END questions

  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetpassword/:confirmToken',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/reviews',
    name: 'Reviews',
    // component: Reviews,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/vouchers',
    name: 'Voucher',
    component: Vouchers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      requiresAuth: true
    }
  },

  // PROFILE
  {
    path: '/detail',
    name: 'Detail',
    // component: ProfileDetail,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/detail/mail',
    name: 'DetailMail',
    redirect: { name: 'Detail' }
    // component: ProfileDetail,
    // props: { showProfile: false, showMailSettings: true },
    // meta: {
    //   requiresAuth: true
    // }
  }
  // {
  //   path: '/detail/settings',
  //   name: 'Settings',
  //   component: ''
  // }
  // END PROFILE
]

routes = routes.concat(aboutRoutes)
routes = routes.concat(articleRoutes)
routes = routes.concat(eventRoutes)
routes = routes.concat(networkRoutes)
routes = routes.concat(proposalRoutes)
routes = routes.concat(reservationRoutes)

let router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // when use press back button will go at the position he was on the page
      return savedPosition
    }
    if (to.hash) {
      // if has a hash positition to go
      return { selector: to.hash } // go to the page in scrolled Position
    }
    return { x: 0, y: 0 } // go to the page in scroll = 0 Position
  },
  routes
})

router.be

router.beforeEach((to, from, next) => {
  let isLoggedin = store.getters.getIsLoggedIn
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  store.commit('setActiveDrawerType', '')

  switch (to.name) {
    case 'Dashboard':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/network')
      break;
    case 'Detail':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/profile')
      break;
    case 'Reservations':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/reservations')
      break;
    case 'proposals':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/reservations/proposals')
      break;
    case 'ProfileDetail':
      log.methods.saveItemView(0, to.params.profileId)
      break
    case 'QuestionsAnswered':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/activities/questions')
      break
    case 'Chats':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/messages')
      break
    case 'Events':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/network/events')
      break;
    case 'EventDetail':
      log.methods.saveItemView(2, to.params.eventId)
      break
    case 'Questions':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/network/questions')
      break;
    case 'QuestionDetail':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/network/questions/' + to.params.questionId)
      // log.methods.saveItemView(4, to.params.questionId)
      break
    case 'proposal-detail':
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/en/my/reservations/proposals/' + to.params.proposalKey)
      // log.methods.saveItemView(4, to.params.questionId)
      break
    case 'ArticleDetail':
      log.methods.saveItemView(1, to.params.articleId)
      break
    case 'LocationDetail':
      log.methods.saveItemView(3, to.params.locationId)
      break
    default:
      window.location.replace(process.env.VUE_APP_S2M_BASE_URL)
      break
  }

  if (to.name === 'EventDetail') {
      next()
  }
  return

  // if (requiresAuth && !isLoggedin) {
  //   store.commit('setLoginCallback', to)
  //   next('/login')
  // } else if (requiresAuth && isLoggedin) {
  //   next()
  // } else {
  //   if (to.name !== 'Login') {
  //     store.commit('setLoginCallback', to)
  //   }
  //   if (isLoggedin && (to.name === 'Login' || to.name === 'Register')) {
  //     next('/')
  //   } else {
  //     next()
  //   }
  // }
})

export default router
