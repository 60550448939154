<template>
  <div
    class="eventImage"
    :class="[!isTile ? 'is-detail-header' : 'is-tile-header' ]"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="startDate !== '' && !hideDateTime && isTile"
        class="dateTime has-background-red-light has-text-centered is-uppercase"
      >
        <div
          :class="[!isTile ? 'is-size-3' : 'is-size-5' ]"
          class="has-text-weight-bold"
        >{{ date }}</div>
        <div
          v-if="time !== ''"
          class="time"
          :class="[!isTile ? 'is-size-5' : 'is-size-7' ]"
        >{{ time }}</div>
      </div>
    </transition>

    <figure
      class="image"
      :class="[!isTile ? 'is-16by9' : 'is-3by2' ]"
    >
      <img
        src="@/assets/img/spacer3_1.gif"
        :style="{backgroundImage: `url(${image})`}"
        class="has-background-grey"
      />
    </figure>
  </div>
</template>

<script>
export default {

  props: {
    isTile: {
      type: Boolean,
      default: false
    },
    hideDateTime: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    startMinutes: {
      type: Number,
      default: 0
    },
    endMinutes: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
    }
  },

  computed: {
    date() {
      let strDate = this.$options.filters.dateFormat(this.startDate, 'en', '', 'dd MMM')
      let endDate = this.$options.filters.dateFormat(this.endDate, 'en', '', 'dd MMM')
      if (strDate !== endDate) {
        strDate += ' - ' + endDate
      }
      return strDate
    },

    time() {
      let strTime = ''
      if (this.startMinutes === 0 && (this.endMinutes === 0 || this.endMinutes === 1440)) {
        return ''
      }
      strTime = this.$options.filters.minutesToTime(this.startMinutes)
      if (this.endMinutes !== this.startMinutes) {
        strTime += ' - ' + this.$options.filters.minutesToTime(this.endMinutes)
      }
      return strTime
    }
  }
}
</script>

<style lang="scss" scoped>
.is-detail-header {
  figure {
    @media screen and (max-width: 800px) {
      height: 240px;
    }
    img {
      // opacity: 0.8;
    }
  }
}
.eventImage {
  // background-color: $grey-light;
  // border: 5px solid $red-light;
  overflow: hidden;
  position: relative;

  // .title {
  //   bottom: (2 * $gap);
  //   left: $gap;
  //   margin-top: 0;
  //   position: absolute;
  //   right: $gap;
  //   z-index: 1;

  //   h2 {
  //     text-shadow: 2px 2px 5px $black;
  //   }
  // }

  .dateTime {
    left: 5%;
    position: absolute;
    z-index: 2;
    padding: 10px;
  }
}
</style>
