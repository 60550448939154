import Vue from "vue";
import store from '@/store/store.js'

const state = {
  siteMenu: [
    {
      gaAction: 'SubMenu-Connect',
      hasSelectedMenu: false,
      isHover: false,
      routeName: '',
      text: 'Connect',
      position: 'is-left',
      menu: [
        {
          gaLabel: 'link-FindPeople',
          routeName: 'Network',
          text: 'Find people and expertise',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Locations',
          routeName: 'Locations',
          text: 'Explore locations',
          externalUrl: ''
        }
      ]
    },
    {
      gaAction: 'SubMenu-Collaborate',
      hasSelectedMenu: false,
      isHover: false,
      routeName: '',
      text: 'Collaborate',
      position: 'is-left',
      menu: [
        {
          gaLabel: 'link-Questions',
          routeName: 'Questions',
          text: 'Explore questions',
          externalUrl: ''
        },
        {
          gaLabel: 'link-AskQuestion',
          routeName: 'QuestionsNew',
          text: 'Ask a question',
          externalUrl: ''
        },
        // {
        //   gaLabel: 'link-ModalVirtualLounge',
        //   routeName: 'Network',
        //   text: 'Virtual cowork lounge',
        //   externalUrl: '',
        //   modal: ''
        // },
        {
          gaLabel: 'link-ModalOwnVirtualRoom',
          routeName: '',
          text: 'Your own virtual room',
          externalUrl: '',
          modal: 'modalJitsiStartaRoomTips'
        }
      ]
    },
    {
      gaAction: 'SubMenu-Grow',
      hasSelectedMenu: false,
      isHover: false,
      routeName: '',
      text: 'Grow',
      position: 'is-left',
      menu: [
        {
          gaLabel: 'link-Events',
          routeName: 'Events',
          text: 'Explore events',
          externalUrl: ''
        },
        {
          gaLabel: 'link-CreateEvent',
          routeName: 'NewEvents',
          text: 'Create an event',
          externalUrl: ''
        },
        {
          gaLabel: 'link-ReadStories',
          routeName: '',
          text: 'Read stories',
          externalUrl: 'https://magazine.seats2meet.com'
        },
        {
          gaLabel: 'link-WriteBlog',
          routeName: '',
          text: 'Write a blog',
          externalUrl: 'https://magazine.seats2meet.com/guide-for-bloggers/'
        }
      ]
    },
    {
      gaAction: 'SubMenu-About',
      hasSelectedMenu: false,
      isHover: false,
      routeName: '',
      text: 'About',
      position: 'is-right',
      menu: [
        {
          gaLabel: 'link-AboutPassport',
          routeName: 'AboutPassport',
          text: 'About the S2M passport',
          externalUrl: ''
        },
        {
          gaLabel: 'link-ReadTips',
          routeName: 'AboutTips',
          text: 'Read our best tips',
          externalUrl: ''
        }
      ]
    },
    {
      gaAction: 'MainMenu',
      gaLabel: 'link-Seats2meet.com',
      hasSelectedMenu: false,
      isHover: false,
      routeName: '',
      text: 'Seats2meet.com',
      position: 'is-right',
      externalUrl: process.env.VUE_APP_S2M_BASE_URL,
      menu: [],
    }
  ],

  profileMenu: [
    {
      gaAction: 'SubMenu-My',
      showOnDesktop: true,
      showOnTouch: true,
      categoryName: 'My...',
      menu: [
        {
          gaLabel: 'link-Inbox',
          manualPageRoute: '',
          routeName: 'Inbox',
          text: 'Notifications',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Profile',
          manualPageRoute: 'profile',
          routeName: 'Detail',
          text: 'Profile',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Reservations',
          manualPageRoute: '',
          routeName: 'Reservations',
          text: 'Bookings',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Proposals',
          manualPageRoute: '',
          routeName: 'proposals',
          text: 'Proposals',
          externalUrl: ''
        },
        {
          gaLabel: 'link-QuestionsAsked',
          manualPageRoute: '',
          routeName: 'QuestionsAsked',
          text: 'Questions',
          externalUrl: ''
        },
        {
          gaLabel: 'link-OrganizedEvents',
          manualPageRoute: '',
          routeName: 'OrganizedEvents',
          text: 'Events I organize',
          externalUrl: ''
        },
        {
          gaLabel: 'link-AttendingEvents',
          manualPageRoute: '',
          routeName: 'AttendingEvents',
          text: 'Events I go to',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Chats',
          manualPageRoute: '',
          routeName: 'Chats',
          text: 'Chats',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Reviews',
          manualPageRoute: '',
          routeName: 'Reviews',
          text: 'Reviews',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Voucher',
          manualPageRoute: '',
          routeName: 'Voucher',
          text: 'Vouchers',
          externalUrl: ''
        }
      ]
    },

    {
      gaAction: 'SubMenu-Relevant',
      showOnDesktop: true,
      showOnTouch: true,
      categoryName: 'Relevant...',
      menu: [
        {
          gaLabel: 'link-Events',
          manualPageRoute: '',
          routeName: 'RelevantEvents',
          text: 'Events',
          externalUrl: ''
        },
        {
          gaLabel: 'link-Questions',
          manualPageRoute: '',
          routeName: 'QuestionsRelevant',
          text: 'Questions',
          externalUrl: ''
        }
      ]
    },

    {
      gaAction: 'SubMenu-Settings',
      showOnDesktop: false,
      showOnTouch: true,
      categoryName: 'My settings',
      menu: [
        {
          gaLabel: 'link-CookieSettings',
          manualPageRoute: 'cookieSettings',
          routeName: 'Detail',
          text: 'Cookie settings',
          externalUrl: ''
        },
        {
          gaLabel: 'link-MailSettings',
          manualPageRoute: 'mailSettings',
          routeName: 'Detail',
          text: 'Mail settings',
          externalUrl: ''
        },
        {
          gaLabel: 'link-ResetPassword',
          manualPageRoute: 'resetPassword',
          routeName: 'Detail',
          text: 'Reset password',
          externalUrl: ''
        },
        {
          gaLabel: 'link-DeleteProfile',
          manualPageRoute: 'delete',
          routeName: 'Detail',
          text: 'Delete profile',
          externalUrl: ''
        }
      ]
    }
  ],

  selectedMenuItems: [0, 0, 0, 0],
  visiblePageComponent: '' 
}

const getters = {
  getSiteMenu: state => state.siteMenu,
  getProfileMenu: state => state.profileMenu
}

const mutations = {
  /**
   * Set visible page component name
   */
  setVisiblePageComponent: (state, newState) => {
    state.visiblePageComponent = newState;
  },
  setSelectedMenuItemIndex: (
    state,
    { menuGroupIndex, selectedMenuItemIndex }
  ) => {
    Vue.set(state.selectedMenuItems, menuGroupIndex, selectedMenuItemIndex)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
