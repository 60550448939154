<template>
  <div id="skyline">
    <div
      :id="topBannerId"
      class="topBanner"
    >
      <div class="hero">
        <div class="hero-body">
          <div
            v-if="showTitle"
            class="container is-fluid"
          >
            <h2
              class="title is-2 is-size-3-touch has-text-centered"
              :class="{
                'has-text-white':
                  (skyLineIndex >= 0 && skyLineIndex < 8) ||
                  (skyLineIndex >= 19 && skyLineIndex < 24)
              }"
            >{{ title }}</h2>
            <p
              class="subtitle is-4 is-size-5-mobile is-size-4-tablet has-text-centered"
              :class="{
                'has-text-white':
                  (skyLineIndex >= 0 && skyLineIndex < 8) ||
                  (skyLineIndex >= 19 && skyLineIndex < 24)
              }"
            >{{ subTitle }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="skyLineIndex >= 21 || skyLineIndex <= 5"
      class="stars"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    subTitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      dayTimeSpeed: 60000,
      grad_count: 24,
      topBannerId: 'topBanner_' + new Date().getTime(),
      skyLineIndex: new Date().getHours(),
      layer1: document.createElement('div'),
      layer2: document.createElement('div'),
      layer1Class: '',
      layer2Class: ''
    }
  },

  created() { },

  computed: {
    showTitle() {
      let visible = false
      if (
        this.$route.name === 'Dashboard' ||
        this.$route.name === 'Login' ||
        this.$route.name === 'Register' ||
        this.$route.name === 'ConfirmProfile' ||
        this.$route.name === 'ResetPassword'
      ) {
        visible = true
      }
      return visible
    }
  },

  mounted() {
    this.setupSkyLine()
  },

  methods: {
    apply_styles(elms, styles) {
      !Array.isArray(elms) && (elms = [elms])
      for (let i = 0; i < elms.length; i++) {
        for (let key in styles) {
          if (styles.hasOwnProperty(key)) {
            elms[i].style[key] = styles[key]
          }
        }
      }
    },

    fadeSkyLine() {
      let self = this
      let o = window.getComputedStyle(this.layer2).opacity
      if (o < 1) {
        this.layer2.style.opacity = +o + 0.05
        let t1 = setTimeout(() => {
          self.fadeSkyLine()
          clearTimeout(t1)
        }, this.dayTimeSpeed)
      } else {
        this.layer2.style.opacity = 1
        let t2 = setTimeout(() => {
          self.flip()
          clearTimeout(t2)
        }, this.dayTimeSpeed)
      }
    },

    flip() {
      if (this.skyLineIndex >= this.grad_count - 1) {
        this.skyLineIndex = 0
      }

      this.layer2.style.opacity = 0
      this.layer1.className = 'sky-gradient-' + this.key(++this.skyLineIndex)
      this.layer2.className = 'sky-gradient-' + this.key(this.skyLineIndex + 1)

      this.fadeSkyLine()
    },

    key(n) {
      return n < 10 ? '0' + n : n
    },

    setupSkyLine() {
      this.layer1.className = 'sky-gradient-' + this.key(this.skyLineIndex)
      this.layer2.className = 'sky-gradient-' + this.key(this.skyLineIndex + 1)
      this.apply_styles([this.layer1, this.layer2], {
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        right: '0px'
      })
      this.layer2.style.opacity = 0
      document.getElementById(this.topBannerId).appendChild(this.layer1)
      document.getElementById(this.topBannerId).appendChild(this.layer2)
      this.fadeSkyLine()
    }
  }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
#skyline {
  position: relative;
  .topBanner {
    padding-bottom: $gap * 3;
    position: relative;
    @media screen and (max-width: $tablet) {
      padding-bottom: $gap * 2;
    }
  }
}

.hero {
  position: relative;
  z-index: 1;
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: url("~@/assets/img/stars.png") repeat top center;
  z-index: 0;
}

.greeting-wrapper {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}
</style>

<style lang="scss">
#skyline {
  .sky-gradient-00,
  .sky-gradient-24 {
    color: white;
    background: #00000c;
  }

  /* Gradients */
  .sky-gradient-00,
  .sky-gradient-24 {
    background: #00000c;
  }
  .sky-gradient-01 {
    background: linear-gradient(to bottom, #020111 85%, #191621 100%);
  }
  .sky-gradient-02 {
    background: linear-gradient(to bottom, #020111 60%, #20202c 100%);
  }
  .sky-gradient-03 {
    background: linear-gradient(to bottom, #020111 10%, #3a3a52 100%);
  }
  .sky-gradient-04 {
    background: linear-gradient(to bottom, #20202c 0%, #515175 100%);
  }
  .sky-gradient-05 {
    background: linear-gradient(
      to bottom,
      #40405c 0%,
      #6f71aa 80%,
      #8a76ab 100%
    );
  }
  .sky-gradient-06 {
    background: linear-gradient(
      to bottom,
      #4a4969 0%,
      #7072ab 50%,
      #cd82a0 100%
    );
  }
  .sky-gradient-07 {
    background: linear-gradient(
      to bottom,
      #757abf 0%,
      #8583be 60%,
      #eab0d1 100%
    );
  }
  .sky-gradient-08 {
    background: linear-gradient(to bottom, #82addb 0%, #ebb2b1 100%);
  }
  .sky-gradient-09 {
    background: linear-gradient(
      to bottom,
      #94c5f8 1%,
      #a6e6ff 70%,
      #b1b5ea 100%
    );
  }
  .sky-gradient-10 {
    background: linear-gradient(to bottom, #b7eaff 0%, #94dfff 100%);
  }
  .sky-gradient-11 {
    background: linear-gradient(to bottom, #9be2fe 0%, #67d1fb 100%);
  }
  .sky-gradient-12 {
    background: linear-gradient(to bottom, #90dffe 0%, #38a3d1 100%);
  }
  .sky-gradient-13 {
    background: linear-gradient(to bottom, #57c1eb 0%, #246fa8 100%);
  }
  .sky-gradient-14 {
    background: linear-gradient(to bottom, #2d91c2 0%, #1e528e 100%);
  }
  .sky-gradient-15 {
    background: linear-gradient(
      to bottom,
      #2473ab 0%,
      #1e528e 70%,
      #5b7983 100%
    );
  }
  .sky-gradient-16 {
    background: linear-gradient(
      to bottom,
      #1e528e 0%,
      #265889 50%,
      #9da671 100%
    );
  }
  .sky-gradient-17 {
    background: linear-gradient(
      to bottom,
      #1e528e 0%,
      #728a7c 50%,
      #e9ce5d 100%
    );
  }
  .sky-gradient-18 {
    background: linear-gradient(
      to bottom,
      #154277 0%,
      #576e71 30%,
      #e1c45e 70%,
      #b26339 100%
    );
  }
  .sky-gradient-19 {
    background: linear-gradient(
      to bottom,
      #163c52 0%,
      #4f4f47 30%,
      #c5752d 60%,
      #b7490f 80%,
      #2f1107 100%
    );
  }
  .sky-gradient-20 {
    background: linear-gradient(
      to bottom,
      #071b26 0%,
      #071b26 30%,
      #8a3b12 80%,
      #240e03 100%
    );
  }
  .sky-gradient-21 {
    background: linear-gradient(
      to bottom,
      #010a10 30%,
      #59230b 80%,
      #2f1107 100%
    );
  }
  .sky-gradient-22 {
    background: linear-gradient(to bottom, #090401 50%, #4b1d06 100%);
  }
  .sky-gradient-23 {
    background: linear-gradient(to bottom, #00000c 80%, #150800 100%);
  }
}
</style>
