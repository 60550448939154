<template>
  <div
    v-if="reviewObj"
    class="review-input-container"
  >
    <div v-if="!reviewState.isSuccessSaved && !saveErrorMessage">
      <table class="has-margin-bottom">
        <thead>
          <tr>
            <th class="tdColumn1 headerCell"></th>
            <th class="tdColumn2 headerCell">
              <img
                src="@/assets/img/greyThumbs.png"
                border="0"
                alt="Inapplicable"
                title="Inapplicable"
              />
            </th>
            <th class="tdColumn3 headerCell">
              <img
                src="@/assets/img/thumbUpSmall.png"
                border="0"
                alt="Good"
                title="Good"
              />
            </th>
            <th class="tdColumn4 headerCell">
              <img
                src="@/assets/img/thumbDownSmall.png"
                border="0"
                alt="Bad"
                title="Bad"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="question in reviewObj.Questions"
            :key="question.Id"
          >
            <td>{{ question.Question }}</td>
            <td class="">
              <input
                type="radio"
                value="0"
                v-model="question.Value"
              />
            </td>
            <td class="">
              <input
                type="radio"
                value="1"
                v-model="question.Value"
              />
            </td>
            <td class="">
              <input
                type="radio"
                value="-1"
                v-model="question.Value"
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="has-background-white">
            <td></td>
            <td colspan="3">
              <span
                :title="score"
                :class="reviewScoreClass"
              ></span>
            </td>
          </tr>
        </tfoot>
      </table>
      <label>Comment:</label>
      <textarea
        class="textarea has-margin-bottom"
        v-model="commentText"
      />
    </div>
    <div
      v-else-if="reviewState.isSuccessSaved"
      class="has-text-centered"
    >
      <figure class="image is-96x96 is-inline-block has-margin-bottom">
        <img
          src="@/assets/img/done.svg"
          alt="done"
          title="Hooray!"
        >
      </figure>
      <div class="title is-3">Thank you</div>
      <div class="subtitle is-4">Your review has been saved, thank you for your feedback!</div>
    </div>
    <div
      v-else-if="saveErrorMessage"
      class="has-text-centered"
    >
      <ErrorAnimation class="has-margin-bottom-x2" />
      <div class="title is-3">Oops! Something went wrong</div>
      <div class="subtitle is-4">{{saveErrorMessage}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import reviewProvider from '../../providers/review'
import ErrorAnimation from '@/components/UI/Components/ErrorAnimation'

export default {
  name: 'Location-review-input',

  components: {
    ErrorAnimation
  },

  props: {
    reviewId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isLoading: false,
      reviewObj: null,
      commentText: this.commentText,
      reviewState: {
        reviewId: this.reviewId,
        answerGiven: false,
        commentTextFilledIn: false,
        isSaving: false,
        isSuccessSaved: false
      },
      routeName: `Page-${this.$route.name}`,
      saveErrorMessage: '',
    }
  },

  computed: {
    ...mapGetters({
      profileStats: 'getProfileStats'
    }),

    // Get review score graphic basesd on answers given
    reviewScoreClass() {
      let score = this.score
      if (score > 1 && score <= 10) {
        return 'reviewImageSprite status01'
      } else if (score > 10 && score <= 20) {
        return 'reviewImageSprite status02'
      } else if (score > 20 && score <= 30) {
        return 'reviewImageSprite status03'
      } else if (score > 30 && score <= 40) {
        return 'reviewImageSprite status04'
      } else if (score > 40 && score <= 50) {
        return 'reviewImageSprite status05'
      } else if (score > 50 && score <= 60) {
        return 'reviewImageSprite status06'
      } else if (score > 60 && score <= 70) {
        return 'reviewImageSprite status07'
      } else if (score > 70 && score <= 80) {
        return 'reviewImageSprite status08'
      } else if (score > 80 && score <= 90) {
        return 'reviewImageSprite status09'
      } else if (score > 90 && score <= 100) {
        return 'reviewImageSprite status10'
      }
      return 'reviewImageSprite status-noScore'
    },

    // Keep track if user give minimal one answer
    answerGiven(val) {
      let totalAnswered = 0
      if (this.reviewObj) {
        for (let i = 0; i < this.reviewObj.Questions.length; i++) {
          if (Number(this.reviewObj.Questions[i].Value)) {
            totalAnswered++
          }
        }
      }

      if (totalAnswered > 0) {
        return true
      }
      return false
    },

    // Update score bases on users input
    score() {
      let score = 0.0
      let totalAnswered = 0
      let totalPositiveAnswered = 0

      if (this.reviewObj) {
        for (let i = 0; i < this.reviewObj.Questions.length; i++) {
          let val = Number(this.reviewObj.Questions[i].Value)

          if (val !== 0) {
            totalAnswered++
          }
          if (val === 1) {
            totalPositiveAnswered++
          }
        }
      }
      if (totalAnswered > 0 && totalPositiveAnswered > 0) {
        score = totalPositiveAnswered / totalAnswered * 100
      }

      return Math.round(score)
    }
  },

  watch: {
    answerGiven(val) {
      // Update reviewState
      this.reviewState.answerGiven = val
    },

    commentText(val) {
      this.reviewState.commentTextFilledIn = Boolean(val)
    },

    reviewState: {
      handler: function(newValue) {
        this.$emit('updateReviewState', newValue)
      },
      deep: true
    }
  },

  created() {
    this.$emit('updateReviewObj', null)
    this.$emit('updateReviewState', {
      reviewId: this.reviewId,
      answerGiven: false,
      commentTextFilledIn: false,
      isSaving: false,
      isSuccessSaved: false
    })
    this.getReview()
  },

  methods: {
    /**
     * Get review
     */
    getReview() {
      let self = this
      reviewProvider.methods.getReviewById(this.reviewId).then(response => {
        self.reviewObj = response.data
        self.reviewObj.AnsweredOn = self.$options.filters.parseIsoDateStringToDate(self.reviewObj.AnsweredOn)
        self.$emit('updateReviewObj', response.data)
      })
    },

    /**
     * Save review
     */
    saveReview() {
      let self = this

      this.commentText = this.$options.filters.stripHtml(this.commentText, true)

      if (!this.reviewState.isSaving) {
        this.reviewState.isSaving = true
        self.$googleAnalytics.pushEvent(self.routeName, 'button-PostReview', 'Review')

        reviewProvider.methods.saveReviewAnswers(this.reviewObj.Key, {
          ReviewKey: this.reviewObj.Key,
          Questions: this.reviewObj.Questions,
          Comment: this.commentText,
        })
          .then(response => {
            if (response.status === 204) {
              this.saveErrorMessage = 'Could not post your review due to an error.'
              return
            }

            self.reviewObj.AnsweredOn = new Date()

            // Update profile score
            let currentReviews = self.profileStats.NrOfReviews
            self.profileStats.NrOfReviews = currentReviews + 1
            self.$store.commit('setProfileStats', self.profileStats)

            // Update review state
            self.reviewState.isSuccessSaved = true
          })
          .catch(() => {
            this.saveErrorMessage = 'Could not post your review due to an error.'
          })
          .then(() => {
            // Update review state
            self.reviewState.isSaving = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  th {
    text-align: center;
  }
  tbody,
  tfoot {
    tr {
      &:nth-child(even) {
        background-color: $grey-light;
      }
      &:nth-child(odd) {
        background-color: white;
      }
      td {
        padding: 3px;
        width: 16%;
        text-align: center;
        &:first-child {
          text-align: left;
          width: 42%;
        }
      }
    }
  }
  tfoot {
    tr {
      td {
        border-top: 1px solid $grey-light;
        padding-bottom: 0;
        padding-top: 10px;
      }
    }
  }
}

.reviewImageSprite {
  display: inline-block;
  width: 102px;
  height: 21px;
  background: transparent url(~@/assets/img/ReviewIconsSprite.png) no-repeat 0 0;

  &.status01 {
    background-position: 0 -49px;
  }

  &.status02 {
    background-position: 0 -97px;
  }

  &.status03 {
    background-position: 0 -146px;
  }

  &.status04 {
    background-position: 0 -195px;
  }

  &.status05 {
    background-position: 0 -244px;
  }

  &.status06 {
    background-position: 0 -293px;
  }

  &.status07 {
    background-position: 0 -342px;
  }

  &.status08 {
    background-position: 0 -390px;
  }

  &.status09 {
    background-position: 0 -438px;
  }

  &.status10 {
    background-position: 0 -487px;
  }
}
</style>
