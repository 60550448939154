import { eraseCookie, getCookie, setCookie } from '@/utils/browserStorage'
import cookieProvider from '@/providers/cookie'

const state = {
  cookies: {},
  cookieIsSet: true,
  showCookieSettingsModal: false,
  currentCookieLanguage: 'en'
}

//mutation to update state
const mutations = {
  setCookies: (state, newState) => {
    state.cookies = newState
  },
  updateCookieIsSetState: (state, newState) => {
    state.cookieIsSet = newState
  },
  setShowCookieSettingsModal: (state, newState) => {
    state.showCookieSettingsModal = newState
  },
  setCurrentCookieLanguage: (state, newState) => {
    state.currentCookieLanguage = newState
  }
}

const actions = {
  async fetchCookieApiData({ commit }) {
    // action to fetch API data, comes as object & is stored as object 
    let browserCookie = getCookie('s2m_cc') !== '' ? JSON.parse(getCookie('s2m_cc')) : ''
    try {
      await cookieProvider.methods.getCookies().then(response => { //add argument for language here
        if (response.status === 200) {
          commit('setCookies', response.data);
          commit('updateCookieIsSetState', browserCookie !== '' && response.data.Version === browserCookie.Version)
        }
      })
    } catch (error) {
      console.log("You got an error in store actions: ", error)
    }
  },

  saveCookieSettings({ commit }, cookieSettings) {
    setCookie('s2m_cc', JSON.stringify(cookieSettings), 300, true)
    // this.$cookies.set('s2m_cc', cookieSettings, {
    //   path: '/',
    //   maxAge: 60 * 60 * 24 * 300, // expired over 300 days,
    //   sameSite: 'lax',
    //   encode: String
    //   // secure: true
    // })
    commit('updateCookieIsSetState', true)
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
