<template>
  <div class="flow-setNewPassword">
    <forgot-password
      v-if="!showSetNewPassword && !showSetPasswordConfirmation"
      :hideTitle="hideTitle"
      :linkActionLogin="linkActionLogin"
      @sentSuccessfully="setShowSetNewPassword"
      :profileEmail="profileEmail"
    />

    <change-password
      v-if="showSetNewPassword"
      :hideTitle="hideTitle"
      :linkActionRequest="setShowRequestNewPassword"
      @resetSuccessfully="setShowSetPasswordConfirmation"
    />

    <set-password-confirmation
      :hideTitle="hideTitle"
      v-if="showSetPasswordConfirmation"
      :linkActionLogin="linkActionLogin"
      :showCustomConfirmationText="showCustomConfirmationText"
    >
      <template #confirmationText>
        <slot name="confirmationText"></slot>
      </template>
    </set-password-confirmation>
  </div>
</template>

<script>
import ChangePassword from './ChangePassword.vue'
import ForgotPassword from './ForgotPassword.vue'
import SetPasswordConfirmation from './SetPasswordConfirmation.vue'
export default {
  name: 'RequestNewPassportFlow',

  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },

    linkActionLogin: {
      type: [Boolean, Function],
      default: false
    },

    showCustomConfirmationText: {
      type: Boolean,
      default: false
    },

    profileEmail: {
      type: String,
      default: ''
    }
  },

  components: { ChangePassword, ForgotPassword, SetPasswordConfirmation },

  data() {
    return {
      showSetNewPassword: false,
      showSetPasswordConfirmation: false,
    }
  },

  methods: {
    setShowRequestNewPassword() {
      this.showSetNewPassword = false
      this.showSetPasswordConfirmation = false
      this.$emit('currentStep', 1)
    },
    setShowSetNewPassword() {
      this.showSetNewPassword = true
      this.showSetPasswordConfirmation = false
      this.$emit('currentStep', 2)
    },

    setShowSetPasswordConfirmation() {
      this.showSetNewPassword = false
      this.showSetPasswordConfirmation = true
      this.$emit('currentStep', 3)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>