import axios from 'axios'

export default {
  methods: {
    async getArticle(articleId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/article/${articleId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getArticles(searchTerm = '', page = 1, itemsPerPage = 25) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/article`,
        {
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    }
  }
}
