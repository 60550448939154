<template>
  <div class="">
    <div
      v-on:click="gotoLocation(locationObj.Id)"
      class="card-location has-framework-roundness"
      style="overflow: hidden;"
    >
      <div class="hover-overlay">
        <div class="button-bar has-text-centered">
          <button class="button is-outlined is-inverted is-yellow">
            See details
          </button>
        </div>
      </div>
      <img
        src="@/assets/img/spacer5_3.gif"
        :style="{
          backgroundImage: `url(${$options.filters.getLocationImage(
            locationObj.Image,
            locationObj.Id,
            320
          )})`
        }"
        v-bind:alt="locationObj.Name"
        width="320"
        height="240"
      />
      <div class="card-content padding">
        <p class="has-text-centered title is-5 has-text-blue-dark locationTitle">
          {{ locationObj.Name }}
        </p>
        <p class="has-text-centered">
          {{ locationObj.City }}
          <template v-if="showCheckInDistance">
            <br />
            <span class="is-size-7">(
              <i class="fas fa-map-marker-alt"></i>
              {{ locationObj.Distance.toFixed(1) }} km.)</span>
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    location: {
      type: Object,
      default() {
        return {
          Id: 0,
          Name: '',
          Image: '',
          Address: '',
          City: '',
          Latitude: 0,
          Longitude: 0,
          Distance: 0,
          NrOfCheckIns: 0,
          Email: '',
          Phone: '',
          MeetingTypes: []
        }
      }
    }
  },
  data() {
    return {
      locationObj: this.$props.location
    }
  },

  computed: {
    ...mapGetters({
      currentCheckin: 'getCurrentCheckin',
      latitude: 'getLatitude',
      longitude: 'getLongitude'
    }),

    showCheckInDistance() {
      let output = false
      output =
        this.currentCheckin === null
          ? false
          : this.currentCheckin.Latitude !== 0 &&
          this.currentCheckin.Longitude !== 0

      if (output === false && (this.latitude > 0 || this.longitude > 0)) {
        output = true
      }
      return output
    }
  },

  methods: {
    gotoLocation(locationId) {
      this.$store.commit('setSelectedLocation', locationId)
    },

    setShowCheckinProcess(locationId) {
      this.$store.commit('setLocation', this.$props.location)
      this.$router.push({
        name: 'LocationDetail',
        params: { id: locationId, startWithCheckin: true },
        props: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "../assets/styles/template.scss";

.card-location {
  border: 5px solid $yellow;
  background-color: white;
  position: relative;

  &:hover {
    .hover-overlay {
      background: rgba($black, 0.5);
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      .button-bar {
        display: block;
      }
    }
  }
  .hover-overlay {
    display: none;
    .button-bar {
      bottom: $gap * 2;
      display: none;
      position: absolute;
      width: 100%;
    }
  }

  img {
    background-color: $grey-light;
    width: 100%;
  }

  .title {
    margin-bottom: 0;
    min-height: 45px;
  }
}
</style>
