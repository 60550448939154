var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"skyline"}},[_c('div',{staticClass:"topBanner",attrs:{"id":_vm.topBannerId}},[_c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[(_vm.showTitle)?_c('div',{staticClass:"container is-fluid"},[_c('h2',{staticClass:"title is-2 is-size-3-touch has-text-centered",class:{
              'has-text-white':
                (_vm.skyLineIndex >= 0 && _vm.skyLineIndex < 8) ||
                (_vm.skyLineIndex >= 19 && _vm.skyLineIndex < 24)
            }},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"subtitle is-4 is-size-5-mobile is-size-4-tablet has-text-centered",class:{
              'has-text-white':
                (_vm.skyLineIndex >= 0 && _vm.skyLineIndex < 8) ||
                (_vm.skyLineIndex >= 19 && _vm.skyLineIndex < 24)
            }},[_vm._v(_vm._s(_vm.subTitle))])]):_vm._e()])])]),(_vm.skyLineIndex >= 21 || _vm.skyLineIndex <= 5)?_c('div',{staticClass:"stars"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }