/**
 * Component files
 */
const Network = () => import('@/views/Network')
const NetworkVisualisation = () => import('@/views/NetworkVisualisation')

const networkRoutes = [
    {
        path: '/network',
        name: 'Network',
        component: Network,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/network/location/:locationId',
        name: 'NetworkLocation',
        component: Network,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/network/map',
        name: 'NetworkMap',
        component: NetworkVisualisation,
        meta: {
            requiresAuth: true
        }
    },
    {
        // Redirect URL
        path: '/network/questions/:questionId',
        redirect: to => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/questions/' + to.params.questionId }
        },
    },
]

export default networkRoutes