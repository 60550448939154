<template>
  <div v-if="isInitiated && terms && !hasAgreedToTerms">
    <div
      v-if="!hasAgreedToTerms"
      id="modalAgreeTerms"
      class="modal"
      :class="{ 'is-active' : !hasAgreedToTerms }"
    >
      <div class="modal-background"></div>
      <div class="modal-card has-background-white">
        <h4 class="modal-card-head">We updated our terms</h4>
        <div class="modal-card-body content">
          <div v-html="terms.Changes"></div>
          <hr />
          <strong>{{ terms.Version }}</strong>
          <hr />
          <div v-html="terms.Text"></div>
        </div>
        <div class="modal-card-foot">
          <ui-checkbox
            v-model="agreed"
            iconAdditionClass="has-text-white"
          >
            I agree to the these terms
          </ui-checkbox>

          <a
            href="javascript:"
            class="button has-margin-left"
            :class="{'is-success':agreed, 'is-disabled' : !agreed}"
            :disabled="!agreed"
            @click="agreeToTerms"
          >Agree & close</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import termsProvider from '../providers/terms'

export default {
  data() {
    return {
      agreed: false,
      terms: null
    }
  },
  computed: mapGetters({
    isInitiated: 'getIsInitiated',
    isLoggedIn: 'getIsLoggedIn',
    hasAgreedToTerms: 'getHasAgreedToTerms',
    profile: 'getProfile'
  }),
  watch: {
    isInitiated: {
      handler: 'getTerms',
      immediate: true
    }
  },
  methods: {
    agreeToTerms() {
      termsProvider.methods.agreeToTerms(this.terms.Id).then(response => {
        this.$store.commit('setHasAgreedToTerms', true)
      })
    },
    getTerms() {
      termsProvider.methods.getLatestTerms().then(response => {
        this.terms = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

@media screen and (min-width: 769px) {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    width: 70vw;
  }
}
</style>
