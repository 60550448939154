<template>
  <div
    class="modal"
    :class="{'is-active': reviewId > 0}"
  >
    <div class="modal-background"></div>
    <div class="modal-card has-background-white">
      <div
        v-if="reviewObj"
        class="modal-card-head"
      >
        <div>
          <div class="title is-size-3">Review you experience at {{ reviewObj.LocationName }}</div>
          <div class="subtitle is-size-5">
            Reservation: <span v-text="reviewObj.ReservationId"></span>
            ({{ $t(`General.MeetingType${reviewObj.MeetingTypeId}`) }})
          </div>
          <h4>Your review will be displayed on the website</h4>
        </div>
      </div>
      <div class="modal-card-body">
        <location-review-input
          ref="reviewInput"
          :reviewId="reviewId"
          @updateReviewObj="updateReviewObj"
          @updateReviewState="updateReviewState"
        />
      </div>
      <div class="modal-card-foot">
        <div v-if="reviewState && !reviewState.isSuccessSaved">
          <button
            @click="closeSaveReview"
            :disabled="reviewState.isSaving"
            class="button is-ghost"
          >Cancel</button>
          <button
            @click="$refs.reviewInput.saveReview"
            class="button is-primary"
            :disabled="!reviewState.commentTextFilledIn && !reviewState.answerGiven"
            :class="{'is-loading' : reviewState.isSaving, 'is-disabled' : !reviewState.commentTextFilledIn && !reviewState.answerGiven}"
          >Post review</button>
        </div>
        <div v-else>
          <button
            @click="closeSaveReview"
            class="button"
          >Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationReviewInput from '@/components/partial/LocationReviewInput'

export default {
  props: {
    reviewId: {
      type: Number,
      default: 0
    }
  },

  components: {
    'location-review-input': LocationReviewInput
  },

  data() {
    return {
      commentText: '',
      reviewObj: null,
      isSuccessSaved: false,
      reviewState: null
    }
  },

  methods: {
    closeSaveReview() {
      this.$emit('closeSaveReview', this.reviewObj)
    },

    updateReviewObj(val) {
      this.reviewObj = val
    },

    updateReviewState(val) {
      this.reviewState = val
      this.$emit('updatedReviewState', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
