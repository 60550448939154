const Articles = () =>
  import(/* webpackChunkName: "route-articles" */ '@/views/articles/Articles')
const ArticleDetail = () =>
  import(
    /* webpackChunkName: "route-article-detail" */ '@/views/articles/ArticleDetail'
  )
const articlesRoutes = [
  {
    path: '/articles',
    name: 'articles',
    component: Articles
  },
  {
    path: '/articles/:articleId',
    name: 'article-detail',
    component: ArticleDetail
  }
]

export default articlesRoutes
