import axios from 'axios'

export default {
  methods: {
    async getCookies(cs = 'en-US') {
      return await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/Cookie/latest`, {
        params: {
          cs
        },
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
        }
      })
    },
  }
}