<template>
  <div class="changePassword-component">
    <h3
      v-if="!hideTitle"
      class="title is-3 has-text-blue-dark"
    >Set new password</h3>
    <div class="content">
      <p>An email has been sent with a confirmation code. You need this to adjust your password.</p>
    </div>

    <message
      class="is-danger"
      v-if="errorMessage"
    >
      <span>{{ errorMessage }}</span>
      <template v-if="failCode === -1 || failCode === -2">
        <br />
        <a
          @click="linkActionRequest"
          class="icon-text has-text-weight-bold"
        >
          <span class="icon">
            <i class="fas fa-arrow-circle-left"></i>
          </span>
          <span>Send new confirmation code</span>
        </a>
      </template>
    </message>

    <form
      onSubmit="return false;"
      autocomplete="off"
    >
      <div class="field">
        <label class="label">Password <span class="has-text-danger">*</span></label>
        <div class="control has-icons-right">
          <input
            class="input"
            autocomplete="off"
            v-bind:class="{ 'is-success': password1 !== '' && !showPasswordWarning, 'is-danger': showPasswordWarning}"
            type="password"
            v-model="password1"
            maxlength="64"
          >
          <span
            v-if="password1 !== ''"
            class="icon is-small is-right has-text-success"
          >
            <i class="fas fa-check"></i>
          </span>
        </div>
        <p v-if="showPasswordWarning">
          <span
            class="help"
            v-bind:class="{ 'is-danger': showPasswordWarning }"
          >Password is required</span>
        </p>
      </div>

      <div
        v-if="!confirmToken"
        class="field"
      >
        <label class="label">Repeat password <span class="has-text-danger">*</span></label>
        <div class="control has-icons-right">
          <input
            class="input"
            autocomplete="off"
            v-bind:class="{ 'is-success': password2 !== '' && !showPasswordWarning, 'is-danger': showPasswordWarning}"
            type="password"
            v-model="password2"
            maxlength="64"
          >
          <span
            v-if="password2 !== ''"
            class="icon is-small is-right has-text-success"
          >
            <i class="fas fa-check"></i>
          </span>
        </div>
        <p v-if="showPasswordWarning">
          <span
            class="help"
            v-bind:class="{ 'is-danger': showPasswordWarning }"
          >Password is not the same as above</span>
        </p>
      </div>

      <div class="field mb-4">
        <label class="label">Confirmation code <span class="has-text-danger">*</span></label>
        <div class="level is-mobile">
          <div class="level-item">
            <input
              type="text"
              ref="codePart1"
              @keyup="checkCodePart1"
              autocomplete="none"
              aria-autocomplete="both"
              size="2"
              max="2"
              maxlength="2"
              v-model="codePart1"
              class="input has-text-centered"
              :class="{'is-danger': hasCodeError}"
            />
          </div>
          <div class="level-item">
            <strong class="is-size-7">-</strong>
          </div>
          <div class="level-item">
            <input
              type="text"
              ref="codePart2"
              @keyup="checkCodePart2"
              autocomplete="none"
              aria-autocomplete="both"
              size="2"
              max="2"
              maxlength="2"
              v-model="codePart2"
              class="input has-text-centered"
              :class="{'is-danger': hasCodeError}"
            />
          </div>
          <div class="level-item">
            <strong class="is-size-7">-</strong>
          </div>
          <div class="level-item">
            <input
              type="text"
              ref="codePart3"
              @keyup="updateCode"
              autocomplete="none"
              aria-autocomplete="both"
              size="2"
              max="2"
              maxlength="2"
              v-model="codePart3"
              class="input has-text-centered"
              :class="{'is-danger': hasCodeError}"
            />
          </div>
        </div>
      </div>
    </form>

    <div class="buttons is-right">
      <a
        @click="savePassword"
        class="button is-primary"
        :disabled="disableButton"
        :class="{'is-disabled' : disableButton, '': !disableButton, 'is-loading': isSaving }"
      >
        <span>Confirm new password</span>
      </a>
    </div>

    <hr />

    <a
      @click="linkActionRequest"
      class="icon-text text_color_cyan has-text-weight-bold mr-5"
    >
      <span class="icon">
        <i class="fas fa-arrow-circle-left"></i>
      </span>
      <span>Send new confirmation code</span>
    </a>
    <a
      v-if="!confirmToken && linkActionLogin"
      @click="linkActionLogin"
      class="icon-text text_color_cyan has-text-weight-bold"
    >
      <span class="icon">
        <i class="fas fa-arrow-circle-left"></i>
      </span>
      <span>Back to login</span>
    </a>
  </div>
</template>

<script>
import loginProvider from '../../providers/login'
import Message from '../UI/Message.vue'

export default {
  components: { Message },
  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },

    confirmToken: {
      type: String,
      default: ''
    },
    linkActionLogin: {
      type: [Boolean, Function],
      default: false
    },
    linkActionRequest: {
      type: [Boolean, Function],
      default: false
    }
  },

  data() {
    return {
      isSaving: false,
      password1: '',
      password2: '',
      hasFailed: false,
      codePart1: '',
      codePart2: '',
      codePart3: '',
      code: '',
      validationCounter: 0,
      failCode: 0,
      Messages: {
        "Message_ErrorCode-1": "The confirmation code may have expired or not entered correctly.",
        "Message_ErrorCode-2": "New password could not be saved. The confirmation code may have expired or not entered correctly.",
        "Message_ErrorCode400": "Some fields are not filled in. Please fill in all fields.",
        "Message_ErrorCode429": "Whoops. Wait for a couple of seconds and try again.",
        "Message_ErrorCode500": "Whoops. Due to a problem we cannot save your new password.",
        "Message_ErrorCode501": "You have not entered the same passwords twice. These must be equal.",
        "Message_ErrorConfirmationCode": "The confirmation code may have expired or not entered correctly."
      }
    }
  },

  computed: {
    errorMessage() {
      if (this.failCode === -1) {
        return this.Messages['Message_ErrorCode-1']
      } else if (this.failCode === -2 || this.failCode === -3 || this.failCode === -4) {
        return this.Messages['Message_ErrorCode-2']
      } else if (this.failCode === 400) {
        return this.Messages.Message_ErrorCode400
      } else if (this.failCode === 429) {
        return this.Messages.Message_ErrorCode429
      } else if (this.failCode === 500) {
        return this.Messages.Message_ErrorCode500
      } else if (this.failCode === 501) {
        return this.Messages.Message_ErrorCode501
      }
      return ''
    },

    hasCodeError() {
      return this.failCode === -1
        || this.failCode === -2
        || this.failCode === -3
        || this.failCode === -4
    },

    disableButton() {
      return this.password1 === ''
        || this.password2 === ''
        || this.password1 !== this.password2
        || this.code === ''
    },

    showPasswordWarning() {
      if (this.password1 !== '' && this.password2 !== '') {
        if (this.password1 === this.password2) {
          return false
        } else {
          return true
        }
      }
      return false
    },

    showCodeWarning() {
      return Boolean(this.code)
    }
  },

  methods: {
    validatePasswords() {
      this.validationCounter++
      return this.password1 !== ''
        && this.password2 !== ''
        && this.password1 === this.password2
        && this.code
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    updateCode() {
      let output = ''
      output += this.codePart1.trim()
      output += this.codePart2.trim()
      output += this.codePart3.trim()
      this.code = output
    },

    savePassword() {
      this.hasFailed = false

      if (!this.isSaving && this.validatePasswords()) {
        this.isSaving = true
        loginProvider.methods.changePassword({ Token: this.confirmToken, Password: this.password2, Pin: this.code })
          .then(response => {
            this.failCode = response.data
            if (this.failCode > 0) {
              this.$emit('resetSuccessfully')
            } else {
              this.isProcessing = false
            }
          })
          .catch((e) => {
            this.failCode = e.response.status
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
