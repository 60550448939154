<template>
  <div
    v-if="getMatchesFinished === true && matches.length > 0"
    class="matchedContainer"
  >
    <div
      v-if="showMatches && matches.length > 0"
      class="title is-3 has-text-centered has-text-blue-dark"
    >
      {{
      !showOpportunities
      ? 'We found the following matches'
      : 'We found the following opportunities'
      }}
    </div>

    <!-- <div
      v-if="getMatchesFinished && matches.length === 0"
      class="has-text-centered"
    >
      <img
        :key="'noMatchAni'"
        src="@/assets/img/noMatchFound.gif"
        width="64"
        height="64"
      />

      <p class="title has-text-blue-dark">No matches found</p>
      <p class="is-size-5">
        Oops. We couldn't find any relevant opportunities based on what you are
        working on today
      </p>
      <p class="is-size-5">
        Specify what you are working on so we can look again, or try luck by
        popping the serendipity bubble and we will give you random opportunities
      </p>

      <div class="padding">
        <button
          v-if="matches.length === 0"
          @click="() => { getRandomCheckins(); $googleAnalytics.pushEvent(`Page-${routeName}`, 'button-SupriseMe', 'Matches'); }"
          class="button is-primary is-medium"
        >Surprise me</button>
      </div>
    </div> -->

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="getMatchesFinished && matches.length > 0"
        name="matches"
        v-masonry
        transition-duration="0s"
        item-selector=".column"
        class="is-multiline masonry-container"
      >
        <match-item
          v-for="(match, index) in matches"
          :key="index"
          class="match column is-half-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-third-fullhd"
          @removeMatch="removeMatch"
          :match="match"
          :showLocation="showLocation"
          :showCheckInDistance="showCheckInDistance"
          :showTags="showTags"
        ></match-item>
      </div>
    </transition>
    <hr class="separator" />
    <div class="has-text-right">
      <router-link
        v-if="showMoreMatches"
        to="/matches"
        @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-MoreMatches', 'Matches')"
        class="button is-primary"
      >Show more matches</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import checkin from '../providers/checkin'
import match from '../providers/match'
import { EventBus } from '../eventBus/event-bus'

export default {
  props: {
    canEdit: {
      type: Boolean,
      default: false
    },
    showLocation: {
      type: Boolean,
      default: false
    },
    showTags: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      isLoading: false,
      getMatchesFinished: false,
      itemsPerPage: 6,
      page: 1,
      matches: [],
      matchReloadCounter: 0,
      mWorkingOn: '',
      showMoreMatches: false,
      showMatches: true,
      showOpportunities: false,
      routeName: this.$route.name
    }
  },
  computed: {
    ...mapState(['currentCheckin']),
    ...mapGetters({
      currentHour: 'getCurrentHour',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      isOnline: 'getConnectionStatus',
      latitude: 'getLatitude',
      longitude: 'getLongitude',
      profile: 'getProfile'
    }),

    showCheckInDistance() {
      return this.currentCheckin === null
        ? false
        : this.currentCheckin.Latitude !== 0 &&
        this.currentCheckin.Longitude !== 0
    }
  },
  watch: {
    currentCheckin(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.matches = []
        this.matchReloadCounter = 0
        this.page = 1
        this.loadMatches()
      }
    }
  },

  created() {
    if (this.currentCheckin) {
      this.loadMatches()
    }
  },
  methods: {
    goToMatches() { },

    checkout() {
      this.$store.dispatch('checkout', this.currentCheckin)
    },

    getMatches(latitude, longitude, workingOn) {
      this.getMatchesFinished = false
      let profileIds = []
      if (this.profile && this.profile.Id > 0) {
        profileIds.push(this.profile.Id)
      }

      for (let i = 0; i < this.matches.length; i++) {
        profileIds.push(this.matches[i].ProfileId)
      }

      if (!this.isLoading && workingOn !== '') {
        this.loading = true
        EventBus.$emit('isLoadingMatchesCheck', true)
        match.methods
          .getMatches(
            workingOn,
            latitude,
            longitude,
            this.page,
            this.itemsPerPage,
            '',
            this.currentCheckin.ProfileId,
            0,
            0,
            this.currentCheckin.Id
          )
          .then(response => {
            let currentMatches = this.matches
            let matches = response.data

            if (response.data.MorePages > 0) {
              this.showMoreMatches = true
            }

            for (let i = 0; i < matches.length; i++) {
              let match = matches[i]
              if (currentMatches.indexOf(match) === -1) {
                currentMatches.push(match)
              }
            }
            this.matches = currentMatches

            if (this.matches.length === 0) {
              this.getMatchesFinished = true
            } else {
              this.matchReloadCounter = 0
              this.getMatchesFinished = true
            }
          })
          .finally(() => {
            this.isLoading = false
            this.$emit('loadingReady', true)
            EventBus.$emit('isLoadingMatchesCheck', false)
          })
      }
    },
    getRandomCheckins() {
      let profileIds = []

      if (this.profile && this.profile.Id > 0) {
        profileIds.push(this.profile.Id)
      }
      if (this.checkin && this.checkin.ProfileId) {
        profileIds.push(this.checkin.ProfileId)
      }

      for (let i = 0; i < this.matches.length; i++) {
        profileIds.push(this.matches[i].ProfileId)
      }
      if (!this.isLoading) {
        this.isLoading = true
        checkin.methods
          .getRandomCheckins(3, profileIds)
          .then(response => {
            let checkins = response.data.Results
            for (let i = 0; i < checkins.length; i++) {
              let checkin = checkins[i]
              let match = {
                Id: checkin.Id,
                TypeId: 0,
                ItemId: checkin.ProfileId,
                ProfileId: checkin.ProfileId,
                ProfileName: checkin.ProfileName,
                LocationId: checkin.LocationId,
                LocationName: checkin.LocationName,
                LocationCity: checkin.LocationCity,
                StartTime: checkin.StartTimeStamp,
                EndTime: checkin.EndTimeStamp,
                Name: checkin.ProfileName,
                Image: checkin.ProfileImage,
                Tags: checkin.Tags,
                Distance: checkin.Distance
              }

              this.matches.push(match)
            }
          })
          .catch(e => {
            this.$console.info(e)
          })
          .finally(() => {
            this.isLoading = false
            this.$emit('loadingReady', this.isLoading)
            this.getMatchesFinished = true
          })
      }
    },
    setWorkingOn(workingOn) {
      this.mWorkingOn = this.currentCheckin.WorkingOn
      this.setShowMatches()
      this.loadMatches()
    },
    loadMatches() {
      if (!this.isLoading) {
        this.$emit('loadingReady', this.isLoading)
        this.getMatches(
          this.currentCheckin.latitude,
          this.currentCheckinlongitude,
          this.currentCheckin.WorkingOn
        )
      }
    },
    removeMatch(match) {
      var index = this.matches.indexOf(match)
      this.matches.splice(index, 1)
      this.loadMatches()
    },

    // setShowEditWorkingOn() {
    //   this.setVisibleItem('editworkingon')
    // },
    setShowMatches() {
      this.setVisibleItem('showmatches')
    },
    setVisibleItem(item) {
      // this.showEditWorkingOn = item === 'editworkingon'
      this.showMatches = item === 'showmatches'
    }
  }
}
</script>

<style lang="scss" scoped>
.masonry-container {
  margin: -$gap;
}

/* base */
.match {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.match-move {
  transition: transform 1s;
}

/* appearing */
.match-enter-active,
.match-leave-active {
  transition: opacity 0.5s;
}

/* appear at / disappear to */
.match-enter,
.match-leave-to {
  opacity: 0;
}
</style>
