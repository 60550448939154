<template>
  <div v-if="incomingQuestion">
    <article class="questionItemMedia media">
      <figure class="media-left">
        <p class="image is-64x64">
          <img
            src="@/assets/img/spacer1_1.gif"
            class="is-round has-shadow"
            :style="{backgroundImage: `url(${$options.filters.getProfileImage(incomingQuestion.ProfileImage, incomingQuestion.ProfileId, 84)})`}"
          >
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <div class="has-text-grey postDetailBar">
            <span
              v-show="incomingQuestion.IsSolved"
              class="has-text-success has-margin-right"
              style="display: none;"
            ><i class="fas fa-check-circle"></i> solved</span>
            <span class="has-margin-right">{{ incomingQuestion.CreatedOn | dateTimeStampToDate | longDateFormat }} -
              {{ incomingQuestion.CreatedOn | timeFromDateTimeStamp }}</span>
          </div>
          <p v-if="incomingQuestion.CurrentCheckIn !== null">
            <i class="font-icon s2m-icon-location-2"></i> {{ incomingQuestion.CurrentCheckIn.LocationName }}
          </p>
          <div :inner-html.prop="`<strong>${incomingQuestion.ProfileName}</strong>: ` + incomingQuestion.Text | nl2br"></div>
        </div>
      </div>
    </article>

    <div v-if="incomingQuestion.ProfileId !== profile.Id">
      <a
        v-if="!showAnswerInput"
        @click="hideIncomingQuestion()"
        class="button is-small"
      >Not now</a>
      <a
        v-if="!showAnswerInput"
        @click="setShowAnswerInput()"
        class="button is-primary is-small"
      >Add answer</a>
      <div
        v-if="showAnswerInput"
        class="modal messageInputModal"
        :class="{ 'is-active' : showAnswerInput }"
      >
        <div class="modal-background"></div>
        <div class="modal-card has-background-white">
          <div class="modal-card-body">
            <textarea
              class="textarea has-margin-bottom"
              v-model="answerText"
            />
          </div>
          <div class="modal-card-foot">
            <button
              @click="cancelShowAnswerInput"
              :disabled="isSaving"
              class="button"
            >Cancel</button>
            <button
              @click="sendAnswer"
              :disabled="isSaving"
              class="button is-primary"
              :class="{'is-loading' : isSaving}"
            >Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import answer from '@/providers/answer'
import question from '@/providers/question'

export default {
  props: {
    questionId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isSaving: false,
      answerText: '',
      incomingQuestion: null,
      showAnswerInput: false
    }
  },

  computed: mapGetters({
    isInitiated: 'getIsInitiated',
    isLoggedIn: 'getIsLoggedIn',
    profile: 'getProfile'
  }),

  created() {
    this.getIncomingQuestion()
  },

  methods: {
    getIncomingQuestion() {
      question.methods.getQuestionById(this.questionId).then(response => {
        this.incomingQuestion = response.data
      })
    },

    hideIncomingQuestion() {
      this.$store.commit('setShowIncomingQuestion', false)
    },

    setShowAnswerInput() {
      this.showAnswerInput = true
    },

    cancelShowAnswerInput() {
      this.showAnswerInput = false
      this.answerText = ''
    },

    sendAnswer() {
      if (!this.isSaving) {
        this.isSaving = true
        answer.methods
          .answerQuestion(this.incomingQuestion.Id, this.answerText)
          .then(response => {
            this.$store.commit('setShowIncomingQuestion', false)
            this.answerText = ''
            this.cancelShowAnswerInput()
          })
          .then(() => {
            this.isSaving = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.messageInputModal {
  textarea {
    min-height: 150px;
  }
}
.inboxContainer {
  overflow: auto;
  max-height: 400px;
}
.media-content {
  width: 100%;
}
</style>
