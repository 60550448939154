<template>
  <div
    class="level"
    :class="additionalClass"
  >
    <div class="level-left">
      <div :class="titleClass">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="level-right">
      <a
        v-if="showAdd"
        @click="addFunction"
        class="has-icon"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fa', 'plus']" />
        </span>
        <span>{{ addLabel }}</span>
      </a>

      <a
        v-if="showEdit"
        @click="editFunction"
        class="has-icon"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fa', 'edit']" />
        </span>
        <span>{{ editLabel }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-header',

  props: {
    addFunction: {
      type: Function,
      default: () => { },
    },

    editFunction: {
      type: Function,
      default: () => { },
    },

    addLabel: {
      type: String,
      default: function() {
        return this.$t('Form.Control.Add')
      },
    },

    editLabel: {
      type: String,
      default: function() {
        return this.$t('Form.Control.Edit')
      },
    },

    additionalClass: {
      type: String,
      default: '',
    },

    titleClass: {
      type: String,
      default: 'title is-3',
    },

    showAdd: {
      type: Boolean,
      default: false,
    },

    showEdit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
