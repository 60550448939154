<template>
  <div
    v-if="checkin"
    class="checkin box p-4 is-shadowless has-background-primary has-text-centered"
  >
    <div class="body">
      <div class="top">
        <span
          v-if="!checkin.HasLeft"
          class="checkin-status icon is-medium is-rounded"
          :class="checkin.IsConfirmed ? 'has-text-success': 'has-text-grey-light'"
          :title="checkin.IsConfirmed ? 'Is present' : 'has-not-confirmed'"
        >
          <i class="fas fa-lg fa-check"></i>
        </span>
        <avatar-image
          class="is-84x84 m-auto"
          :imageSrc="checkin.ProfileImage"
          :itemId="checkin.ProfileId"
          :itemName="checkin.ProfileName"
          :photoSize="84"
        />
      </div>
      <div class="box-content box is-shadowless p-3 pt-6 has-background-white">
        <div class="content-top">
          <div
            class="has-text-weight-bold mb-3"
            v-text="checkin.ProfileName"
          ></div>
          <div
            v-if="showWorkingon && workingOn !== ''"
            class="mb-3"
          >
            <span
              :title="checkin.WorkingOn"
              v-html="workingOn"
            ></span>
          </div>
          <div :class="{'mb-3': locationOrTimeShown}">
            <div v-if="
                showTime &&
                  checkin.StartMinutes !== 0 &&
                  checkin.EndMinutes !== 1440
              ">
              <i class="far fa-clock"></i>
              <span>
                {{ printTime(checkin) }}
              </span>
            </div>
            <div v-if="checkin.LocationId > 0 && showLocation">
              <i class="fas fa-map-marker-alt mr-1"></i>
              <span>{{ checkin.LocationName }}</span>
              <span v-if="checkin.Distance > -1 && showDistance === true">({{ checkin.Distance.toFixed(0) }}km)</span>
            </div>
          </div>
        </div>
        <div class="content-bottom has-text-centered">
          <a
            v-if="isCurrentCheckin && checkinType === 'location' && !checkin.HasLeft"
            class="button"
            @click="() => { !checkin.IsConfirmed ? confirmCheckin() : checkout()}"
            :class="{'is-success':!checkin.IsConfirmed, 'is-warning':checkin.IsConfirmed}"
          >{{!checkin.IsConfirmed ? `I'm present` : `I'm leaving`}}</a>
          <template v-else>
            <a
              @click="() => { showProfileDetail(checkin.ProfileId, 'detail'); $googleAnalytics.pushEvent(`Page-${$route.name}`, 'button-CheckinDetailView', getGaLabel()); }"
              class="custom-button is-rounded is-success is-outlined"
              title="View profile"
            >
              <span class="text">View profile</span>
              <span class="icon is-medium">
                <font-awesome-icon :icon="['far', 'ellipsis-h']" />
              </span>
            </a>
            <a
              v-if="!isCurrentCheckin"
              @click="() => { showProfileDetail(checkin.ProfileId, 'chat'); $googleAnalytics.pushEvent(`Page-${$route.name}`, 'button-SendMessageView', getGaLabel()); }"
              class="custom-button is-rounded is-success is-outlined"
              title="Send message"
            >
              <span class="text">Send message</span>
              <span class="icon is-medium">
                <font-awesome-icon :icon="['fas', 'comment-alt-dots']" />
              </span>
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import checkinProvider from '@/providers/checkin'

export default {
  props: {
    checkin: null,
    showWorkingon: {
      default: true,
      type: Boolean
    },

    showTags: {
      default: false,
      type: Boolean
    },

    showTime: {
      default: true,
      type: Boolean
    },

    showLocation: {
      default: true,
      type: Boolean
    },

    showDistance: {
      default: true,
      type: Boolean
    },

    sectionName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isBusy: false
    }
  },

  computed: {
    ...mapState(['profile', 'currentCheckin']),

    locationOrTimeShown() {
      return (this.showTime &&
        this.checkin.StartMinutes !== 0 &&
        this.checkin.EndMinutes !== 1440)
        || (this.checkin.LocationId > 0 && this.showLocation)
    },

    checkinType() {
      let output = 'network'
      if (
        this.currentCheckin &&
        this.currentCheckin.EventId
      ) {
        output = 'event'
      } else if (this.currentCheckin && this.currentCheckin.LocationId) {
        output = 'location'
      }
      return output
    },

    isCurrentCheckin() {
      return this.profile && this.profile.Id === this.checkin.ProfileId
    },

    workingOn() {
      return (this.checkin && this.checkin.WorkingOn) !== ''
        ? this.$options.filters.stringShortner(
          this.$options.filters.stripHtml(this.checkin.WorkingOn),
          100
        )
        : ''
    }
  },

  methods: {
    getGaLabel() {
      return (this.sectionName ? this.sectionName + ' | ' : '') + 'Tile-Checkin'
    },

    printTime(checkin) {
      let time = `${this.$options.filters.minutesToTime(checkin.StartMinutes)} - ${this.$options.filters.minutesToTime(checkin.EndMinutes)}`
      if (checkin.EndMinutes < checkin.StartMinutes) {
        time = `${this.$options.filters.minutesToTime(checkin.EndMinutes)} - ${this.$options.filters.minutesToTime(checkin.StartMinutes)}`
      }
      return time
    },

    showProfileDetail(profileId, view, message = '') {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: view,
        chatMessage: ''
      })
    },

    checkout() {
      this.$store.dispatch('checkout', this.currentCheckin)
    },

    confirmCheckin() {
      let self = this
      if (!this.isBusy) {
        this.isBusy = true
        checkinProvider.methods
          .confirmCheckin(this.currentCheckin.Id)
          .then(response => {
            this.$store.dispatch('saveCurrentCheckin', response.data)
          })
          .finally(() => {
            self.isBusy = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkin {
  height: 100%;
  .is-84x84 {
    width: 84px;
    height: 84px;
  }
  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    .top {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      .checkin-status {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
      }
    }
    .box-content {
      margin-top: -#{$gap * 1.5};
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;
      .content-top {
        flex-grow: 1;
      }
      .content-bottom {
        flex-grow: 0;
        .custom-button {
          display: inline-flex;
          position: relative;
          &:not(:first-child) {
            margin-left: 10px;
          }

          &.is-rounded {
            overflow: hidden;
            @include rounded(50%);
          }

          .text {
            position: absolute;
            opacity: 0;
            overflow: hidden;
            width: 1px;
            height: 1px;
          }
          &.is-success {
            &.is-outlined {
              color: $primary-dark;
              border: $control-border-width solid $primary-dark;
              background-color: white;
              :hover {
                color: white;
                background-color: $primary-dark;
              }
            }
          }
        }
      }
    }
  }
}
</style>
