<template>
  <div>
    <h2 class="title is-3">Email settings</h2>

    <div
      class="has-margin-bottom"
      v-for="rule in notificationRules"
      :key="rule.Id"
    >
      <ui-checkbox
        v-model="rule.SendMail"
        inputAdditionClass=""
        iconAdditionClass="has-text-white"
        @input="updateNotificationRule(rule)"
      >
        {{ texts[rule.ChangeType] }}
      </ui-checkbox>
    </div>

    <hr class="seperator" />

    <ui-checkbox
      v-model="receiveNewsletter"
      inputAdditionClass=""
      iconAdditionClass="has-text-white"
      @input="updateMailList"
    >
      Send me the Seats2meet newsletter, I’m a curious person and want to be updated on a regular basis!
    </ui-checkbox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import mailchimp from '../providers/mailchimp'
import notification from '../providers/notification'
import profile from '../providers/profile'

export default {
  data() {
    return {
      notification: {
        type: Object,
        data() {
          return {}
        }
      },
      notificationRules: [],
      receiveNewsletter: false,
      texts: {
        'NewReviewComment': 'Send me a message when there’s a comment on my review',
        'NewQuestionAnswer': 'Send me an email when someone gives an answer to my question',
        'NewChatMessage': 'Send me an email when someone sent me a message',
        'NewQuestion': 'Send me an email when a new question is asked',
        'NewAnswerComment': 'Send me a message when an email when someone comments on my answer ',
        'NewReview': 'Send me review emails to review my experience.',
        'ReservationReminder': 'Send me booking reminder emails',
        'NewReservationComment': 'Send me an email when the location add a comment to a reservation of my',
      }
      // texts: {
      //   23: 'Send me a message when there’s a comment on my review’',
      //   33: 'Send me an email when someone gives an answer to my question',
      //   21: 'Send me an email when someone sent me a message',
      //   32: 'Send me an email me when a new question is asked',
      //   34: 'Send me a message when an email when someone comments on my answer ',
      //   5: 'Send me review emails to review my experience.',
      //   35: 'Send me booking reminder emails'
      // }
    }
  },

  created() {
    this.checkMailList()
  },

  mounted() {
    this.getProfileNotificationRules()
  },

  methods: {
    getProfileNotificationRules() {
      // notification.methods.getProfileOldNotificationRules()
      notification.methods.getProfileNotificationRules().then(response => {
        this.notificationRules = response.data
      })
    },

    updateNotificationRule(rule) {
      notification.methods.updateNotificationRule(rule).then(response => { })
    },

    checkMailList() {
      mailchimp.methods.checkIfProfileOnMailList().then(response => {
        this.receiveNewsletter = response
      })
    },

    updateMailList() {
      if (this.receiveNewsletter) {
        mailchimp.methods.setProfileOnMailList().then(response => { })
      } else {
        mailchimp.methods.removeProfileFromMailList().then(response => { })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
</style>
