<template>
  <div class="position-cookieBar">
    <div class="box-cookieBar is-flex box container is-fluid has-background-white p-4">
      <div class="col-info">
        <h6 class="title is-6 mb-0">{{$t('Components.CookieBar.Title_CookieBar')}}</h6>
        <p v-html="$t('Components.CookieBar.Text_Description')"></p>
      </div>
      <div class="col-controls pl-4">
        <a
          @click="showCookieSettings()"
          class="icon-text is-align-items-center has-text-primary-dark mb-2 mt-4"
        >
          <span class="icon is-small mr-1 has-text-weight-bold">
            >
          </span>
          <span>
            {{$t('Components.CookieBar.Button_Settings')}}
          </span>
        </a>
        <br />
        <button
          class="button is-primary is-responsive mt-3"
          @click="save()"
        >{{$t('Components.CookieBar.Button_Accept')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'CookieBar',

  data() {
    return {
      myCurrentCookiesSettings: {},
    }
  },

  computed: {
    ...mapState('cookieStore', ['cookies'])
  },

  methods: {
    ...mapActions('cookieStore', ['saveCookieSettings']),

    ...mapMutations(
      'cookieStore', ['setShowCookieSettingsModal', 'updateCookieIsSetState']
    ),

    showCookieSettings() {
      this.setShowCookieSettingsModal(true)
    },

    // Save cookie preferences
    save() {
      let output = {}
      output['Version'] = this.cookies.Version
      output['Id'] = this.cookies.Id

      for (let i = 0; i < this.cookies.CookieTypes.length; i++) {
        const cookieType = this.cookies.CookieTypes[i]
        output[cookieType.Key] = 1
      }

      this.saveCookieSettings(output)
    },
  }
}
</script>

<style lang="scss" scoped>
.position-cookieBar {
  position: fixed;
  bottom: $gap;
  z-index: 1000;
  width: 100vw;

  .box-cookieBar {
    border: 1px solid $primary;

    .col-info {
      flex-grow: 1;
    }

    .col-controls {
      flex-grow: 0;
      flex-shrink: 0;

      .icon {
        background-color: $primary-dark;
        color: white;
        border-radius: 50%;
        svg {
          width: 8px;
        }
      }
    }
  }
}
</style>