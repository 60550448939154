<script>
import { mapGetters, mapState } from 'vuex'
import TermsForm from './TermsForm.vue'
import termsProvider from '../../providers/terms'
export default {
  name: 'PrivacyTerms',
  extends: TermsForm,
  computed: {
    ...mapState('cartStore', ['cart']),
    ...mapGetters('cartStore', ['isBusy'])
  },

  created() {
    this.hasAgreed = false // Moet komen uit profile object of state
    this.linkAgreeWithTerms = 'Components.Auth.ProfileTerms.Link_AgreeWithTerms'
    this.modalTitle = this.$t('Components.Auth.ProfileTerms.Modal_Title')
    this.initReady = false
    this.loadProfileTerms()
  },

  mounted() {
    this.$el.style.display = 'none'
  },

  methods: {
    async loadProfileTerms() {
      this.disabled = true
      let response = await termsProvider.methods.getLatestTerms().catch()
      if (response && response.status === 200) {
        this.processTerms(response.data)
        this.$emit('termsData', response.data)
      }
      this.disabled = false
      this.$el.style.display = ''
    },

    processTerms(terms) {
      this.description = terms.Text
      this.version = terms.Version
    }
  },

}
</script>

<style lang="scss" scoped>
</style>