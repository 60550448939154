<template>
  <div class="field">
    <div
      v-if="!hideLabel && label"
      class="field-label is-normal"
    >
      <label
        ref="label"
        :for="labelId"
        class="label has-text-left"
      >
        {{ label }}<span
          v-if="isRequired"
          class="has-text-danger"
        >*</span>
      </label>
    </div>
    <div class="field-body">
      <div class="field">
        <div
          :class="['control', { 'has-icons-left': inputIcon, 'has-no-error': !hasErrors }]"
          class="has-icons-right"
        >
          <input
            :value="fieldValue"
            :type="type"
            :class="['input', inputSizeClass, { 'is-danger': hasErrors }, { 'is-static' : isStatic}]"
            :placeholder="placeolder"
            :disabled="disabledStatus"
            :readonly="readonly"
            :id="inputId"
            :maxlength="maxLength"
            autocomplete="none"
            aria-autocomplete="both"
            @blur="onBlur"
            @keyup="onChange"
            @focus="onFocus"
            @input="onInput"
          />
        </div>
        <div v-if="errorMessage">
          <div
            v-if="!Array.isArray(errorMessage)"
            class="help is-danger"
            v-html="errorMessage"
          ></div>
          <div
            v-if="Array.isArray(errorMessage)"
            class="help is-danger"
          >
            <div
              v-for="(message, index) in errorMessage"
              :key="`e${index}`"
              v-html="message"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFormInputField from './BaseFormInputField'

export default {
  name: 'BaseBulmaField',
  extends: BaseFormInputField,

  components: {
    // TransitionExpand
  },

  props: {
    maxLength: {
      type: Number,
      default: 524288
    },

    labelAllowTextWrap: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'text'
    }
  },

  data() {
    return {
      inputIcon: null
    }
  }
}
</script>