import axios from 'axios'
import store from '../store/store'

let getEventCalendarToken = null
let getAttandingEventsToken = null
let getOrganizedEventsToken = null
let cancelRequest_eventRegistration = null

export default {
  methods: {
    /**
     * Cancel get event calendar events request
     */
    cancelGetEventCalendar() {
      if (getEventCalendarToken) {
        getEventCalendarToken.cancel()
      }
    },

    /**
     * Cancel get attending/visited event request
     */
    cancelGetAttendingEvents() {
      if (getAttandingEventsToken) {
        getAttandingEventsToken.cancel()
      }
    },

    /**
     * Cancel get organized event request
     */
    cancelGetOrganizedEvents() {
      if (getOrganizedEventsToken) {
        getOrganizedEventsToken.cancel()
      }
    },

    async getEventById(eventId) {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return value
    },

    async getEventCalendar(searchTerm = '', page = 1, itemsPerPage = 25) {
      this.cancelGetEventCalendar()
      getEventCalendarToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event`,
        {
          cancelToken: getEventCalendarToken.token,
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getEventsOrganizedByProfile(
      searchTerm = '',
      page = 1,
      itemsPerPage = 25,
      showAll = true
    ) {
      this.cancelGetOrganizedEvents()
      getOrganizedEventsToken = axios.CancelToken.source()
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/organized`,
        {
          cancelToken: getOrganizedEventsToken.token,
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            showAll: showAll
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    /**
     * Get profile attending events
     * @param {*} searchTerm
     * @param {*} page
     * @param {*} itemsPerPage
     */
    async getEventsProfileAttends() {
      this.cancelGetAttendingEvents()
      getAttandingEventsToken = axios.CancelToken.source()
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/event/attending`,
        {
          cancelToken: getAttandingEventsToken.token,

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    async getEventsProfileVisited(
      searchTerm = '',
      page = 1,
      itemsPerPage = 25
    ) {
      this.cancelGetAttendingEvents()
      getAttandingEventsToken = axios.CancelToken.source()
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/visited`,
        {
          cancelToken: getAttandingEventsToken.token,
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    async getEvent(eventId) {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/event/public/E${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY
          }
        }
      )
      return value
    },

    async getEventOrganizers(eventId) {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${eventId}/organizer`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    async getEventState(eventId) {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${eventId}/state`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    async getRights(eventId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/rights/event/${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    //#region POST
    /**
     * Post new or updated event data
     * @param {Object} event 
     * @returns 
     */
    async saveEvent(event) {
      return await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event`,
        event,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    /**
     * Create new event organizer
     * @param {Object} organizer 
     * @returns 
     */
    async createEventOrganizer(organizer) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${
          organizer.EventId
        }/organizer`,
        JSON.stringify(organizer),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Upload event image
     * @param {Number} eventId
     * @param {String} base64Image
     */
    async uplouadEventImage(eventId, base64Image) {
      // let img = await this.resizeImage(base64Image)
      base64Image = base64Image.split(',')[1]

      const response = axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/upload/image`,
        {
          Base64Image: base64Image,
          Type: 'Event',
          ItemId: eventId,
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    },

    // async saveEventBase64Image(criteria) {
    //   let base64string = criteria.Base64Image.split(',')[1]
    //   criteria.Base64Image = base64string

    //   const response = await axios.post(
    //     `${process.env.VUE_APP_API_BASE_URL}/api/event/uploadBase64image`,
    //     JSON.stringify(criteria),
    //     {
    //       headers: {
    //         'Content-type': 'application/json',
    //         token: process.env.VUE_APP_API_KEY,
    //         profileToken: store.getters.getProfileToken
    //       }
    //     }
    //   )
    //   return response
    // },


    /**
     * Post new event registration
     * @param {Number} eventId 
     * @returns 
     */
    async postEventRegistration(eventId) {
      cancelRequest_eventRegistration && cancelRequest_eventRegistration('Request is cancelled due to new request')
      return await axios.post(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/registration`, {
        EventId: eventId
      }, {
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken,
        },
        cancelToken: new axios.CancelToken(function executor(c) {
          cancelRequest_eventRegistration = c;
        })
      })
    },
    //#endregion POST


    //#region PUT
    /**
     * Update organizer data
     * @param {Object} organizer 
     * @returns 
     */
    async updateEvent(event) {
      return await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${event.Id}`,
        event,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    async updateEventOrganizer(organizer) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${
          organizer.EventId
        }/organizer/${organizer.Id}`,
        JSON.stringify(organizer),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
    //#endregion PUT


    //#region DELETE
    async deleteEvent(eventId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${eventId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async deleteEventOrganizer(organizer) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/${
          organizer.EventId
        }/organizer/${organizer.Id}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async cancelEventRegistration(registrationId) {
      return await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/event/registration/${registrationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    }
  }
  //#endregion DELETE
}
