import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPaperPlane, faPencilAlt, faUser, faFlag, faTrashAlt, faCopy, faUsers, faFileInvoice,  } from '@fortawesome/free-solid-svg-icons'
import { faCommentAltDots, faCheck, faCoffee, faInfoCircle, faCommentLines, faExclamationTriangle, faUndoAlt, } from '@fortawesome/pro-solid-svg-icons'
import { faEllipsisH, faMugHot, faPrint as farPrint, faPlus as farPlus, faMinus as farMinus, faTimes as farTimes, faFileInvoice as farFileInvoice } from '@fortawesome/pro-regular-svg-icons'
library.add(
  faPaperPlane,
  faCoffee,
  faEllipsisH,
  faCommentAltDots,
  faCheck,
  faMugHot,
  faInfoCircle,
  faPencilAlt,
  faUser,
  faUsers,
  faFlag,
  faTrashAlt,
  faCopy,
  faCommentLines,
  faFileInvoice,
  faExclamationTriangle,
  faUndoAlt,

  // Pro regular
  farPrint,
  farPlus,
  farMinus,
  farTimes,
  farFileInvoice

)

// export default FontAwesomeIcon
Vue.component('font-awesome-icon', FontAwesomeIcon)