import axios from 'axios'
import store from '../store/store'

let getValidateAvailabilityToken = null
let getAvailabilityToken = null
let copyReservationCheckAvailabilityToken = null
let selectSpaceCancel = null

export default {
  methods: {
    async getAvailability(
      startDate,
      endDate,
      channelId,
      countryId,
      LocationId,
      searchTerm,
      meetingtypeId,
      seats,
      startTime,
      endTime,
      settingId = 0,
      spaceId = 0,
      voucherId = 0,
      tenderId = 0,
      widgetId = -100
    ) {
      if (getAvailabilityToken) {
        getAvailabilityToken.cancel('Canceled by new request.')
      }
      getAvailabilityToken = axios.CancelToken.source()

      /**
       * API GET CALLS
       */
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/availability`,
        {
          cancelToken: getAvailabilityToken.token,
          params: {
            q: searchTerm,
            channel: channelId,
            country: countryId,
            location: LocationId,
            meetingtype: meetingtypeId,
            seats: seats,
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            setting: settingId,
            space: spaceId,
            voucher: voucherId,
            tender: tenderId,
            widgetId: widgetId,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    },

    async checkSpaceTimeAvailability(space) {
      if (getValidateAvailabilityToken) {
        getValidateAvailabilityToken.cancel()
      }
      getValidateAvailabilityToken = axios.CancelToken.source()

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/availability/time/check`,
        JSON.stringify(space),
        {
          cancelToken: getValidateAvailabilityToken.token,

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    },

    async copyReservationCheckAvailability(reservationId, startDate, endDate) {
      if (copyReservationCheckAvailabilityToken) {
        copyReservationCheckAvailabilityToken.cancel('Canceled by new request.')
      }
      copyReservationCheckAvailabilityToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/availability/copy/check`,
        {
          cancelToken: copyReservationCheckAvailabilityToken.token,
          params: {
            endDate: endDate,
            reservationId: reservationId,
            startDate: startDate,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    },

    /**
     * API POST CALLS
     */
    async selectSpace({
      CartType = 0,
      SearchId = null,
      LocationId = null,
      Hash = null,
    } = {}) {
      if (SearchId === null, LocationId === null, Hash === null) { return }
      selectSpaceCancel && selectSpaceCancel('Request cancelled')
      
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/availability/select`,
        {
          CartType,
          SearchId,
          LocationId,
          Hash
        },
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            selectSpaceCancel = c;
          }),
          
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken,
          },
        }
      )
      return response
    }
  }
}