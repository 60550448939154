/**
 * Component files
 */
const Events = () => import(/* webpackChunkName: "Events" */ '@/views/Events')
const EventDetail = () => import(/* webpackChunkName: "EventDetail" */ '@/views/EventDetail')

const eventRoutes = [
    {
        path: '/events',
        name: 'Events',
        component: Events,
        props: { pageView: 'explore' },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/events/going',
        name: 'AttendingEvents',
        component: Events,
        props: { pageView: 'going' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/relevant',
        name: 'RelevantEvents',
        component: Events,
        props: { pageView: 'relevant' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/new',
        name: 'NewEvents',
        component: Events,
        props: { pageView: 'new' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/organized',
        name: 'OrganizedEvents',
        component: Events,
        props: { pageView: 'organized' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId',
        name: 'EventDetail',
        component: EventDetail,
        props: { pageView: 'detail' },
        meta: {
            requiresAuth: false
        }
    },

    {
        path: '/events/:eventId/dashboard',
        name: 'EventDashboard',
        component: EventDetail,
        props: { pageView: 'dashboard' },
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/events/:eventId/edit',
        name: 'EditEventDetail',
        component: EventDetail,
        props: { pageView: 'edit' },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/:eventId/going',
        name: 'EventAttendees',
        component: EventDetail,
        props: { pageView: 'checkins' },
        meta: {
            requiresAuth: true
        }
    }
]

export default eventRoutes