<template>
  <div>
    <h3
      v-if="!hideTitle"
      class="title is-3 has-text-blue-dark"
    >You are ready</h3>

    <message class="is-success">
      <font-awesome-icon
        :icon="['fas', 'check']"
        class="has-margin-right"
      />
      <span>Your new password is set and ready for use.</span>
    </message>

    <template v-if="!showCustomConfirmationText">
      <div class="content">
        <p>Your new password is ready for use. Click on the button below to login with your new password.</p>
      </div>
      <a
        @click="linkActionLogin"
        class="button is-primary is-medium"
      >
        <span>Login</span>
      </a>
    </template>
    <slot name="confirmationText"></slot>
  </div>
</template>

<script>
import Message from '../UI/Message.vue'
export default {
  components: { Message },
  name: 'SetPasswordConfirmation',

  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },

    showCustomConfirmationText: {
      type: Boolean,
      default: false
    },

    linkActionLogin: {
      type: [Boolean, Function],
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
</style>