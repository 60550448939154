import axios from 'axios'
import localforage from 'localforage'
import store from '../store/store'

export default {
  methods: {
    async getProfileLocations() {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/locations/profile`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    async getLocationsFromStorage() {
      try {
        const value = await localforage.getItem('locations')
        return value
      } catch {
        
      }
    },

    async getLocationsFromApi(
      latitude = 0,
      longitude = 0,
      searchTerm = '',
      page = 1,
      itemsPerPage = 20,
      channelId = 1,
      radius = 0
    ) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/location`,
          {
            headers: {
              'Content-type': 'application/json',
              apitoken: process.env.VUE_APP_API_KEY,
            },
            params: {
              channelId: channelId,
              latitude: searchTerm ? 0 : latitude,
              longitude: searchTerm ? 0 : longitude,
              radius: radius,
              searchTerm: searchTerm !== '' ? searchTerm.trim() : '',
              page: page,
              itemsPerPage: itemsPerPage,
              meetingtypeId: 2
            }
          }
        )
        if (page === 1) {
          // this.saveLocationsInStorage(
          //   latitude,
          //   longitude,
          //   radius,
          //   searchTerm.trim(),
          //   response.data.Results
          // )
        }
        return response.data
      } catch (e) {
        if (process.NODE_ENV === 'development') {
          console.log(e)
        }
      }
    },

    saveLocationsInStorage(latitude, longitude, radius, searchTerm, locations) {
      let obj = {
        Latitude: searchTerm ? 0 : latitude,
        Longitude: searchTerm ? 0 : longitude,
        Radius: searchTerm ? 0 : radius,
        SearchTerm: searchTerm.trim(),
        Locations: locations
      }

      localforage.setItem('locations', obj)

      for (let i = 0; i < locations.length; i++) {
        let location = locations[i]
        localforage
          .setItem('location_' + location.Id, location)
          .then(value => {
            if (process.NODE_ENV === 'development') {
              console.log('Locations stored locally')
            }
          })
          .catch(e => {
            if (process.NODE_ENV === 'development') {
              console.log(e)
            }
          })
      }
    },
    

    /** ----------------------------------------------
     * NEW API CALLS
    ----------------------------------------------- */
    /**
     * Get location by Id
     * @param {Number} locationId 
     * @returns {Object}
     */
    async getLocationByLocationId(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    /**
     * Get location languages
     * @param {Number} locationId
     * @returns 
     */
    async getLocationLanguageIds(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/location/${locationId}/language`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    /**
     * Get location active manager
     * @param {Number} locationId
     * @returns 
     */
    getActiveManager(locationId) {
      const response = axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/location/${locationId}/manager/active`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    }
  }
}
