<template>
  <transition name="fade">
    <div
      v-if="reviews.length > 0"
      class="openReviewRequestContainer"
    >
      <!-- <transition name="fade"> -->
      <div v-if="selectedReview > 0">
        <save-review
          @closeSaveReview="resetSelectedReview"
          :reviewId="selectedReview"
        ></save-review>
      </div>
      <!-- </transition> -->

      <div class="reviewWrapper">
        <h3 class="title is-3 has-text-centered">Review your experience</h3>
        <transition name="fade">
          <div
            class=""
            v-if="selectedReviewSlide !== 0"
          >
            <div
              class="review-item"
              @click="() => { selectReview(selectedReviewSlide); $googleAnalytics.pushEvent(routeName, 'button-ShowReviewForm', 'Review'); }"
            >
              <img
                class="has-background-grey-light"
                src="@/assets/img/spacer5_3.gif"
                :style="{backgroundImage: `url(${$options.filters.getLocationImage(selectedReviewSlide.LocationImage, selectedReviewSlide.LocationId, 320)})`}"
              >
              <h4
                v-html="selectedReviewSlide.LocationName"
                class="has-text-centered"
              ></h4>
              <div class="content has-text-centered">
                <p>
                  <i class="far fa-file-alt"></i> {{ selectedReviewSlide.ReservationId }} &nbsp;
                  <i class="far fa-calendar-alt"></i> {{ selectedReviewSlide.ReservationDate | longDateFormat }}
                </p>
                <button class="button is-primary">Give a review</button>
              </div>
            </div>
          </div>
        </transition>
        <span
          class="nav-prev icon is-large"
          @click="GoToSlide('<')"
          v-show="slideIndex > 0"
          style="display: none;"
        >
          <i class="fas fa-3x fa-chevron-left"></i>
        </span>
        <span
          class="nav-next icon is-large"
          @click="GoToSlide('>')"
          v-show="(slideIndex + 1) < reviews.length"
          style="display: none;"
        >
          <i class="fas fa-3x fa-chevron-right"></i>
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import reviewProvider from '../providers/review'

export default {
  data() {
    return {
      selectedReviewSlide: 0,
      itemsPerPage: 3,
      page: 1,
      reviews: [],
      selectedReview: 0,
      slideIndex: 0,
      routeName: `Page-${this.$route.name}`
    }
  },
  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    })
  },
  watch: {
    isInitiated: {
      handler: 'getOpenReviews',
      immediate: true
    }
  },
  methods: {
    getOpenReviews() {
      reviewProvider.methods.getOpenReviews(this.page, this.itemsPerPage).then(response => {
        this.reviews = response.data.Results
        if (this.reviews.length > 0) {
          this.slideIndex = 0
          this.selectedReviewSlide = this.reviews[0]
          this.$emit('hasReviews', true)
        }
      })
    },

    GoToSlide(step) {
      if (step === '>') {
        if (this.slideIndex >= this.reviews.length - 1) {
          return
        }
        this.slideIndex = this.slideIndex >= this.reviews.length - 1 ? this.reviews.length - 1 : this.slideIndex + 1
      } else {
        if (this.slideIndex <= 0) {
          return
        }
        this.slideIndex = this.slideIndex <= 0 ? 0 : this.slideIndex - 1
      }
      this.selectedReviewSlide = 0
      let t = setTimeout(() => {
        this.selectedReviewSlide = this.reviews[this.slideIndex]
        clearInterval(t)
      }, 300)
    },

    selectReview(review) {
      this.selectedReview = review.Id
    },

    resetSelectedReview(value) {
      var self = this
      if (value) {
        if (value.AnsweredOn.getFullYear() > 1900) {
          let reviewIndex = self.reviews.findIndex(r => r.Id === value.Id)
          if (reviewIndex > -1) {
            self.reviews.splice(reviewIndex, 1)
          }
        }
      }
      this.selectedReview = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.openReviewRequestContainer {
  .dateBar,
  .row {
    padding: 3px 5px;
    &.row-reservationId {
      a {
        float: right;
      }
    }
    &.row-locationName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dateBar {
    margin-bottom: 5px;
  }
  .image {
    width: 120px;
  }
  .media {
    margin: 0;
  }
}
.reviewWrapper {
  position: relative;
  .review-item {
    img {
      display: block;
      margin: 0 auto 5px;
      overflow: hidden;
      width: 86%;
    }
  }
  .nav-prev,
  .nav-next {
    cursor: pointer;
    color: $green;
    left: 3px;
    position: absolute;
    top: 130px;
    z-index: 1;
    &.icon {
      width: 2rem;
    }
    &:hover {
      color: $cyan;
      background: rgba(white, 0.5);
    }
  }
  .nav-next {
    left: auto;
    right: 3px;
  }
}
</style>
