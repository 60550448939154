<template>
  <div class="inbox-wrapper has-text-centered">
    <div class="top">
      <h3 class="title is-3 has-text-blue-dark">Messages</h3>
    </div>
    <div class="wrapper has-margin-bottom">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="notifications.length !== 0"
          key="messagesView"
          class="inbox-content has-text-centered"
        >
          <div
            class="title is-1 is-marginless"
            :class="{'has-text-blue-dark' : nrOfMessages !== 0, 'has-text-grey' : nrOfMessages === 0}"
          >
            <span
              :data-badge="nrOfMessages"
              :class="{'badge is-badge-danger has-badge-large' : nrOfMessages > 0}"
            >
              <i class="fas fa-lg fa-envelope"></i>
            </span>
          </div>
          <div>
            <div
              v-if="nrOfMessages"
              class="title is-size-6-mobile is-size-5-tablet has-margin-left"
            >{{nrOfMessages}} unread message(s)</div>
            <div
              class="title is-size-6-mobile is-size-5-tablet has-margin-left has-text-grey"
              v-else
            >You have currently no new messages</div>
            <div class="subtitle is-6">Total messages: {{notifications.length}}</div>
          </div>
        </div>

        <div
          v-if="notifications.length === 0"
          key="noMessagesView"
          class="inbox-content has-text-centered"
        >
          <span class="title is-1 is-marginless has-text-grey">
            <i class="fas fa-lg fa-envelope"></i>
          </span>
          <span class="title has-margin-left is-5 has-text-grey">You have currently no messages</span>
        </div>
      </transition>
    </div>
    <div class="bottom">
      <router-link
        class="button is-primary"
        :to="{ name: 'Inbox'}"
      >View messages</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import notification from '@/providers/notification'

export default {
  data() {
    return {
    }
  },

  components: {
  },

  computed: {
    ...mapState('chatStore', ['chats']),
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      notifications: 'getNotifications',
      passportState: 'getPassportState'
    }),
    nrOfMessages() {
      let notifications = this.$store.getters.getNotifications
      return notifications.filter(n => n.IsRead === false).length
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.inbox-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
  }
  // height: 100%;
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // .wrapper {
  //   display: flex;
  //   height: 100%;
  //   .inbox-content {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;
  //   }
  // }
}
</style>
