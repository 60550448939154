import Vue from 'vue'
import axios from 'axios'
import format from 'date-fns/format'
import store from '../store/store'

let getCheckinsRequestToken = null
let getCheckinKnowledgeRequestToken = null
let cancelRequest_processCheckin = null

export default {
  methods: {
    cancelCheckinsRequest() {
      if (getCheckinsRequestToken) {
        getCheckinsRequestToken.cancel()
      }
    },
    cancelCheckinKnowledgeRequest() {
      if (getCheckinKnowledgeRequestToken) {
        getCheckinKnowledgeRequestToken.cancel()
      }
    },

    //#region GET

    /**
     * Get checkins
     */
    async getCheckins({
      q = '',
      page = 0,
      itemsPerPage = 0,
      eventId = 0,
      locationId = 0,
      reservationId = 0,
      date = new Date(),
      latitude = 0,
      longitude = 0,
      radius = 0,
      filterProfileId = 0,
      cancelPreviousRequest = true
    } = {}) {
      if (cancelPreviousRequest) {
        this.cancelCheckinsRequest()
      }
      getCheckinsRequestToken = axios.CancelToken.source()

      date = Vue.options.filters.dateObjectIsoDateString(date)

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin`,
        {
          cancelToken: getCheckinsRequestToken.token,
          params: {
            q,
            page,
            itemsPerPage,
            eventId,
            locationId,
            reservationId,
            date,
            latitude,
            longitude,
            radius,
            filterProfileId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getEventCheckins(
      eventId = 0,
      searchTerm = '',
      page = 0,
      itemsPerPage = 0
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/event/${eventId}`,
        {
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getCheckinKnowledge(
      locationId = 0,
      eventId = 0,
      q = '',
      page = 1,
      itemsPerPage = 10,
      dateTime = Vue.options.filters.dateObjectIsoDateString(new Date()),
      channelId = 0
    ) {
      this.cancelCheckinKnowledgeRequest()
      getCheckinKnowledgeRequestToken = axios.CancelToken.source()

      return await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/knowledge`, {
        params: {
          channelId: channelId,
          locationId: locationId,
          eventId: eventId,
          q: q || null,
          date: dateTime,
          page: page,
          itemsPerPage: itemsPerPage
        },
        cancelToken: getCheckinKnowledgeRequestToken.token,
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY
        }
      })
    },

    async getLocationCheckins(
      locationId = 0,
      searchTerm = '',
      page = 0,
      itemsPerPage = 0
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/location/${locationId}`,
        {
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getCheckinByReservationId(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/checkin/reservation/${reservationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getRandomCheckins(nrOfItems, filterProfileIds) {
      let criteria = {
        FilterProfileIds: filterProfileIds
      }

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/checkin/random/${nrOfItems}?` +
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async confirmCheckin(checkinId) {
      let profile = store.getters.getProfile

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/checkin/confirm/${checkinId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileKey: profile.Key
          }
        }
      )
      return response
    },

    
    /**
     * Get checkin by checkin ID
     * @param {Number} checkinId 
     * @returns 
     */
    async getCheckinById(checkinId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/${checkinId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
    //#endregion GET


    //#region POST
    /**
     * Post new checkin
     */
    async postCheckin({ Type = 'Network', ItemId = 0, StartDate = format(new Date(), 'YYYY-MM-DD'), StartMinutes = 0, EndDate = format(new Date(), 'YYYY-MM-DD'), EndMinutes = 0, Latitude = 0, Longitude = 0, Status = '', Tags = '', ProfileImage = '', IsConfirmed = true, IsGuestFrom = 0 } = {}) {
      cancelRequest_processCheckin && cancelRequest_processCheckin('Request is cancelled due to new request')
      return await axios.post(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin`, {
        Type,
        ItemId,
        StartDate,
        StartMinutes,
        EndDate,
        EndMinutes,
        Latitude,
        Longitude,
        Status,
        Tags,
        ProfileImage,
        IsConfirmed,
        IsGuestFrom
      },
      {
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken,
        },
        cancelToken: new axios.CancelToken(function executor(c) {
          cancelRequest_processCheckin = c;
        })
      })
    },
    //#endregion POST

    //#region PUT

    /**
     * Update checkin
     */
    async updateCheckin({ Id = 0, StartDate = format(new Date(), 'YYYY-MM-DD'), StartMinutes = 0, EndDate = format(new Date(), 'YYYY-MM-DD'), EndMinutes = 0, Latitude = 0, Longitude = 0, Status = '', Tags = '' } = {}) { 
      cancelRequest_processCheckin && cancelRequest_processCheckin('Request is cancelled due to new request')
      if (Id === 0) { return false }

      return await axios.put(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin`, {
        Id,
        StartDate,
        StartMinutes,
        EndDate,
        EndMinutes,
        Latitude,
        Longitude,
        Status,
        Tags
      },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
          cancelToken: new axios.CancelToken(function executor(c) {
            cancelRequest_processCheckin = c;
          })
        })
    },


    /**
     * Update checkin present state
     */
    async updateCheckinPresentState(checkinId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/${checkinId}/present`,
        {},
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Update checkin state to has left
     */
    async updateCheckinLeftState(checkinId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/checkin/${checkinId}/left`,
        {},
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    //#endregion PUT
  }
}
