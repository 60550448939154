<template>
  <div>
    <div
      class="modal"
      v-if="showDelete"
      :class="{'is-active': showDelete}"
    >
      <div class="modal-background"></div>
      <div class="modal-content has-background-white">
        <div class="padding">
          <div class="has-text-centered">
            <div class="title is-4">Are you sure you want to delete this review?</div>
            <div>#{{ mReview.ReservationId }} {{ mReview.LocationName }}</div>
            <hr class="separation" />
            <a
              @click="setShowDeleteReview"
              class="button is-ghost has-margin-right"
            >cancel</a>
            <a
              @click="deleteReview"
              class="button is-danger"
            >Delete</a>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="setShowDeleteReview"
      ></button>
    </div>
    <div v-if="!showDelete">
      <article class="reviewMedia media">
        <div class="media-left">
          <div class="wrapper-reviewScore">
            <span
              :title="mReview.Score"
              :class="getReviewScoreClass(mReview.Score)"
            ></span>
          </div>
          <a
            @click="setShowDeleteReview"
            class="button button-delete-review is-text is-small is-white"
          >
            <span class="icon has-text-danger"><i class="fas fa-lg fa-minus-circle"></i></span>
            <span class="has-text-danger">Delete</span></a>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="has-text-grey postDetailBar">
              <strong>{{ review.LocationName }}</strong>
              <span class="has-margin-right">{{ mReview.AnsweredOn | longDateFormat }} -
                {{ mReview.AnsweredOn | timeFromDateTimeStamp }}</span>
              <span v-if="mReview.Comments.length > 0">{{ mReview.Comments.length }} comments</span>
            </div>
            <p class="has-text-weight-bold">{{ mReview.ProfileName }}</p>

            <add-comment
              @commentAdded="commentAdded"
              :type="'review'"
              :itemId="review.Id"
            ></add-comment>
            <!-- <p :inner-html.prop="mReview.Text | nl2br"></p> -->
          </div>

          <comment-item
            v-for="comment in filterComments(mReview, 0)"
            :key="comment.Id"
            :commentObj="comment"
            :comments="mReview.Comments"
          ></comment-item>

          <hr class="separation" />
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import comment from '@/providers/comment'
import review from '@/providers/review'

export default {
  props: {
    review: null
  },
  data() {
    return {
      mReview: this.$props.review,
      showDelete: false
    }
  },
  methods: {
    commentAdded(type, itemId, comment) {
      if (type === 'review') {
        this.saveComment(itemId, comment)
      }
    },

    getReviewScoreClass(score) {
      if (score > 1 && score <= 10) {
        return 'reviewImageSprite status01'
      } else if (score > 10 && score <= 20) {
        return 'reviewImageSprite status02'
      } else if (score > 20 && score <= 30) {
        return 'reviewImageSprite status03'
      } else if (score > 30 && score <= 40) {
        return 'reviewImageSprite status04'
      } else if (score > 40 && score <= 50) {
        return 'reviewImageSprite status05'
      } else if (score > 50 && score <= 60) {
        return 'reviewImageSprite status06'
      } else if (score > 60 && score <= 70) {
        return 'reviewImageSprite status07'
      } else if (score > 70 && score <= 80) {
        return 'reviewImageSprite status08'
      } else if (score > 80 && score <= 90) {
        return 'reviewImageSprite status09'
      } else if (score > 90 && score <= 100) {
        return 'reviewImageSprite status10'
      }
      return 'reviewImageSprite status-noScore'
    },

    filterComments(review, parentId) {
      return review.Comments.filter(c => c.ParentId === parentId)
    },

    saveComment(reviewId, commentText) {
      comment.methods.addComment('review', reviewId, commentText).then(response => {
        this.review.Comments.push(response.data)

        this.$emit('reviewCommentAdded', this.review)
      })

      this.showCommentInput = false
    },

    setShowDeleteReview() {
      this.showDelete = !this.showDelete
    },

    deleteReview() {
      review.methods.deleteReview(this.review.Id).then(response => {
        this.$emit('reviewDeleted', this.review.Id)
        this.setShowDeleteReview()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.reviewMedia {
  .button-delete-review {
    cursor: pointer;
  }

  .reviewImageSprite {
    display: inline-block;
    width: 102px;
    height: 21px;
    background: transparent url(~@/assets/img/ReviewIconsSprite.png) no-repeat 0
      0;

    &.status01 {
      background-position: 0 -49px;
    }

    &.status02 {
      background-position: 0 -97px;
    }

    &.status03 {
      background-position: 0 -146px;
    }

    &.status04 {
      background-position: 0 -195px;
    }

    &.status05 {
      background-position: 0 -244px;
    }

    &.status06 {
      background-position: 0 -293px;
    }

    &.status07 {
      background-position: 0 -342px;
    }

    &.status08 {
      background-position: 0 -390px;
    }

    &.status09 {
      background-position: 0 -438px;
    }

    &.status10 {
      background-position: 0 -487px;
    }
  }
}

.media-content {
  width: 100%;
}
.media-comments {
  font-size: 90%;
}

@media screen and (max-width: 420px) {
  .media {
    display: block;
    .media-left {
      margin: 0 0 $gap 0;

      .wrapper-reviewScore {
        display: inline-block;
      }
    }
    .media-content {
    }

    .media-right {
      margin: $gap 0 0 0;
    }
  }
}
</style>
