import axios from 'axios'
import store from '../store/store'

let getMatchesCancellationToken = null

export default {
  methods: {
    cancelGetMatches() {
      if (getMatchesCancellationToken) {
        getMatchesCancellationToken.cancel()
      }
    },

    /**
     * Get matches
     * @param {string} workingon
     * @param {number} latitude
     * @param {number} longitude
     * @param {number} page
     * @param {number} itemsPerPage
     * @param {string} typeIds
     * @param {number} fp
     * @param {number} eventId
     * @param {number} locationId
     * @param {number} checkinId
     */
    getMatches(
      workingon,
      latitude,
      longitude,
      page,
      itemsPerPage,
      typeIds = '',
      fp = 0,
      eventId = 0,
      locationId = 0,
      checkinId = 0
    ) {
      this.cancelGetMatches()
      getMatchesCancellationToken = axios.CancelToken.source()
      return axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/match`, {
        cancelToken: getMatchesCancellationToken.token,
        params: {
          q: workingon,
          lat: latitude,
          lon: longitude,
          page: page,
          itemsPerPage: itemsPerPage,
          typeIds: typeIds || null,
          fp: fp,
          eventId: eventId,
          locationId: locationId,
          checkinId: checkinId
        },
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    getCheckinMatches(
      locationId,
      reservationId,
      eventId,
      latitude,
      longitude,
      workingOn,
      ignoreProfileIds,
      page = 0,
      itemsPerPage = 0
    ) {
      let criteria = {
        LocationId: locationId,
        ReservationId: reservationId,
        EventId: eventId,
        WorkingOn: workingOn,
        IgnoreProfileIds: ignoreProfileIds,
        Latitude: latitude,
        Longitude: longitude,
        Page: page,
        ItemsPerPage: itemsPerPage
      }

      return axios.get(
        `${
          process.env.VUE_APP_API_BASE_URL
        }/api/checkin/recommendation?${JSON.stringify(criteria)}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    async getMatchedWith(page, itemsPerPage) {
      const response = await axios.get(
        `${
          process.env.VUE_APP_API_BASE_URL
        }/api/match/matchedwith?page=${page}&itemsPerPage=${itemsPerPage}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveMatchClickLog(typeId, itemId) {
      let criteria = {
        TypeId: typeId,
        ItemId: itemId
      }

      const response = await axios.post(
        `${
          process.env.VUE_APP_API_BASE_URL
        }/api/match/log?typeId=${typeId}&itemId=${itemId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    }
  }
}
