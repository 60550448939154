<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      class="component-drawer"
      v-if="showDrawer"
      :key="'showDrawer' + showDrawer"
    >
      <div
        class="drawer-background"
        @click="closeDrawer()"
      ></div>
      <div
        class="drawer"
        :class="[position, drawerCssClass, animateClass]"
      >
        <div class="drawer-head has-padding">
          <div class="title">
            <slot name="title"></slot>
          </div>
          <a
            class="button-close"
            :class="textCssClass"
            @click="closeDrawer()"
          >
            <span class="icon is-medium">
              <i class="fas fas fa-2x fa-times"></i>
            </span>
          </a>
        </div>
        <div
          class="drawer-body has-padding"
          :class="drawerContentCssClass"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'drawer',

  props: {
    closeFunc: {
      type: Function,
      default: () => { }
    },
    drawerCssClass: {
      type: String,
      default: 'has-background-white'
    },
    drawerContentCssClass: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'left'
    },
    showDrawer: {
      type: Boolean,
      default: false
    },
    textCssClass: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      animateTimer: null,
      showSlideDrawer: this.showDrawer
    }
  },

  computed: {
    animateClass() {
      let cls = 'animated slide'
      cls = cls + (this.showSlideDrawer ? 'In' : 'Out')
      cls = cls + this.position.charAt(0).toUpperCase() + this.position.slice(1)
      return cls
    }
  },

  beforeDestroy() {
    /**
     * Destroy timer
     */
    if (this.animateTimer) {
      clearTimeout(this.animateTimer)
    }
  },

  methods: {
    closeDrawer() {
      this.showSlideDrawer = false
      this.animateTimer = setTimeout(this.closeFunc, 240)
    }
  }
}
</script>

<style lang="scss" scoped>
.component-drawer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000000;
  .drawer {
    display: flex;
    flex-direction: column;
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: auto;
    right: auto;
    height: 100vh;
    max-width: 420px;
    transition: 0.75s;
    animation-duration: 0.5s;
    width: 100%;
    @include box-shadow();

    &.left {
      left: 0;
      width: 100%;
      .button-close {
      }
    }

    &.right {
      right: 0;
      width: 100%;
    }

    // &.is-active {
    //   width: 420px;
    //   @media screen and (max-width: 500px) {
    //     width: 100%;
    //     max-width: 420px;
    //   }
    // }

    .drawer-head {
      display: flex;
      flex-grow: 0;
      flex-shrink: 0;
      .title {
        flex-grow: 1;
        flex-shrink: 1;
      }
      .button-close {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    .drawer-body {
      padding-top: unset;
      // height: unset;
    }
  }
}
</style>
