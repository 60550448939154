import { render, staticRenderFns } from "./ProfileStats.vue?vue&type=template&id=2c288c35&scoped=true&"
import script from "./ProfileStats.vue?vue&type=script&lang=js&"
export * from "./ProfileStats.vue?vue&type=script&lang=js&"
import style0 from "./ProfileStats.vue?vue&type=style&index=0&id=2c288c35&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c288c35",
  null
  
)

export default component.exports