<script>
import BaseField from './BaseBulmaField'
import { email } from 'vuelidate/lib/validators'

export default {
  name: 'EmailField',
  extends: BaseField,

  props: {
    // name: {
    //   type: String,
    //   default: 'email',
    //   note: 'The input data name'
    // },
    label: {
      type: String,
      default: 'E-mail',
      note: 'The field label and/or instruction text.'
    },
    validationRules: {
      type: Object,
      default: () => {
        return {
          email
        }
      },
      note: 'The validation rule(s). Preset to required and email.'
    }
  },
  data() {
    return {
    }
  },
  methods: {
    blur(event) {
      this.focused = false
      this.validateOnChange = true
      this.$emit('blur', { event, field: this })
    }
  }
}
</script>
