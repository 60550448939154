import Vue from 'vue'
import axios from 'axios'
import store from '../store/store'

let getReservationsCancel

export default {
  methods: {
    async checkWorkspaceOverlap({
        startDate = new Date().toISOString(),
        startMinutes = 0,
        endMinutes = 1400,
    } = {}) {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/workspace/checkoverlap`, {
        params: {
          startDate,
          startMinutes,
          endMinutes
        },
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
      return response
    },
    
    /**
     * Get reservations
     * @returns 
     */
    async getReservations({ startDate = new Date(), endDate = new Date(1900, 1, 1), locationId = 0, meetingTypeId = 0, q = '', page = 1, itemsPerPage = 20, channelId = 0, sortDirection = 'ASC', statusId = 2 } = {}) {
      getReservationsCancel && getReservationsCancel('Request successful cancelled')

      startDate = Vue.options.filters.dateObjectIsoDateString(startDate)
      endDate = Vue.options.filters.dateObjectIsoDateString(endDate)
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/reservation`,
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            getReservationsCancel = c;
          }),
          params: {
            startDate,
            endDate,
            locationId,
            meetingTypeId,
            q,
            page,
            itemsPerPage,
            channelId,
            sortDirection,
            statusId
          },

          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Get reservation by reservation Id
     * @param {Number} reservationId 
     * @returns 
     */
    async getReservationById(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}`,
        { 
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    return response
    },

    /**
     * Get reservation by reservation key
     * @param {String} key 
     * @returns 
     */
    async getReservationByKey(key) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${key}`,
        { 
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    return response
    },

    async getReservationPdf(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/pdf`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveReservation(
      reservation,
      changeAllInSet = false
    ) {
      let criteria = {
        Reservation: reservation,
        ChangeAllInSet: changeAllInSet
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservation.Id}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async copyReservation(criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/copy`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async cancelReservation(reservationId, reasonType, reason, cancelruleId, applyCancelFee, percentage) {
      let criteria = {
        ReasonType: reasonType,
        Reason: reason,
        CancelRuleId: cancelruleId,
        ApplyCancelFee: applyCancelFee,
        Percentage: percentage
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/cancel`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async addReservationContact(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/contact`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async updateReservationContact(reservationId, contactId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/contact/${contactId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
    
    async deleteReservationContact(reservationId, contactId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/contact/${contactId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getReservationProgram(reservationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/program`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async editExternalLink(externalLink) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${externalLink.ReservationId}/link`,
        JSON.stringify(externalLink),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )

      return response
    },

    /**
     * Save new program item
     * @param {Number} reservationId 
     * @param {Array} programItems 
     * @returns 
     */
    async saveReservationProgram(reservationId, programItems) {
      let criteria = {
        ProgramItems: programItems
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/program`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Manage External link API endpoints
     */
    async addExternalLink(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/link`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async deleteExternalLink(reservationId, linkId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${reservationId}/link/${linkId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async editExternalLink(externalLink) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/reservation/${externalLink.ReservationId}/link`,
        JSON.stringify(externalLink),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**----------------------------------------------
     * API V2
     ----------------------------------------------*/
    /**
     * 
     * @param {Number} reservationId 
     * @param {String} name 
     * @param {Number} profileId 
     * @param {Number} companyId 
     * @param {Number} statusId 
     * @param {String} language 
     * @param {Boolean} changeAllInSet 
     * @returns {Object} reservation
     */
    async saveReservationBasic(
      reservationId, name, profileId, companyId, statusId, language, changeAllInSet
    ) {
      let criteria = {
        Name: name,
        ProfileId: profileId,
        CompanyId: companyId,
        StatusId: statusId,
        Language: language,
        ChangeAllInSet: changeAllInSet
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Save reservation invoice address
     * @param {Number} reservationId 
     * @param {Object} invoiceAddress 
     * @param {Boolean} changeAllInSet 
     * @returns {Object} reservation
     */
    async saveReservationInvoiceAddress(
      reservationId, invoiceAddress, changeAllInSet
    ) {

      let criteria = invoiceAddress
      criteria.ChangeAllInSet = changeAllInSet

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/address`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
    
    /**
     * Add reservation spaces to reservation
     * @param {Number} reservationId 
     * @param {Array} criteria 
     * @returns {Object} reservation
     */
    async addReservationSpaces(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/spaces`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Update reservation space
     * @param {Number} reservationId 
     * @param {Object} criteria 
     * @returns {Object} reservation
     */
    async updateReservationSpace(reservationId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/space`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Delete space from reservation
     * @param {Number} reservationId 
     * @param {Object} criteria 
     * @returns {Object} reservation
     */
    async deleteReservationSpace(reservationId, criteria) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/space`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
          data: criteria
        }
      )
      return response
    },

    /**
     * Save new reservation options
     * @param {Number} reservationId 
     * @param {Array} criteria 
     * @returns {Object} reservation
     */
    async addReservationOptions(reservationId, criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/options`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Save reservation option
     * @param {Number} reservationId 
     * @param {Object} criteria
     * @returns {Object} reservation
     */
    async updateReservationOption(reservationId, criteria) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/option`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Delete option from reservation
     * @param {Number} reservationId 
     * @param {Object} criteria 
     * @returns {Object} reservation
     */
    async deleteReservationOption(reservationId, criteria) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/option`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
          data: criteria
        }
      )
      return response
    },
    
    /**
     * Save reservation tags
     * @param {Number} reservationId 
     * @param {String} tags 
     * @returns 
     */
    async saveReservationTags(reservationId, tags = '') {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/tags`,
        {
          Tags: tags,
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Change reservation date
     * @param {Number} reservationId 
     * @param {Array} spaces 
     * @returns {Object} updated Reservation object
     */
    async changeReservationDate(reservationId, spaces) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/reservation/${reservationId}/changedate`,
        {
          Spaces: spaces,
        },
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    }
  }
}
