import axios from 'axios'
import store from '../store/store'

let getProfileNotificationsRequestToken = null

export default {
  methods: {
    cancelGetProfileNotificationsRequest() {
      if (getProfileNotificationsRequestToken) {
        getProfileNotificationsRequestToken.cancel('new load started')
      }
    },

    getProfileNotificationRules() {
      return axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification/rules`, {
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    getProfileOldNotificationRules() {
      return axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/notification/rules`, {
        headers: {
          'Content-type': 'application/json',
          token: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    updateNotificationRule(rule) {
      return axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification/rules/${rule.Id}`,
        JSON.stringify(rule),
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    /**
     * Get notifications
     * @param {*} page
     * @param {*} itemsPerPage
     * @param {*} unreadOnly
     */
    getProfileNotifications(page = 1, itemsPerPage = 200, unreadOnly = false) {
      // Set new token
      getProfileNotificationsRequestToken = axios.CancelToken.source()

      return axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification`, {
        params: {
          page: page,
          itemsPerPage: itemsPerPage,
          unreadOnly: unreadOnly
        },
        cancelToken: getProfileNotificationsRequestToken.token,
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },

    markNotificationAsRead(notificationId) {
      return axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification/${notificationId}/read`, null,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    markAllAsRead: async () => {
      const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification/read/all`, null,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    deleteNotification(notificationId) {
      return axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/notification/${notificationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    }
  }
}
