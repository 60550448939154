<template>
  <article
    v-if="!hideChatItem"
    @click="setSelectedChat(chat)"
    class="chatItemMedia media"
  >
    <div class="media-left">
      <AvatarImage
        class="image-profile is-48x48"
        :itemId="chatProfileId"
        :itemName="chatName"
        :imageSrc="chatImage"
        :fixedColorIndex="0"
      />
    </div>
    <div class="media-content">
      <div class="content">
        <div class="has-text-grey postDetailBar">
          {{lastActivityDateTime}}
        </div>
        <strong class="has-text-primary-dark">{{ chatName }}:</strong>
        <strong
          v-if="chat.IsGroup"
          class
        >{{ chatName }}:</strong>
        <div>
          <span>
            {{ lastMessageName }}:
            <span :inner-html.prop="
                lastMessage | stringShortner(maxCharacters, true)
              "></span>
          </span>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    chat: null,
    maxCharacters: {
      type: Number,
      default: 50
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    }),

    hideChatItem() {
      return this.chat === null
        || (this.chat.GroupId === 0 && this.chatProfileId === 0)
    },

    lastActivityDateTime() {
      if (this.chat.Messages.length === 0) { return '' }
      let d = format(new Date(this.chat.LastActivity), 'DD-MM-YYYY, HH:mm')
      return d
    },

    chatImage() {
      if (this.chat === null || this.chat.Profiles === null || this.chat.Profiles.length === 0) { return '' }
      if (this.chat.GroupId !== 0) {
        return ''
      }

      let profile = this.chat.Profiles.filter(
        p => p.ProfileId !== this.profile.Id
      )[0]
      if (profile) {
        return profile.ProfileImage
      }
      return ''
    },

    chatName() {
      if (this.chat === null || this.chat.Profiles === null || this.chat.Profiles.length === 0) { return '' }

      if (this.chat.GroupId !== 0) {
        return this.chat.Name
      }

      let profile = this.chat.Profiles.filter(
        p => p.ProfileId !== this.profile.Id
      )[0]
      if (profile) {
        return profile.ProfileName
      }
      return ''
    },

    chatProfileId() {
      if (this.chat === null || this.chat.Profiles === null || this.chat.Profiles.length === 0) { return 0 }
      if (this.chat.GroupId !== 0) {
        return 0
      }

      let profile = this.chat.Profiles.filter(
        p => p.ProfileId !== this.profile.Id
      )[0]
      if (profile) {
        return profile.ProfileId
      }
      return 0
    },

    lastMessage() {
      let messages = this.chat.Messages
      let lastMessage = messages[messages.length - 1]

      return lastMessage.Message
    },

    lastMessageName() {
      let messages = this.chat.Messages
      let lastMessage = messages[messages.length - 1]

      if (lastMessage.ProfileId !== this.profile.Id) {
        return lastMessage.ProfileName
      } else {
        return 'You'
      }
    }
  },

  methods: {
    ...mapMutations('chatStore', ['setSelectedChat']),

    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },
  }
}
</script>
