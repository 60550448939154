import Vue from 'vue'

Vue.filter('sortArray', (arr, key) => {
  function compare(a, b) {
    let valA = a[key].toLowerCase()
    let valB = b[key].toLowerCase()
    if (valA < valB) return -1
    if (valA > valB) return 1
    return 0
  }

  return arr.sort(compare)
})

Vue.filter('getRandom', (arr, maxItems) => {
  let len = arr.length
  if (maxItems > len) {
    maxItems = len
  }
  let result = new Array(maxItems)
  let taken = new Array(len)
  while (maxItems--) {
    var x = Math.floor(Math.random() * len)
    result[maxItems] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
})
