import Vue from 'vue'

Vue.filter('getSettingName', function(value) {
  let name = ''

  switch (value) {
    case 1:
      name = 'Different'
      break
    case 2:
      name = 'Cabaret'
      break
    case 3:
      name = 'Carre'
      break
    case 4:
      name = 'Creative setting'
      break
    case 5:
      name = 'Exam'
      break
    case 6:
      name = 'School'
      break
    case 7:
      name = 'Circle'
      break
    case 8:
      name = 'Theater'
      break
    case 9:
      name = 'U-shape'
      break
    case 10:
      name = 'Meeting'
      break
    case 11:
      name = 'Diner'
      break
    case 12:
      name = 'Party'
      break
    case 13:
      name = 'Lunch'
      break
    case 14:
      name = 'Reception'
      break
    case 15:
      name = 'Buffet'
      break
    case 16:
      name = 'Sit-down'
      break
    case 17:
      name = 'Fish'
      break
    case 19:
      name = 'Knowledge'
      break
    case 20:
      name = 'Conference'
      break
    case 21:
      name = 'Workshop'
      break
    case 22:
      name = 'Creative setting'
      break
    case 23:
      name = 'Workspace'
      break
    case 24:
      name = 'Block'
      break  
    case 25:
      name = 'Corona'
      break
  }

  return name
})
