import axios from 'axios'
import store from '../store/store'
import Vue from 'vue'
import '@/filters/stringFilters.js'

export default {
  methods: {
    async deleteReview(reviewId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/v1/review/${reviewId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    getProfileReviews(page, itemsPerPage) {
      return axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/review`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    async getReviewById(id) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/review/${id}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getReviewByKey(key) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/review/${key}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getOpenReviews(page, itemsPerPage) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/review/open`,
        {
          params: {
            channelId: 1,
            locationId: 0,
            page: page,
            itemsPerPage: itemsPerPage
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveReviewAnswers(reviewKey, criteria) {
      return await axios.post(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/review/${reviewKey}/response`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
      })
    }
  }
}
