import axios from 'axios'
import store from '../store/store'

import { eraseCookie, getCookie, setCookie } from '@/utils/browserStorage'
import login from '@/providers/login'

export default {
  methods: {
    isAuthorized() {
      return new Promise((resolve, reject) => {
        resolve(this.getProfileTokenFromCookie())
      })
    },

    getProfileTokenFromCookie() {
      let apCookie = getCookie('ap')
      let ltCookie = getCookie('lt')

      if (apCookie === '' || ltCookie === '') {
        store.dispatch('logoff')
        store.commit('setIsInitiated', true)

        return false
      } else {
        if (ltCookie !== '') {
          return this.verifyProfileToken(ltCookie).then(response => {
            return response
          })
        }
        return false
      }
    },

    verifyProfileToken(profileToken) {
      if (profileToken) {
        return login.methods
          .verifyProfileToken(profileToken)
          .then(response => {
            if (response.status === 200) {
              if (response.data) {
                store.dispatch('saveProfileToken', profileToken)
                return true
              } else {
                store.dispatch('logoff')
                return false
              }
            }
            return false
          })
          .catch(e => {
            return false
          })
          .finally(() => {
            store.commit('setIsInitiated', true)
          })
      }
      return false
    }
  }
}
