<template>
  <div class="">
    <transition-group
      name="fade"
      mode="out-in"
      tag="div"
      class="tagsarea textarea field is-grouped is-grouped-multiline tags"
      :class="{ 'is-borderless': inputBorderless }"
    >
      <span
        v-for="(tag, index) in mTags"
        :key="'t_' + index"
        class="tag is-outlined is-white is-medium"
      >
        <span>{{ tag }}</span>
        <a
          @click="removeTag(index)"
          class="delete has-background-danger"
        ></a>
      </span>
      <span
        key="tagInput"
        class="wrapper-input"
      >
        <input
          ref="tagInput"
          type="text"
          v-model="tagInput"
          class="input is-small input-tag"
          :placeholder="$t('Components.Reservation.Tags.Placeholder_AddTag')"
          @keyup.enter="addTag"
          @keyup.tab="addTag"
          @blur="addTag"
        />
        <a
          @click="addTag"
          key="addTagButton"
          class="button is-small is-primary"
          :class="{ 'is-disabled': !tagInput }"
        >
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'plus']" />
          </span>
        </a>
      </span>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    inputBorderless: {
      type: Boolean,
      default: false
    },
    setFocusInput: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: function() {
        return []
      }
    },
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLoadingTagsFromQuestion: false,
      tagInput: '',
      mTags: this.tags,
      tagInputPlaceHolder: 'Add tag',
      timer: null
    }
  },

  created() { },

  computed: {},

  methods: {
    /**
     * Add tag to tags array
     */
    addTag() {
      if (this.tagInput) {
        let tag = this.tagInput
        let arr = this.tagInput.split(/[.\*+-/_#]/)
        for (let i in arr) {
          if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
            this.mTags.push(arr[i].trim())
          }
        }
        arr = []

        this.tagInput = ''
        this.$refs.tagInput.focus()
        this.$emit('tagAdded', tag)
      }
    },

    /**
     * Remove tag from array
     */
    removeTag(index) {
      this.$emit('tagDeleted', this.tags[index])
      this.mTags.splice(index, 1)
    },

    /**
     * Check if tag already exists in array
     */
    tagAlreadyAdded(tag) {
      var index = this.mTags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.row-whyTags {
  display: flex;
  align-items: baseline;
  .message {
    margin-bottom: 0;
    padding: 2px 5px;
  }
}

.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}

.field.is-grouped.is-grouped-multiline {
  align-items: baseline;
  background-color: $white;
  min-height: 60px;
  height: auto;

  &:last-child {
    margin-bottom: $gap;
  }

  .tags {
    .tag {
      font-size: $size-6;
    }
    a.is-delete {
      color: $text;
    }
  }

  .wrapper-input {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    .input-tag {
      width: 100px;
      margin-right: 5px;
      border-left: transparent;
      border-top: transparent;
      border-right: transparent;
    }
  }
}
</style>
