<template>
  <article class="message">
    <div class="message-body">
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: 'Message',
  props: {
    size: {
      type: String,
      default: ''
    },

    /**
     * type bepaalt het type weergave
     * eq. is-info = for information style. It defines the style of the message
     */
    type: {
      type: String,
      default: 'is-info'
    }
  },
  data() {
    return {}
  },

  computed: {
    combindedClass() {
      let c = this.type

      if (this.size.length > 0) {
        c = c + ' ' + this.size
      }

      return c
    }
  }
}
</script>

<style lang="scss" scoped>
// .#{$prefix}message {
//   overflow: hidden;
//   .#{$prefix}message-body {
//     border: none;
//   }
// }
</style>
