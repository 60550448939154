<template>
  <div class="registration-from">
    <h3
      v-if="!isCheckinProcess"
      class="title is-3"
    >Registration</h3>
    <h3
      v-else
      class="title is-4 has-text-centered"
    >Let's get started</h3>
    <ul
      v-if="!isCheckinProcess && !profileAvtivated"
      class="steps"
    >
      <li
        class="step-item"
        :class="{'is-active' : currentStep === 1, 'is-completed is-success' : currentStep > 1 || showFinished}"
      >
        <div class="step-marker">
          <span
            class="icon"
            v-if="currentStep > 1"
          >
            <i class="fa fa-check"></i>
          </span>
        </div>
        <div class="step-details">
          <p class="has-text-weight-bold">Let's get started</p>
        </div>
      </li>
      <li
        v-if="isMobile"
        :class="{'is-active' : currentStep === 2, 'is-completed is-success' : currentStep > 2 || showFinished}"
        class="step-item"
      >
        <div class="step-marker"></div>
        <div
          class="step-details"
          :class="{'has-text-grey':currentStep < 2}"
        >
          <p class="has-text-weight-bold">Almost there</p>
        </div>
      </li>
      <li
        class="step-item"
        :class="{'is-completed is-success' : showFinished}"
      >
        <div class="step-marker">
          <span class="icon">
            <i class="fa fa-flag"></i>
          </span>
        </div>
        <div
          class="step-details"
          :class="{'has-text-grey':currentStep < 3}"
        >
          <p class="has-text-weight-bold">Finish</p>
        </div>
      </li>
    </ul>

    <div v-if="(!showFinished && !isMobile && !isCheckinProcess) || currentStep === 1">
      <message
        v-if="(hasErrors || emailAlreadyExists) && currentStep === 1"
        class="is-danger"
      >
        <div class="content">
          <ul class="mt-0">
            <li v-if="hasOpenRrequiredFields">There are fields not correct or filled in.</li>
            <li v-if="emailAlreadyExists">Email address you entered already exists.</li>
          </ul>
        </div>
      </message>

      <form
        ref="registerForm"
        onSubmit="return false;"
        autocomplete="none"
        autofill="off"
      >
        <div class="field">
          <label class="label">First name</label>
          <div class="control has-icons-right">
            <input
              class="input"
              autocomplete="none"
              aria-autocomplete="both"
              v-bind:class="{ 'is-success' : firstNameNotEmpty, 'is-danger': showFirstNameWarning}"
              type="text"
              v-model="firstName"
              placeholder="First name"
            />
            <span
              v-if="!firstNameEmpty"
              class="icon is-small is-right"
            >
              <i class="fas fa-check"></i>
            </span>
          </div>
          <p v-if="showFirstNameWarning">
            <span
              class="help"
              v-bind:class="{ 'is-danger': showFirstNameWarning }"
            >First name is required</span>
          </p>
        </div>

        <div class="field">
          <label class="label">Last name</label>
          <div class="control has-icons-right">
            <input
              class="input"
              autocomplete="none"
              aria-autocomplete="both"
              autofill="off"
              v-bind:class="{ 'is-success': lastNameNotEmpty, 'is-danger': showLastNameWarning}"
              type="text"
              v-model="lastName"
              placeholder="Last name"
            />
            <span
              v-if="!lastNameEmpty"
              class="icon is-small is-right"
            >
              <i class="fas fa-check"></i>
            </span>
          </div>
          <p v-if="showLastNameWarning">
            <span
              class="help"
              v-bind:class="{ 'is-danger': showLastNameWarning }"
            >Last name is required</span>
          </p>
        </div>

        <div
          v-if="!isCheckinProcess"
          class="field"
        >
          <label class="label">Email</label>
          <div class="control has-icons-right">
            <input
              class="input"
              autocomplete="none"
              aria-autocomplete="both"
              v-bind:class="{ 'is-success': !emailEmpty && !emailInvalid, 'is-danger': (showEmailWarning || emailAlreadyExists)}"
              type="text"
              v-model="email"
              placeholder="email"
            />
            <span
              v-if="!showEmailWarning"
              class="icon is-small is-right"
            >
              <i class="fas fa-check"></i>
            </span>
          </div>
          <p v-if="showEmailWarning">
            <span
              class="help"
              v-bind:class="{ 'is-danger': showEmailWarning }"
            >A valid email is required</span>
          </p>
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control has-icons-right">
            <input
              class="input"
              autocomplete="none"
              aria-autocomplete="both"
              v-bind:class="{ 'is-success': passwordNotEmpty, 'is-danger': showPasswordWarning}"
              type="password"
              v-model="password"
            />
            <span
              v-if="!passwordEmpty"
              class="icon is-small is-right"
            >
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div v-if="showPasswordWarning">
            <div
              class="help"
              v-bind:class="{ 'is-danger': showPasswordWarning }"
            >
              <div
                v-for="(message, index) in passwordMessages"
                :key="'pm'+index"
                v-text="message"
              ></div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Create the best match</label>
          <em>Enter at least 3 keywords that describe your skills and knowledge.</em>
          <br />
        </div>

        <message
          v-if="showTagsWarning"
          class="is-danger"
        >
          You must enter at least 3 keywords
        </message>

        <div class="tagsarea field is-grouped is-grouped-multiline tags">
          <span
            class="tag is-outlined is-white is-medium"
            v-for="(tag, index) in tags"
            :key="'t'+index"
          >
            <span>{{ tag }}</span>
            <a
              @click="removeTag(index)"
              class="delete has-background-danger"
            ></a>
          </span>

          <div class="control control-tag-input">
            <div class="field has-addons">
              <input
                type="text"
                autocomplete="none"
                aria-autocomplete="both"
                v-model="tagInput"
                v-on:keyup.13="addTag"
                class="input is-3"
                placeholder="Add tag"
              />
              <a
                class="button is-primary"
                @click="addTag"
              >
                <i class="fas fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="field is-grouped is-grouped-multiline">
          <div class="tags has-addon tagsSuggestionWrapper">
            <template v-for="suggestion in tagSuggestions">
              <div
                :key="'tag_s_'+suggestion.Id"
                class="tag is-outlined is-white is-medium"
                @click="addTagBySuggestion(suggestion.RecTag)"
              >
                <span>{{ suggestion.RecTag }}</span>
                <a class="add has-background-primary"></a>
              </div>
            </template>
          </div>
        </div>

        <ul>
          <li
            v-for="autocomplete in tagsAutocomplete"
            :key="autocomplete.Id"
          >
            <label>{{ autocomplete.Text }}</label>
            <a @click="addTagBySuggestion(autocomplete.Text)"></a>
          </li>
        </ul>

        <hr class="separator" />

        <ui-checkbox
          v-model="subscribeToNewsLetter"
          inputAdditionClass
          iconAdditionClass="has-text-white"
        >Send me the Seats2meet newsletter, I’m a curious person and want to be updated on a regular basis!</ui-checkbox>
        <hr class="separator" />
        <nav
          v-if="(isMobile && currentStep === 1) || isCheckinProcess"
          class="level is-mobile"
        >
          <div class="level-left">
            <div class="level-item">
              <router-link
                v-if="!isCheckinProcess"
                :to="{name: 'Login'}"
                class="has-text-weight-bold"
              >
                <span class="icon">
                  <i class="fas fa-arrow-circle-left"></i>
                </span>
                <span>Back to log in</span>
              </router-link>
              <a
                v-else
                @click="cancelRegistration"
              >
                <span class="icon">
                  <i class="fas fa-arrow-circle-left"></i>
                </span>
                <span>Back</span>
              </a>
            </div>
          </div>

          <div class="level-right">
            <div class="level-item">
              <a
                @click="goToNextStep"
                class="button has-text-right is-primary"
              >
                <span>Next</span>
                <span class="icon">
                  <i class="fas fa-arrow-circle-right"></i>
                </span>
              </a>
            </div>
          </div>
        </nav>
      </form>
    </div>

    <!-- TERMS STEP MOBILE OR CHECKIN FLOW -->
    <div v-if="!showFinished">
      <div v-if="(!isMobile && !isCheckinProcess) || currentStep === 2">
        <!-- && !isCheckinProcess -->
        <div
          v-if="terms"
          v-html="terms.Text"
          class="content termsTextHolder"
        ></div>
        <div class="agreeToTermHolder">
          <hr />
          <message
            v-if="hasErrors && !agreedToTerms"
            class="is-danger"
          >
            <span>You need to agree with the terms</span>
          </message>

          <ui-checkbox
            v-model="agreedToTerms"
            inputAdditionClass
            iconAdditionClass="has-text-white"
            v-bind:class="{ 'is-danger': showAgreedToTermsWarning }"
          >I agree with the terms and conditions</ui-checkbox>
          <hr />
        </div>
      </div>
    </div>

    <nav
      v-if="!showFinished"
      class="level is-mobile"
    >
      <div
        v-if="!isMobile && currentStep === 1 && !isCheckinProcess"
        class="level-left"
      >
        <div class="level-item content">
          <router-link
            v-if="!isCheckinProcess"
            :to="{name: 'Login'}"
            class="icon-text has-text-weight-bold"
          >
            <span class="icon">
              <i class="fas fa-arrow-circle-left"></i>
            </span>
            <span>Back to log in</span>
          </router-link>
          <a
            v-else
            @click="cancelRegistration"
          >
            <span class="icon">
              <i class="fas fa-arrow-circle-left"></i>
            </span>
            <span>Back</span>
          </a>
        </div>
      </div>
      <div
        v-if="(isMobile || isCheckinProcess) && currentStep === 2"
        class="level-left"
      >
        <div class="level-item">
          <a
            @click="currentStep=currentStep-1"
            class="has-text-weight-bold"
          >
            <span class="icon">
              <i class="fas fa-arrow-circle-left"></i>
            </span>
            <span>Back</span>
          </a>
        </div>
      </div>

      <div
        v-if="(!isMobile && !isCheckinProcess) || currentStep === 2"
        class="level-right"
      >
        <div class="level-item">
          <a
            @click="createProfile"
            class="button is-primary"
            :class="{ 'is-loading': isRegistering }"
          >
            <span>{{ isCheckinProcess ? 'Next' : 'Create profile' }}</span>
            <span class="icon">
              <i class="fas fa-arrow-circle-right"></i>
            </span>
          </a>
        </div>
      </div>
    </nav>

    <!-- REGISTRATION SUCCESS SCREEN -->
    <div v-if="showFinished && !profileAvtivated">
      <div
        v-if="isInvalidCode"
        class="notification has-icon is-warning icon-text is-flex-wrap-nowrap"
      >
        <span class="icon notification-icon fa-lg">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        <span>The activation code you entered is not valid or your passport is already activated.</span>
      </div>
      <div class="content">
        <p>Check your email, use the link to verify your email address or enter the pin below.</p>
      </div>

      <div class="level is-mobile">
        <div class="level-item">
          <input
            type="text"
            ref="codePart1"
            @keyup="checkCodePart1"
            size="2"
            max="2"
            maxlength="2"
            v-model="codePart1"
            class="input is-large has-text-centered"
          />
        </div>
        <div class="level-item">
          <strong class="is-size-7">-</strong>
        </div>
        <div class="level-item">
          <input
            type="text"
            ref="codePart2"
            @keyup="checkCodePart2"
            size="2"
            max="2"
            maxlength="2"
            v-model="codePart2"
            class="input is-large has-text-centered"
          />
        </div>
        <div class="level-item">
          <strong class="is-size-7">-</strong>
        </div>
        <div class="level-item">
          <input
            type="text"
            ref="codePart3"
            size="2"
            max="2"
            maxlength="2"
            v-model="codePart3"
            class="input is-large has-text-centered"
          />
        </div>
      </div>
      <div class="has-text-centered">
        <button
          @click="activateByPincode"
          class="button is-medium"
          :disabled="codePart1 === '' || codePart2 === '' || codePart3 === ''"
          :class="{'is-primary': codePart1 !== '' && codePart2 !== '' && codePart3 !== '', 'is-disabled': codePart1 === '' || codePart2 === '' || codePart3 === '', 'is-loading' : isLoading}"
        >Verify your PIN code</button>
        <!-- <div v-if="!isCheckinProcess">
          <hr class="separator" />
          <router-link
            :to="{name: 'Login'}"
            class="has-text-weight-bold"
          >
            <span class="icon">
              <i class="fas fa-arrow-circle-left"></i>
            </span>
            <span>Back to log in</span>
          </router-link>
        </div> -->
      </div>
    </div>
    <div v-if="showFinished && profileAvtivated">
      <div class="has-text-centered mb-4">
        <div class="title is-2">Hooray!</div>
        <success-animation />
      </div>
      <div class="content has-text-centered has-text-weight-bold is-size-5">
        <p>Hi {{firstName}},</p>
        <p>Your profile has been activated.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import autocompleteProvider from '../providers/autocomplete'
import tag from '../providers/tag'
import terms from '../providers/terms'
import profile from '../providers/profile'
import Message from './UI/Message.vue'
import SuccessAnimation from './UI/Components/SuccessAnimation.vue'

export default {
  components: { Message, SuccessAnimation },
  props: {
    enteredEmail: {
      type: String,
      default: ''
    },
    isCheckinProcess: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      agreedToTerms: false,
      subscribeToNewsLetter: false,
      codePart1: '',
      codePart2: '',
      codePart3: '',
      currentStep: 1,
      hasErrors: false,
      isInvalidCode: false,
      isLoading: false,
      isRegistering: false,
      firstName: '',
      lastName: '',
      email: this.enteredEmail,
      emailAlreadyExists: false,
      password: '',
      showFinished: false,
      profileAvtivated: false,
      tagInput: '',
      tags: [],
      tagsAutocomplete: [],
      tagSuggestions: [],
      terms: null,
      validationCounter: 0,
      hasOpenRrequiredFields: false,
      verificationKey: ''
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      isMobile: 'getIsMobile'
    }),
    firstNameEmpty() {
      return this.firstName.trim() === ''
    },
    firstNameNotEmpty() {
      return this.firstName.trim() !== ''
    },
    lastNameEmpty() {
      return this.lastName.trim() === ''
    },
    lastNameNotEmpty() {
      return this.lastName.trim() !== ''
    },
    passwordEmpty() {
      return this.password.trim() === ''
    },
    passwordNotEmpty() {
      return this.password.trim() !== ''
    },
    emailEmpty() {
      return this.email.trim() === ''
    },
    emailInvalid() {
      return !/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        this.email
      )
    },

    isValidated() {
      return this.validationInput()
    },

    showAgreedToTermsWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && !this.agreedToTerms) {
        showWarning = true
      }

      return showWarning
    },

    showEmailWarning() {
      let showWarning = false

      if (
        this.validationCounter > 0 &&
        (this.emailEmpty || this.emailInvalid)
      ) {
        showWarning = true
      }

      return showWarning
    },

    showFirstNameWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && this.firstNameEmpty) {
        showWarning = true
      }

      return showWarning
    },

    showLastNameWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && this.lastNameEmpty) {
        showWarning = true
      }

      return showWarning
    },

    showPasswordWarning() {
      let showWarning = false

      if (this.passwordMessages.length > 0) {
        showWarning = true
      }

      return showWarning
    },

    passwordMessages() {
      // Show warning when field is empty after first submit
      if (this.validationCounter > 0 && this.passwordEmpty) {
        return ['Password is required']
      }

      // Stop processing when password field is empty
      if (this.passwordEmpty) { return [] }

      // Build warning lines
      let messages = []

      if (this.password.length < 8) {
        messages.push('You must enter at least 8 characters.')
      }
      if (!/(?=.*[A-Z])/.test(this.password)) {
        messages.push('You must enter at least 1 uppercase character.')
      }
      if (!/(?=.*\d)/.test(this.password)) {
        messages.push('You must enter at least 1 number.')
      }

      return messages
    },

    showTagsWarning() {
      return this.validationCounter > 0 && this.tags.length < 3
    }
  },

  created() {
    this.getLatestTerms()
    this.loadFreshdeskWidget()
  },

  beforeDestroy() {
    this.removeFreshdeskWidget()
  },

  methods: {
    ...mapActions(['saveProfileToken']),

    loadFreshdeskWidget() {
      if (document.getElementById('freshworks-container')) {
        document.getElementById('freshworks-container').style.display = ''
        return
      }
      window.fwSettings = {
        'widget_id': 11000000300
      }
      let fdWidgetScript = document.createElement('script')
      fdWidgetScript.setAttribute('src', 'https://widget.freshworks.com/widgets/11000000300.js')
      fdWidgetScript.async = true
      fdWidgetScript.onerror = (oError) => {
        throw new URIError('The script ' + oError.target.src + 'didn\'t load correctly.')
      }
      fdWidgetScript.onload = () => {
      }
      document.head.appendChild(fdWidgetScript)
    },

    removeFreshdeskWidget() {
      if (typeof document === 'undefined') { return }
      const fdContainer = document.getElementById('freshworks-container')
      if (fdContainer) {
        fdContainer.parentNode.removeChild(fdContainer)
      }

      const fdIframe = document.querySelector('iframe#freshworks-frame')
      if (fdIframe) {
        fdIframe.parentElement.removeChild(fdIframe)
      }
    },

    cancelRegistration() {
      this.$emit('cancelRegistration')
    },

    clearFormFields() {
      let self = this
      this.agreedToTerms = false
      this.subscribeToNewsLetter = false
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.password = ''
      this.tagInput = ''
      this.tags = []
      this.tagsAutocomplete = []
      this.tagSuggestions = []
    },

    getLatestTerms() {
      terms.methods.getLatestTerms().then(response => {
        this.terms = response.data
      })
    },

    createProfile() {
      let self = this

      this.isLoading = true
      if (this.validateInput()) {
        this.isRegistering = true

        var criteria = {
          ChannelId: 1,
          FirstName: this.$options.filters.stripHtml(this.firstName),
          LastName: this.$options.filters.stripHtml(this.lastName),
          Email: this.$options.filters.stripHtml(this.email),
          Password: this.$options.filters.stripHtml(this.password),
          Tags: this.$options.filters.stripHtml(this.tags.join(',')),
          TermsId: this.terms.Id,
          HasAgreedToTerms: this.agreedToTerms,
          SubscribeToNewsLetter: this.subscribeToNewsLetter ? 1 : 0
        }
        axios
          .post(
            `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile`,
            JSON.stringify(criteria),
            {
              headers: {
                'Content-type': 'application/json',
                apiToken: process.env.VUE_APP_API_KEY
              }
            }
          )
          .then(response => {
            if (response.status === 201) {
              self.verificationKey = response.data.VerificationKey

              if (!self.isCheckinProcess) {
                self.currentStep = 3
                self.showFinished = true
                self.isRegistering = false
              } else {
                // Is direct checkin
                self.showFinished = true
              }
              let scrollTop = 120
              if (self.isMobile) {
                scrollTop = 240
              }
              window.scrollTo(0, scrollTop)
            }
          })
          .catch(e => {
            if (e.response.status === 403 || e.response.status === 409) {
              self.showCreateProfile = true
              self.isRegistering = false
              self.emailAlreadyExists = true
              let scrollTop = 120
              if (this.isMobile) {
                scrollTop = 240
              }
              window.scrollTo(0, scrollTop)
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    getAutocomplete() {
      this.tagsAutocomplete = []

      if (this.tagInput.length > 2) {
        autocompleteProvider.methods.getAutocompleteTagItems({ q: this.tagInput }).then(response => {
          this.tagsAutocomplete = response.data
        })
      }
    },

    addTag() {
      this.tagSuggestions = []
      this.tagsAutocomplete = []
      this.tagsAutocomplete.length = 0

      let text = this.$options.filters.stripHtml(this.tagInput)
      let arr = text.split(/[.\*+-/_#]/)
      for (let i in arr) {
        if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
          this.tags.push(arr[i].trim())
        }
      }
      this.getTagSuggestions(text)
      arr = []
      text = ''

      this.tagInput = ''
    },

    removeTag(index) {
      this.tags.splice(index, 1)
    },

    addTagBySuggestion(tag) {
      this.tagSuggestions = []
      this.tagsAutocomplete = []

      if (!this.tagAlreadyAdded(tag)) {
        this.tags.push(tag)
      }

      this.getTagSuggestions(tag)
      this.tagInput = ''
    },

    tagAlreadyAdded(tag) {
      var index = this.tags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    },

    getTagSuggestions(input) {
      this.tagsAutocomplete = []
      this.tagSuggestions = []

      let nrOfItems = this.isCheckinProcess ? 5 : 10

      tag.methods.getTagSuggestion(input, nrOfItems).then(response => {
        this.tagSuggestions = response.data.Results
      })
    },

    goToNextStep() {
      this.validationCounter = this.validationCounter + 1

      if (this.validateInput()) {
        this.currentStep = this.currentStep + 1

        if (!this.isCheckinProcess) {
          let scrollTop = 120
          if (this.isMobile) {
            scrollTop = 240
          }
        } else {
          // this.createProfile()
        }
      }
    },

    validateInput() {
      let result = false
      let emailAlreadyExists = false
      let errorCounter = 0
      let validationCounter = this.validationCounter
      validationCounter++
      this.validationCounter = validationCounter
      this.hasOpenRrequiredFields = false
      this.hasErrors = false

      let scrollTop = 120
      if (this.isMobile) {
        scrollTop = 240
      }

      if (this.firstNameEmpty) {
        errorCounter++
      }

      if (this.lastNameEmpty) {
        errorCounter++
      }

      if (this.emailEmpty || this.emailInvalid) {
        errorCounter++
      }
      if (this.showTagsWarning) {
        errorCounter++
      }

      if (this.passwordEmpty || this.showPasswordWarning) {
        errorCounter++
      }

      if (!this.isMobile && !this.agreedToTerms && !this.isCheckinProcess) {
        errorCounter++
      }
      if (!this.agreedToTerms && this.currentStep === 2) {
        errorCounter++
      }

      if (errorCounter === 0) {
        result = true
        return result
      } else {
        window.scrollTo(0, scrollTop)
        this.hasOpenRrequiredFields = true
        this.hasErrors = true

        return result
      }
    },

    activateByPincode() {
      let self = this
      this.isInvalidCode = false
      if (
        this.codePart1 !== '' &&
        this.codePart2 !== '' &&
        this.codePart3 !== ''
      ) {
        let pin = this.codePart1 + this.codePart2 + this.codePart3
        this.isLoading = true

        profile.methods
          .checkAuthPin({ VerificationKey: this.verificationKey, Pin: pin })
          .then(async (response) => {
            if (response.status === 200) {

              if (!self.isCheckinProcess) {
                this.profileAvtivated = true
                this.saveProfileToken(response.data.ProfileToken)
                let t = setTimeout(() => {
                  self.$emit('isLoggedIn')
                  self.$store.commit('setIsFirstRun', true)
                  self.$router.push({ name: 'Dashboard' })
                }, (4 * 1000))
              }
              else {
                this.saveProfileToken(response.data.ProfileToken)
                this.$emit('profileCreated')
              }
            } else {
              this.isInvalidCode = true
            }
          })
          .catch(e => {
            this.isLoading = false
            this.isInvalidCode = true
          })
          .then(() => {
            // this.isInvalidCode = true
          })
      }
    },

    checkCodePart1() {
      if (this.codePart1 !== '' && this.codePart1.length === 2) {
        this.$refs.codePart2.focus()
      }
    },

    checkCodePart2() {
      if (this.codePart2 !== '' && this.codePart2.length === 2) {
        this.$refs.codePart3.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.termsTextHolder {
  margin-bottom: 25px;
  max-height: 350px;
  overflow: auto;
}

.agreeToTermHolder {
  margin-bottom: 25px;
}
</style>
