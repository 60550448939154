<template>
  <article class="media">
    <div class="media-content">
      <a
        v-if="!showCommentInput"
        @click="setShowCommentInput"
        class="link is-small"
      >reply</a>

      <div
        v-if="showCommentInput"
        class="field"
      >
        <p class="control">
          <textarea
            v-model="comment"
            class="textarea"
            placeholder="Add a comment..."
          />
        </p>
      </div>
      <div
        v-if="showCommentInput"
        class="field"
      >
        <p class="control">
          <a
            @click="setShowCommentInput"
            class="button is-ghost"
          >Cancel</a>
          <a
            @click="saveComment"
            class="button is-primary"
          >Save</a>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    itemId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      comment: '',
      showCommentInput: false
    }
  },
  methods: {
    setShowCommentInput() {
      this.showCommentInput = !this.showCommentInput
    },
    saveComment() {
      this.$emit('commentAdded', this.type, this.itemId, this.comment)

      this.showCommentInput = false
    }
  }
}
</script>
