var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chatMessage)?_c('article',{staticClass:"chatMessageItemMedia media"},[(_vm.chatMessage.ProfileId !== _vm.profile.Id)?_c('div',{staticClass:"media-left"},[_c('AvatarImage',{staticClass:"image-profile is-48x48",attrs:{"itemId":_vm.chatMessage.ProfileId,"itemName":_vm.chatMessage.ProfileName,"imageSrc":_vm.chatMessage.ProfileImage,"fixedColorIndex":0}})],1):_vm._e(),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"has-text-grey postDetailBar",class:{
          'has-text-left': _vm.chatMessage.ProfileId !== _vm.profile.Id,
          'has-text-right': _vm.chatMessage.ProfileId === _vm.profile.Id
        }},[_vm._v(" "+_vm._s(_vm.dateTime)+" ")]),_c('div',{class:{
          'has-text-left': _vm.chatMessage.ProfileId !== _vm.profile.Id,
          'has-text-right': _vm.chatMessage.ProfileId === _vm.profile.Id
        }},[_c('strong',{},[_vm._v(_vm._s(_vm.chatMessage.ProfileName)+":")])]),_c('div',{staticClass:"text",class:{ right: _vm.chatMessage.ProfileId === _vm.profile.Id },domProps:{"innerHTML":_vm._f("nl2br")(_vm.chatMessage.Message)}})])]),(_vm.chatMessage.ProfileId === _vm.profile.Id)?_c('div',{staticClass:"media-right"},[_c('AvatarImage',{staticClass:"image-profile is-48x48",attrs:{"itemId":_vm.chatMessage.ProfileId,"itemName":_vm.chatMessage.ProfileName,"imageSrc":_vm.chatMessage.ProfileImage}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }