<template>
  <div id="checkinProcess">
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <get-profile
        :key="'profile'"
        @profileFound="profileFound"
        @cancelProcess="cancelProcess"
        :isCheckinProcess="true"
        :event="event"
        v-if="!isLoggedIn && profile.Key === ''"
      ></get-profile>

      <CheckWorkspaceAvailability
        :key="'checkWorkspaceAvailability'"
        @cancelProcess="cancelProcess"
        @finishedProcess="finishedProcess"
        v-if="
          !finished &&
            profile.Key !== '' &&
            checkAvailability === true &&
            locationOpen
        "
        :locationId="location.Id"
        :startDate="openingHours.Start"
        :endDate="openingHours.End"
        :minTime="openingHours.MinMinutes"
        :maxTime="openingHours.MaxMinutes"
        :startTime="openingHours.MinMinutes"
        :endTime="openingHours.MaxMinutes"
      ></CheckWorkspaceAvailability>

      <direct-checkin
        :key="'directCheckin'"
        @cancelProcess="cancelProcess"
        @finishedProcess="finishedProcess"
        v-if="!finished && profile.Key !== '' && checkAvailability === false"
        :checkins="checkins"
        :minTime="openingHours.MinMinutes"
        :maxTime="openingHours.MaxMinutes"
        :startDate="event !== null ? event.StartDate : openingHours.Start"
        :startTime="event !== null ? event.StartMinutes : openingHours.MinMinutes"
        :endDate="event !== null ? event.EndDate : openingHours.End"
        :endTime="event !== null ? event.EndMinutes : openingHours.MaxMinutes"
        :locationId="locationId"
        :eventId="eventId"
      ></direct-checkin>

      <current-checkin-detail
        key="currentCheckinDetail"
        @activateCountDown="activateCountDown"
        @close="cancelProcess"
        @callbackFinished="callbackFinished"
        v-if="finished"
        :showMatchesButton="showMatchesButton"
        :showTimeMatchItem="showTimeMatchItem"
        :event="event"
      ></current-checkin-detail>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CheckWorkspaceAvailability from './CheckWorkspaceAvailability'
import DirectCheckIn from './DirectCheckIn'
import CurrentCheckinDetail from './CurrentCheckinDetail'
import checkinProvider from '../providers/checkin'
import GetProfile from '@/components/GetProfile'
import { EventBus } from '../eventBus/event-bus'

export default {
  components: {
    'get-profile': GetProfile,
    CheckWorkspaceAvailability,
    'current-checkin-detail': CurrentCheckinDetail,
    'direct-checkin': DirectCheckIn
  },
  props: {
    checkins: {
      type: Array,
      default() {
        return []
      }
    },
    event: {
      type: Object,
      default() {
        return null
      }
    },
    location: {
      type: Object,
      default() {
        return null
      }
    },
    openingHours: {
      type: Object,
      default() {
        return {
          NrOfLocations: 0,
          Start:
            this.$options.filters.dateObjectIsoDateString(new Date()) +
            'T13:00:00',
          End:
            this.$options.filters.dateObjectIsoDateString(new Date()) +
            'T13:00:00',
          MaxMinutes: 0,
          MinMinutes: 0
        }
      }
    },
    showMatchesButton: {
      type: Boolean,
      default: true
    },
    showTimeMatchItem: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: new Date(),
      finished: false,
      locationId: this.location !== null ? Number(this.location.Id) : 0,
      eventId: this.event !== null ? Number(this.event.Id) : 0,
      locationObj: this.location,
      checkAvailability: false,
      searchKey: '',
      showGetProfile: true,
      errors: [],
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
      foundCheckin: 'getCurrentCheckin'
    }),
    ...mapState('cartStore', ['cart']),
    ...mapGetters('cartStore', ['isBusy']),

    locationOpen() {
      let isOpen = false

      let currentTime = this.$options.filters.timeToMinutes(new Date())
      if (
        this.openingHours.NrOfLocations > 0 &&
        currentTime >= this.openingHours.MinMinutes &&
        currentTime <= this.openingHours.MaxMinutes
      ) {
        isOpen = true
      }
      return isOpen
    }
  },

  created() {
    if (this.event === null && this.location !== null) {
      this.doCapacityCheck()
    } else if (this.event !== null) {
      this.locationId = this.event.LocationId
    }

    if (this.event) {
    }
  },

  beforeDestroy() {
    this.deleteCart()
  },

  methods: {
    ...mapActions('cartStore', ['deleteCart']),

    callbackFinished() {
      this.$emit('callbackFinished', null)
    },

    getCheckins() {
      checkinProvider.methods.getCheckins({ date: this.event.StartDate })
        .then(response => {
          let found = null
          let checkins = response.data.Results

          let checkin = checkins.filter(c => {
            return c.ProfileKey === this.profile.Key && c.HasLeft === false
          })

          if (checkin.length > 0) {
            found = checkin[0]
          }

          this.$store.commit('setCurrentCheckin', found)
        })
        .catch(e => { })
        .then(() => { })
    },
    activateCountDown() {
      let self = this

      let i = 4
      this.timer = window.setInterval(function() {
        i--
        if (i <= 0) {
          clearInterval(self.timer)
          self.timer = null
          self.$emit('cancelProcess')
        }
      }, 5000)
    },

    cancelProcess() {
      this.$emit('cancelProcess')
    },

    finishedProcess() {
      this.finished = true
    },

    profileFound() {
      this.getCheckins()
    },

    doCapacityCheck() {
      var found = this.locationObj.Meetingtypes.find(
        lm => lm.MeetingtypeId === 2
      )
      if (found) {
        this.checkAvailability = found.CheckCapacity
      }
    },
    checkinFound() {
      this.$store.commit('setCurrentCheckin', this.foundCheckin)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
</style>
