import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    isItemPinned(typeId, itemId) {
      return axios.get(
        `${
          process.env.VUE_APP_API_BASE_URL
        }/api/pin/pinned?locationId=0&typeId=${typeId}&itemId=${itemId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    savePin(typeId, itemId) {
      return axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/pin?typeId=${typeId}&itemId=${itemId}`,
        '',
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },

    deletePin(id) {
      return axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/pin/${id}`, {
        headers: {
          'Content-type': 'application/json',
          token: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    }
  }
}
