import chatProvider from '@/providers/chat'

/**
 * States
 */
const state = {
  chats: [],
  selectedChat: null,
}

/**
 * Getters
 */
const getters = {
  getChats: state => state.chats
}

/**
 * Mutations
 */
const mutations = {
  /**
   * Set chats in store
   */
  setChats: (state, newState) => {
    state.chats = newState
  },
  setSelectedChat: (state, newSelectedChat) => {
  state.selectedChat = newSelectedChat
},
}

/**
 * Actions
 */
const actions = {
  /**
   * Get profile chats
   */
  getProfileChats: async ({ commit }) => {
    await chatProvider.methods.getProfileChats().then(response => {
      commit('setChats', response.data.Results)
    })
  },

  /**
   * Add new chat in chats and update store
   */
  newChat: (context, data) => {
    let obj = data
    let chats = self.chats
    let chatIndex = chats.findIndex(c => c.Id === obj.Id)
    if (chatIndex > -1) {
      chats[chatIndex].Messages = obj.Messages
    } else {
      chats.push(obj)

      // Join new chat
      context.dispatch('newChat', obj.Id)
    }

    // Update store
    context.commit('setChats', chats)
  },

  /**
   * Update existing chat
   */
  updateExistingChat: ({ state, commit }, data) => {
    let chatMessage = data
    let chats = state.chats

    let chatIndex = chats.findIndex(c => c.Id === chatMessage.ChatId)
    if (chatIndex > -1) {
      chats[chatIndex].LastActivity = new Date().getTime()
      chats[chatIndex].Messages.push(chatMessage)
      chats = chats.sort(
        (a, b) => parseFloat(b.LastActivity) - parseFloat(a.LastActivity)
      )

      // Update store
      commit('setChats', chats)
    }
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
