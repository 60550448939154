<template>
  <div v-if="profileObj">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <div class="modal-card-head">
          <div
            v-if="showProfilePhoto"
            class="title is-5"
          >Don't be shy! Show yourself with a picture.</div>
          <div
            v-if="showProfileBio"
            class="title is-5"
          >Tell us who you are with your Bio</div>
          <div
            v-if="showProfileTags"
            class="title is-5"
          >Better tags = better matches</div>
          <div
            v-if="showFinished"
            class="title is-5"
          >You are all set up!</div>
        </div>

        <div class="modal-card-body">
          <!-- Profile photo -->
          <div
            v-if="showProfilePhoto"
            class="step-photo"
          >
            <div
              v-if="!showPhotoEdit"
              class="is-flex photo-preview-container"
            >
              <figure class="is-flex-shrink-0 image is-128x128 mr-4">
                <img
                  class="is-round has-shadow has-background-grey-light"
                  src="@/assets/img/spacer1_1.gif"
                  :style="{backgroundImage: `url(${profileImage})`}"
                />
              </figure>
              <div class="is-flex-grow-1 is-flex-shrink-1 pt-5">
                If you are still happy with your current profile photo then you can skip this step by clicking '<strong>Skip photo</strong>'.
              </div>
            </div>
            <div
              v-if="showPhotoEdit"
              class="is-flex photo-edit-container"
            >
              <div
                class="box info-container is-flex-shrink-0 has-padding has-background-white-ter mr-5"
                :class="{'has-preview': profileOldImage}"
              >
                <p v-if="!profileOldImage">We see that you haven't uploaded a picture for your profile. To have the most out of your Seats2meet experience we recommend you to complete your profile so other users can recognize and connect to you.</p>
                <div
                  v-if="profileOldImage"
                  class="profileOldImage has-text-centered"
                >
                  <div class="has-text-weight-bold mb-4">Current photo:</div>
                  <figure class="is-flex-shrink-0 image is-128x128">
                    <img
                      class="is-round has-shadow has-background-grey-light"
                      src="@/assets/img/spacer1_1.gif"
                      :style="{backgroundImage: `url(${profileImage})`}"
                    />
                  </figure>
                </div>
              </div>
              <div class="crop-container is-flex-grow-1">

                <profile-image-cropper
                  ref="cropper"
                  @updateSaveButtonState="(val) => { newProfileImageChosen = !val }"
                />
              </div>
            </div>
          </div>

          <!-- Profile biography -->
          <div v-if="showProfileBio">
            <div v-if="!showBioEdit">Seats2meet is all about connecting, collaborating and growing. Introduce yourself to the S2M ecosystem with your bio and start connecting.</div>
            <div v-else>
              <textarea
                v-model="profileObj.Bio"
                class="textarea"
              ></textarea>
            </div>
          </div>

          <div v-if="showProfileTags">
            <div class="has-margin-bottom">In the S2M passport we use your tags to match you to experts, content, events, locations and questions from the ecosystem. That means that the better tags you use, the better will be the matches.</div>
            <strong style="display: block; margin-bottom: 5px;">Enter at least 3 tags</strong>
            <div v-if="!showTagEdit">
              <div
                v-if="tags && tags.length > 0"
                class="tags"
              >
                <span
                  v-for="(tag, index) in tags"
                  :key="index"
                  class="tag is-outlined is-white is-medium"
                >{{ tag }}</span>
              </div>
            </div>

            <div v-if="showTagEdit">
              <div class="field">
                <div
                  v-if="tags"
                  class="tags"
                >
                  <span
                    v-for="(tag, index) in tags"
                    :key="index"
                    class="tag is-outlined is-white is-medium"
                  >
                    {{ tag }} &nbsp;
                    <a
                      @click="removeTag(index)"
                      class="delete has-background-danger"
                    ></a>
                  </span>
                </div>
              </div>

              <div class="field">
                <div class="field has-addons">
                  <div class="control">
                    <input
                      type="text"
                      v-model="tagInput"
                      v-on:keyup.enter="addTag"
                      class="input is-fullwidth"
                      placeholder="Add tag"
                    />
                  </div>
                  <div class="control">
                    <a
                      class="button is-primary"
                      @click="addTag"
                    >
                      <i class="fas fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showFinished">
            <div v-if="profileComplete">Yay! You are all set up and ready to connect, collaborate and grow with the S2M ecosystem.</div>
            <div v-if="!profileComplete">
              In a rush? You can always come back
              <br />
              <br />Are you in a rush to go make awesome things? No worries. You can always come back to complete or edit your information. Just make sure to do it soon, so you are matched to experts, events, content, locations and questions.
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <div class="button-container">
            <div v-if="showProfilePhoto">
              <div v-if="!showPhotoEdit">
                <a
                  @click="nextStep"
                  class="button is-ghost"
                >Skip photo</a>
                <a
                  @click="showPhotoEdit = !showPhotoEdit"
                  class="button is-primary"
                  v-text="profileOldImage ? 'Change photo': 'Upload photo'"
                ></a>
              </div>
              <div v-else>
                <div v-if="!newProfileImageChosen">
                  <a
                    v-if="!profileOldImage"
                    @click="nextStep"
                    class="button is-ghost"
                  >Skip photo</a>
                  <a
                    v-if="profileOldImage"
                    @click="showPhotoEdit = !showPhotoEdit"
                    class="button is-ghost"
                  >Cancel</a>
                </div>
                <div v-else>
                  <a
                    v-if="!profileOldImage"
                    @click="nextStep"
                    class="button is-ghost"
                  >Skip photo</a>
                  <a
                    v-if="profileOldImage"
                    @click="resetPhoto"
                    class="button is-ghost"
                  >Back</a>
                  <a
                    @click="savePhoto"
                    class="button is-primary"
                  >Save photo</a>
                </div>
              </div>
            </div>

            <div v-if="showProfileBio">
              <div v-if="!showBioEdit">
                <a
                  @click="nextStep"
                  class="button is-ghost"
                >Skip this</a>
                <a
                  @click="showBioEdit = !showBioEdit"
                  class="button is-primary"
                >Add bio</a>
              </div>
              <div v-else>
                <a
                  @click="showBioEdit = !showBioEdit"
                  class="button is-ghost"
                >Cancel</a>
                <a
                  @click="saveBio"
                  class="button is-primary"
                >Save bio</a>
              </div>
            </div>

            <div v-if="showProfileTags">
              <div v-if="!showTagEdit">
                <a
                  @click="nextStep"
                  class="button is-ghost"
                >Skip this</a>
                <a
                  @click="showTagEdit = !showTagEdit"
                  class="button is-primary"
                  v-text="profileObj.Tags.length ? 'Edit tags':'Add tags'"
                ></a>
              </div>
              <div v-else>
                <a
                  v-if="profileObj.Tags.length >= 3"
                  @click="cancelEditTags"
                  class="button is-ghost"
                >Cancel</a>
                <a
                  @click="() => { if(tags.length >= 3) {saveTags()}}"
                  class="button"
                  :class="tags.length >= 3 ? 'is-primary': 'is-disabled'"
                >Save tags</a>
              </div>
            </div>

            <div v-if="showFinished">
              <a
                @click="saveProfileLastChecked"
                class="button is-primary"
              >Done</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profile from '../providers/profile'
import ProfileImageCropper from './profile/ProfileImageCropper'

export default {
  components: { ProfileImageCropper },
  data() {
    return {
      checks: [],
      currentStep: 0,
      isValidImage: true,
      isSaving: false,
      tags: [],
      tagInput: '',
      validationCounter: 0,
      passportState: this.$store.getters.getPassportState,
      profileObj: this.$store.getters.getProfile,
      profileImage: '',
      profileOldImage: '',
      newProfileImageChosen: false,
      showBioEdit: false,
      showFinished: false,
      showPhotoEdit: false,
      showProfilePhoto: false,
      showProfileBio: false,
      showProfileTags: false,
      showTagEdit: false
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile'
    }),
    profileComplete() {
      let isComplete = false

      if (
        this.newProfileImageChosen &&
        this.profileObj.Bio !== ''
      ) {
        isComplete = true
      }
      return isComplete
    }
  },

  watch: {},

  mounted() {
    this.profileObj = this.profile
    this.profileObj.Tags = this.$options.filters.stripHtml(this.profileObj.Tags).trim()
    if (this.profileObj.Tags) {
      this.tags = this.profileObj.Tags.split(',')
    }

    this.profileImage = this.$options.filters.getProfileImage(
      this.profileObj.Image,
      this.profileObj.Id,
      150
    )

    this.profileOldImage = this.profileObj.Image ? this.profileImage : ''

    this.startup()
  },

  methods: {
    startup() {
      if (this.profileObj) {
        if (!this.checkAlreadyAdded('photo')) {
          this.checks.push('photo')
        }

        if (this.profileObj.Bio === '' && !this.checkAlreadyAdded('bio')) {
          this.checks.push('bio')
        }

        if (!this.checkAlreadyAdded('tags')) {
          this.checks.push('tags')
        }

        if (!this.checkAlreadyAdded('finished')) {
          this.checks.push('finished')
        }

        this.setVisibleItem(this.checks[0])
      }
    },

    checkAlreadyAdded(check) {
      var index = this.checks.indexOf(check)
      if (index > -1) {
        return true
      }
      return false
    },

    goToStep(step) {
      this.currentStep = step
      this.setVisibleItem(this.checks[step])
    },

    nextStep() {
      if (this.currentStep + 1 <= this.checks.length) {
        this.currentStep = this.currentStep + 1
        this.goToStep(this.currentStep)
      } else {
        this.setShowUpdateFinished()
      }
    },

    cancelEditTags() {
      this.tags = this.profile.Tags.split(',')
      this.showTagEdit = !this.showTagEdit
    },

    resetPhoto() {
      this.newProfileImageChosen = false
      this.showPhotoEdit = false

      this.profileImage = this.$options.filters.getProfileImage(
        this.profileObj.Image,
        this.profileObj.Id,
        150
      )
    },

    savePhoto() {
      let self = this
      self.isSaving = true

      if (this.newProfileImageChosen) {
        this.profileImage = this.$refs['cropper'].getNewPhoto()
        profile.methods
          .uploadImage(this.profileImage, this.profileObj)
          .then(response => {
            this.profileObj.Image = response.data.Image
            this.saveProfile()
          })
      }

      this.nextStep()
    },

    saveBio() {
      this.saveProfile()

      this.nextStep()
    },

    saveTags() {
      this.profileObj.Tags = this.tags.join(',')

      this.saveProfile()

      this.nextStep()
    },

    saveProfile() {
      profile.methods.saveProfile(this.profileObj).then(response => {
        this.$store.commit('setProfile', this.profileObj)
        // this.$emit('profileSaved')
      })
    },

    saveProfileLastChecked() {
      profile.methods.saveProfileLastChecked().then(response => {
        this.passportState.ShowProfileCheck = false
        this.$store.commit('setPassportState', this.passportState)
        this.$store.commit('setShowProfileCheck', false)
        if (this.newProfileImageChosen) {
          location.reload(true)
        }
      })
    },

    addTag() {
      let text = this.$options.filters.stripHtml(this.tagInput)
      let arr = text.split(/[.\*+-/_#]/)
      for (let i in arr) {
        if (arr[i].trim() && !this.tagAlreadyAdded(arr[i].trim())) {
          this.tags.push(arr[i].trim())
        }
      }
      arr = []
      text = ''
      this.tagInput = ''
    },

    removeTag(index) {
      this.tags.splice(index, 1)
    },

    addTagBySuggestion(tag) {
      if (!this.tagAlreadyAdded(tag)) {
        this.tags.push(tag)
      }

      this.tagInput = ''
    },

    tagAlreadyAdded(tag) {
      var index = this.tags.indexOf(tag)
      if (index > -1) {
        return true
      }
      return false
    },

    closeShowProfileCheck() {
      this.$store.commit('setShowProfileCheck', false)
    },

    setShowProfilePhoto() {
      this.setVisibleItem('photo')
    },

    setShowProfileBio() {
      this.setVisibleItem('bio')
    },

    setShowProfileTags() {
      this.setVisibleItem('tags')
    },

    setShowUpdateFinished() {
      this.setVisibleItem('finished')
    },

    setVisibleItem(item) {
      this.showBioEdit = false
      this.showPhotoEdit = !this.profileOldImage
      this.showTagEdit = false

      this.showProfilePhoto = item === 'photo'
      this.showProfileBio = item === 'bio'
      this.showFinished = item === 'finished'

      this.showProfileTags = item === 'tags'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
.title {
  margin-left: 10px;
  margin-right: 10px;
}

.button-container {
  width: 100%;
}

.modal-card-foot {
  @media screen and (max-width: 550px) {
    display: block;
    flex-direction: row;
    .breadcrumb {
      display: none;
      margin-top: $gap;
    }
  }
}

.breadcrumb {
  span {
    display: inline-block;
    a {
      text-decoration: none;
      text-align: center;
      margin: 0;
    }
  }
}

.title.isActive {
  color: #ff0000;
}

.step-photo {
  .photo-edit-container {
    .info-container {
      width: 200px;

      img {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;

      .info-container {
        flex-shrink: unset !important;
        margin-right: unset !important;
        width: auto;
        &.has-preview {
          display: none;
        }
      }
    }
  }
}
</style>
