const ProposalDetail = () =>
  import(/* webpackChunkName: "Proposal-detail" */ '@/views/proposals/ProposalDetail')
const Proposals = () =>
  import(/* webpackChunkName: "Proposals" */ '@/views/proposals/Proposals')

const proposalRoutes = [
  {
    path: '/proposals',
    name: 'proposals',
    component: Proposals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/proposals/:proposalKey',
    name: 'proposal-detail',
    // component: ProposalDetail,
    // meta: {
    //   requiresAuth: false,
    // },
  }
]

export default proposalRoutes