import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    /**
     * Add new comment
     * @param {String} type 
     * @param {Number} itemId 
     * @param {Object} comment 
     * @returns 
     */
    async addComment(type, itemId, comment) {
      let criteria = {
        ItemId: itemId,
        Comment: comment
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/comment/${type}`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Add reservation comment
     * @param {Number} itemId 
     * @param {Object} comment 
     * @returns 
     */
    async addReservationComment(itemId, comment) {
      let criteria = {
        ItemId: itemId,
        Comment: comment
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/comment/reservation`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Reply to comment
     * @param {Number} commentId 
     * @param {Object} comment 
     * @returns 
     */
    async replyToComment(commentId, comment) {
      let criteria = {
        ItemId: commentId,
        Comment: comment
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/comment/${commentId}/reply`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Delete comment
     * @param {Number} commentId 
     * @returns 
     */
    async deleteComment(commentId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/comment/${commentId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
  }
}
