import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    /**
     * Get latest terms of channel 1
     */
    getLatestTerms: () => {
      return axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/term/privacy/latest`,
        {
          params: {
            channelId: 1,
            locationId: 0
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY
          }
        }
      )
    },

    /**
     * Update agreed to terms status
     */
    agreeToTerms: termsId => {
      return axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/Profile/terms/${termsId}`,
        {},
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    }
  }
}
