<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      class="modal"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Tips about asking questions</p>
          <button
            @click="onClickCancel"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <Tips :shownInModal="true" />
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>
  </transition>
</template>

<script>
import Tips from '@/components/question/Tips'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    onClickCancel: {
      type: Function,
      required: true
    }
  },

  components: {
    Tips
  }
}
</script>

<style lang="scss" scoped>
</style>
