<template>
  <label
    class="is-unselectable"
    :class="classes"
  >
    <input
      type="checkbox"
      :name="name"
      :checked="checked"
      :value="checkValue"
      @input="onChange"
      :disabled="disabled"
    ><span
      v-if="label"
      :class="cssClassLabel"
    >{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'base-checkbox',

  props: {
    label: {
      type: String,
      default: ''
    },
    cssClassLabel: {
      type: [Object, Array, String],
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      required: true
    },
    checkValue: {
      type: [Number, String, Boolean],
      default: null
    },
    classes: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    }
  },

  computed: {
    checked: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.indexOf(this.checkValue) !== -1
        }
        return this.value
      },
      set() {
      }
    }
  },

  methods: {
    onChange(evt) {
      if (Array.isArray(this.value)) {
        if (this.value.indexOf(this.checkValue) !== -1) {
          this.value.splice(this.value.indexOf(this.checkValue), 1)
        } else {
          this.value.push(this.checkValue)
        }
        this.$emit('input', this.value)
      } else {
        this.$emit('input', evt.target.checked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  display: inline-flex;
  align-items: center;
  &.has-input-right {
    span {
      order: -1;
      margin-right: 5px;
    }
  }
  &:not(.has-input-right) {
    span {
      margin-left: 5px;
    }
  }
}
</style>