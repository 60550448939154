import axios from 'axios'

let request_getAutocompleteDataCancelToken = null
let request_getAutocompleteTag = null

export default {
  methods: {
    //#region GET

    /**
     * Get location autocomplete items
     * @returns 
     */
    async getAutocompleteLocationItems({ countryId = 0, channelId = 0, locationId = 0, meetingtypeId = 0, searchTerm = '' } = {}) {
      request_getAutocompleteDataCancelToken && request_getAutocompleteDataCancelToken('Cancel previous request due to new request')
      
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/autocomplete/location`,
        {
          params: {
            q: searchTerm,
            channelId: channelId,
            countryId: countryId,
            locationId: locationId,
            meetingtypeId: meetingtypeId,
          },

          cancelToken: new axios.CancelToken(function executor(c) {
            request_getAutocompleteDataCancelToken = c;
          }),

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    /**
     * Get automcomplete tag items
     * @returns 
     */
    async getAutocompleteTagItems({ q = '', page = 1, nrOfTags = 10 } = {}) {
      request_getAutocompleteTag && request_getAutocompleteTag('Cancel previous request due to new request')

      return await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/autocomplete/tag`,
        {
          params: {
            q,
            page,
            nrOfTags
          },

          cancelToken: new axios.CancelToken(function executor(c) {
            request_getAutocompleteTag = c;
          }),

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
    }

    //#endregion
  }
}
