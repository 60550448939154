<template>
  <div
    v-if="currentCheckin"
    class="my-checkin-wrapper has-text-centered"
  >
    <div v-if="!isReady">
      <ProfileHeader
        :id="currentCheckin.ProfileId"
        :name="getGreetingText() + ' ' + currentCheckin.ProfileName"
        :image="currentCheckin.ProfileImage"
        :imageAlt="currentCheckin.ProfileName"
      />
      <p
        v-if="currentCheckin.EventId === 0"
        class="subtitle is-5"
      >What's your focus today?</p>
      <p
        v-else
        class="subtitle is-5"
      >What are you looking for during this event?</p>

      <div class="is-mobile matchesWrapper">
        <div class="column">
          <checkin-workingon
            @resetTimer="resetTimeoutSeconds"
            @callbackCheckinUpdated="setReadyState"
            v-if="currentCheckin"
          ></checkin-workingon>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="title is-4 has-text-centered has-text-blue-dark">Hooray! You're checked in successfully</h3>
      <div v-if="currentCheckin.EventId">
        <div
          v-html="eventConfirmationText"
          class="content has-text-left"
        ></div>
        <button
          @click="close"
          class="button is-primary"
        >Close</button>
      </div>

      <hr class="separator" />
      <div v-if="currentCheckin.EventId === 0">
        <p
          v-if="!getMatchesFinished"
          class="has-text-centered"
        >
          <img
            src="@/assets/img/s2m-loader-big.gif"
            class="loaderImage"
          />
        </p>

        <transition
          name="fade"
          mode="out-in"
        >
          <button
            v-if="getMatchesFinished && matches.length !== 0 && isLoggedIn && showMatchesButton"
            class="button is-primary"
            @click="gotoMatches"
          >See your matches</button>
          <div v-else-if="getMatchesFinished">
            <!-- You're the first one, check later again if we found some relevant people for you :)
          <p>&nbsp;</p> -->
            <button
              @click="close"
              class="button is-primary"
            >Close</button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import matchProvider from '../providers/match'
import { EventBus } from '../eventBus/event-bus'
import ProfileHeader from '@/components/partial/ProfileHeader'

export default {
  props: {
    eventId: {
      type: Number,
      default: 0
    },
    showMatchesButton: {
      type: Boolean,
      default: true
    },
    showTimeMatchItem: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      default: null
    }
  },

  components: {
    ProfileHeader
  },

  data() {
    return {
      getMatchesFinished: false,
      matchIndex: 0,
      itemsPerPage: 4,
      page: 1,
      matches: [],
      matchReloadCounter: 0,
      passportUrl: process.env.VUE_APP_BASE_URL,
      seconds: this.$store.getters.getTimeoutSeconds,
      showOpportunities: false,
      showLocation: false,
      isUpdated: false,
      leaveTimer: null
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
      foundCheckin: 'getCurrentCheckin',
      currentCheckin: 'getCurrentCheckin'
    }),

    isReady() {
      return this.currentCheckin.WorkingOn !== '' || this.isUpdated
    },

    confirmationTitle() {
      let output = `Hooray! You're checked in successfully`
      return output
    },

    eventConfirmationText() {
      if (this.event === null) { return '' }
      let text = `<p>Hi ##profileName##,</p>
        <p>Nice that you are going to the event '<strong>##eventName##</strong>'.</p>
        <p>See you on ##date##.</p>
        <p>##organizerName##<br/>
        Organizer</p>`

      text = text.replace('##profileName##', this.profile.FirstName)
        .replace('##eventName##', this.event.Name)
        .replace('##date##', this.$options.filters.longDateFormat(this.event.StartDate))
        .replace('##organizerName##', this.eventOrganisorName)

      return text
    },

    eventOrganisorName() {
      if (this.event === null) { return '' }
      let organisor = this.event.Organizers.find(eo => eo.IsPrimary === true)
      return organisor.Name
    }
  },

  watch: {
    currentCheckin: {
      immediate: true,
      handler: function(currentCheckin) {
        if (currentCheckin && currentCheckin.WorkingOn !== '' && currentCheckin.EventId !== 0) {
          this.getMatches(currentCheckin.Latitude, currentCheckin.Longitude, currentCheckin.WorkingOn, currentCheckin.EventId, currentCheckin.LocationId)
        }
      }
    },

    isReady: {
      immediate: true,
      handler: function(val) {
        if (val) {
          this.leaveTimer && clearTimeout(this.leaveTimer)
          this.leaveTimer = setTimeout(() => {
            this.$emit('callbackFinished', null)
            clearTimeout(this.leaveTimer)
          }, 2000)
        }
      }
    }
  },

  beforeDestroy() {
    this.leaveTimer && clearTimeout(this.leaveTimer)
  },

  methods: {
    close() {
      this.$emit('close')
    },

    gotoHome() {
      this.$router.push({ name: 'Dashboard' })
    },

    setReadyState() {
      this.isUpdated = true
    },

    getMatches(latitude, longitude, workingOn, eventId, locationId) {
      this.$emit('resetTimer')

      let profileIds = []
      if (this.profile && this.profile.Id > 0) {
        profileIds.push(this.profile.Id)
      }

      for (let i = 0; i < this.matches.length; i++) {
        profileIds.push(this.matches[i].ProfileId)
      }

      matchProvider.methods.getMatches(workingOn, latitude, longitude, this.page, this.itemsPerPage, '0', 0, eventId, locationId).then(response => {
        let currentMatches = this.matches
        let matches = response.data.Results

        if (response.data.MorePages > 0) {
          this.showMoreMatches = true
        }

        for (let i = 0; i < matches.length; i++) {
          let match = matches[i]
          if (currentMatches.indexOf(match) === -1) {
            currentMatches.push(match)
          }
        }
        this.matches = currentMatches
        EventBus.$emit('passingMatches', currentMatches)

        if (this.matches.length === 0) {
          this.$emit('activateCountDown')
          this.getMatchesFinished = true
        } else {
          this.matchReloadCounter = 0
          this.getMatchesFinished = true
        }
      })
        .finally(() => {
          this.getMatchesFinished = true
        })
    },

    getNextMatch() {
      this.matchIndex = this.matchIndex + 2
    },

    getPrevMatch() {
      this.matchIndex = this.matchIndex - 2
    },

    getGreetingText() {
      let d = new Date()
      if (d.getHours() < 12) {
        return 'Good morning'
      } else if (d.getHours() >= 12 && d.getHours() < 18) {
        return 'Good afternoon'
      } else {
        return 'Good evening'
      }
    },

    gotoMatches() {
      if (this.$store.getters.getSelectedLocation > 0) {
        this.$store.commit('setSelectedLocation', 0)
      }
      this.$store.commit('setActiveDrawerType', '')
      if (this.currentCheckin.EventId === 0) {
        this.$router.push({ name: 'Matches' })
      } else {
        // EventBus.$emit('updatedWorkingOn', response.data)
        // window.scrollTo(0, 0)
      }
    },

    resetTimeoutSeconds() {
      this.seconds = 60
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 20%;
}
</style>
