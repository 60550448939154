<template>
  <a
    v-if="item.externalUrl"
    :href="item.externalUrl"
    target="_blank"
    rel="noopener"
  >
    <span :class="{'icon-text is-flex-wrap-nowrap': isDropdownItem}">
      <span
        v-if="isDropdownItem"
        class="icon is-small mr-2"
      >
        <font-awesome-icon :icon="['fas', 'angle-right']" />
      </span>
      <span class="text-icon is-flex is-flex-wrap-nowrap is-align-items-center">
        <span class="inline-block mr-2 is-whitespace-nowrap">{{item.text}}</span>
        <span class="icon is-small is-size-6">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
        </span>
      </span>
    </span>
  </a>
  <router-link
    v-else
    :to="{ name: item.routeName }"
    :class="{'icon-text': isDropdownItem}"
  >
    <span
      v-if="isDropdownItem"
      class="icon is-small mr-2"
    >
      <font-awesome-icon :icon="['fas', 'angle-right']" />
    </span>
    <span class="is-unselectable">{{ item.text }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    isDropdownItem: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    }
  }
}
</script>