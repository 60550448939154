<template>
  <div
    class="modal modal-voucherDetails"
    @click="closeVoucherDetail"
    :class="{ 'is-active' : selectedVoucher.Id > 0 }"
  >
    <div class="modal-card has-background-white">
      <div class="has-padding-top padding_horizontal title is-5">
        {{selectedVoucher.Name}}
      </div>
      <div class="modal-card-body">
        <div class="columns">
          <div class="column is-one-third"><strong>Voucher code:</strong></div>
          <div class="column">{{selectedVoucher.Code}}</div>
        </div>
        <div class="columns">
          <div class="column is-one-third"><strong>Date:</strong></div>
          <div class="column">Valid from {{selectedVoucher.ValidFrom | dateFormat('en', 'shortDateFormat')}} until {{selectedVoucher.ValidUntil | dateFormat('en', 'shortDateFormat')}}</div>
        </div>

        <div class="columns">
          <div class="column is-one-third"><strong>Voucher can be used for:</strong></div>
          <div class="column">
            <template v-for="(id, index) in selectedVoucher.MeetingTypeIds">
              {{ index > 0 ? ',' : '' }}
              {{ textLabels[`seatType_${id}`] }}
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <strong>Seats:</strong>
          </div>
          <div class="column">
            <template v-if="selectedVoucher.MinSeats === 0 && selectedVoucher.MaxSeats === 0">
              Unlimited
            </template>
            <template v-else-if="selectedVoucher.MinSeats === 0 && selectedVoucher.MaxSeats > 0">
              valid up to {{ selectedVoucher.MaxSeats }} seat(s)
            </template>
            <template v-else-if="selectedVoucher.MinSeats > 0 && selectedVoucher.MaxSeats === 0">
              valid from {{ selectedVoucher.MaxSeats }} seat(s)
            </template>
            <template v-else-if="selectedVoucher.MinSeats > 0 && selectedVoucher.MaxSeats > 0">
              valid from {{ selectedVoucher.MaxSeats }} up to {{selectedVoucher.MaxSeats}} seat(s)
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <strong>Hours:</strong>
          </div>
          <div class="column">
            <template v-if="selectedVoucher.MinSeats === 0 && selectedVoucher.MaxSeats === 0">
              Unlimited
            </template>
            <template v-else-if="selectedVoucher.MinSeats === 0 && selectedVoucher.MaxSeats > 0">
              valid up to {{ selectedVoucher.MaxSeats }} seat(s)
            </template>
            <template v-else-if="selectedVoucher.MinSeats > 0 && selectedVoucher.MaxSeats === 0">
              valid from {{ selectedVoucher.MaxSeats }} seat(s)
            </template>
            <template v-else-if="selectedVoucher.MinSeats > 0 && selectedVoucher.MaxSeats > 0">
              valid from {{ selectedVoucher.MaxSeats }} up to {{selectedVoucher.MaxSeats}} seat(s)
            </template>
          </div>
        </div>
        <div
          v-if="selectedVoucher.IsActionVoucher"
          class="columns"
        >
          <div class="column is-one-third">
            <strong>Voucher may only be used once per person:</strong>
          </div>
          <div class="column">Yes</div>
        </div>
        <div
          v-if="selectedVoucher.Credit > 0"
          class="columns"
        >
          <div class="column is-one-third">
            <strong>Credit:</strong>
          </div>
          <div class="column">{{ selectedVoucher.CreditsRemaining }}</div>
        </div>
        <div
          v-if="selectedVoucher.SeatsCount > 0"
          class="columns"
        >
          <div class="column is-one-third">
            <strong>Seats remaining:</strong>
          </div>
          <div class="column">{{ selectedVoucher.SeatsRemaining }}</div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <strong>Redeemable at:</strong>
          </div>
          <div class="column column-locations">
            <ul>
              <li
                v-for="(location, index) in selectedVoucher.Locations"
                :key="index"
              >
                {{ location.LocationName }}
              </li>
            </ul>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <strong>Used at bookings:</strong>
          </div>
          <div class="column column-bookings">
            <ul>
              <li
                v-for="(reservation, index) in selectedVoucher.Reservations"
                :key="index"
              >
                {{ reservation.ReservationId }}
              </li>
            </ul>
          </div>
        </div>
        <hr class="separator" />
      </div>
      <footer
        class="modal-card-foot"
        @click="closeVoucherDetail"
      >
        <button class="button is-primary">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      textLabels: {
        seatType_1: 'Meetingspace',
        seatType_2: 'Workspace'
      }
    }
  },

  computed: {
    ...mapGetters({
      selectedVoucher: 'getSelectedVoucher'
    })
  },

  beforeDestroy() {
    this.$store.commit('setSelectedVoucher', {})
  },

  methods: {
    closeVoucherDetail() {
      this.$store.commit('setSelectedVoucher', {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";
.columns {
  margin-top: 0;
  margin-bottom: $gap;
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.column-bookings {
  li {
    display: inline-block;
    width: 25%;
  }
}
</style>
