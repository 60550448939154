<template>
  <div v-if="id">
    <div class="imageHolder has-text-centered">
      <avatar-image
        class="is-inline-block"
        :class="{'is-64x64' : isTile, 'is-96x96' : !isTile}"
        :imageSrc="image"
        :itemId="id"
        :photoSize="isTile ? 84 : 120"
        :itemName="avatarName"
      />
    </div>
    <div
      v-if="name"
      class="has-text-centered"
      :class="{'has-text-weight-bold' : isTile, 'title is-4' : !isTile}"
    >{{name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: '',
      required: true
    },
    imageAlt: {
      type: String,
      default: '',
      required: false
    },
    isTile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    avatarName() {
      return this.imageAlt === '' ? this.name : this.imageAlt
    }
  },
}
</script>
