import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async saveItemView(typeId = 0, itemId = 0) {
      let criteria = {
        TypeId: typeId,
        ItemId: itemId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/log/click`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )

      return response
    },


    async saveProposalView(requestId) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/log/proposal/${requestId}/view`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          },
        }
      )
      return response
    }
  }
}
