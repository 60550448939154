import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import i18nConfig from './i18nConfig'
import 'core-js/stable';

import localforage from 'localforage'
import * as VueGoogleMaps from 'vue2-google-maps'
import auth from '@/providers/auth.js'

/**
 * Filters
 */
import './filters/currencyFilters'
import './filters/dateTimeFilters'
import './filters/imageFilters'
import './filters/objectFilters'
import './filters/stringFilters'
import './filters/settingFilters'

// Global functions
import Console from './utils/console'
import GoogleAnalytics from './utils/googleAnalytics'
import * as objectHelper from './utils/objectHelper'

// Fontawesome
import './fontAwesome.config'

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Browser Detection
import browserDetect from "vue-browser-detect-plugin"
Vue.use(browserDetect)

Vue.prototype.$console = Console
Vue.prototype.$objectHelper = objectHelper
Vue.prototype.$googleAnalytics = GoogleAnalytics

import UiComponentModal from '@/components/UI/Components/Modal'
import Login from '@/components/Login'
import AgreeToTerms from '@/components/AgreeToTerms'
import AvatarImage from '@/components/UI/AvatarImage'
import AddComment from '@/components/AddComment'
import ArticleDetail from '@/components/ArticleDetail'
import ChatItem from '@/components/ChatItem'
import CheckinItem from '@/components/CheckinItem'
import CheckinWorkingon from '@/components/CheckinWorkingon'
import CommentItem from '@/components/CommentItem'
import CreateProfile from '@/components/CreateProfile'
import CreateQuestion from '@/components/CreateQuestion'
import Inbox from '@/components/Inbox'
import IncomingQuestion from '@/components/IncomingQuestion'
import LocationDetail from '@/components/drawer/LocationDetail'
import LocationItem from '@/components/Locations/LocationItem'
import NotificationRules from '@/components/NotificationRules'
import OpenReviews from '@/components/OpenReviews'
import ProfileCheck from '@/components/ProfileCheck'
import ProfileStats from '@/components/ProfileStats'
import DrawerPublicProfileDetail from '@/components/drawer/DrawerPublicProfileDetail'
import Matches from '@/components/Matches'
import MatchItem from '@/components/MatchItem'
import SaveReview from '@/components/SaveReview'
import SkyLine from '@/components/SkyLine'
import ReviewItem from '@/components/ReviewItem'
import AnimatedNumber from '@/components/AnimatedNumber'
import VoucherDetailModal from '@/components/VoucherDetailModal'
import SectionHeader from '@/components/UI/SectionHeader'
import UiSubmitFormControls from '@/components/UI/Form/SubmitFormControls'

// Form
import BaseBulmaField from '@/components/UI/Form/BaseBulmaField'
import BaseCheckbox from '@/components/UI/Form/BaseCheckbox'
import BaseForm from '@/components/UI/Form/BaseForm'
import BaseFormInputField from '@/components/UI/Form/BaseFormInputField'
import EmailField from '@/components/UI/Form/EmailField'
import InputNumeric from '@/components/UI/Form/InputNumeric'
import RequiredField from '@/components/UI/Form/RequiredField'

Vue.component('BaseBulmaField', BaseBulmaField)
Vue.component('BaseCheckbox', BaseCheckbox)
Vue.component('BaseForm', BaseForm)
Vue.component('BaseFormInputField', BaseFormInputField)
Vue.component('EmailField', EmailField)
Vue.component('InputNumeric', InputNumeric)
Vue.component('RequiredField', RequiredField)

/**
 *  Partials
 */
import EventHeader from '@/components/partial/EventHeader'

/**
 * UI elements
 */
import UiCheckbox from '@/components/UI/Checkbox'
import UiLoader from '@/components/UI/Loader'

/**
 * Configuration
 */
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['c-lab-bookingwidget']
localforage.config({
  name: 's2m-profile'
})

Vue.use(VueGoogleMaps, {
  load: {
    // Google map key
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    // If you want to set the version, you can do so:
    v: '3.40'
  }
})  

Vue.component('ui-component-modal', UiComponentModal)
Vue.component('login', Login)
Vue.component('ui-section-header', SectionHeader)
Vue.component('agree-to-terms', AgreeToTerms)
Vue.component('AvatarImage', AvatarImage)
Vue.component('add-comment', AddComment)
Vue.component('article-detail', ArticleDetail)
Vue.component('chat-item', ChatItem)
Vue.component('checkin-item', CheckinItem)
Vue.component('checkin-workingon', CheckinWorkingon)
Vue.component('comment-item', CommentItem)
Vue.component('create-profile', CreateProfile)
Vue.component('create-question', CreateQuestion)
Vue.component('inbox', Inbox)
Vue.component('incoming-question', IncomingQuestion)
Vue.component('location-detail', LocationDetail)
Vue.component('location-item', LocationItem)
Vue.component('notificationRules', NotificationRules)
Vue.component('open-reviews', OpenReviews)
Vue.component('profile-check', ProfileCheck)
Vue.component('profile-stats', ProfileStats)
Vue.component('drawer-public-profile-detail', DrawerPublicProfileDetail)
Vue.component('matches', Matches)
Vue.component('match-item', MatchItem)
Vue.component('save-review', SaveReview)
Vue.component('review-item', ReviewItem)
Vue.component('animated-number', AnimatedNumber)
Vue.component('skyline', SkyLine)
Vue.component('voucher-detail-modal', VoucherDetailModal)
Vue.component('ui-submit-form-controls', UiSubmitFormControls)

/**
 * Partials
 */
Vue.component('event-header', EventHeader)

/**
 * UI elements
 */
Vue.component('ui-checkbox', UiCheckbox)
Vue.component('ui-loader', UiLoader)

/**
 * Plugins
 */
Vue.use(require('vue-masonry').VueMasonryPlugin)

export const i18n = i18nConfig

auth.methods.isAuthorized().then(() => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})
