/* global jQuery */

const debug = process.env.NODE_ENV !== 'production'

export default {
  debugger() {
    if (debug) {
      debugger
    }
  },

  /**
   * Print data in console bar of the browser
   * @param {Any} data
   */
  info(data) {
    if (debug) {
      console.info(data)
    }
  },

  /**
   * Print data in console bar of the browser
   * @param {Any} data
   */
  log(data) {
    if (debug) {
      console.log(data)
    }
  },

  /**
   * Show SignalR log
   */
  signalrLogger() {
    if (debug) {
      jQuery.connection.hub.logging = true
    }
  }
}
