import availabilityProvider from '../../providers/availability'
import cartProvider from '../../providers/cart'

const state = {
  cart: null,

  // The number of API requests being processed
  busy: 0
}

/**
 * Getters
 */
const getters = {
  isBusy: (state) => state.busy > 0,
}

/**
 * Mutations
 */
const mutations = {
  /**
   * Keep track of the number of API interactions being processed
   */
  increaseBusyState: (state) => {
    state.busy = state.busy + 1
  },
  decreaseBusyState: (state) => {
    state.busy = Math.max(0, state.busy - 1)
  },

  /**
   * Set the Cart model from the API
   * The complete object is updated, which means all observables will be recalculated.
   */
    setCart: (state, data) => {
    state.cart = data
  },
}

/**
 * Actions
 */
const actions = {
  // Create cart
  createCart: async({ commit }, { CartType = 0, SearchId, LocationId, Hash } = {}) => {
    commit('increaseBusyState')

    let response = await availabilityProvider.methods.selectSpace({
      CartType,
      SearchId,
      LocationId,
      Hash,
    })

    if (response.status === 200 && response.data.CartKey) {
      commit('setCart', response.data)
    }

    commit('decreaseBusyState')
  },

  // Delete cart
    deleteCart: async ({ commit, state }) => {
    commit('increaseBusyState')

    if (state.cart) {
      let cartKey = state.cart.CartKey
      await cartProvider.methods.deleteCart(cartKey).catch(() => { })
      commit('setCart', null)
    }
    commit('decreaseBusyState')

    state.busy = 0
  },

    /**
     * Finalize cart
     */
  finalizeCart: async ({ commit, state }) => {
    commit('increaseBusyState')
    if (state.cart === null) {
      commit('decreaseBusyState')
      return
    }

    const response = await cartProvider.methods.finalizeCart(
      state.cart.CartKey,
    )
    
    commit('decreaseBusyState')
    return response
  },

  /**
   * Save the current cart details to the database. The returned cart object is used as new truth.
   * During this action the busy state of the interface is set, blocking certain actions
   */
  saveCart: async ({ commit, state }) => {
    if (!state.cart) { return }
    commit('increaseBusyState')
    return await cartProvider.methods.updateCart(state.cart)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          commit('setCart', response.data)
        }
      })
      .finally(() => {
        commit('decreaseBusyState')
      })
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
