<template>
  <article
    v-if="chatMessage"
    class="chatMessageItemMedia media"
  >
    <div
      v-if="chatMessage.ProfileId !== profile.Id"
      class="media-left"
    >
      <AvatarImage
        class="image-profile is-48x48"
        :itemId="chatMessage.ProfileId"
        :itemName="chatMessage.ProfileName"
        :imageSrc="chatMessage.ProfileImage"
        :fixedColorIndex="0"
      />
    </div>
    <div class="media-content">
      <div class="content">
        <div
          class="has-text-grey postDetailBar"
          :class="{
            'has-text-left': chatMessage.ProfileId !== profile.Id,
            'has-text-right': chatMessage.ProfileId === profile.Id
          }"
        >
          {{dateTime}}
        </div>
        <div :class="{
            'has-text-left': chatMessage.ProfileId !== profile.Id,
            'has-text-right': chatMessage.ProfileId === profile.Id
          }">
          <strong class="">{{ chatMessage.ProfileName }}:</strong>
        </div>
        <div
          class="text"
          :class="{ right: chatMessage.ProfileId === profile.Id }"
          :inner-html.prop="chatMessage.Message | nl2br"
        ></div>
      </div>
    </div>
    <div
      v-if="chatMessage.ProfileId === profile.Id"
      class="media-right"
    >
      <AvatarImage
        class="image-profile is-48x48"
        :itemId="chatMessage.ProfileId"
        :itemName="chatMessage.ProfileName"
        :imageSrc="chatMessage.ProfileImage"
      />
    </div>
  </article>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
export default {
  props: {
    chatMessage: null
  },

  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),

    dateTime() {
      let d = format(new Date(this.chatMessage.CreatedOn), 'DD-MM-YYYY, HH:mm')
      return d
    },
  },

  methods: {
    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .text {
    &.right {
      float: right;
    }
  }
  .media-right {
    margin-top: 0 !important;
  }
}
</style>
