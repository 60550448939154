<template>
  <div class="getProfileWrapper">
    <div v-if="!showCreateProfile">
      <div class="field">
        <p class="has-text-centered has-margin-bottom">
          <img
            src="@/assets/img/icons/checkin.png"
            alt='check-in'
          >
        </p>
        <h3
          class="title is-4 has-text-centered"
          v-html="title"
        ></h3>
        <NoAuthLoginFlow />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoAuthLoginFlow from '@/components/Auth/NoAuthLoginFlow'

export default {
  props: {
    isCheckinProcess: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: null
    }
  },

  components: {
    NoAuthLoginFlow
  },

  data() {
    return {
      profileEmail: '',
      showCreateProfile: false,
      validationCounter: 0
    }
  },
  computed: {
    ...mapGetters({
    }),

    title() {
      let output = 'Check-in'
      if (this.event !== null) {
        output += ' on ' + this.$options.filters.stringShortner(this.event.Name, 50)
      }
      return output
    },
  },
  methods: {
    cancelRegistration() {
      this.showCreateProfile = false
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.button_kiosk {
  background-color: #88c548;
  color: white;
}

img:not(.loaderImage) {
  width: 20%;
}

footer {
  background-color: #d3d3d3;

  img {
    width: 128px;
    @media screen and (max-width: $tablet) {
      width: 60%;
    }
  }
}

.buttonPillar {
  margin-top: 30px;
  .buttonCancel {
    margin-right: 15px;
  }
}
</style>
