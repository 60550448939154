const reservationRoutes = [
  {
    path: '/reservations',
    name: 'Reservations',
    component: () => import(/* webpackChunkName: "reservations" */ '@/views/reservations/Reservations'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reservations/:reservationKey',
    name: 'reservation-detail',
    component: () => import(/* webpackChunkName: "reservation-detail" */ '@/views/reservations/ReservationDetail'),
    meta: {
      requiresAuth: true,
    },
  }
]

export default reservationRoutes