import Vue from 'vue'
import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async logOff() {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/logoff`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    }
  }
}
