<template>

  <div
    v-click-outside="closeDropDown"
    class="dropdown"
    :class="[dropdownClasses, position]"
  >
    <!-- navbar-link is-arrowless -->
    <div
      class="dropdown-trigger is-flex is-clickable"
      :class="{ 'is-active': menuItem.hasSelectedMenu }"
      @click="toggleDropDown()"
    >
      {{ menuItem.text }}
      <span class="icon">
        <font-awesome-icon :icon="[
          'fas',
          !dropdownVisible ? 'angle-down' : 'angle-up',
        ]" />
      </span>
    </div>
    <div
      class="dropdown-menu is-unselectable"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content has-background-grey-light">
        <div
          class="dropdown-item"
          v-for="(submenuItem, submenuIndex) in menuItem.menu"
          :key="'msi_' + submenuIndex"
        >
          <LinkItem
            :isDropdownItem="true"
            :item="submenuItem"
            class="icon-text is-align-items-start is-flex-wrap-nowrap"
            :class="{
              'is-active': $route.name === submenuItem.routeName
            }"
            @click.native="() => { handleDropdownMenuItemClick(submenuItem) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import LinkItem from '../UI/LinkItem.vue'
export default {
  name: 'MenuDropdown',

  components: { LinkItem },

  props: {
    menuItem: {
      type: Object,
      required: true
    },
    position: {
      type: String,
      default: 'is-left'
    }
  },

  data() {
    return {
      dropdownVisible: false
    }
  },

  computed: {
    dropdownClasses() {
      return {
        'is-active': this.dropdownVisible,
      }
    },
  },

  methods: {
    ...mapMutations(['setActiveModalType']),
    closeDropDown() {
      this.dropdownVisible = false
    },

    toggleDropDown() {
      this.dropdownVisible = !this.dropdownVisible
    },

    handleDropdownMenuItemClick(submenuItem) {
      if (submenuItem.modal) {
        this.setActiveModalType(submenuItem.modal)
      }
      this.closeDropDown()

      // $googleAnalytics.pushEvent('SiteTopMenu', menuItem.gaAction, submenuItem.gaLabel); hideDrowdown(submenuItem);
    }
  },

  directives: {
    'click-outside': {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // check that click was outside the el and its children
          if (!(el == event.target || el.contains(event.target))) {
            //  if it was, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  }
}
</script>