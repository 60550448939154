<template>
  <div
    v-if="selectedPublicProfile.profileId > 0"
    class="drawer right has-background-white"
    :class="[{ 'is-active': selectedPublicProfile.profileId > 0 }, !showChat ? 'detail-view': 'chat-view']"
  >
    <ui-loader v-if="isLoading" />
    <!-- CHAT VIEW -->
    <div
      v-if="!isLoading && showChat"
      key="chatView"
      class="drawer-content"
    >
      <div class="padding header header-chat">
        <a
          class="icon-text close-button"
          v-if="profile !== null"
          @click="hideChatView"
        >
          <span class="icon">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Back</span>
        </a>
        <div
          v-if="publicProfile"
          class="profile-detail"
        >
          <span class="is-size-7">Send message to:</span><br />
          <span v-html="publicProfile.FullName"></span>
        </div>
      </div>
      <ChatDetail
        v-if="!isLoading"
        :chat="activeChat"
        :selectedProfileId="publicProfile.Id"
        :systemChatMessage="systemChatMessage"
      />
    </div>

    <!-- PROFILE VIEW -->
    <div
      v-if="!isLoading && !showChat"
      key="detailView"
      class="drawer-content"
    >
      <div class="padding header">
        <a
          class="icon-text close-button"
          v-if="profile !== null"
          @click="hidePublicProfileDetail"
        >
          <span class="icon">
            <i class="fas fa-times-circle"></i>
          </span>
          <span>Close</span>
        </a>
      </div>

      <div class="wrapper has-padding-left has-padding-right">
        <div class="content has-padding-bottom">
          <ProfileHeader
            class="has-margin-bottom"
            :id="publicProfile.Id"
            :name="publicProfile.FullName"
            :image="publicProfile.Image"
          />

          <!-- <div class="is-5 has-text-centered"><a @click="savePin" class="button">Pin</a></div> -->

          <div
            v-if="publicProfile.Bio !== ''"
            class="margin_vertical"
          >
            <div class="has-text-centered">
              <strong>Biography:</strong>
            </div>
            <div :inner-html.prop="publicProfile.Bio | nl2br"></div>
            <hr class="separator" />
          </div>
          <div v-if="hasTags">
            <div class="has-text-centered mb-3">
              <strong>Interest and knowledge:</strong>
            </div>
            <div class="tags is-block has-text-centered">
              <template v-for="(tag, index) in profileTags">
                <span
                  v-if="tag.trim()"
                  :key="'t'+index"
                  v-html="$options.filters.stringShortner(tag, 30)"
                  class="tag is-outlined is-white"
                ></span>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isSelf"
        class="padding footer has-text-centered"
      >
        <button
          class="button is-primary"
          v-if="isLoggedIn"
          @click="showChatView"
        >
          <span>Send message</span>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import pin from '@/providers/pin'
import profileProvider from '../../providers/profile'
import ChatDetail from '@/components/ChatDetail'
import ProfileHeader from '@/components/partial/ProfileHeader'

export default {
  components: {
    ChatDetail,
    ProfileHeader
  },

  data() {
    return {
      activeChat: null,
      publicProfile: null,
      isLoading: true,
      showChat: false,
      wHeight: window.innerHeight,
      systemChatMessage: ''
    }
  },

  computed: {
    ...mapState('chatStore', ['chats']),
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
      selectedPublicProfile: 'getSelectedPublicProfile'
    }),

    hasTags() {
      if (!this.publicProfile) { return false }
      let output = false
      for (let i in this.publicProfile.Tags) {
        if (this.publicProfile.Tags[i].trim()) {
          output = true
          break
        }
      }
      return output
    },

    profileTags() {
      if (!this.publicProfile || this.publicProfile.Tags.trim().length === 0) { return [] }
      return this.publicProfile.Tags.split(',')
    },

    isPinned() {
      if (this.pinItem && this.pinItem.Id > 0) {
        return true
      }
      return false
    },

    isSelf() {
      if (this.publicProfile.Id === this.profile.Id) {
        return true
      }
      return false
    }
  },

  watch: {
    chats: {
      handler: function(val) {
        if (val === null) { return }
        this.getActiveChats()
      },
      deep: true
    }
  },

  async created() {
    // Body tag
    document.body.style.overflow = 'hidden'
    // HTML tag
    document.documentElement.style.overflow = 'hidden'

  },

  async mounted() {
    try {
      await this.getPublicProfile()
      await this.getProfileChats()
    }
    catch { }
    finally {
      this.isLoading = false
    }

    if (this.isLoggedIn && this.selectedPublicProfile.view === 'chat') {
      if (this.selectedPublicProfile.chatMessage) {
        this.systemChatMessage = this.selectedPublicProfile.chatMessage
      }
      this.showChatView()
    }
  },

  beforeDestroy() {
    this.hidePublicProfileDetail()
    // Body tag
    document.body.style.overflow = ''
    // HTML tag
    document.documentElement.style.overflow = ''
  },

  methods: {
    ...mapActions('chatStore', ['getProfileChats']),

    /**
     * Get active chat
     */
    getActiveChats() {
      if (this.chats === null) { return }

      let activeChats = this.chats.filter(obj => {
        for (let i = 0, length = obj.Profiles.length; i < length; i++) {
          if (obj.Profiles[i].ProfileId === this.publicProfile.Id) {
            return true
          }
        }
        return false
      })

      if (activeChats.length > 0) {
        this.activeChat = activeChats[0]
      }
    },

    hideChatView() {
      this.showChat = false
      this.systemChatMessage = ''
    },

    showChatView() {
      this.showChat = true
    },

    getPublicProfile() {
      let self = this

      profileProvider.methods.getPublicProfile(this.selectedPublicProfile.profileId)
        .then(response => {
          self.publicProfile = response.data

          self.getActiveChats()
        })
    },

    isItemPinned() {
      pin.methods
        .isItemPinned(7, this.publicProfile.Id)
        .then(response => {
          if (response.status === 200) {
            this.pinItem = response.data
          }
        })
        .then(() => {
          this.pinChecked = true
        })
    },

    hidePublicProfileDetail() {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: 0,
        view: 'detal',
        chatMessage: null
      })
      this.systemChatMessage = ''
      // this.messageTextarea = ''
      // this.messageToCheckinIsSent = false
    },

    goToMatches(tag) {
      this.$router.push({ path: `matches?q=${tag}` })
    },

    savePin() {
      pin.methods.savePin(7, this.publicProfile.Id).then(response => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  height: 100%;
  z-index: 32;

  &.detail-view {
    .drawer-content {
      .footer {
        flex-grow: 0;
      }
      .wrapper {
        overflow: auto;
      }
    }
  }

  &.chat-view {
    .header {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      .profile-detail {
        line-height: 16px;
        margin-left: $gap * 2;

        figure {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    .chatMessages {
      display: flex;
      flex-direction: column;
      height: calc(100% - 54px);

      // .messages-wrapper {
      //   height: 100%;
      //   overflow: auto;
      // }
    }
  }

  .wrapper {
    flex-grow: 1;
  }
}
</style>
