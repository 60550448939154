import axios from 'axios'
import store from '../store/store'

let getAllQuestionsCancelToken = null
let getQuestionsCancelToken = null
let getQuestionsProfileMatchedCancelToken = null
let getQuestionsProfileAnsweredOnToken = null

export default {
  methods: {
    cancelGetAllQuestions(message = 'Canceled by new request') {
      if (getAllQuestionsCancelToken) {
        getAllQuestionsCancelToken.cancel(message)
      }
    },
    cancelGetQuestions(message = 'Canceled by new request') {
      if (getQuestionsCancelToken) {
        getQuestionsCancelToken.cancel(message)
      }
    },

    cancelGetQuestionsProfileMatchedCancelToken(
      message = 'Canceled by new request'
    ) {
      if (getQuestionsProfileMatchedCancelToken) {
        getQuestionsProfileMatchedCancelToken.cancel(message)
      }
    },

    cancelGetQuestionsProfileAnsweredOn(message = 'Canceled by new request') {
      if (getQuestionsProfileAnsweredOnToken) {
        getQuestionsProfileAnsweredOnToken.cancel(message)
      }
    },

    /**
     * Get all questions
     */
    async getAll(
      searchTerm = '',
      showUnsolvedOnly = false,
      page = 0,
      itemsPerPage = 0,
      tags = ''
    ) { 
      this.cancelGetAllQuestions()
      getAllQuestionsCancelToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question`,
        {
          cancelToken: getAllQuestionsCancelToken.token,
          params: {
            searchTerm: searchTerm || null,
            showOpen: showUnsolvedOnly,
            page: page,
            itemsPerPage: itemsPerPage,
            tags: tags || null
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getQuestionById(questionId) {
      const value = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question/${questionId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return value
    },

    /**
     * Get questions asked by the autenticated profile
     */
    async getProfileQuestions(searchTerm, showUnsolvedOnly, page, itemsPerPage, tags) {
      this.cancelGetQuestions()
      getQuestionsCancelToken = axios.CancelToken.source()

      const response = axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question/profile`,
        {
          cancelToken: getQuestionsCancelToken.token,
          params: {
            searchTerm: searchTerm || null,
            showOpen: showUnsolvedOnly,
            page: page,
            itemsPerPage: itemsPerPage,
            tags: tags || null
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getQuestionsProfileAnsweredOn(showUnsolvedOnly, page, itemsPerPage) {
      this.cancelGetQuestionsProfileAnsweredOn()
      getQuestionsProfileAnsweredOnToken = axios.CancelToken.source()
      const response = axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question/answeredon`,
        {
          cancelToken: getQuestionsProfileAnsweredOnToken.token,
          params: {
            page,
            itemsPerPage,
            showOpen: showUnsolvedOnly
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async getQuestionsProfileMatched(showUnsolvedOnly, page, itemsPerPage) {
      this.cancelGetQuestionsProfileMatchedCancelToken()
      getQuestionsProfileMatchedCancelToken = axios.CancelToken.source()

      const response = axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question/matched`,
        {
          cancelToken: getQuestionsProfileMatchedCancelToken.token,
          params: {
            page,
            itemsPerPage,
            showOpen: showUnsolvedOnly
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async saveQuestion(question, tags) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question`,
        JSON.stringify({
          GroupId: 0,
          Text: question,
          Tags: tags,
          Url: ''
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
  }
}
