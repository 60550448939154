<template>
  <textarea
    class="textarea has-margin-bottom"
    :class="{ 'is-borderless' : inputBorderless }"
    :value="value"
    @blur="(e) => { stripHtml(e), onBlur(e) }"
    @focus="stripHtml"
    @input="onInput"
  ></textarea>
</template>

<script>
export default {
  props: {
    inputBorderless: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },

  methods: {
    /**
     * Strip HTML tags
     */
    stripHtml(e) {
      this.$emit('input', this.$options.filters.stripHtml(e.target.value))
    },

    onBlur(e) {
      this.$emit('blur', 'updateTags')
    },

    onInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-borderless,
.is-borderless:hover {
  border-color: transparent;
}
</style>
