import { EventBus } from '@/eventBus/event-bus'
import notificationProvider from '@/providers/notification'

/**
 * States
 */
const state = {
  notifications: []
}

/**
 * Getters
 */
const getters = {
  getNotifications: state => state.notifications
}

/**
 * Mutations
 */
const mutations = {
  /**
   * Set notifications in store
   */
  setNotifications: (state, newState) => {
    state.notifications = newState
  }
}

/**
 * Actions
 */
const actions = {
  /**
   * Get notifications
   */
  getProfileNotifications: context => {
    notificationProvider.methods.getProfileNotifications().then(response => {
      context.commit('setNotifications', response.data)
    })
  },

  /**
   * Send notification to store
   */
  sendNotification: (context, data) => {
    let obj = JSON.parse(data)
    let arr = context.getters.getNotifications
    arr.unshift(obj)
    context.commit('setNotifications', arr)

    EventBus.$emit('newNotificationMessage', obj)

    if (obj.TypeId === 32) {
      context.commit('setShowIncomingQuestion', obj.ItemId)
    }

    // Weet niet wat de code hieronder doet aangezien het nergens op gebind wordt
    // let notifications = self.notifications
    // notifications.unshift(obj)
    // if (notifications.length > 20) {
    //   notifications.pop()
    // }
  }
}

/**
 * Export
 */
export default {
  state,
  getters,
  actions,
  mutations
}
