import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async getChatById(chatId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/chat/${chatId}`,
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * Get profile chats
     * @returns 
     */
    async getProfileChats() {
      return await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/chat`, {
        headers: {
          'Content-type': 'application/json',
          apitoken: process.env.VUE_APP_API_KEY,
          profileToken: store.getters.getProfileToken
        }
      })
    },


    /**
     * Create new chat
     * @param {Number} profileId 
     * @param {String} message 
     * @param {Number} matchId 
     * @returns 
     */
    async createChat(selectedProfileId = 0, message = '', matchId = 0) {
      let criteria = {
        ProfileId: selectedProfileId,
        Message: message,
        MatchId: matchId
      }

      if(selectedProfileId === 0 || message === '') { return }

      return await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/chat`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    },


    /**
     * Send new message  to selected chat
     * @param {Number} chatId 
     * @param {Message} message 
     * @param {String} key 
     * @returns 
     */
    sendMessage(chatId, message, key = '') {
      let criteria = {
        ChatId: chatId,
        Message: message,
        Key: key
      }

      if (chatId === 0 || message === '') { return }

      // S2M CORE API
      return axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/chat/message`,
        {
          chatId,
          message,
          key
        },
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
    }
  }
}
