const state = {
  article: null,

  articlesPageState: {
    articles: [],
    page: 1,
    itemsPerPage: 25,
    reachedEnd: false,
    morePages: 1,
    searchTerm: ''
  }
}

const getters = {
  getArticle: state => state.article,

  getArticlesPageState: state => state.articlesPageState
}

const mutations = {
  setArticle: (state, newState) => {
    state.article = newState
  },

  setArticlesPageState: (state, newState) => {
    state.articlesPageState = newState
  }
}

const actions = {}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
