<template>
  <div id="app">
    <ModalCookieSettings
      v-if="showCookieSettingsModal"
      :showModal="showCookieSettingsModal"
      :onClickCancel="hideModalCookieSettings"
    />
    <CookieBar v-if="!cookieIsSet" />

    <div v-if="isInitiated">
      <div
        v-if="appOnlineChecked && !isOnline"
        class="offline-modal"
      >
        <div class="padding">
          <div class="notification is-warning has-text-centered">
            <span class="icon">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            <span>There is no internet connection.</span>
          </div>
        </div>
      </div>
    </div>

    <div class="has-top-sitebar mobile">
      <transition name="fade">
        <div
          class="drawer-background"
          v-if="
            activeDrawerType !== '' && activeDrawerType !== 'siteMenuOpPage' && activeDrawerType !== 'checkinProcessDrawer'
          "
          @click="hideDrawers"
        ></div>
      </transition>
    </div>

    <!-- DRAWERS -->
    <DrawerAskQuestion v-if="activeDrawerType === 'askQuestionDrawer'" />
    <article-detail v-if="activeDrawerType === 'articleDetailDrawer'"></article-detail>
    <event-detail v-if="activeDrawerType === 'eventDetailDrawer'"></event-detail>
    <location-detail v-if="
        activeDrawerType === 'locationDetailDrawer' || selectedLocation !== 0
      "></location-detail>
    <drawer-public-profile-detail v-if="activeDrawerType === 'profileDetailDrawer'" />

    <!-- MODALS -->
    <agree-to-terms v-if="!hasAgreedToTerms"></agree-to-terms>
    <ModalJitsiStartaRoomTips
      :showModal="activeModalType === 'modalJitsiStartaRoomTips'"
      :onClickCancel="closeModal"
    />
    <ModalJitsiTips
      :showModal="activeModalType === 'modalJitsiTips'"
      :onClickCancel="closeModal"
    />

    <!-- <profile-check v-if="isInitiated && isLoggedIn && profile.Id !== 0 && showProfileCheck"></profile-check> -->

    <voucher-detail-modal v-if="isInitiated && activeDrawerType === 'voucherDetailModal'"></voucher-detail-modal>

    <div
      ref="appWrapper"
      class="wrapper-app"
    >
      <div class="wrapper-app-header">
        <div
          ref="appNav"
          class="app-nav"
        >
          <SiteTopHeader class="container is-fluid" />
        </div>
        <skyline
          ref="skyline"
          class="no-margin-bottom"
          :title="'S2M Passport'"
          :subTitle="'Your digital workspace'"
        ></skyline>
      </div>

      <div class="wrapper-app-content site-main-content has-padding-bottom-x2">
        <div class="page-content container is-fluid has-background-white box is-shadowless">
          <transition
            name="slide-fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </div>
      </div>
    </div>

    <SiteFooter class="wrapper-app-footer" />
  </div>
</template>

<script>
/* global $ */
import authProvider from './providers/auth'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { getCookie, setCookie } from './utils/browserStorage'
import * as bulmaToast from 'bulma-toast'

import CookieBar from '@/components/cookie/CookieBar'
import ModalCookieSettings from '@/components/cookie/ModalCookieSettings'
import ModalJitsiStartaRoomTips from '@/components/global/ModalJitsiStartaRoomTips'
import ModalJitsiTips from '@/components/global/ModalJitsiTips'
import SiteFooter from '@/components/site/SiteFooter'
import DrawerAskQuestion from '@/components/drawer/DrawerAskQuestion'
import SiteTopHeader from '@/components/site/SiteTopHeader'
import { EventBus } from './eventBus/event-bus'

bulmaToast.setDoc(window.document)

export default {
  /* global jQuery */

  components: {
    CookieBar,
    DrawerAskQuestion,
    ModalCookieSettings,
    ModalJitsiStartaRoomTips,
    ModalJitsiTips,
    SiteTopHeader,
    SiteFooter
  },

  data() {
    return {
      userUpdatedCookies: false,
      appOnlineChecked: false,
      grad_count: 24,
      signalRIsSetup: false,
      now_base_url: process.env.NOW_BASE_URL,
      s2m_base_url: process.env.S2M_BASE_URL,
      s2mSearchWidget: '',
      drawerIsActive: false,
      sitePassportBannerCls: new Date().getHours(),
      showModalJitsiTips: false,
      hourChecker: null,
      darkMediaQuery: false
    }
  },

  computed: {
    ...mapState('chatStore', ['chats']),
    ...mapState('cookieStore', ['cookieIsSet', 'showCookieSettingsModal']),
    ...mapState(['activeModalType']),
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
      currentHour: 'getCurrentHour',
      hasAgreedToTerms: 'getHasAgreedToTerms',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      isOnline: 'getConnectionStatus',
      profile: 'getProfile',
      selectedArticle: 'getSelectedArticle',
      selectedLocation: 'getSelectedLocation',
      selectedPublicProfile: 'getSelectedPublicProfile',
      showProfileCheck: 'getShowProfileCheck'
    })
  },

  watch: {
    '$route.name': function() {
      this.$refs.appWrapper.scrollTop = 0
    },

    isLoggedIn: {
      immediate: true,
      handler: function(val) {
        if (val) {
          this.controlSignalR()
        }
      }
    },

    selectedArticle() {
      if (this.selectedArticle !== 0) {
        this.$store.commit('setActiveDrawerType', 'articleDetailDrawer')
      } else {
        this.$store.commit('setActiveDrawerType', '')
      }
    },

    selectedLocation() {
      if (this.selectedLocation !== 0) {
        this.$store.commit('setActiveDrawerType', 'locationDetailDrawer')
      } else {
        this.$store.commit('setActiveDrawerType', '')
      }
    },

    selectedPublicProfile: {
      deep: true,
      handler: function(val) {
        if (val.profileId !== 0) {
          this.$store.commit('setActiveDrawerType', 'profileDetailDrawer')
        } else {
          if (this.selectedLocation !== 0) {
            this.$store.commit('setActiveDrawerType', 'locationDetailDrawer')
          } else {
            this.$store.commit('setActiveDrawerType', '')
          }
        }
      }
    },

    activeDrawerType(val) {
      let self = this

      let scrollbarWidth = window.innerWidth - document.body.clientWidth
      if (val !== '') {
        document.body.style = `overflow: hidden; padding-right: ${scrollbarWidth}px`
      } else {
        document.body.style = ''
      }
    }
  },

  beforeCreate() {
    this.$store.dispatch('processBrowserDetection', this.$browserDetect)
  },

  created() {
    this.fetchCookieApiData()

    if (getCookie('CountryId') !== '') {
      this.countryId = Number(getCookie('CountryId'))
    }
    let cMT = getCookie('mt')
    if (cMT === '') {
      setCookie('mt', this.generateGuid(), 100, true)
      let t = setTimeout(() => {
        this.getProfileTokenFromCookie()
        clearTimeout(t)
      }, 200)
    } else {
      this.getProfileTokenFromCookie()
    }

    this.hourChecker = setInterval(() => {
      let h = new Date().getHours()
      if (h !== this.currentHour) {
        this.$store.commit('setCurrentHour', h)
      }
    }, 5 * 60000)
  },

  mounted() {
    window.addEventListener('load', () => {
      this.updateOnlineStatus()
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    })

    EventBus.$on('showToast', this.showToast)
    EventBus.$on('pageScrollTo', val => {
      // this.$refs.appWrapper.scrollTop = val
      window.scroll(0, val)
    })

    this.darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.updateFavIcon();
    if (this.darkMediaQuery) {
      this.darkMediaQuery.addEventListener('change', this.updateFavIcon)
    }
  },

  beforeDestroy() {
    EventBus.$off('showToast')
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    clearInterval(this.hourChecker)
  },

  methods: {
    ...mapActions('cookieStore', ['fetchCookieApiData']),
    ...mapMutations(['setActiveModalType']),
    ...mapMutations('cookieStore', ['setShowCookieSettingsModal']),


    updateFavIcon() {
      let favIconImg = '/favicon' + (!this.darkMediaQuery.matches ? '' : '-dark') + '.png';
      let favicon = document.querySelector('link[rel="icon"]')
      if (!favicon) { return }
      favicon.href = favIconImg;
    },

    hideModalCookieSettings() {
      this.setShowCookieSettingsModal(false)
    },

    hideDrawers() {
      if (this.activeDrawerType !== 'profileDetailDrawer') {
        this.$store.commit('setSelectedLocation', 0)
      }
      this.$store.commit('setActiveDrawerType', '')
      this.$store.commit('setSelectedPublicProfile', {
        profileId: 0,
        view: 'detail'
      })
    },

    closeModal() {
      this.setActiveModalType('')
    },

    updateOnlineStatus() {
      this.$store.commit('setConnectionStatus', navigator.onLine || false)
      this.appOnlineChecked = true
    },

    generateGuid() {
      return (
        this.s4() +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        this.s4() +
        this.s4()
      )
    },

    s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    },

    getProfileTokenFromCookie() {
      let apCookie = getCookie('ap')
      let ltCookie = getCookie('lt')

      if (apCookie === '' || ltCookie === '') {
        this.$store.dispatch('logoff')
        this.$store.commit('setIsInitiated', true)
        this.$store.state.passportState.IsLoading = false
      } else {
        if (ltCookie !== '' && this.$store.getters.getProfileToken === '') {
          authProvider.methods.verifyProfileToken(ltCookie)
        }
      }
    },

    controlSignalR() {
      let self = this
      if (this.signalRIsSetup === true) { return }

      // SignalR via API Core

      // SIGNALR VIA S2M
      // jQuery(function() {
      //   jQuery.getScript(`${process.env.VUE_APP_API_BASE_URL}/signalr/hubs`, () => {
      //     jQuery.connection.hub.url = `${process.env.VUE_APP_API_BASE_URL}/signalr`
      //     jQuery.connection.hub.logging = false
      //     // self.$console.signalrLogger()

      //     // Connections
      //     let notificationHub = jQuery.connection.notificationHub

      //     // Add connections in store
      //     self.$store.commit('setNotificationHub', notificationHub)

      //     // Send notification
      //     notificationHub.client.sendNotification = message => {
      //       self.$store.dispatch('sendNotification', message)
      //     }

      //     // Startup connection
      //     jQuery.connection.hub
      //       .start({ withCredentials: false })
      //       .done(function() {
      //         // Join notification group
      //         self.$store.dispatch('joinNotificationGroup')
      //       })
      //       .fail(function() { })
      //   })
      // })

      this.signalRIsSetup = true
    },

    showToast(toast) {
      bulmaToast.toast({
        message: toast.message,
        duration: 3000,
        type: toast.type === 'info' ? 'is-dark' : 'is-warning',
        position: 'bottom-center',
        dismissible: true,
        closeOnClick: true,
        animate: { in: 'fadeInUp', out: 'fadeOutDown' },
      })
    },
  }
}
</script>

<style lang="scss">
@import "./assets/styles/main.scss";

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $grey !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $grey !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $grey !important;
}
</style>
