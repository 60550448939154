import Vue from 'vue'

Vue.filter(
  'stringShortner',
  (str = '', nrCharacters = 0, useWordBoundary = false) => {
    if (nrCharacters === 0) {
      return str
    }
    let text = str.toString()
    let toLong = text.length > nrCharacters
    let newText = toLong ? text.substr(0, nrCharacters - 1) : text

    if (useWordBoundary === true) {
      let arrWords = text.split(' ')
      toLong = arrWords.length >= nrCharacters
      newText = text
      if (toLong === true) {
        arrWords.splice(nrCharacters, arrWords.length - nrCharacters)
        newText = arrWords.join(' ')
      }
    }
    return toLong ? newText + '&hellip;' : newText
  }
)

Vue.filter('nl2br', (str = '') => {
  if (str != null) {
    return str.replace(/\n/g, '<br />')
  }
  return str
})

Vue.filter('br2nl', (str = '') => {
  if (str != null) {
    return str.replace(/<br>/g, '<br />')
  }
  return str
})

Vue.filter('stripHtml', (str = '', brToSpace = false, brToNewLine = false) => {
  if (brToSpace === true) {
    str = str.replace(/<br\s*[\/]?>/gi, ' ')
  } else if (brToNewLine) {
    str = str.replace(/<br\s*[\/]?>/gi, '\n')
  }
  str = decodeHtml(str)
  return str.replace(/(<([^>]+)>)/gi, '')
})

Vue.filter('convertToSlug', (str = '') => {
  if (!str) { return str }
  return str
    .toLowerCase() // converts to lower case
    .normalize("NFD") // normalizes accented characters (For example: á --> a)
    .replace(".com", " ") // replaces .com with a space
    .replace("&", "and") // replaces & with 'and'
    .replace(/[^\w -]+/g, "") // removes all special characters except spaces and hyphens
    .replace(/ +/g, "-") // condenses consecutive spaces to a single hyphen
    .replace(/-+/g, "-"); // condenses consecutive hyphens to a single hyphen
})

function decodeHtml(html) {
  let divElm = document.createElement('div')
  divElm.innerHTML = html
  return divElm.innerText
}
function encodeHtml(txt) {
  let divElm = document.createElement('div')
  divElm.innerText = txt
  return divElm.innerHTML
}
