<template>
  <Drawer
    v-if="showDrawer"
    :showDrawer="showDrawer"
    :closeFunc="closeFunc"
    position="right"
    drawerCssClass="has-background-blue-dark"
    textCssClass="has-text-white"
  >
    <template v-slot:content>
      <div class="drawer-content">
        <template v-for="(main, index) in profileMenu">
          <div
            class="category-item has-padding-left has-padding-right has-text-weight-bold is-size-6 has-text-grey"
            :key="'m' + index"
          >
            <div class="is-divider is-small"></div>
            <div>{{ main.categoryName }}</div>
          </div>
          <LinkItem
            v-for="(menuItem, menuItemIndex) in main.menu"
            :key="index + '_mi' + menuItemIndex"
            :item="menuItem"
            @click.native="() => { $googleAnalytics.pushEvent('ProfileMobileMenu', main.gaAction, menuItem.gaLabel); setSelectedPageType(menuItem.manualPageRoute); closeFunc(); }"
            class="navbar-item is-unselectable"
            :class="{ 'is-active': setActiveMenuItem(menuItem) }"
          />
        </template>
        <div
          v-if="passportState.Locations.length"
          class="has-padding-top"
        >
          <div class="category-item has-padding-left has-padding-right has-text-weight-bold is-size-6 has-text-grey">
            <div class="is-divider is-small"></div>
            <div>My locations</div>
          </div>
          <a
            class="navbar-item is-unselectable"
            v-for="location in passportState.Locations"
            @click="closeFunc()"
            :key="location.Id"
            :href="buildLocationUrl(location)"
            target="_blank"
          >{{ location.Name }}</a>
        </div>
        <hr class="navbar-divider" />

        <div class="navbar-item has-padding-top-x2 has-padding-bottom-x2 has-padding-bottom">
          <button
            class="button is-primary"
            :class="{ 'is-loading': IsLoggingOut }"
            :disabled="IsLoggingOut"
            @click="
              () => {
                $store.dispatch('logoff')
                IsLoggingOut = true
              }
            "
          >Log out</button>
        </div>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Drawer from '@/components/UI/Drawer'
import LinkItem from '@/components/UI/LinkItem'

export default {
  name: 'drawer-site-menu',

  props: {
    closeFunc: {
      type: Function,
      default: () => { }
    },

    showDrawer: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Drawer,
    LinkItem
  },

  data() {
    return {
      IsLoggingOut: false
    }
  },

  computed: {
    ...mapState('menuStore', ['profileMenu']),
    ...mapGetters({
      passportState: 'getPassportState',
      selectedPageType: 'getSelectedPageType'
    })
  },

  methods: {
    buildLocationUrl(location) {
      return `${process.env.VUE_APP_S2M_BASE_URL}/locations/${location.Id}/${location.UrlName}/manage/dashboardmanage`
    },

    hideDrowdown() {
      this.disableMenuHoverTemporary()
      this.isHover = false
    },

    disableMenuHoverTemporary() {
      let self = this
      this.disableMenuActions = true

      if (this.menuTimerAction !== null) {
        clearTimeout(this.menuTimerAction)
      }
      this.menuTimerAction = setTimeout(() => {
        this.disableMenuActions = false
        clearTimeout(this.menuTimerAction)
      }, 250)
    },

    setActiveMenuItem(menuItem) {
      if (menuItem.manualPageRoute) {
        return (
          this.$route.name === menuItem.routeName &&
          menuItem.manualPageRoute === this.selectedPageType
        )
      } else {
        return this.$route.name === menuItem.routeName
      }
    },

    setSelectedPageType(page = '') {
      this.$store.commit('setSelectedPageType', page)
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-content {
  margin-left: -$gap;
  margin-right: -$gap;
}
.category-item {
  div {
    font-size: 16px;
    text-transform: uppercase;
  }
}
.navbar-item {
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  padding-left: $gap;
  padding-right: $gap;

  &:hover,
  &.is-active {
    color: white;
    background-color: transparent;
    text-decoration: unset;
  }
}
</style>
