<template>
  <div>
    <div
      class="title is-5"
      v-html="$t('Components.Auth.AuthPinValidationForm.Main_Title', localeOverride.locale)"
    ></div>
    <div
      class="has-margin-bottom"
      v-html="$t('Components.Auth.AuthPinValidationForm.Text_Explanation', localeOverride.locale)"
    ></div>
    <Message
      v-if="pinError"
      class="has-margin-bottom is-danger"
    >{{
      $t('Components.Auth.AuthPinValidationForm.Message_ErrorPin', localeOverride.locale)
    }}</Message>
    <TransitionExpand>
      <Message
        v-if="newCodeSent"
        class="has-margin-bottom is-success"
      >
        {{ $t('Components.Auth.AuthPinValidationForm.Message_NewPin', localeOverride.locale) }}
      </Message>
    </TransitionExpand>
    <BaseForm
      :onSubmitCallback="handleSubmit"
      @onStatusChange="onStatusChange"
    >
      <RequiredField
        class="is-hidden"
        name="code"
        :value="code"
        @validated="onCodeValidated"
      />
      <div :class="`level has-margin-bottom input-codes is-mobile`">
        <div :class="`level-item`">
          <input
            type="text"
            ref="codePart1"
            @keyup="checkCodePart1"
            size="2"
            max="2"
            maxlength="2"
            name="codePart1"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
        <div :class="`level-item`">
          <i class="fas fa-minus"></i>
        </div>
        <div :class="`level-item`">
          <input
            type="text"
            ref="codePart2"
            @keyup="checkCodePart2"
            size="2"
            max="2"
            maxlength="2"
            name="codePart2"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
        <div :class="`level-item`">
          <i class="fas fa-minus"></i>
        </div>
        <div :class="`level-item`">
          <input
            type="text"
            ref="codePart3"
            @keyup="checkCodePart3"
            size="2"
            max="2"
            maxlength="2"
            name="codePart3"
            autocomplete="off"
            aria-autocomplete="both"
            :class="[
              `input has-text-centered is-fullwidth`,
              {
                'is-danger': pinError || errorCodeMessage,
              },
            ]"
          />
        </div>
      </div>
      <p v-if="errorCodeMessage">
        <span :class="`help is-danger`">
          {{ errorCodeMessage }}
        </span>
      </p>

      <div :class="`level is-mobile`">
        <div :class="`level-left`">
        </div>
        <div :class="`level-right`">
          <div :class="`level-item`">
            <button
              class="button is-primary"
              :class="{ 'is-loading': isBusy || isProcessing }"
              type="submit"
              v-html="$t('Components.Auth.AuthPinValidationForm.Button_Request', localeOverride.locale)"
            >
            </button>
          </div>
        </div>
      </div>
    </BaseForm>
    <hr />
    <div
      v-if="email"
      class=""
    >
      {{ $t('Components.Auth.AuthPinValidationForm.Text_CodenotWorking', localeOverride.locale) }}
      <a
        @click="sendNewPin"
        v-html="$t('Components.Auth.AuthPinValidationForm.Link_CodenotWorking', localeOverride.locale)"
      ></a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import BaseForm from '@/components/UI/Form/BaseForm'
import Message from '@/components/UI/Message'
import RequiredField from '@/components/UI/Form/RequiredField'
import profileProvider from '../../providers/profile'
import TransitionExpand from '../UI/TransitionExpand.vue'

export default {
  name: 'AuthPinValidationForm',

  components: {
    BaseForm,
    Message,
    RequiredField,
    TransitionExpand,
  },

  props: {
    verificationKey: {
      type: String,
      required: true
    },
    email: {
      type: String,
      default: ''
    }
  },

  inject: {
    localeOverride: {
      type: Object,
      default: function() { return { locale: this.$i18n.locale } }
    },
  },

  data() {
    return {
      isProcessing: false,
      code: '',
      errorCodeMessage: '',
      checkCounter: 0,
      newCodeSent: false,
      pinError: false,
      mVerificationKey: this.verificationKey,
      messageTimer: null
    }
  },

  computed: {
    ...mapGetters('cartStore', ['isBusy']),
  },

  created() { },

  methods: {
    ...mapActions(['saveProfileToken']),

    handleSubmit({ formData }) {
      if (this.isProcessing === true) { return }

      this.pinError = false
      this.newCodeSent = false
      this.checkCounter = this.checkCounter + 1

      return new Promise((resolve, reject) => {
        profileProvider.methods.checkAuthPin({ VerificationKey: this.mVerificationKey, Pin: formData.code })
          .then((response) => {
            if (response.status === 200) {
              this.saveProfileToken(response.data.ProfileToken)
              this.$emit('callbackAction', {
                verifiedSuccess: true
              })
              resolve({ response: 'COMPLETED' })
            }
          })
          .catch(() => {
            this.pinError = true
            reject({ response: 'INVALID' })
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    },

    onStatusChange({ status }) {
      switch (status) {
        case 'READY':
          this.isProcessing = false
          break
        case 'VALIDATING':
          this.isProcessing = true
          break
        case 'INVALID':
          this.isProcessing = false
          break
        case 'COMPLETED':
          this.isProcessing = false
          break
      }
    },

    onCodeValidated() {
      this.errorCodeMessage = ''
      if (this.code === '') {
        this.errorCodeMessage = this.$t('App.Text_InputRequired', this.localeOverride.locale)
      }
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    checkCodePart3() {
      this.updateCode()
    },

    updateCode() {
      let self = this
      let output = ''
      output += self.$refs.codePart1 ? self.$refs.codePart1.value.trim() : ''
      output += self.$refs.codePart2 ? self.$refs.codePart2.value.trim() : ''
      output += self.$refs.codePart3 ? self.$refs.codePart3.value.trim() : ''
      self.code = output
    },

    sendNewPin() {
      this.checkCounter = 0
      this.errorCodeMessage = ''

      profileProvider.methods.fetchProfile({
        FirstName: '',
        LastName: '',
        ChannelId: 1,
        Email: this.email,
      })
        .then(response => {
          this.mVerificationKey = response.data
          this.newCodeSent = true
          this.messageTimer && clearTimeout(this.messageTimer)
          this.messageTimer = setTimeout(() => {
            this.newCodeSent = false
            clearTimeout(this.messageTimer)
          }, 3000);
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style></style>
