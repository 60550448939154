/* global ga */
/* global dataLayer */

export default {
  /**
   * Push page view
   * @param {String} page
   */
  pushTrackPageview(page = '') {
    if (typeof ga !== 'undefined' && typeof ga === 'function' && page !== '') {
      ga('send', {
        hitType: 'pageview',
        page: page.toString()
      })
    }
  },

  /**
   * Push event to Google analytics
   * @param {String} GA_category
   * @param {String} GA_action
   * @param {String} GA_label
   * @param {Number} GA_value
   */
  pushEvent(GA_category = '', GA_action = '', GA_label = '', GA_value = 1) {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'Passport_tag',
        eventCategory: ' | ' + GA_category.toString(),
        eventAction: GA_action.toString(),
        eventLabel: GA_label.toString(),
        value: parseInt(GA_value),
        noninteraction: false
      })
    }
  }
}
