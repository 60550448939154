import axios from 'axios'
import store from '../store/store'

let updateCartCancel = null

export default {
  methods: {
    /**
     * API POST CALLS
     */
    async finalizeCart(cartKey) {
      let criteria = {
        CartKey: cartKey
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/cart/${cartKey}/finalize`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    /**
     * API PUT CALLS
     */
    async updateCart(cart) {
      updateCartCancel && updateCartCancel('New request fired')
      
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/cart/${cart.CartKey}`,
        JSON.stringify(cart),
        {
          cancelToken: new axios.CancelToken(function executor(c) {
            updateCartCancel = c;
          }),
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
      })
      return response
    },
    
    /**
     * API DELETE CALLS
     */
    async deleteCart(cartKey) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/cart/${cartKey}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },
  }
}