<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="showModal"
      class="modal"
      :class="{ 'is-active': showModal }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Welcome to the virtual cowork lounge of Seats2meet</p>
          <button
            @click="close"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="title is-3 has-text-blue-dark">Here are a few tips for an amazing experience:</div>
          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">1</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              Our virtual cowork lounge works best in Chrome browser
            </div>
          </div>

          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">2</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              Are you on your phone? Then you need an app. Download the app called <strong>Jitsi</strong> (this is free!). Ready? Go in your app to your <strong>menu icon</strong> > <strong>settings</strong> > and put at the server URL: <strong>https://bla.seats2meet.com</strong>
            </div>
          </div>

          <div class="row-tip">
            <span class="wrapper-tip-icon">
              <span class="tip-number">3</span>
              <i class="font-icon s2m-icon-s2m-silhouette"></i>
            </span>
            <div>
              It is recommended to have a connection of at least 3 Mbps for high image quality. Don't worry, most connections are working fine.
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="level is-mobile">
            <div class="level-left has-padding-right">
              <div>Powered by Jitsi.<br />
                <a
                  @click="() => { $googleAnalytics.pushEvent(`Page-${$route.name}`, 'link-PoweredByJitsi', getGaLabel()); close() }"
                  href="https://magazine.seats2meet.com/jitsi-wat-is-het-en-waarom-deze-tool-gebruiken/"
                  target="_blank"
                  rel="noopener"
                >Learn more about why we use this tool!</a></div>
            </div>
            <div class="level-right has-padding-left">
              <a
                @click="() => { $googleAnalytics.pushEvent(`Page-${$route.name}`, 'button-JoinOpenLounge', getGaLabel()); close() }"
                href="https://bla.seats2meet.com/openlounge"
                target="_blank"
                rel="noopener"
                class="button is-primary"
              >
                <span>Join the open lounge</span>
                <span class="icon is-small">
                  <i class="fas fa-external-link-alt"></i>
                </span>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    onClickCancel: {
      type: Function,
      default: function() { }
    },

    sectionName: {
      type: String,
      default: ''
    }
  },

  methods: {
    ...mapMutations(['setActiveModalType']),

    getGaLabel() {
      return (this.sectionName ? this.sectionName + ' | ' : '') + 'Popup-VirtualOpenLounge'
    },

    close() {
      this.setActiveModalType('')
      this.onClickCancel()
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';
.row-tip {
  display: flex;
  margin-bottom: $gap;
  align-items: center;
  .wrapper-tip-icon {
    $iconSize: 32px;
    height: $iconSize;
    width: $iconSize;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-right: $gap;
    position: relative;
    .tip-number {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $iconSize;
      width: $iconSize;
      font-size: 16px;
      color: white;
      font-weight: $weight-bold;
    }
    .font-icon {
      display: inline-block;
      font-size: $iconSize;
      height: $iconSize;
      width: $iconSize;
      line-height: 1;
      color: $blue-dark;
      &:before {
        line-height: 1;
      }
    }
  }
}

.modal-card-foot {
  .level {
    width: 100%;
    @media screen and (max-width: 419px) {
      flex-direction: column;

      .level-left {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
