<template>
  <div
    v-if="location"
    class="sidebarNav sidebarNav-location"
    :class="{ isOpen: activeDrawerType === 'locationDetailDrawer' }"
  >
    <div class="padding header">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <a
          v-if="!checkinProcessShown"
          :key="'locationCloseButton'"
          class="close-button icon-text"
          @click="closeLocationDetailDrawer"
        >
          <span class="icon has-text-primary-dark">
            <font-awesome-icon
              :icon="['fas', 'times-circle']"
              class="fas fa-times-circle has-margin-right-half"
            />
          </span>
          <span>Close</span>
        </a>
        <button
          v-if="checkinProcessShown"
          :key="'checkinProcessBackButton'"
          class="button"
          @click="cancelProcess"
        >
          <span class="icon has-text-primary-dark">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>Back</span>
        </button>
      </transition>
    </div>
    <div class="wrapper">
      <transition
        name="slide-fade"
        mode="out-in"
      >
        <div
          v-if="checkinProcessShown"
          :key="'checkingprocess'"
        >
          <div class="padding has-text-centered">
            <p class="locationTitle title is-5 no-margin-bottom">{{ location.Name }}</p>
          </div>
          <checkin-process
            class="column is-full"
            @cancelProcess="cancelProcess"
            :openingHours="openingHours"
            :location="location"
            :checkins="checkins"
          ></checkin-process>
        </div>
        <div
          v-else
          :key="'locationdetail'"
          style="overflow-x: hidden;"
        >
          <div class="collage">
            <figure class="image is-5by3">
              <img
                src="@/assets/img/spacer5_3.gif"
                :style="{
                  backgroundImage:
                    'url(' +
                    $options.filters.getLocationImage(
                      location.Image,
                      location.Id,
                      640
                    ) +
                    ')'
                }"
              />
            </figure>
          </div>
          <div class="padding">
            <p class="locationTitle title is-5 no-margin-bottom">{{ location.Name }}</p>
            {{ location.Address }}, {{ location.City }}
            <div
              v-show="!isLoading"
              class="has-margin-bottom"
            >
              <i class="far fa-clock has-margin-right-half has-text-primary-dark"></i>
              <strong v-show="!locationOpen">
                <a
                  class="is-link icon-text"
                  :href="locationUrl"
                  target="_blank"
                >
                  <span>Contact us</span>
                  <span class="icon has-text-primary-dark">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                  </span>
                </a>
              </strong>
              <span v-show="locationOpen">
                <span>{{ openingHours.MinMinutes | minutesToTime }}</span>
                <span>-</span>
                <span>{{ openingHours.MaxMinutes | minutesToTime }}</span>
              </span>
            </div>
            <div class="columns columns-checkinsStats">
              <div
                v-if="mayCheckInOnLocation"
                class="column has-text-centered"
              >
                <button
                  class="button is-medium is-primary"
                  @click="checkinProcessShown = !checkinProcessShown"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'check']" />
                  </span>
                  <span>Check-in</span>
                </button>
              </div>
              <div
                v-if="checkins.length > 0 && !checkinFilterSearchString"
                @click="gotoNetworkPage"
                class="column column-checkinsStats has-text-centered"
              >
                <div class="statsWrapper">
                  <span class="title mr-2">
                    <span>{{ checkins.length }}</span>
                  </span>
                  <span class="subtitle">Checkin(s) today</span>
                </div>
              </div>
            </div>

            <div
              v-if="checkinFilterSearchString"
              class="has-margin-top has-margin-bottom has-text-weight-bold"
            >
              Based on your search we found the following people on this
              location
            </div>

            <div
              v-show="checkins.length > 0"
              class="checkins"
            >
              <transition
                name="fade"
                mode="out-in"
                tag="div"
                class="has-text-centered"
              >
                <div
                  :key="checkinIndex"
                  class="columns-checkins columns is-multiline"
                >
                  <div
                    v-for="(checkin, index) in checkins.slice(
                      checkinIndex,
                      checkinIndex + 2
                    )"
                    :key="'c_' + index"
                    class="column is-full is-size-7-mobile"
                  >
                    <checkin-item
                      :checkin="checkin"
                      :showTime="showCheckinTime"
                    ></checkin-item>
                  </div>
                </div>
              </transition>
              <span
                class="nav-prev icon is-large"
                @click="previousCheckin"
                v-show="checkinIndex + 2 > 2"
                style="display: none;"
              >
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  size="3x"
                />
              </span>
              <span
                class="nav-next icon is-large"
                @click="nextCheckin"
                v-show="checkinIndex + 2 < checkins.length"
                style="display: none;"
              >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  size="3x"
                />
              </span>
            </div>

            <div class="contactWrapper padding_vertical">
              <strong>Contact information</strong>
              <br />
              <div v-if="location.PhoneNumber !== ''">
                <font-awesome-icon
                  :icon="['fas', 'phone']"
                  class="has-margin-right-half has-text-primary-dark"
                />
                {{ location.PhoneNumber }}
              </div>
              <div v-if="location.Email !== ''">
                <font-awesome-icon
                  :icon="['fas', 'envelope']"
                  class="has-margin-right-half has-text-primary-dark"
                />
                {{ location.Email }}
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="has-text-centered btn-bottom">
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="!checkinProcessShown"
            class="buttons is-centered"
          >
            <router-link
              v-if="checkins.length"
              key="whosInHouse"
              :to="{
                name: 'NetworkLocation',
                params: { locationId: selectedLocation }
              }"
              class="button is-primary is-marginless-bottom"
            >
              <span>See who else is here</span>
            </router-link>
            <a
              key="locationInfoButton"
              :href="locationUrl"
              target="_blank"
              class="button is-info is-marginless-bottom"
            >
              <span>Location information</span>
            </a>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/utils/browserStorage'
import { mapGetters } from 'vuex'
import checkinProvider from '../../providers/checkin'
import locationProvider from '../../providers/location'
import CheckInProcess from '@/components/CheckInProcess'
import matchProvider from '@/providers/match'
import openinghoursProvider from '../../providers/openinghours'

export default {
  components: {
    'checkin-process': CheckInProcess
  },

  data() {
    return {
      checkins: [],
      checkinProcessShown: false,
      location: null,
      nextIcon:
        '<span class="icon"><i class="fas fa-chevron-right"></i></span>',
      prevIcon: '<span class="icon"><i class="fas fa-chevron-left"></i></span>',
      openingHours: {
        NrOfLocations: 0,
        Start: this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00',
        End: this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00',
        MaxMinutes: 0,
        MinMinutes: 0
      },
      checkinIndex: 0,
      showCheckinTime: true,
      checkinFilterSearchString: this.$store.getters.getCheckinSearchFilters
        .searchString
    }
  },

  computed: {
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
      currentCheckin: 'getCurrentCheckin',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      selectedLocation: 'getSelectedLocation'
    }),

    locationUrl() {
      let langPrefix = 'en'
      if (
        getCookie('CultureString') !== null &&
        getCookie('CultureString')
          .toLowerCase()
          .indexOf('nl-') !== -1
      ) {
        langPrefix = 'nl'
      }

      return this.location === null ? '' : `${process.env.VUE_APP_S2M_BASE_URL}/${langPrefix}/locations/${this.location.Id}/` + this.location.Name.toLowerCase() // converts to lower case
        .normalize("NFD") // normalizes accented characters (For example: á --> a)
        .replace(".com", " ") // replaces .com with a space
        .replace("&", "and") // replaces & with 'and'
        .replace(/[^\w -]+/g, "") // removes all special characters except spaces and hyphens
        .replace(/ +/g, "-") // condenses consecutive spaces to a single hyphen
        .replace(/-+/g, "-"); // condenses consecutive hyphens to a single hyphen
    },

    mayCheckInOnLocation() {
      return this.currentCheckin === null
        || (this.currentCheckin
          && this.currentCheckin.LocationId === 0
          && this.currentCheckin.EventId === 0
          && this.locationOpen)
    },

    locationOpen() {
      let isOpen = false

      let currentTime = this.$options.filters.timeToMinutes(new Date())
      if (
        this.openingHours.NrOfLocations > 0 &&
        currentTime >= this.openingHours.MinMinutes &&
        currentTime <= this.openingHours.MaxMinutes
      ) {
        isOpen = true
      }
      return isOpen
    }
  },

  watch: {
    location() {
      let self = this
      this.loadLocationOpeningHours(this.selectedLocation)
    },

    selectedLocation() {
      if (this.selectedLocation > 0) {
        this.getLocationData()
      }
    }
  },

  mounted() {
    this.getLocationData()
    this.getLocationCheckins()
  },

  created() {
    // Body tag
    document.body.style.overflow = 'hidden'
    // HTML tag
    document.documentElement.style.overflow = 'hidden'
  },

  beforeDestroy() {
    // Body tag
    document.body.style.overflow = ''
    // HTML tag
    document.documentElement.style.overflow = ''
  },

  methods: {
    cancelProcess() {
      this.checkinProcessShown = false
      this.getLocationCheckins()
    },
    getLocationData() {
      let self = this
      locationProvider.methods
        .getLocationByLocationId(this.selectedLocation)
        .then(response => {
          self.location = response.data
        })
    },

    getLocationCheckins() {
      if (!this.checkinFilterSearchString) {
        this.showCheckinTime = true
        // Get all location checkins
        checkinProvider.methods
          .getLocationCheckins(this.selectedLocation, '', 0, 0)
          .then(response => {
            this.checkins = response.data.Results

            if (this.checkins && this.checkins.length > 0) {
              this.checkinIndex = 0
            }
          })
      } else {
        // Get matched checkins based on checkin Filter Search String
        this.showCheckinTime = false
        matchProvider.methods.cancelGetMatches()
        matchProvider.methods
          .getMatches(
            this.checkinFilterSearchString,
            0,
            0,
            1,
            200,
            '0',
            0,
            0,
            this.selectedLocation
          )
          .then(response => {
            let arr = []
            response.data.Results.map(match => {
              arr.push({
                ProfileId: match.ProfileId,
                ProfileImage: match.Image,
                ProfileName: match.Name,
                Tags: match.Tags,
                WorkingOn: ''
              })

              this.checkins = arr
              if (this.checkins && this.checkins.length > 0) {
                this.checkinIndex = 0
              }
            })
          })
          .catch(e => { })
          .then(() => {
            self.isLoading = false
          })
      }
    },

    gotoNetworkPage() {
      this.$router.push({
        name: 'NetworkLocation',
        params: { locationId: this.selectedLocation }
      })
    },

    nextCheckin() {
      this.checkinIndex = this.checkinIndex + 2
    },

    previousCheckin() {
      this.checkinIndex = this.checkinIndex - 2
    },

    closeLocationDetailDrawer() {
      this.checkinIndex = 0
      this.checkins = []
      this.$store.commit('setSelectedLocation', 0)
      this.$store.commit('setActiveDrawerType', '')
    },

    getUrl(location) {
      let langPrefix = 'en'
      if (
        getCookie('CultureString') !== null &&
        getCookie('CultureString')
          .toLowerCase()
          .indexOf('nl-') !== -1
      ) {
        langPrefix = 'nl'
      }
      return `${process.env.VUE_APP_S2M_BASE_URL}/${langPrefix}/locations/${location.Id}/${location.UrlName}`
    },

    async loadLocationOpeningHours(locationId) {
      this.isLoading = true
      try {
        const response = await openinghoursProvider.methods.getOpeningHours({
          date: new Date(),
          locationId: locationId,
          meetingtypeId: 2
        })
        if (response.status === 200) {
          this.openingHours = response.data
        }
      }
      catch (err) {
        return null
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarNav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  width: 0;
  z-index: 31;
  @include box-shadow();
  // border-left: 5px solid $yellow;

  &.isOpen {
    width: 420px;
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }
  }

  .header {
    .close-button {
      font-weight: bold;
      color: darken(lightgray, 20%);
      &:hover {
        color: darken(lightgray, 50%);
      }
    }
  }

  .wrapper {
    width: 420px;
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }
  }
}

.sidebarNav-location {
  padding-bottom: $gap * 3;
  &.isOpen {
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }
  }
  .wrapper {
    @media screen and (max-width: 500px) {
      width: 100%;
      max-width: 420px;
    }
    max-height: 85%;
    overflow-x: hidden;
    img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $grey-light;
    }

    .locationTitle {
      margin-bottom: 0px;
    }

    .columns-checkinsStats {
      align-items: center;
      .box {
        padding: 10px;
      }

      .column-checkinsStats {
        cursor: pointer;
      }
    }
    .checkins {
      padding: 0 $gap * 2;
      position: relative;
      .columns-checkins {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .column {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .nav-prev,
      .nav-next {
        cursor: pointer;
        color: $green;
        left: -10px;
        position: absolute;
        top: 85px;
        z-index: 1;
        &.icon {
          width: 2rem;
        }
        &:hover {
          color: $cyan;
          background: rgba(white, 0.5);
        }
      }
      .nav-next {
        left: auto;
        right: -10px;
      }
    }
  }
  .btn-bottom {
    bottom: $gap;
    left: 0;
    position: absolute;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .buttons {
      .button {
        flex-grow: 1;
        font-size: 96%;
      }
    }
  }
}
</style>
