import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    async answerQuestion(questionId, answer = '', url = '') {
      let criteria = {
        QuestionId: questionId,
        Text: answer,
        Url: url
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/question/answer`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async setBestAnswer(answerId) {
      let criteria = {
        AnswerId: answerId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/question/setanswer/` + answerId,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async upvoteAnswer(answerId) {
      let criteria = {
        AnswerId: answerId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/question/answer/` +
          answerId +
          `/upvote`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    },

    async downvoteAnswer(answerId) {
      let criteria = {
        AnswerId: answerId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/question/answer/` +
          answerId +
          `/downvote`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            token: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      )
      return response
    }
  }
}
