<template>
  <div
    v-if="isInitiated"
    class="processWrapper"
  >
    <div
      v-if="userAlreadyCheckedIn || noAvailablity"
      class
    >
      <div class="has-text-centered">
        <div
          v-if="userAlreadyCheckedIn"
          class="notification is-warning has-shadow"
        >
          <span class="icon is-large is-left">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span>You're already checked-in</span>
        </div>

        <div
          v-if="noAvailablity"
          class="notification is-warning has-shadow"
        >
          <span class="icon is-large is-left">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span>No available workspaces found</span>
        </div>
      </div>
    </div>

    <div v-if="!userAlreadyCheckedIn && !noAvailablity">
      <div v-if="stepIndex === 0">
        <div class="setTimeWrapper">
          <div class="has-text-centered">
            <img
              src="@/assets/img/icons/clock.png"
              class
              alt="connect"
            />
            <h3 class="title is-4 has-text-centered has-text-blue-dark">What time are you leaving?</h3>
          </div>
        </div>

        <h3 class="title is-5 has-text-centered">
          <span class="control normal-text">
            {{
          mStartTime | minutesToTime
          }}
          </span>
          <span class="control normal-text">-</span>
          <span class="control normal-text">{{ mEndTime | minutesToTime }}</span>
        </h3>
        <TimePicker
          @updateEndTime="setEndTime"
          :minTime="mMinTime"
          :maxTime="maxTime"
          :startTime="mStartTime"
          :endTime="mEndTime"
          class="is-large"
        ></TimePicker>
        <hr class="separator" />
        <div class="has-text-right">
          <a
            @click="cancelProcess"
            v-if="!showSpinner"
            class="button is-warning has-margin-right"
          >Cancel</a>
          <a
            v-on:click="getAvailableWorkspaces()"
            class="button is-primary"
            :class="{ 'is-loading': showSpinner }"
            :disabled="showSpinner"
          >
            <span>NEXT</span>
            <span class="icon">
              <i class="far fa-arrow-alt-circle-right"></i>
            </span>
          </a>
        </div>
      </div>

      <div v-if="stepIndex === 1">
        <div
          v-if="showWorkspaces"
          class="worspaceOptiosWrapper"
        >
          <div class="has-text-centered">
            <img
              src="@/assets/img/icons/bestversionofyou.png"
              alt="best version of yourself"
            />
          </div>
          <h3 class="title is-4 has-text-centered has-text-success">Choose your workspace</h3>
          <hr class="separator" />
          <Message class="c-message is-link">
            <img
              src="@/assets/img/sc.png"
              title="Social capital"
            />
            <strong>
              By checking in you pay with your presence, your knowledge, your
              expertise or just being open for connection with our valuable
              network. We call this Social Capital.
            </strong>
          </Message>
          <hr class="separator" />
          <table class="seatTypeOptions">
            <tr
              v-for="(workspace, index) in workspaces"
              :key="index"
            >
              <td class="normal-text">
                <span>{{ workspace.SpaceName }}</span>
              </td>
              <td
                nowrap
                class="td-price normal-text"
              >
                <div>
                  <img
                    v-if="workspace.PriceTotal === 0"
                    src="@/assets/img/sc.png"
                    title="Social capital"
                  />
                  <span v-html="price(workspace.PriceTotal)"></span>
                </div>
              </td>
              <td class="has-text-right">
                <button
                  @click="chooseWorkspace(workspace)"
                  class="button is-primary"
                  v-bind:class="{ 'is-loading': isBusy && selectedWorkspaceId === workspace.SpaceId }"
                  :disabled="isBusy"
                >
                  <span>Select</span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="stepIndex === 2">
        <ProfileHeader
          :id="currentCheckin.ProfileId"
          :name="getGreetingText() + ' ' + profile.FirstName"
          :image="profile.Image"
          :imageAlt="profile.FirstName"
          class="has-margin-bottom-x2"
        />

        <p class="title is-6 mb-2">Tell us what keeps you busy or has your focus</p>

        <base-bulma-field
          v-model="workingOn"
          class="mb-4"
        />
        By telling us what keeps you busy or has your focus, our algorithm connects you with experts and other professionals, events and interesting content on the day you come to co-work.
        <hr class="separator" />
        <div class="buttons is-centered">
          <!-- <a
            @click="cancelProcess"
            v-if="!showSpinner"
            class="button is-warning has-margin-right"
          >Cancel</a> -->
          <a
            @click="createReservation()"
            class="button is-primary is-large"
            :class="{ 'is-loading': isBusy }"
            :disabled="showSpinner"
          >
            <span>Check-in</span>
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="isFinalizing"
      class="has-text-centered"
    >
      <img
        v-if="isFinalizing"
        src="@/assets/img/s2m-loader-big.gif"
        class="loaderImage"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import checkinProvider from '../providers/checkin'
import reservationProvider from '../providers/reservation'
import TimePicker from './TimePicker'
import availabilityProvider from '../providers/availability'
import ProfileHeader from '@/components/partial/ProfileHeader'
import Message from './UI/Message.vue'
import BaseBulmaField from './UI/Form/BaseBulmaField.vue'

export default {
  components: {
    TimePicker,
    ProfileHeader,
    Message,
    BaseBulmaField
  },

  props: {
    locationId: { type: Number, default: 0 },
    startDate: { type: String, required: false, default: () => this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00' },
    endDate: { type: String, required: false, default: () => this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00' },
    minTime: { type: Number, required: false, default: 0 },
    maxTime: { type: Number, required: false, default: 0 },
    startTime: { type: Number, required: false, default: 0 },
    endTime: { type: Number, required: false, default: 0 }
  },
  data() {
    return {
      isFinalizing: false,
      minutesSteps: 15,
      searchKey: '',
      showSpinner: false,
      workspaces: [],
      noAvailablity: false,
      userAlreadyCheckedIn: false,
      mMinTime: this.MinTime,
      mStartTime: this.startTime,
      mEndTime: this.endTime,
      searchId: 0,
      selectedWorkspaceId: 0,

      /**
       * Track process step
       * 0 = Choose time
       * 1 = Choose workspace
       * 2 = Fill in working on
       */
      stepIndex: 0,
      workingOn: ''
    }
  },
  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      currentCheckin: 'getCurrentCheckin',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      // location: 'getLocation',
      profile: 'getProfile'
    }),
    ...mapState('cartStore', ['cart']),
    ...mapGetters('cartStore', ['isBusy']),

    showWorkspaces() {
      let show = false
      if (this.workspaces.length > 0 && !this.isFinalizing) {
        let workspace = this.workspaces[0]
        if (this.workspaces.length > 1 || workspace.PricePerSeat > 0) {
          show = true
        }
      }
      return show
    }
  },

  created() {
    let currentMinutes = this.$options.filters.timeToMinutes(new Date())
    let rounded =
      Math.ceil(currentMinutes / this.minutesSteps) * this.minutesSteps
    this.mStartTime = this.startTime < rounded ? rounded : this.startTime
    this.mMinTime = rounded
  },

  methods: {
    ...mapMutations(['setCurrentCheckin']),
    ...mapActions('cartStore', ['createCart', 'saveCart', 'finalizeCart']),
    ...mapMutations('cartStore', ['setCart']),

    getGreetingText() {
      let d = new Date()
      if (d.getHours() < 12) {
        return 'Good morning'
      } else if (d.getHours() >= 12 && d.getHours() < 18) {
        return 'Good afternoon'
      } else {
        return 'Good evening'
      }
    },

    async chooseWorkspace(workspace) {
      if (this.isBusy || this.cart !== null) { return } {
        this.selectedWorkspaceId = workspace.SpaceId
        await this.createCart({
          SearchId: this.searchId,
          LocationId: this.locationId,
          Hash: workspace.Hash
        })
        this.stepIndex = 2
        this.showSpinner = false
      }
    },

    price(price) {
      return this.$options.filters.toCurrency(
        this.$options.filters.roundToTwo(price)
      )
    },

    cancelProcess() {
      this.$emit('cancelProcess')
    },

    setEndTime(endTime) {
      this.mEndTime = Number(endTime)
    },

    /**
     * Get available workspaces
     */
    async getAvailableWorkspaces() {
      this.showSpinner = true
      this.userAlreadyCheckedIn = false
      this.noAvailablity = false
      this.searchId = 0

      // First check for overlap
      try {
        const cwoResponse = await reservationProvider.methods.checkWorkspaceOverlap({
          startDate: this.startDate,
          startMinutes: this.mStartTime,
          endMinutes: this.mEndTime
        })

        if (cwoResponse.data) {
          this.showSpinner = false
          this.userAlreadyCheckedIn = true
          return
        }
      }
      catch (error) {
        this.showSpinner = false
        return
      }

      // When there is no overlap then get available workspaces
      availabilityProvider.methods.getAvailability(
        this.startDate,
        this.endDate,
        1,
        0,
        this.locationId,
        '',
        2,
        1,
        this.mStartTime,
        this.mEndTime,
        0,
        0,
        0,
        0,
      )
        .then((response) => {
          let locations = response.data.Locations

          //  Show message when there is no availability
          if (locations.length === 0) {
            this.showSpinner = false
            this.noAvailablity = true
            return
          }

          // Show available workspaces
          if (locations.length) {
            this.searchId = locations[0].SearchId
            this.workspaces = locations[0].Spaces

            // Create cart right away when there is only one free workspace available.
            if (
              this.workspaces.length === 1 &&
              this.workspaces[0].PriceTotal === 0
            ) {
              this.chooseWorkspace(this.workspaces[0])
              return
            }

            // Show available workspaces
            if (this.workspaces.length > 1) {
              this.showSpinner = false
              this.stepIndex = 1
            }
          }
        })
        .catch()
    },

    /**
     * Create reservation
     */
    async createReservation() {
      let reservation = null
      // Set working on in cart
      this.cart.Name = this.workingOn
      this.cart.Tags = this.profile.Tags.trim()
      // Set invoice address
      this.cart.InvoiceAddress.SendTo = this.profile.FirstName + ' ' + this.profile.LastName
      this.cart.InvoiceAddress.Email = this.profile.Email
      this.setCart(this.cart)

      // Save last changes to cart
      try {
        await this.saveCart()
      } catch (error) { }

      // Checkout when current checkin exists
      try {
        await checkinProvider.methods.updateCheckinLeftState(this.currentCheckin.Id).then(response => { })
      } catch (error) { }

      // Then cart to reservation
      try {
        let cfResponse = await this.finalizeCart()
        if (cfResponse.status === 201) {
          reservation = cfResponse.data
        }
      } catch (error) { }

      try {
        // Get new passport state
        await this.$store.dispatch('getPassportState')
        // Mark checkin as present
        if (reservation && reservation.Id === this.currentCheckin.ReservationId) {
          cResponse = await checkinProvider.methods.updateCheckinPresentState(this.currentCheckin.Id)
          if (response.status === 200) {
            this.setCurrentCheckin(response.data)
          }
        }
      }
      catch (error) { }

      // checkin process finished
      this.$emit('finishedProcess')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-message::v-deep {
  .message-body {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    padding: $gap;
    img {
      width: 32px;
      margin-right: $gap;
    }
  }
}

.floating-notification {
  position: fixed;
  left: 0;
  width: 100vw;

  .has-text-centered {
    width: 60vw;
    margin: 0 auto;
  }
}

img:not(.loaderImage) {
  width: 20%;
}

.seatTypeOptions {
  width: 100%;
  td {
    padding: 10px $gap;
    &:first-child {
      padding-left: 0px;
    }
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &.td-price {
      white-space: nowrap;
      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 24px;
          margin-right: $gap;
        }
      }
    }
  }
}
</style>
