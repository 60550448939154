<template>
  <div
    v-if="isInitiated"
    class="processWrapper"
  >
    <div v-if="showSpinner && eventId !== 0 && !userAlreadyCheckedIn">
      <ui-loader />
      <div class="title is-3 has-text-centered">We are checking you into the event</div>
    </div>

    <div
      v-if="userAlreadyCheckedIn"
      class="floating floating-notification"
    >
      <div class="has-text-centered">
        <div
          v-if="userAlreadyCheckedIn"
          class="notification is-warning has-shadow"
        >
          <span class="icon is-large is-left">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          <span>You're already checked-in</span>
        </div>
      </div>
      <hr class="separator">
      <div class="has-text-centered">
        <a
          class="button is-grey"
          @click="cancelProcess"
        >Close</a>
        <router-link
          class="button is-primary has-margin-left"
          v-if="!isLoggedIn"
          :to="{name: 'Login'}"
        >Login</router-link>
      </div>
    </div>
    <div v-if="profile.Key !== '' && eventId === 0">
      <div class="setTimeWrapper">
        <div class="has-text-centered">
          <img
            src="@/assets/img/icons/clock.png"
            class="static-image"
            alt="connect"
          >
          <hr class="separator">
          <h3 class="title is-4 has-text-centered has-text-blue-dark">What time are you leaving?</h3>
        </div>
      </div>
      <h3 class="title is-5 has-text-centered">
        I'll be here until:
        <span class="control normal-text">{{ mStartTime | minutesToTime }}</span>
        <span class="control normal-text">-</span>
        <span class="control normal-text">{{ mEndTime | minutesToTime }}</span>
      </h3>

      <time-picker
        @updateEndTime="setEndTime"
        :minTime="mMinTime"
        :maxTime="maxTime"
        :startTime="mStartTime"
        :endTime="mEndTime"
      ></time-picker>

      <hr class="separator">
      <div class="has-text-right">
        <a
          @click="cancelProcess"
          class="button is-grey has-margin-right"
        >Cancel</a>
        <a
          v-on:click="directCheckIn()"
          class="button is-primary"
          :class="{ 'is-loading': showSpinner }"
          :disabled="showSpinner"
        >
          <span>NEXT</span>
          <span class="icon">
            <i class="far fa-arrow-alt-circle-right"></i>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus/event-bus'
import checkinProvider from '../providers/checkin'
import eventProvider from '../providers/event'
import TimePicker from './TimePicker'
import { mapGetters } from 'vuex'

export default {
  components: {
    'time-picker': TimePicker
  },
  props: {
    locationId: { type: Number, required: false, default: 0 },
    reservationId: { type: Number, required: false, default: 0 },
    startDate: { type: String, required: false, default: () => this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00' },
    endDate: { type: String, required: false, default: () => this.$options.filters.dateObjectIsoDateString(new Date()) + 'T13:00:00' },
    eventId: { type: Number, required: false, default: 0 },
    minTime: { type: Number, required: false, default: 0 },
    maxTime: { type: Number, required: false, default: 0 },
    startTime: { type: Number, required: false, default: 0 },
    endTime: { type: Number, required: false, default: 0 },
    checkins: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      latitude: 0,
      longitude: 0,
      userAlreadyCheckedIn: false,
      showSpinner: false,
      doneCheckin: false,
      isNow: false,
      minutesSteps: 15,
      mMinTime: this.minTime,
      mStartDate: this.startDate,
      mEndDate: this.endDate,
      mStartTime: this.startTime,
      mEndTime: this.endTime,
      dayDiff: 0
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
      foundCheckin: 'getCheckin',
      currentCheckin: 'getCurrentCheckin'
    }),

    checkinType() {
      if (this.eventId !== 0) {
        return 'Event'
      }
      if (this.locationId !== 0) {
        return 'Location'
      }
      if (this.reservationId !== 0) {
        return 'Reservation'
      }
      return 'Network'
    }

  },

  watch: {
    // currentCheckin(newVal, oldVal) {
    //   if (typeof newVal !== 'undefined' && newVal !== null) {
    //     if (this.eventId > 0) {
    //       this.directCheckIn()
    //     }
    //   } else {
    //     if (this.eventId > 0) {
    //       this.directCheckIn()
    //     }
    //   }
    // }
  },

  created() {
    if (this.locationId === 0 && this.eventId === 0) {
      this.getLatLon()
    }
    this.dayDiff = this.checkIfIsToday()
    this.isNow = this.checkIsNow()

    let currentMinutes = this.$options.filters.timeToMinutes(new Date())
    let rounded = Math.ceil(currentMinutes / this.minutesSteps) * this.minutesSteps
    this.mMinTime = rounded

    if (this.eventId > 0) {
      this.directCheckIn()
    } else {
      this.mStartTime = this.startTime < rounded ? rounded : this.startTime
    }
  },

  methods: {
    checkIfIsToday() {
      return this.$options.filters.daysBetweenCurrentDate(new Date(this.mStartDate))
    },

    checkIsNow() {
      let currentMinutes = this.$options.filters.timeToMinutes(new Date())
      let isNow = false

      if (this.dayDiff === 0 && currentMinutes >= this.minTime && currentMinutes <= this.maxTime) {
        isNow = true
      }
      return isNow
    },

    cancelProcess() {
      this.$emit('cancelProcess')
    },

    getLatLon() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.processPosition)
      }
    },

    processPosition(position) {
      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude

      this.$store.commit('setLatitude', position.coords.latitude)
      this.$store.commit('setLongitude', position.coords.longitude)
    },

    setEndTime(endTime) {
      this.mEndTime = Number(endTime)
    },

    directCheckIn() {
      let self = this

      if (this.doneCheckin) { return }

      let workingOn = ''
      let latitude = 0
      let longitude = 0
      this.showSpinner = true

      if (this.eventId !== 0) {
        this.processEventRegistatrion()
      } else {
        if (this.isNow && this.currentCheckin) {
          checkinProvider.methods.updateCheckinLeftState(this.currentCheckin.Id)
            .then(response => {
              self.processCheckin(latitude, longitude, workingOn)
            })
            .catch(e => { })
            .then(() => { })
        } else {
          self.processCheckin(latitude, longitude, workingOn)
        }
      }
    },

    /**
     * Post new event registration
     */
    processEventRegistatrion() {
      eventProvider.methods.postEventRegistration(this.eventId)
        .then(async (response) => {
          try {
            let checkinResponse = false
            let eventStateResponse = await eventProvider.methods.getEventState(response.data.EventId)

            if (eventStateResponse.status === 200) {
              checkinResponse = await checkinProvider.methods.getCheckinById(eventStateResponse.data.ProfileCheckedIn)
            }

            if (checkinResponse !== false && checkinResponse.status === 200) {
              this.$store.commit('setCurrentCheckin', checkinResponse.data)
            }
          }
          catch { }

          this.$emit('finishedProcess')
          EventBus.$emit('checkinSucceeded', response.data)
        })
        .catch(e => {
          if (e.response.status === 403 || e.response.status === 409) {
            this.userAlreadyCheckedIn = true
          }
        })
        .finally(() => {
          this.showSpinner = false
          this.doneCheckin = true
        })
    },


    /**
     * Post location or netwerk checkin
     */
    processCheckin(latitude, longitude, workingOn) {
      checkinProvider.methods.postCheckin({
        Type: this.checkinType,
        ItemId: this.$props.eventId,
        StartDate: this.mStartDate,
        StartMinutes: this.mStartTime,
        EndDate: this.mEndDate,
        EndMinutes: this.mEndTime,
        Latitude: latitude,
        Longitude: longitude,
        Status: workingOn
      })
        .then(response => {
          if (this.isNow) {
            this.$store.commit('setCurrentCheckin', response.data)
          }
          this.$emit('finishedProcess')
          EventBus.$emit('checkinSucceeded', response.data)
        })
        .catch(e => {
          if (e.response.status === 403 || e.response.status === 409) {
            this.userAlreadyCheckedIn = true
          }
        })
        .finally(() => {
          this.showSpinner = false
          this.doneCheckin = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 20%;
}
</style>
