import Vue from 'vue'
import axios from 'axios'
import store from '../store/store'
import { eraseCookie, getCookie, setCookie } from '../utils/browserStorage'

export default {
  methods: {
    async authorizeUser(email, password) {
      var criteria = {
        email: email,
        password: password,
        machineToken: getCookie('mt')
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/login`,
        JSON.stringify(criteria),
        {
          params: {
            minutes: Vue.options.filters.timeToMinutes(new Date())
          },
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async verifyProfileToken(profileToken) {
      if (profileToken) {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/Login/verify/token`,
          '',
          {
            params: {
              minutes: Vue.options.filters.timeToMinutes(new Date())
            },
            headers: {
              'Content-type': 'application/json',
              apiToken: process.env.VUE_APP_API_KEY,
              profileToken: profileToken,
              machineToken: getCookie('mt')
            }
          }
        )
        return response
      }
    },

    async resetPassword(email) {
      if (email) {
        let criteria = {
          ChannelId: 1,
          Email: email,
          LocalePrefix: 'en',
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/login/reset`,
          JSON.stringify(criteria),
          {
            headers: {
              'Content-type': 'application/json',
              apiToken: process.env.VUE_APP_API_KEY,
              token: process.env.VUE_APP_API_KEY,
              profileToken: store.getters.getProfileToken
            }
          }
        )
        return response
      }
    },

    async changePassword({
      Pin,
      Password,
      Token = ''
      } = {}) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/login/changepassword`,
        {
          Password,
          Token,
          Pin,
        },
        {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
          }
        }
      )
      return response
    }
  }
}
