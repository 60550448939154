import axios from 'axios'
import store from '../store/store'

export default {
  methods: {
    checkIfProfileOnMailList() {
      let profile = store.getters.getProfile

      return axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/api/mailchimp?email=${profile.Email}`,
          {
            headers: {
              'Content-type': 'application/json',
              token: process.env.VUE_APP_API_KEY,
              profileToken: store.getters.getProfileToken
            }
          }
        )
        .then(response => {
          return response.data
        })
    },

    setProfileOnMailList() {
      let profile = store.getters.getProfile
      let criteria = {
        Email: profile.Email,
        FirstName: profile.FirstName,
        LastName: profile.LastName
      }

      return axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/mailchimp`,
          JSON.stringify(criteria),
          {
            headers: {
              'Content-type': 'application/json',
              token: process.env.VUE_APP_API_KEY,
              profileToken: store.getters.getProfileToken
            }
          }
        )
        .then(response => {
          return response.data
        })
    },

    removeProfileFromMailList() {
      let profile = store.getters.getProfile

      return axios
        .delete(
          `${process.env.VUE_APP_API_BASE_URL}/api/mailchimp?email=${profile.Email}`,
          {
            headers: {
              'Content-type': 'application/json',
              token: process.env.VUE_APP_API_KEY,
              profileToken: store.getters.getProfileToken
            }
          }
        )
        .then(response => {
          return response.data
        })
    }
  }
}
