<template>
  <div class="login-fields-component">
    <div v-if="showLogginInput">
      <div v-if="!isLoggedIn && isInitiated">
        <h3 class="title is-3 has-text-blue-dark">Log in</h3>

        <Message
          class="is-warning"
          v-if="validationCounter > 0 && loginErrorMessage !== ''"
        >{{ loginErrorMessage }}</Message>

        <div class="field">
          <form
            onSubmit="return false;"
            autocomplete="off"
          >
            <label class="label">Email</label>
            <div class="control">
              <input
                class="input is-medium"
                ref="email"
                autocomplete="none"
                aria-autocomplete="both"
                v-bind:class="{ 'is-success': (emailNotEmpty && !emailInvalid), 'is-danger': showEmailWarning }"
                type="email"
                placeholder="Email"
                v-model="email"
                maxlength="64"
                @keyup.enter="authorizeUser"
              >
            </div>
            <p v-if="showEmailWarning">
              <span
                class="help"
                v-bind:class="{ 'is-danger': showEmailWarning }"
              >This email is invalid</span>
            </p>
          </form>
        </div>

        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input
              class="input is-medium"
              type="password"
              autocomplete="none"
              aria-autocomplete="both"
              v-bind:class="{ 'is-danger': showPasswordWarning } "
              placeholder="Password"
              ref="password"
              v-model="password"
              maxlength="64"
              @keyup.enter="authorizeUser"
            >
          </div>
          <p v-if="showPasswordWarning">
            <span
              class="help"
              v-bind:class="{ 'is-danger': showPasswordWarning }"
            >Password is empty</span>
          </p>
        </div>
        <div class="buttons is-right">
          <a
            @click="authorizeUser"
            class="button is-medium is-primary"
            v-bind:class="{ 'is-loading': showSpinner }"
          >Log in</a>
        </div>
      </div>

      <hr />
      <div class="content">
        <div class="block">
          Forgot your password? <a @click="setShowResetPasswordInput">Request new password</a>
        </div>
        <div class="block">
          Don't you have an profile yet? <router-link :to="{ name: 'Register' }">Register here</router-link>
        </div>
      </div>
    </div>

    <div
      class="resetPassword-fields-component"
      v-if="showResetPasswordInput"
    >
      <request-new-password-flow :linkActionLogin="setShowLoginInput" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import loginProvider from '../providers/login'
import RequestNewPasswordFlow from './Auth/RequestNewPasswordFlow.vue'
import Message from './UI/Message.vue'

export default {
  components: { Message, RequestNewPasswordFlow },
  data() {
    return {
      email: '',
      isSending: false,
      password: '',
      base_url: process.env.VUE_APP_BASE_URL,
      s2m_base_url: process.env.S2M_BASE_URL,
      showLogginInput: true,
      showResetPasswordConfirm: false,
      showResetPasswordInput: false,
      showSpinner: false,
      validationCounter: 0,
      loginFailed: false,
      loginFailCode: 0
    }
  },
  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      profile: 'getProfile',
      profileToken: 'getProfileToken'
    }),
    emailEmpty() {
      return this.email === ''
    },
    emailNotEmpty() {
      return this.email !== ''
    },
    emailInvalid() {
      return !/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.email)
    },
    showEmailWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && (this.emailEmpty || this.emailInvalid)) {
        showWarning = true
      }

      return showWarning
    },
    passwordEmpty() {
      return this.password === ''
    },
    showPasswordWarning() {
      let showWarning = false

      if (this.validationCounter > 0 && this.password === '') {
        showWarning = true
      }

      return showWarning
    },
    isLoggedIn() {
      let loggedIn = false

      if (this.profileToken !== null && this.profileToken.length > 0) {
        loggedIn = true
      }

      return loggedIn
    },

    loginErrorMessage() {
      if (this.loginFailCode === 403) {
        return `Your profile is not activated yet. Please click on the link in the passport activation mail to activate your profile or do a new password request via 'Forgot your password?'.`
      }
      if (this.loginFailCode === 404) {
        return `Email address or passord is incorrect`
      }
      return ''
    }
  },
  watch: {},

  created() {
    if (this.$route.query.v && this.$route.query.v === 'pwreset') {
      this.setShowResetPasswordInput()
    }
  },

  methods: {
    authLogin(auth) {
      let callbackData = this.$store.getters.getLoginCallback

      if (callbackData !== null && typeof callbackData !== 'undefined') {
        window.location.href = `${process.env.S2M_BASE_URL}/auth/${auth}?referer=${encodeURIComponent(process.env.VUE_APP_BASE_URL + callbackData.path)}`
      } else {
        window.location.href = `${process.env.S2M_BASE_URL}/auth/${auth}?referer=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}`
      }
    },

    authorizeUser() {
      this.loginFailed = false
      this.loginFailCode = 0

      // Strip HTML tags
      this.email = this.$options.filters.stripHtml(this.email)
      this.password = this.$options.filters.stripHtml(this.password)

      let validationCounter = this.validationCounter
      validationCounter++
      this.validationCounter = validationCounter

      if (this.emailNotEmpty && !this.emailInvalid && !this.passwordEmpty) {
        this.showSpinner = true

        loginProvider.methods
          .authorizeUser(this.email, this.password)
          .then(response => {
            this.showSpinner = false
            if (response.data.StatusCode === 1) {
              this.$store.state.passportState.IsLoading = true
              this.$store.dispatch('saveProfileToken', response.data.ProfileToken)

              this.$emit('isLoggedIn')
            }
            else {
              if (
                response.data.StatusCode === -1 ||
                response.data.StatusCode === -3
              ) {
                this.loginFailCode = 404
              }

              // Not yet activated
              else if (response.data.StatusCode === -2) {
                this.loginFailCode = 403
              }
              this.loginFailed = true
            }
          })
          .catch(e => {
            this.showSpinner = false
            this.loginFailCode = e.response.status
            if (e.response.status === 401 || e.response.status === 404) {
              this.loginFailed = true
              this.loginFailCode = 404
            }
          })
      }
    },

    logOff() {
      // logoffProvider.methods.logOff()
      //   .then(response => { })
      //   .catch(e => { })
      //   .finally(() => {
      this.$store.dispatch('logoff')
      // })
    },

    setShowLoginInput() {
      this.showResetPasswordInput = false
      this.showResetPasswordConfirm = false
      this.showLogginInput = true
    },

    setShowResetPasswordInput() {
      this.showResetPasswordInput = true
      this.showResetPasswordConfirm = false
      this.showLogginInput = false
    },

    // setShowResetPasswordConfirm() {
    //   this.showResetPasswordInput = true
    //   this.showResetPasswordConfirm = true
    //   this.showLogginInput = false
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.is-divider,
.is-divider-vertical {
  // border-color: ;
  &::after {
    padding: 0.1rem 0.8rem;
    font-size: $size-5;
    font-weight: $weight-bold;
  }
}

.social-buttons-content {
  button {
    width: 100%;

    @media screen and (max-width: $tablet) {
      width: 60%;
    }

    &.buttonFacebook {
      @include buttonStyle(#3c5193);
    }
    &.buttonLinkedIn {
      @include buttonStyle(#0877b5);
    }
    &.buttonGoogle {
      @include buttonStyle(#146fb6);
    }
  }
}
</style>
