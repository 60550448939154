<template>
  <div class="buttons is-right">
    <transition
      name="fade"
      mode="out-in"
    >
      <a
        v-if="!isSaving && !hideCancelButton"
        @click="onClickCancel"
        class="button is-ghost has-margin-right"
        :disabled="isSaving"
      >{{ buttonLabelCancel }}</a>
    </transition>
    <button
      v-if="!hideSaveButton"
      @click="onClickSave"
      class="button"
      :class="[confirmButtonCssClass, { 'is-loading': isSaving, 'is-disabled': isSaving || disableSaveButton }]"
      :disabled="isSaving || disableSaveButton"
    >
      {{ buttonLabelConfirm }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    onClickCancel: {
      type: Function,
      required: true
    },
    onClickSave: {
      type: Function,
      required: true
    },
    disableSaveButton: {
      type: Boolean,
      default: false
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    buttonLabelConfirm: {
      type: String,
      // default: 'Save'
      default: function() { return this.t('Form.Control.Save') }
    },
    confirmButtonCssClass: {
      type: String,
      default: 'is-primary'
    },
    buttonLabelCancel: {
      type: String,
      // default: 'Cancel'
      default: function() { return this.t('Form.Control.Cancel') }
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    hideSaveButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      differencesDiscovered: "versionControl/getDifferencesDiscovered"
    })
  }
}
</script>
