import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import localforage from 'localforage'
import checkinProvider from '../providers/checkin'
import profileProvider from '../providers/profile'
import logoffProvider from '../providers/logOff'
import '../filters/dateTimeFilters'
import '../filters/stringFilters'

// Stores
import articleStore from './modules/article'
import cartStore from './modules/cart'
import chatStore from './modules/chat'
import notificationStore from './modules/notification'
import signalrStore from './modules/signalr'
import modalStore from './modules/modal'
import menuStore from './modules/menu'
import reservationStore from './modules/reservation'
import cookieStore from './modules/cookieStore'

import { eraseCookie, getCookie, setCookie } from '../utils/browserStorage'

Vue.use(Vuex)

let getPassportStateToken = null

export default new Vuex.Store({
  state: {
    browser: null,
    isOutdatedBrowser: false,
    activeDrawerType: '',
    checkinSearchFilters: {
      suggestedTags: [],
      searchString: ''
    },
    activeModalType: '',
    connectionStatus: false,
    cookieExpireAfterNrDays: 100,
    countries: [],
    currentCheckin: null,
    currentHour: new Date().getHours(),
    drawerIsActive: false,
    eventsPageState: {
      events: [],
      page: 1,
      itemsPerPage: 25,
      visibleItem: 'all',
      bottom: false,
      reachedEnd: false,
      recommendedEvents: [],
      searchTerm: ''
    },
    filterReservationsMeetingtype: 0,
    filterReservationsStatus: 0,
    hasAgreedToTerms: true,
    isFirstRun: false,
    isInitiated: false,
    langPrefix: 'en',
    latitude: 0,
    locations: [],
    longitude: 0,
    loginCallback: null,
    profile: {
      Id: 0,
      Key: '',
      FullName: '',
      FirstName: '',
      LastName: '',
      Image: '',
      Email: '',
      Bio: '',
      Tags: '',
      Address: '',
      PostalCode: '',
      City: '',
      Country: '',
      Phone: '',
      FacebookAccount: '',
      LinkedInAccount: '',
      TwitterAccount: '',
      GoogleAccount: ''
    },
    passportState: {
      CurrentCheckin: null,
      IsLoading: true,
      Locations: [],
      NrOfOpenReviews: 0,
      NrOfUnreadNotifications: 0,
      HasAgreedToTerms: true,
      HasProfileImage: true,
      ShowProfileCheck: false,
      TargetAudiences: []
    },
    profileScore: null,
    profileStats: {
      Id: 0,
      NrOfCheckins: 0,
      NrOfCheckinsNetwork: 0,
      NrOfCheckinsLocation: 0,
      NrOfCheckinsEvent: 0,
      NrOfQuestions: 0,
      NrOfAnswers: 0,
      NrOfReviews: 0,
      NrOfTimesMatched: 0,
      NrOfMsReservations: 0,
      NrOfMsReservationsCancelled: 0,
      NrOfMsReservationsFinal: 0,
      NrOfWsReservations: 0,
      NrOfWsReservationsCancelled: 0,
      NrOfWsReservationsFinal: 0
    },
    profileToken: '',
    questionsPageState: {
      questions: [],
      page: 1,
      itemsPerPage: 25,
      visibleItem: 'all',
      bottom: false,
      reachedEnd: false,
      searchTerm: '',
      unsolvedOnly: true,
      tag: ''
    },
    referralUrl: null,
    selectedArticle: 0,
    selectedEvent: 0,
    selectedLocation: 0,
    selectedPageType: '',
    selectedPublicProfile: {
      profileId: 0,
      view: ''
    },
    selectedVoucher: {},
    showBanner: false,
    showIncomingQuestion: 0,
    showProfileCheck: false,
    showTagValidator: false,
    selectedQuestion: null
  },
  getters: {
    getActiveModalType: state => {
      return state.activeModalType
    },
    getCheckinSearchFilters: state => {
      return state.checkinSearchFilters
    },
    getConnectionStatus: state => {
      return state.connectionStatus
    },
    getCountries: state => {
      return state.countries
    },
    getCurrentCheckin: state => {
      return state.currentCheckin
    },
    getCurrentHour: state => {
      return state.currentHour
    },
    getCurrentLanguagePrefix: state => {
      state.langPrefix =
        getCookie('CultureString') === ''
          ? 'en'
          : getCookie('CultureString').substring(0, 2)
      return state.langPrefix
    },
    getDrawerIsActiveState: state => {
      return state.drawerIsActive
    },
    getActiveDrawerType: state => {
      return state.activeDrawerType
    },
    getFilterReservationsMeetingtype: state => {
      return state.filterReservationsMeetingtype
    },
    getFilterReservationStatus: state => {
      return state.filterReservationsStatus
    },
    getHasAgreedToTerms: state => {
      return state.hasAgreedToTerms
    },
    getIsFirstRun: state => {
      return state.isFirstRun
    },
    getIsInitiated: state => {
      return state.isInitiated
    },
    getIsLoggedIn: state => {
      if (state.profileToken.length > 0 && state.profile) {
        return true
      }
      return false
    },
    getIsMobile: state => {
      let isMobile = false

      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4)
        )
      ) {
        isMobile = true
      }

      return isMobile
    },
    getLatitude: state => {
      return state.latitude
    },
    getLocations: state => {
      return state.locations
    },
    getLongitude: state => {
      return state.longitude
    },
    getLoginCallback: state => {
      return state.loginCallback
    },
    getProfile: state => {
      return state.profile
    },
    getProfileStats: state => {
      return state.profileStats
    },
    getPassportState: state => {
      return state.passportState
    },
    getProfileToken: state => {
      return state.profileToken
    },
    getReferralUrl: state => {
      return state.referralUrl
    },
    getSelectedArticle: state => {
      return state.selectedArticle
    },
    getSelectedEvent: state => {
      return state.selectedEvent
    },
    getSelectedLocation: state => {
      return state.selectedLocation
    },
    getSelectedPageType: state => {
      return state.selectedPageType
    },
    getSelectedPublicProfile: state => {
      return state.selectedPublicProfile
    },
    getSelectedQuestion: state => {
      return state.selectedQuestion
    },
    getSelectedVoucher: state => {
      return state.selectedVoucher
    },
    getShowBanner: state => {
      return state.showBanner
    },
    getShowIncomingQuestion: state => {
      return state.showIncomingQuestion
    },
    getShowProfileCheck: state => {
      return state.showProfileCheck
    },
    getShowTagValidator: state => {
      return state.showTagValidator
    },
    getEventsPageState: state => {
      return state.eventsPageState
    }
  },
  mutations: {
    setActiveModalType: (state, newState) => {
      state.activeModalType = newState
    },
    setCheckinSearchFilters: (state, newVal) => {
      state.checkinSearchFilters = newVal
    },
    setConnectionStatus: (state, newConnectionStatus) => {
      state.connectionStatus = newConnectionStatus
    },

    setCountries: (state, countries) => {
      state.countries = countries
    },

    setCurrentCheckin: (state, newCheckin) => {
      state.currentCheckin = newCheckin
    },
    setCurrentHour: (state, newState) => {
      state.currentHour = newState
    },
    setCurrentLanguagePrefix: (state, newState = '') => {
      if (newState === '') {
        newState = getCookie('CultureString')
      }
      newState = newState === '' ? 'en' : newState.substring(0, 2)
      state.langPrefix = newState
    },
    setDrawerIsActiveState: (state, newState) => {
      state.drawerIsActive = newState
    },
    setEventsPageState: (state, newState) => {
      state.eventsPageState = newState
    },
    setActiveDrawerType: (state, newState = '') => {
      state.activeDrawerType = newState
    },
    setFilterReservationsMeetingtype: (
      state,
      newFilterReservationsMeetingtype
    ) => {
      state.filterReservationsMeetingtype = newFilterReservationsMeetingtype
    },
    setFilterReservationsStatus: (state, newFilterReservationStatus) => {
      state.filterReservationsStatus = newFilterReservationStatus
    },
    setHasAgreedToTerms: (state, newHasAgreedToTerms) => {
      state.hasAgreedToTerms = newHasAgreedToTerms
    },
    setIsFirstRun: (state, newIsFirstRun) => {
      state.isFirstRun = newIsFirstRun
    },
    setIsInitiated: (state, newIsInitiated) => {
      state.isInitiated = newIsInitiated
    },
    setLatitude: (state, newLatitude) => {
      state.latitude = newLatitude
    },
    setLocations: (state, newLocations) => {
      state.locations = []
      state.locations = newLocations
    },
    setLongitude: (state, newLongitude) => {
      state.longitude = newLongitude
    },
    setLoginCallback: (state, newState) => {
      state.loginCallback = newState
    },
    setPassportState: (state, newPassportState) => {
      newPassportState.IsLoading = false
      state.passportState = newPassportState
    },
    setProfile: (state, newProfile) => {
      state.profile = newProfile
    },
    setProfileScore: (state, newProfileScore) => {
      state.profileScore = newProfileScore
    },
    setProfileStats: (state, newProfileStats) => {
      state.profileStats = newProfileStats
    },
    setProfileToken: (state, newprofileToken) => {
      state.profileToken = newprofileToken
    },
    setReferralUrl: (state, newState) => {
      state.referralUrl = newState
    },
    setQuestionsPageState: (state, newState) => {
      state.questionsPageState = newState
    },
    setSelectedArticle: (state, newSelectedArticle) => {
      state.selectedArticle = newSelectedArticle
    },
    setSelectedEvent: (state, newSelectedEvent) => {
      state.selectedEvent = newSelectedEvent
    },
    setSelectedLocation: (state, newSelectedLocation) => {
      state.selectedLocation = newSelectedLocation
    },
    setSelectedPageType: (state, newState) => {
      return (state.selectedPageType = newState)
    },
    setSelectedPublicProfile: (state, newSelectedPublicProfile) => {
      state.selectedPublicProfile = newSelectedPublicProfile
    },
    setSelectedQuestion: (state, newSelectedQuestion) => {
      state.selectedQuestion = newSelectedQuestion
    },

    setSelectedVoucher: (state, newState) => {
      state.selectedVoucher = newState
      if (Object.keys(newState).length === 0) {
        state.activeDrawerType = ''
      } else {
        state.activeDrawerType = 'voucherDetailModal'
      }
    },
    setShowBanner: (state, newShowBanner) => {
      state.showBanner = newShowBanner
    },
    setShowIncomingQuestion: (state, newShowIncomingQuestion) => {
      state.showIncomingQuestion = newShowIncomingQuestion
    },
    setShowProfileCheck: (state, newShowProfileCheck) => {
      state.showProfileCheck = newShowProfileCheck
    },
    setShowTagValidator: (state, newShowTagValidator) => {
      state.showTagValidator = newShowTagValidator
    },

    setBrowserData: (state, newState) => {
      state.browser = newState
     }
  },

  actions: {
    checkout: (context, checkin) => {
      checkinProvider.methods.updateCheckinLeftState(checkin.Id).then(response => {
        context.commit('setCurrentCheckin', null)
        context.dispatch('getPassportState')
      })
    },

    clearCheckinSearchFilters: context => {
      context.commit('setCheckinSearchFilters', {
        suggestedTags: [],
        searchString: ''
      })
    },

    clearConnectionsStatus: context => {
      context.commit('setConnectionStatus', false)
    },
    clearIsInitiated: context => {
      context.commit('setIsInitiated', false)
    },
    clearProfile: context => {
      let profile = {
        Id: 0,
        Key: '',
        Image: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Bio: '',
        Tags: '',
        Address: '',
        PostalCode: '',
        City: '',
        Country: '',
        Phone: '',
        FacebookAccount: '',
        LinkedInAccount: '',
        TwitterAccount: '',
        GoogleAccount: ''
      }
      context.commit('setProfile', profile)
      context.dispatch('deleteFromCache', 'profile')
    },

    clearProfileToken: context => {
      context.commit('setProfileToken', '')
      context.dispatch('deleteFromCache', 'profileToken')
    },
    clearQuestions: context => {
      context.commit('setQuestions', [])
    },

    findProfileCheckin: context => {
      let profileKey = context.getters.getProfile.Key
      let checkins = context.getters.getCheckins
      let found = null

      if (
        typeof checkins !== 'undefined' &&
        checkins !== null &&
        checkins.length > 0
      ) {
        let checkin = checkins.filter(c => {
          return c.ProfileKey === profileKey && c.HasLeft === false
        })

        if (checkin.length > 0) {
          found = checkin[0]
        }
      }

      context.commit('setCheckin', found)
      context.commit('setCurrentCheckin', found)
    },

    hideAllDrawers: context => {
      context.commit('setDrawerIsActiveState', false)
    },

    saveCurrentCheckin: (context, checkin) => {
      context.commit('setCurrentCheckin', checkin)
    },

    saveProfileToken: (context, profileToken) => {
      setCookie('lt', profileToken, context.state.cookieExpireAfterNrDays, true)
      context.commit('setProfileToken', profileToken)
      // context.dispatch('saveInCache', {
      //   name: 'profileToken',
      //   value: '' // profileToken
      // })
      context.dispatch('getProfileData')
    },

    getProfileData: context => {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile`, {
          headers: {
            'Content-type': 'application/json',
            apitoken: process.env.VUE_APP_API_KEY,
            profileToken: context.getters.getProfileToken
          }
        })
        .then(response => {
          let profile = {
            Id: response.data.Id,
            Key: response.data.Key,
            Image: response.data.Image,
            FirstName: response.data.FirstName,
            FullName: response.data.FullName,
            LastName: response.data.LastName,
            Email: response.data.Email,
            Bio: Vue.options.filters.stripHtml(response.data.Bio),
            Tags: response.data.Tags,
            Address: response.data.Address,
            PostalCode: response.data.PostalCode,
            City: response.data.City,
            Country: response.data.Country,
            Phone: response.data.Phone,
            FacebookAccount: response.data.FacebookAccount,
            LinkedInAccount: response.data.LinkedInAccount,
            TwitterAccount: response.data.TwitterAccount,
            GoogleAccount: response.data.GoogleAccount
          }

          setCookie(
            'ap',
            profile.Key,
            context.state.cookieExpireAfterNrDays,
            true
          )

          context.commit('setProfile', profile)
          context.commit('setIsInitiated', true)
          // context.dispatch('saveInCache', { name: 'profile', value: profile })
          context.dispatch('getPassportState')
          context.dispatch('getBannerState')
          context.dispatch('getProfileStats')
          context.dispatch('getProfileNotifications')
          context.dispatch('chatStore/getProfileChats')
        })
        .catch(e => {
          context.commit('setIsInitiated', true)
        })
        .then(() => {
          context.commit('setIsInitiated', true)
        })
    },

    getBannerState: context => {
      let showCookie = getCookie('psb')
      if (showCookie !== '') {
        context.commit('setShowBanner', false)
      } else {
        context.commit('setShowBanner', true)
      }
    },

    getPassportState: async (context) => {
      if (getPassportStateToken) {
        getPassportStateToken.cancel('Canceled by next state request')
      }
      getPassportStateToken = axios.CancelToken.source()
      context.state.passportState.IsLoading = true
      await axios.get(`${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/state`, {
          cancelToken: getPassportStateToken.token,
          params: {
            channelId: 1,
            minutes: Vue.options.filters.timeToMinutes(new Date())
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: context.getters.getProfileToken
          }
        })
        .then(response => {
          context.commit('setPassportState', response.data)
          context.commit('setHasAgreedToTerms', response.data.HasAgreedToTerms)
          context.commit('setCurrentCheckin', response.data.CurrentCheckin)
          // context.commit('setShowProfileCheck', response.data.ShowProfileCheck)
        })
        .catch(e => {
          if (
            typeof e.response !== 'undefined' &&
            e.response.status >= 400 &&
            e.response.status < 500
          ) {
            context.dispatch('logoff')
          }
        })
        .finally(() => { })
    },

    getProfileStats: context => {
      profileProvider.methods.getProfileStats()
      .then(response => {
        context.commit('setProfileStats', response.data)
      })
    },

    logoff: context => {
      let apCookie = getCookie('ap')
      let ltCookie = getCookie('lt')

      if (apCookie !== '' || ltCookie !== '') {
        logoffProvider.methods
          .logOff()
          .then(response => { })
          .catch(e => { })
          .finally(() => {
            eraseCookie('ap')
            eraseCookie('lt')
            context.dispatch('clearProfile')
            context.dispatch('clearProfileToken')
            let t = setTimeout(function() {
              window.location.reload(true)
              clearTimeout(t)
            }, 250)
          })
      }
    },

    deleteFromCache: (context, name) => {
      localforage
        .removeItem(name)
        .then(response => {
          if (process.env.NODE_ENV === 'development') {
            console.log(name + ' removed from cache')
          }
        })
        .catch(e => {
          if (process.env.NODE_ENV === 'development') {
            console.log(e)
          }
        })
    },

    saveInCache: (context, payload) => {
      localforage
        .setItem(payload.name, payload.value)
        .then(response => {
          if (process.env.NODE_ENV === 'development') {
            console.log(name + ' stored in cache')
          }
        })
        .catch(e => {
          if (process.NODE_ENV === 'development') {
            console.log(e)
          }
        })
    },

    updateEventsPageState: (context, state) => {
      context.commit('setEventsPageState', state)
    },

    // updateQuestionsPageState: (context, state) => {
    //   context.commit('setQuestionsPageState', state)
    // },

    processBrowserDetection({ state, commit }, data) {
      commit('setBrowserData', data)
      const BROWERS_VERSION = Number(data.meta.version)
      const isOudDated = (data.isIE && BROWERS_VERSION <= 11)
      state.isOutdatedBrowser = isOudDated
      commit('reservationStore/updateIsOutdatedBrowserState', isOudDated, { root: true })
    }
  },

  /**
   * Modules
   */
  modules: {
    articleStore,
    cookieStore,
    cartStore,
    chatStore,
    notificationStore,
    signalrStore,
    menuStore,
    modalStore,
    reservationStore
  }
})
