const AboutPassport = () =>
  import(/* webpackChunkName: "route-about" */ '@/views/about/AboutPassport')
const AboutTips = () =>
  import(/* webpackChunkName: "route-about" */ '@/views/about/AboutTips')

const aboutRoutes = [
  {
    path: '/about/passport',
    name: 'AboutPassport',
    component: AboutPassport
  },
  {
    path: '/about/tips',
    name: 'AboutTips',
    component: AboutTips
  }
]

export default aboutRoutes
